// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationMenuDropdown__menuButton___Fz7IQ{background-color:rgba(0,0,0,0);border-radius:.5rem;border:2px solid rgba(0,0,0,0);color:var(--white70);outline:none;padding:.25rem}.NavigationMenuDropdown__menuButton___Fz7IQ>svg:first-child{fill:var(--white70);margin-right:2px;margin-left:10px}.NavigationMenuDropdown__menuButton___Fz7IQ:active,.NavigationMenuDropdown__menuButton___Fz7IQ:focus,.NavigationMenuDropdown__menuButton___Fz7IQ:hover{border:2px solid var(--gold)}.NavigationMenuDropdown__activeMenuButton___aHQcP{color:var(--gold)}.NavigationMenuDropdown__activeMenuButton___aHQcP>svg:first-child{fill:var(--gold)}.NavigationMenuDropdown__activeMenuButton___aHQcP>svg:nth-child(2){stroke:var(--gold)}.NavigationMenuDropdown__menuItem___l4y9Y{display:flex}.NavigationMenuDropdown__chevron___N5gTB{width:8px;height:8px;stroke:var(--white70);transform:rotate(-90deg)}.NavigationMenuDropdown__chevronUp___qhVBL{transform:rotate(90deg)}.NavigationMenuDropdown__menu___vzQMz>ul{background-color:var(--primary400);border:1px solid var(--hairline);display:flex;flex-direction:column;padding:.5rem 0}.NavigationMenuDropdown__menuLink___J9stu{color:var(--white);flex:1;padding:.5rem 1rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/layout/NavigationMenuDropdown.scss"],"names":[],"mappings":"AAAA,4CACE,8BAAA,CACA,mBAAA,CACA,8BAAA,CACA,oBAAA,CACA,YAAA,CACA,cAAA,CAEA,4DACE,mBAAA,CACA,gBAAA,CACA,gBAAA,CAGF,uJAGE,4BAAA,CAIJ,kDACE,iBAAA,CAEA,kEACE,gBAAA,CAGF,mEACE,kBAAA,CAIJ,0CACE,YAAA,CAGF,yCACE,SAAA,CACA,UAAA,CACA,qBAAA,CACA,wBAAA,CAGF,2CACE,uBAAA,CAIA,yCACE,kCAAA,CACA,gCAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CAIJ,0CACE,kBAAA,CACA,MAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": "NavigationMenuDropdown__menuButton___Fz7IQ",
	"activeMenuButton": "NavigationMenuDropdown__activeMenuButton___aHQcP",
	"menuItem": "NavigationMenuDropdown__menuItem___l4y9Y",
	"chevron": "NavigationMenuDropdown__chevron___N5gTB",
	"chevronUp": "NavigationMenuDropdown__chevronUp___qhVBL",
	"menu": "NavigationMenuDropdown__menu___vzQMz",
	"menuLink": "NavigationMenuDropdown__menuLink___J9stu"
};
export default ___CSS_LOADER_EXPORT___;
