// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CopyPricePointsModal__form___xaVsf{display:flex;flex-direction:column;row-gap:8px}.CopyPricePointsModal__form___xaVsf ul{display:flex;flex-direction:column;row-gap:8px;margin-top:-16px}.CopyPricePointsModal__form___xaVsf ul ul{margin-block:1rem}.CopyPricePointsModal__form___xaVsf label,.CopyPricePointsModal__form___xaVsf span{width:100%}.CopyPricePointsModal__form___xaVsf .CopyPricePointsModal__listingNameRow___pTiza{display:flex;gap:32px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/CopyPricePointsModal.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,qBAAA,CACA,WAAA,CAEA,uCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,gBAAA,CAEA,0CACE,iBAAA,CAIJ,mFAEE,UAAA,CAGF,kFACE,YAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "CopyPricePointsModal__form___xaVsf",
	"listingNameRow": "CopyPricePointsModal__listingNameRow___pTiza"
};
export default ___CSS_LOADER_EXPORT___;
