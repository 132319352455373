// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationsMetrics__container___HjKrK button{color:var(--white);background-color:rgba(0,0,0,0);outline:none;border:none;cursor:pointer;text-align:left;border-radius:8px}.ReservationsMetrics__container___HjKrK button:focus:focus-visible{outline:1px solid var(--gold)}.ReservationsMetrics__container___HjKrK svg{width:12px;stroke:var(--white)}.ReservationsMetrics__container___HjKrK .ReservationsMetrics__heading___HYPzk{display:flex;flex-direction:row;align-items:center;column-gap:4px;margin-bottom:4px}.ReservationsMetrics__container___HjKrK .ReservationsMetrics__count___UatqW{display:flex;align-items:center;color:var(--white);width:6.75rem;height:1.5rem;margin:0;text-wrap:nowrap;font-size:18px;font-weight:600;line-height:24px}.ReservationsMetrics__container___HjKrK .ReservationsMetrics__count___UatqW span{font-weight:600;font-size:18px;color:var(--white70)}.ReservationsMetrics__container___HjKrK .ReservationsMetrics__count___UatqW span:last-child{font-size:10px;font-weight:500;line-height:16px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/pageHeader/ReservationsMetrics.scss"],"names":[],"mappings":"AACE,+CACE,kBAAA,CACA,8BAAA,CACA,YAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAEA,mEACE,6BAAA,CAIJ,4CACE,UAAA,CACA,mBAAA,CAGF,8EACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CAGF,4EACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,aAAA,CACA,QAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAEA,iFACE,eAAA,CACA,cAAA,CACA,oBAAA,CAEA,4FACE,cAAA,CACA,eAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationsMetrics__container___HjKrK",
	"heading": "ReservationsMetrics__heading___HYPzk",
	"count": "ReservationsMetrics__count___UatqW"
};
export default ___CSS_LOADER_EXPORT___;
