// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PricePointItem__doodadContainer___t6tuo{border:1px solid var(--panelBlue);background-color:var(--panelBlue);border-radius:16px;padding:16px 12px;display:grid;place-items:center flex-start;grid-template-columns:1fr 1fr;row-gap:4px;width:100%}.PricePointItem__doodadContainer___t6tuo:hover{border:1px solid var(--darkGrey100)}.PricePointItem__doodadContainer___t6tuo .PricePointItem__price___Q9tH_{grid-row:span 2;justify-self:flex-end}.PricePointItem__doodadContainer___t6tuo .PricePointItem__activeDays___NUbXx{color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/PricePointItem.scss"],"names":[],"mappings":"AAAA,yCACE,iCAAA,CACA,iCAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,6BAAA,CACA,WAAA,CACA,UAAA,CAEA,+CACE,mCAAA,CAGF,wEACE,eAAA,CACA,qBAAA,CAGF,6EACE,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"doodadContainer": "PricePointItem__doodadContainer___t6tuo",
	"price": "PricePointItem__price___Q9tH_",
	"activeDays": "PricePointItem__activeDays___NUbXx"
};
export default ___CSS_LOADER_EXPORT___;
