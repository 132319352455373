import { tz } from '@date-fns/tz';
import { useDraggable } from '@dnd-kit/core';
import cx from 'classnames';
import { addMinutes, format } from 'date-fns';
import { Avatar } from '@components/avatar/Avatar';
import { Icon } from '@components/icon/Icon';
import { getFullName } from '@utils/formatName';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../../context/useRestaurant';
import type { WaitListEntry } from './apiHelpers';
import styles from './WaitListEntryCard.scss';
import { WaitListTimer } from './WaitListTimer';

export interface WaitListEntryCardProps {
  index: number;
  onClick: () => void;
  waitListEntry: WaitListEntry;
}

export const WaitListEntryCard = ({
  index,
  onClick,
  waitListEntry,
}: WaitListEntryCardProps) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    data: {
      type: 'waitListEntry',
      waitListEntry,
      waitListEntryIndex: index,
    },
    id: waitListEntry.id,
  });
  const { timezone } = useRestaurant();

  const waitEnd = addMinutes(
    new Date(waitListEntry.createdAt),
    waitListEntry.estimatedWait,
  );
  const waitEndDisplay = format(waitEnd, 'p', { in: tz(timezone) });

  return (
    <li className={styles.card}>
      <button
        className={styles.button}
        onClick={onClick}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        <div className={styles.avatarAndInfoStack}>
          <Avatar
            className={styles.avatar}
            firstName={waitListEntry.firstName}
            lastName={waitListEntry.lastName}
          />
          <WaitListTimer waitListEntry={waitListEntry} />
          <div className={styles.infoContainer}>
            <div className={cx(typography.h8, styles.waitEstimate)}>
              Estimate - {waitEndDisplay}
            </div>
            <div className={styles.nameAndGuestCountRow}>
              <div className={styles.userFullName}>
                {getFullName(waitListEntry.firstName, waitListEntry.lastName)}
              </div>
              <div
                aria-label={`${waitListEntry.guestCount} guests`}
                className={styles.guestCount}
              >
                <Icon name="couple" /> {waitListEntry.guestCount}
              </div>
            </div>
          </div>
        </div>
      </button>
    </li>
  );
};
