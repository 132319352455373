import cx from 'classnames';
import { centsToDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import styles from './CheckoutReceiptDetails.scss';

export interface CheckoutReceiptDetailsProps {
  className?: string;
  fee: number;
  priceLabel: string;
  price: number;
  tax: number;
  total: number;
  hideTaxesAndFees?: boolean;
}

export const CheckoutReceiptDetails = ({
  className,
  fee,
  priceLabel,
  price,
  tax,
  total,
  hideTaxesAndFees = false,
}: CheckoutReceiptDetailsProps) => (
  <div className={className}>
    {!hideTaxesAndFees && (
      <div className={cx(styles.row, styles.price, typography.h7_20)}>
        <div>{priceLabel}</div>
        <div>{centsToDollar(price)}</div>
      </div>
    )}
    {!hideTaxesAndFees && (
      <div className={cx(styles.row, typography.c3, styles.subText)}>
        <div>Tax</div>
        <div>{centsToDollar(tax)}</div>
      </div>
    )}
    {!hideTaxesAndFees && (
      <div className={cx(styles.row, typography.c3, styles.subText)}>
        <div>Processing</div>
        <div>{centsToDollar(fee)}</div>
      </div>
    )}
    <div className={cx(styles.row, styles.total)}>
      <div className={typography.h5}>Total</div>
      <div className={typography.h6}>{centsToDollar(total)}</div>
    </div>
  </div>
);
