import cx from 'classnames';
import { useEffect, useRef } from 'react';
import buttonStyles from '@components/button/Button.scss';
import typography from '~styles/typography.scss';
import styles from './ReserveButton.scss';

interface ReserveButtonProps {
  handleClick: () => void;
}

export const ReserveButton = ({ handleClick }: ReserveButtonProps) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [ref, ref.current]);

  return (
    <button
      className={cx(
        typography.h6,
        buttonStyles.btn,
        buttonStyles.btnPrimary,
        styles.reserveButton,
      )}
      onClick={handleClick}
      ref={ref}
    >
      Reserve
    </button>
  );
};
