import { type Control } from 'react-hook-form';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import typography from '~styles/typography.scss';
import { type ListingFormData } from '../types';
import styles from './PublicNameFieldSet.scss';

export interface PublicNameFieldSetProps {
  control: Control<ListingFormData>;
}

export const PublicNameFieldSet = ({ control }: PublicNameFieldSetProps) => (
  <fieldset className={styles.fieldset} data-testid="labels-fieldset">
    <h3 className={typography.t3}>labels</h3>
    <ControlledFormInput
      control={control}
      type="text"
      label="Public Name"
      name="publicName"
      placeholder="VIP Booth (The Den)"
      rules={{
        maxLength: {
          message: 'Too many characters',
          value: 50,
        },
        required: true,
      }}
    />
  </fieldset>
);
