// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Chip__chip___QQHRs{align-items:center;background-color:var(--darkGrey200);border-radius:6px;color:var(--white);display:flex;flex-direction:row;font-size:.875rem;gap:5px;line-height:initial;padding:2px 4px 2px 6px}.Chip__remove___qHUCw{background:none;border:none;cursor:pointer;font-size:0;padding:0}.Chip__removeIcon____lsVg{height:16px;stroke:var(--white)}", "",{"version":3,"sources":["webpack://./src/shared/components/chip/Chip.scss"],"names":[],"mappings":"AAAA,oBACE,kBAAA,CACA,mCAAA,CACA,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,OAAA,CACA,mBAAA,CACA,uBAAA,CAGF,sBACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CACA,SAAA,CAGF,0BACE,WAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chip": "Chip__chip___QQHRs",
	"remove": "Chip__remove___qHUCw",
	"removeIcon": "Chip__removeIcon____lsVg"
};
export default ___CSS_LOADER_EXPORT___;
