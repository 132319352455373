// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateServiceWindowModal__form___t6sJK{display:flex;flex-direction:column;row-gap:16px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/CreateServiceWindowModal.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,qBAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "CreateServiceWindowModal__form___t6sJK"
};
export default ___CSS_LOADER_EXPORT___;
