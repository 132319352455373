import cx from 'classnames';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Card.scss';

export const Card = ({
  bordered = false,
  children,
  className,
  ...attrs
}: {
  bordered?: boolean;
} & PropsWithChildren<HTMLAttributes<HTMLElement>>) => (
  <section
    className={cx(styles.card, className, { [styles.bordered]: bordered })}
    {...attrs}
  >
    {children}
  </section>
);
