import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './NavigationMenuLink.scss';

export interface NavigationMenuLinkProps {
  icon: AnyIcon;
  menuLabel: string;
  to: string;
}

export const NavigationMenuLink = ({
  icon,
  menuLabel,
  to,
}: NavigationMenuLinkProps) => (
  <NavLink
    className={({ isActive }) =>
      cx({
        [typography.t2]: true,
        [styles.menuLink]: true,
        [styles.activeMenuLink]: isActive,
      })
    }
    to={to}
  >
    <Icon name={icon} ariaLabel={`${menuLabel} menu link icon`} />
    {menuLabel}
  </NavLink>
);
