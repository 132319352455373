// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeFilterPill__pill___kCw2D{align-items:center;background-color:var(--primary400);border-radius:100px;border:1px solid rgba(0,0,0,0);color:var(--white);cursor:pointer;display:flex;margin:0;padding:8px 16px}.TimeFilterPill__isOpen___nNcn5,.TimeFilterPill__pill___kCw2D:active,.TimeFilterPill__pill___kCw2D:focus,.TimeFilterPill__pill___kCw2D:hover{border:1px solid var(--gold)}.TimeFilterPill__isOpen___nNcn5:active,.TimeFilterPill__isOpen___nNcn5:focus,.TimeFilterPill__isOpen___nNcn5:hover{background-color:var(--darkBlue)}.TimeFilterPill__icon___Q0U1s{stroke:var(--primary200);width:24px;min-width:24px;margin-right:8px}.TimeFilterPill__popover___LoXOx{background-color:var(--primary500);margin-top:1rem;margin-left:1.5rem}.TimeFilterPill__popover___LoXOx button{background-color:var(--primary500)}.TimeFilterPill__popover___LoXOx h1,.TimeFilterPill__popover___LoXOx h2{margin:1rem 0}@media only screen and (min-width: 1024px){.TimeFilterPill__popover___LoXOx{margin-left:2.5rem}}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/timeFilterPill/TimeFilterPill.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,8BACE,kBAAA,CACA,kCAAA,CACA,mBAAA,CACA,8BAAA,CACA,kBAAA,CACA,cAAA,CACA,YAAA,CACA,QAAA,CACA,gBAAA,CAGF,6IAIE,4BAAA,CAGF,mHAGE,gCAAA,CAGF,8BACE,wBAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA,CAGF,iCACE,kCAAA,CACA,eAAA,CACA,kBAAA,CAEA,wCACE,kCAAA,CAGF,wEAEE,aAAA,CCbF,2CDEF,iCAeI,kBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pill": "TimeFilterPill__pill___kCw2D",
	"isOpen": "TimeFilterPill__isOpen___nNcn5",
	"icon": "TimeFilterPill__icon___Q0U1s",
	"popover": "TimeFilterPill__popover___LoXOx"
};
export default ___CSS_LOADER_EXPORT___;
