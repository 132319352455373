import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { TimeFilterPopover } from '@components/timeFilter/TimeFilterPopover';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { ISOTimeTo12HourTime } from '@utils/time';
import styles from './TimeFilterPill.scss';

export interface TimeFilterPillProps {
  availableTimes: string[];
  closeFilter: () => void;
  isOpenFilter: boolean;
  onSelectTime: (time: string) => void;
  openFilter: () => void;
  selectedTime: string;
}

export const TimeFilterPill = ({
  availableTimes,
  closeFilter,
  isOpenFilter,
  onSelectTime,
  openFilter,
  selectedTime,
}: TimeFilterPillProps) => {
  const { ref } = useHandleClickOutside(closeFilter);

  const togglePopoverVisibility = () => {
    if (isOpenFilter) {
      closeFilter();
    } else {
      openFilter();
    }
  };

  const timePickerClassNames = cx(styles.pill, {
    [styles.isOpen]: isOpenFilter,
  });

  const buttonText = availableTimes.length
    ? ISOTimeTo12HourTime(selectedTime)
    : 'NA';

  return (
    <div ref={ref}>
      <button
        className={timePickerClassNames}
        data-testid="time-filter-pill"
        onClick={togglePopoverVisibility}
      >
        <Icon className={styles.icon} name="clock" />
        {buttonText}
      </button>
      {isOpenFilter && (
        <TimeFilterPopover
          availableTimes={availableTimes}
          className={styles.popover}
          selectedTime={selectedTime}
          setSelectedTime={onSelectTime}
        />
      )}
    </div>
  );
};
