// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestTagEditForm__container___sO9gI{display:flex;flex-direction:column;gap:.5rem;margin-top:.5rem}.GuestTagEditForm__actions___Z4AXD{display:flex;justify-content:flex-end}.GuestTagEditForm__btn___uUPB0{padding:8px 16px}.GuestTagEditForm__secondaryBtn___kVjM9{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/guestTags/GuestTagEditForm/GuestTagEditForm.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,gBAAA,CAGF,mCACE,YAAA,CACA,wBAAA,CAGF,+BACE,gBAAA,CAGF,wCACE,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestTagEditForm__container___sO9gI",
	"actions": "GuestTagEditForm__actions___Z4AXD",
	"btn": "GuestTagEditForm__btn___uUPB0",
	"secondaryBtn": "GuestTagEditForm__secondaryBtn___kVjM9"
};
export default ___CSS_LOADER_EXPORT___;
