// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ControlledColorPickerRadio__container___nRjxH{display:grid;grid-template-columns:repeat(6, 1fr);grid-auto-rows:24px;gap:4px}.ControlledColorPickerRadio__container___nRjxH input[type=radio]{display:none}.ControlledColorPickerRadio__container___nRjxH span{display:flex;width:100%;height:100%;align-items:center;justify-content:center;border-radius:6px;cursor:pointer}.ControlledColorPickerRadio__container___nRjxH span svg{stroke:var(--white);height:16px;width:16px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/servers/ControlledColorPickerRadio.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,oCAAA,CACA,mBAAA,CACA,OAAA,CAEA,iEACE,YAAA,CAGF,oDACE,YAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,cAAA,CAEA,wDACE,mBAAA,CACA,WAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ControlledColorPickerRadio__container___nRjxH"
};
export default ___CSS_LOADER_EXPORT___;
