import cx from 'classnames';
import { forwardRef, type ReactNode } from 'react';
import typography from '~styles/typography.scss';
import styles from './ServiceFloorPlanContainer.scss';

interface Props {
  children?: ReactNode;
  instructions?: string;
  testId?: string;
}

export const ServiceFloorPlanContainer = forwardRef<HTMLDivElement, Props>(
  ({ instructions, children, testId }, floorPlanRef) => (
    <div
      className={cx(typography.t1, styles.restaurantFloorPlanContainer)}
      data-testid={testId}
      ref={floorPlanRef}
    >
      {instructions && (
        <div className={styles.instructions}>{instructions}</div>
      )}
      {children}
    </div>
  ),
);

ServiceFloorPlanContainer.displayName = 'ServiceFloorPlanContainer';
