import cx from 'classnames';
import { type HTMLAttributes, useEffect, useRef } from 'react';
import styles from './AdminCalendar.scss';
import { timeToRow, weekDayToCol } from './utils';

interface CalendarBlockProps {
  date: string;
  startTime: string;
  endTime: string;
  isHighlighted?: boolean;
  isDimmed?: boolean;
  weekDay: number;
}

export const CalendarBlock = ({
  date,
  startTime,
  endTime,
  isHighlighted = false,
  isDimmed = false,
  weekDay,
}: CalendarBlockProps & HTMLAttributes<HTMLDivElement>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isHighlighted && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, []);

  return (
    <div
      ref={ref}
      className={cx(styles.block, {
        [styles.isDimmed]: isDimmed,
        [styles.isHighlighted]: isHighlighted,
      })}
      data-testid={`calendar-block-${date}-${startTime}-${endTime}${isHighlighted ? '-highlighted' : ''}${isDimmed ? '-dimmed' : ''}`}
      style={{
        gridColumn: weekDayToCol(weekDay),
        gridRowStart: timeToRow(startTime),
        gridRowEnd: timeToRow(endTime),
      }}
    />
  );
};
