// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssignServerModal__container___v_QBH{display:flex;flex-direction:column;gap:1rem}.AssignServerModal__container___v_QBH .AssignServerModal__currentServer___LmIYc{align-self:center;display:flex;gap:.5rem;width:max-content}.AssignServerModal__container___v_QBH .AssignServerModal__optionLabel___rHJ5B{align-items:center;display:flex;gap:.5rem;margin-bottom:.5rem;width:min-content}.AssignServerModal__container___v_QBH .AssignServerModal__optionLabel___rHJ5B .AssignServerModal__dot___J1egp{display:none}.AssignServerModal__container___v_QBH .AssignServerModal__select___ffpNb ul{max-height:10rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/server/AssignServerModal.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,gFACE,iBAAA,CACA,YAAA,CACA,SAAA,CACA,iBAAA,CAGF,8EACE,kBAAA,CACA,YAAA,CACA,SAAA,CACA,mBAAA,CACA,iBAAA,CAEA,8GACE,YAAA,CAKF,4EACE,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AssignServerModal__container___v_QBH",
	"currentServer": "AssignServerModal__currentServer___LmIYc",
	"optionLabel": "AssignServerModal__optionLabel___rHJ5B",
	"dot": "AssignServerModal__dot___J1egp",
	"select": "AssignServerModal__select___ffpNb"
};
export default ___CSS_LOADER_EXPORT___;
