import { Button, ButtonVariants } from '@components/button/Button';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import styles from './RefundButton.scss';
import { RefundConfirmationModal } from './RefundConfirmationModal';

interface RefundButtonProps {
  reservationId: string;
  reservationPrice: number;
  isRefunded: boolean;
}

export const RefundButton = ({
  reservationId,
  reservationPrice,
  isRefunded,
}: RefundButtonProps) => {
  const isFreeReservation = reservationPrice === 0;
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();

  if (isRefunded) return null;
  if (isFreeReservation) return null;

  return (
    <>
      <Button
        className={styles.button}
        onClick={openModal}
        label="Refund"
        variant={ButtonVariants.Error}
      />
      <RefundConfirmationModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        reservationId={reservationId}
      />
    </>
  );
};
