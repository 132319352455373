import * as Sentry from '@sentry/react';
import { kebabCase } from 'lodash-es';
import type { ReactNode } from 'react';
import type { IconNameType } from '@shared/types/floorPlans';
import type { FloorPlanTablesRenderer } from './FloorPlan';
import { InteractiveFloorPlanTable } from './InteractiveFloorPlanTable';
import { NonInteractiveFloorPlanTable } from './NonInteractiveFloorPlanTable';

export interface RendererFloorPlanTable {
  iconName: IconNameType;
  iconWidthScale: string;
  left: string;
  name: string;
  orientation: string;
  pulse?: boolean;
  top: string;
}

export interface InteractiveFloorPlanTablesRendererFactoryProps<
  T extends RendererFloorPlanTable,
> {
  showName?: boolean;
  calculateIsHighlighted?: (floorPlanTable: T) => boolean;
  calculateIsSelectable?: (floorPlanTable: T) => boolean;
  errorState?: boolean;
  handleTableOnClick?: (floorPlanTable: T) => void;
  tables: T[];
  unselectableTooltipText?: string;
}

// TODO: Temporary logging to identify Sentry Event (Dec 13th 2024)
// https://peak-reservations-llc.sentry.io/issues/6134993890/events/cf35238c73644d37ad92345752f09146/?project=5972755
const reportMissingFloorPlanTableIconNameToSentry = <T extends object>(
  table: T,
) =>
  Sentry.captureException(
    new Error(
      `Cannot render floor plan table without an icon name: ${JSON.stringify(table)}`,
    ),
  );

export const interactiveFloorPlanTablesRendererFactory =
  <T extends RendererFloorPlanTable>({
    showName = false,
    calculateIsHighlighted = () => false,
    calculateIsSelectable = () => true,
    errorState,
    handleTableOnClick = (): void => undefined,
    tables,
    unselectableTooltipText,
  }: InteractiveFloorPlanTablesRendererFactoryProps<T>): FloorPlanTablesRenderer =>
  // eslint-disable-next-line react/display-name
  (tableIconScale: number): ReactNode => (
    <>
      {tables.map((floorPlanTable) => {
        // TODO: Temporary logging to identify Sentry Event (Dec 13th 2024)
        // https://peak-reservations-llc.sentry.io/issues/6134993890/events/cf35238c73644d37ad92345752f09146/?project=5972755
        if (!floorPlanTable.iconName) {
          reportMissingFloorPlanTableIconNameToSentry(floorPlanTable);
        }
        return (
          <InteractiveFloorPlanTable
            showName={showName}
            errorState={errorState}
            handleTableOnClick={() => handleTableOnClick(floorPlanTable)}
            iconName={floorPlanTable.iconName}
            iconWidthScale={floorPlanTable.iconWidthScale}
            isHighlighted={calculateIsHighlighted(floorPlanTable)}
            isSelectable={calculateIsSelectable(floorPlanTable)}
            key={JSON.stringify(floorPlanTable)}
            left={floorPlanTable.left}
            name={floorPlanTable.name}
            orientation={floorPlanTable.orientation}
            pulse={floorPlanTable.pulse}
            tableIconScale={tableIconScale}
            testId={kebabCase(`floor-plan-table-${floorPlanTable.name}`)}
            top={floorPlanTable.top}
            unselectableTooltipText={unselectableTooltipText}
          />
        );
      })}
    </>
  );

export interface FloorPlanTablesRendererFactoryProps<
  T extends RendererFloorPlanTable,
> {
  calculateIsHighlighted?: (floorPlanTable: T) => boolean;
  tables: T[];
}

export const floorPlanTablesRendererFactory =
  <T extends RendererFloorPlanTable>({
    calculateIsHighlighted = () => false,
    tables,
  }: FloorPlanTablesRendererFactoryProps<T>): FloorPlanTablesRenderer =>
  // eslint-disable-next-line react/display-name
  (tableIconScale: number): ReactNode => (
    <>
      {tables.map((floorPlanTable) => (
        <NonInteractiveFloorPlanTable
          iconName={floorPlanTable.iconName}
          iconWidthScale={floorPlanTable.iconWidthScale}
          isHighlighted={calculateIsHighlighted(floorPlanTable)}
          key={JSON.stringify(floorPlanTable)}
          left={floorPlanTable.left}
          name={floorPlanTable.name}
          orientation={floorPlanTable.orientation}
          tableIconScale={tableIconScale}
          testId={kebabCase(`floor-plan-table-${floorPlanTable.name}`)}
          top={floorPlanTable.top}
        />
      ))}
    </>
  );
