const NAME_SEPARATOR = ' ';

export const formatServerNameAsInitials = (name: string) => {
  if (!name.trim()) return '';

  const allNames = name.trim().split(NAME_SEPARATOR);

  const firstName = allNames[0];

  if (allNames.length === 1) {
    return firstName.slice(0, 2);
  }

  const lastName = allNames[allNames.length - 1];
  return `${firstName[0]}${lastName[0]}`;
};
