import typography from '~styles/typography.scss';
import { RestaurantSettingsSection } from '../shared/RestaurantSettingsSection';
import { PacingControlsForm } from './PacingControlsForm';

interface PacingControlsSettingProps {
  pacingControls: {
    pacingLimit: number;
    pacingWindow: number;
  } | null;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const PacingControlsSetting = ({
  pacingControls,
  refreshRestaurant,
  restaurantId,
}: PacingControlsSettingProps) => (
  <RestaurantSettingsSection
    heading="Pacing Controls"
    isEditable
    renderForm={(exitEditMode) => (
      <PacingControlsForm
        pacingControls={pacingControls}
        exitEditMode={exitEditMode}
        refreshRestaurant={refreshRestaurant}
        restaurantId={restaurantId}
      />
    )}
  >
    <p className={typography.t2}>
      Set the pacing limit and window for your entire restaurant. This setting
      will make sure that at any given window, the number of guests arriving
      does not exceed the limit.
    </p>
    <h3 className={typography.h6}>
      {pacingControls ? 'Pacing' : 'No pacing set'}
    </h3>
    {!!pacingControls && (
      <div
        className={typography.c2}
      >{`${pacingControls.pacingLimit} cover${pacingControls.pacingLimit > 1 ? 's' : ''} per ${pacingControls.pacingWindow} mins`}</div>
    )}
  </RestaurantSettingsSection>
);
