import cx from 'classnames';
import { IconButton } from '@components/iconButton/IconButton';
import typography from '~styles/typography.scss';
import styles from './GuestCountPicker.scss';

const MIN = 1;
const STEP = 1;

interface GuestCountPickerProps {
  max: number;
  className?: string;
  guestCount: number;
  onChange: (args: number) => void;
}

export const GuestCountPicker = ({
  max,
  onChange,
  guestCount,
  className = '',
}: GuestCountPickerProps) => {
  const handleStepDown = () => {
    const newGuestCount = guestCount - STEP;
    if (newGuestCount >= MIN) {
      onChange(newGuestCount);
    }
  };

  const handleStepUp = () => {
    const newGuestCount = guestCount + STEP;
    if (newGuestCount <= max) {
      onChange(newGuestCount);
    }
  };

  return (
    <div
      className={cx(styles.container, className)}
      data-testid="guest-count-picker"
    >
      <h2 className={typography.h7}>Guests</h2>
      <div className={styles.filterRow}>
        <IconButton
          ariaLabel="decrease number of guests"
          disabled={guestCount <= MIN}
          iconName="minus"
          onClick={handleStepDown}
        />
        <span className={typography.h4}>{guestCount}</span>
        <IconButton
          ariaLabel="increase number of guests"
          disabled={guestCount >= max}
          iconName="plus"
          onClick={handleStepUp}
        />
      </div>
    </div>
  );
};
