import {
  type Control,
  type FieldPath,
  type FieldValues,
  useController,
} from 'react-hook-form';
import { type GuestsApiSearch } from './apiHelpers';
import { GuestAutocomplete } from './GuestAutocomplete';

export interface ControlledGuestAutocompleteProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  control: Control<T>;
  name: Name;
  onSelect?: (value: GuestsApiSearch) => void;
  onClear?: () => void;
}

export const ControlledGuestAutocomplete = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  control,
  name,
  onSelect,
  onClear,
}: ControlledGuestAutocompleteProps<T, Name>) => {
  const {
    field: { onChange },
  } = useController({
    control,
    name,
  });

  return (
    <GuestAutocomplete
      onSelect={(guest) => {
        onChange(guest.id);
        if (onSelect) onSelect(guest);
      }}
      onClear={() => {
        onChange(undefined);
        if (onClear) onClear();
      }}
    />
  );
};

ControlledGuestAutocomplete.displayName = 'ControlledGuestAutocomplete';
