import cx from 'classnames';
import { parseISO, startOfWeek } from 'date-fns';
import { WeekSelector } from '../../../components/weekSelector/WeekSelector';
import type { ListingWithServiceWindows } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import { mapN } from '../utils/listingUtils';
import styles from './AdminCalendar.scss';
import { CalendarCol } from './CalendarCol';
import { CalendarHeaderRow } from './CalendarHeaderRow';
import { CalendarHorizontalGridLines } from './CalendarHorizontalGridLines';
import { CalendarTimeColumn } from './CalendarTimeColumn';
import { CalendarVerticalGridLines } from './CalendarVerticalGridLines';
import { ClearCalendarSelectionButton } from './ClearCalendarSelectionButton';

export interface AdminCalendarProps {
  className?: string;
  highlightedListing?: ListingWithServiceWindows;
  interactive: boolean;
}

export const AdminCalendar = ({
  className,
  highlightedListing,
  interactive,
}: AdminCalendarProps) => {
  const { selectedDate, setSelectedDate, selectedCalendarCellListingIds } =
    useListingsContext();
  const weekStart = startOfWeek(parseISO(selectedDate));

  return (
    <div className={cx(styles.main, className)}>
      <WeekSelector
        selectedDate={selectedDate}
        onChangeSelectedDate={setSelectedDate}
      />
      <div className={styles.calendar}>
        <CalendarHorizontalGridLines />
        <CalendarVerticalGridLines />
        {mapN(7, (weekDay) => (
          <CalendarCol
            key={weekDay}
            highlightedListing={highlightedListing}
            isInteractive={interactive}
            weekStart={weekStart}
            weekDay={weekDay}
          />
        ))}
        <CalendarTimeColumn />
        <CalendarHeaderRow />
      </div>
      {interactive && selectedCalendarCellListingIds.length > 0 && (
        <ClearCalendarSelectionButton />
      )}
    </div>
  );
};
