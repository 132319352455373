// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Switch__label___Gqc2Z{align-items:center;display:flex;column-gap:4px;justify-content:flex-end}.Switch__switchTrack___nVxSX{background:var(--hairline);border-radius:80px;cursor:pointer;height:24px;position:relative;width:40px}.Switch__switchTrack___nVxSX:has(input:checked){background:var(--gold)}.Switch__switchTrack___nVxSX:has(input:focus){box-shadow:0 0 0 1px var(--primary100)}.Switch__switchThumb___vrxwE{background-color:var(--white);border-radius:80px;height:18px;left:4px;position:absolute;top:3px;transition-duration:120ms;transition-property:left;width:18px}.Switch__switchThumb___vrxwE:has(~input:checked){left:18px}.Switch__hourglass___uLb5A{width:24px;stroke:var(--white)}.Switch__switchInput___flsDE{cursor:inherit;opacity:0;z-index:1}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/floorPlan/Switch.scss"],"names":[],"mappings":"AAEA,uBACE,kBAAA,CACA,YAAA,CACA,cAAA,CACA,wBAAA,CAGF,6BACE,0BAAA,CACA,kBAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,UAAA,CAEA,gDACE,sBAAA,CAGF,8CACE,sCAAA,CAIJ,6BACE,6BAAA,CACA,kBAAA,CACA,WAAA,CACA,QAAA,CACA,iBAAA,CACA,OAAA,CACA,yBAAA,CACA,wBAAA,CACA,UAAA,CAEA,iDACE,SAAA,CAIJ,2BACE,UAAA,CACA,mBAAA,CAGF,6BACE,cAAA,CACA,SAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "Switch__label___Gqc2Z",
	"switchTrack": "Switch__switchTrack___nVxSX",
	"switchThumb": "Switch__switchThumb___vrxwE",
	"hourglass": "Switch__hourglass___uLb5A",
	"switchInput": "Switch__switchInput___flsDE"
};
export default ___CSS_LOADER_EXPORT___;
