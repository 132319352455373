import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { ErrorHero } from './ErrorHero';

export const ErrorHandler = () => {
  const error = useRouteError();

  useEffect(() => {
    captureException(error);
  }, []);

  return (
    <ErrorHero
      title="Something went wrong."
      subtitle="Sorry, an error has occurred; try reloading the page."
    />
  );
};
