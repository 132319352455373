// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StrandedList__emptyState___U2j7C{border-bottom:1px solid var(--hairline);height:72px}.StrandedList__list___4h6C5{display:flex;column-gap:1rem;overflow:auto;width:100%;padding-bottom:1rem;border-bottom:1px solid var(--hairline)}.StrandedList__card___UsZl8{border-radius:12px;border:1px solid var(--hairline);box-shadow:0 2px 4px rgba(0,0,0,.1);color:var(--white);cursor:pointer;text-transform:uppercase;min-width:280px;height:56px;padding:8px;display:flex;align-items:stretch}.StrandedList__card___UsZl8:hover{background-color:var(--iconBlue)}.StrandedList__card___UsZl8 .StrandedList__details___qsDcU{flex-grow:1;margin-left:8px;display:flex;flex-direction:column;justify-content:center}.StrandedList__card___UsZl8 .StrandedList__details___qsDcU .StrandedList__row___Cu6z2{display:flex;justify-content:space-between}.StrandedList__card___UsZl8 .StrandedList__details___qsDcU .StrandedList__row___Cu6z2 .StrandedList__fullName___b_kLB{color:var(--white70)}.StrandedList__selected___s2Tz7{background-color:var(--soldBlue)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/stranded/StrandedList.scss"],"names":[],"mappings":"AAAA,kCACE,uCAAA,CACA,WAAA,CAGF,4BACE,YAAA,CACA,eAAA,CACA,aAAA,CACA,UAAA,CACA,mBAAA,CACA,uCAAA,CAGF,4BACE,kBAAA,CACA,gCAAA,CACA,mCAAA,CACA,kBAAA,CACA,cAAA,CACA,wBAAA,CACA,eAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA,CACA,mBAAA,CAEA,kCACE,gCAAA,CAGF,2DACE,WAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CAEA,sFACE,YAAA,CACA,6BAAA,CAEA,sHACE,oBAAA,CAMR,gCACE,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyState": "StrandedList__emptyState___U2j7C",
	"list": "StrandedList__list___4h6C5",
	"card": "StrandedList__card___UsZl8",
	"details": "StrandedList__details___qsDcU",
	"row": "StrandedList__row___Cu6z2",
	"fullName": "StrandedList__fullName___b_kLB",
	"selected": "StrandedList__selected___s2Tz7"
};
export default ___CSS_LOADER_EXPORT___;
