// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeamPage__inviteTeamMemberButton___TTfKA{padding:.75rem 1.5rem}.TeamPage__teamTable___tDpNP{margin-top:1.5rem}.TeamPage__teamTable___tDpNP tbody>tr:nth-child(odd){background-color:var(--black50)}.TeamPage__teamTable___tDpNP th{color:var(--white70)}.TeamPage__teamTable___tDpNP th,.TeamPage__teamTable___tDpNP td{padding:.5rem}.TeamPage__teamTable___tDpNP .TeamPage__pill___vkgid{text-transform:uppercase;border-radius:100px;padding:.125rem .5rem}.TeamPage__teamTable___tDpNP .TeamPage__pill___vkgid.TeamPage__active___rFI6t{border:.5px solid var(--white);background-color:var(--green300);color:var(--white)}.TeamPage__teamTable___tDpNP .TeamPage__pill___vkgid.TeamPage__inactive___E9SSV{border:.5px solid var(--darkGrey300);background-color:var(--white70);color:var(--darkGrey300)}.TeamPage__teamTable___tDpNP .TeamPage__pill___vkgid.TeamPage__invited___BMYaN{border:.5px solid var(--white);background-color:var(--secondary300);color:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/team/TeamPage.scss"],"names":[],"mappings":"AAAA,0CACE,qBAAA,CAGF,6BACE,iBAAA,CAEA,qDACE,+BAAA,CAGF,gCACE,oBAAA,CAGF,gEAEE,aAAA,CAGF,qDACE,wBAAA,CACA,mBAAA,CACA,qBAAA,CAEA,8EACE,8BAAA,CACA,gCAAA,CACA,kBAAA,CAGF,gFACE,oCAAA,CACA,+BAAA,CACA,wBAAA,CAGF,+EACE,8BAAA,CACA,oCAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inviteTeamMemberButton": "TeamPage__inviteTeamMemberButton___TTfKA",
	"teamTable": "TeamPage__teamTable___tDpNP",
	"pill": "TeamPage__pill___vkgid",
	"active": "TeamPage__active___rFI6t",
	"inactive": "TeamPage__inactive___E9SSV",
	"invited": "TeamPage__invited___BMYaN"
};
export default ___CSS_LOADER_EXPORT___;
