import cx from 'classnames';
import { startCase } from 'lodash-es';
import { Icon } from '@components/icon/Icon';
import { parseToShortDateFormat } from '@shared/utils/dateFormatters';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import { mapReservationStatus } from 'restaurantAdmin/reservations/reservationUtils';
import type { GuestReservation } from './apiHelpers';
import styles from './GuestReservationItem.scss';

interface GuestReservationItemProps {
  handleGuestReservationClick: (reservation: GuestReservation) => void;
  reservation: GuestReservation;
  selectedGuestReservationId?: string;
}

export const GuestReservationItem = ({
  handleGuestReservationClick,
  reservation,
  selectedGuestReservationId,
}: GuestReservationItemProps) => {
  const itemStyles = cx(styles.reservationItem, {
    [styles.selected]: reservation.id === selectedGuestReservationId,
  });

  const reservationStatusDisplay = startCase(
    mapReservationStatus(reservation.status),
  );

  return (
    <li className={itemStyles}>
      <button onClick={() => handleGuestReservationClick(reservation)}>
        <span className={styles.date}>
          <Icon name="calendar" className={styles.icon} />
          {parseToShortDateFormat(reservation.date)}
        </span>
        <span className={styles.time}>
          <Icon name="clock" className={styles.icon} />
          {ISOTimeTo12HourTime(reservation.time)}
        </span>
        <span className={styles.guests}>
          <Icon name="couple" className={styles.icon} />
          {appendGuestCountLabel(reservation.guestCount)}
        </span>
        <span className={styles.status}>{reservationStatusDisplay}</span>
        <Icon name="chevron" className={cx(styles.icon, styles.chevron)} />
      </button>
    </li>
  );
};
