// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleForm__form___hJBS0{display:flex;flex-direction:column;gap:2rem}.ScheduleForm__form___hJBS0 .ScheduleForm__actions___M69zi{display:flex;flex-direction:column-reverse;gap:1rem;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/schedule/ScheduleForm.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,2DACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "ScheduleForm__form___hJBS0",
	"actions": "ScheduleForm__actions___M69zi"
};
export default ___CSS_LOADER_EXPORT___;
