// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal__backdrop___SaM03{-webkit-tap-highlight-color:rgba(0,0,0,0);inset:0;position:fixed;background:linear-gradient(180deg, rgba(23, 28, 39, 0.7) -129.81%, #171c27 117.79%, #171c27 248.56%)}.Modal__modal___Xap5D{align-items:center;display:flex;inset:0;justify-content:center;position:fixed;width:100vw;z-index:var(--z_modal)}@media only screen and (min-width: 768px){.Modal__modal___Xap5D{width:unset}}", "",{"version":3,"sources":["webpack://./src/shared/components/modal/Modal.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,yBACE,yCAAA,CACA,OAAA,CACA,cAAA,CACA,oGAAA,CAQF,sBACE,kBAAA,CACA,YAAA,CACA,OAAA,CACA,sBAAA,CACA,cAAA,CACA,WAAA,CACA,sBAAA,CCWA,0CDlBF,sBAUI,WAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "Modal__backdrop___SaM03",
	"modal": "Modal__modal___Xap5D"
};
export default ___CSS_LOADER_EXPORT___;
