import cx from 'classnames';
import { type ReactNode, useLayoutEffect } from 'react';
import typography from '~styles/typography.scss';
import styles from './Layout.scss';

export interface PageHeaderProps {
  category?: string;
  title: string;
  children?: ReactNode;
}

export const PageHeader = ({ category, title, children }: PageHeaderProps) => {
  // Here we use the header's contents to approximate "when the whole page
  // changes...". We could use window.location, but that would include changes
  // to all sub-routes where it may not be appropriate to reset scroll.
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [category, title]);

  return (
    <header className={styles.pageHeader}>
      <div className={styles.pageMargin}>
        <h1 className={typography.h4}>
          {category && (
            <div className={cx(styles.category, typography.t3)}>{category}</div>
          )}
          {title}
        </h1>
        <div className={styles.actions}>{children}</div>
      </div>
    </header>
  );
};
