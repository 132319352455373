import { api } from 'restaurantAdmin/api';

export interface EligibleCancellationFeeResponse {
  isEligibleForCancellationFeeUponCancellation: boolean;
  eligibleForCancellationFeeUntil: string;
  cancellationPolicy: {
    fee: number;
  } | null;
}

export const eligibleForCancellationFee = (
  restaurantId: string,
  reservationId: string,
): Promise<EligibleCancellationFeeResponse> =>
  api.get(
    `/restaurants/${restaurantId}/reservations/${reservationId}/eligible-for-cancellation-fee`,
  );
