import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';
import styles from './EditListingModals.scss';

export interface CreateListingWarningModalProps {
  isOpen: boolean;
  closeModal: () => void;
  warnings?: string[];
  handleOnConfirmSubmit: () => void;
}

export const EditListingWarningModal = ({
  isOpen,
  closeModal,
  warnings,
  handleOnConfirmSubmit,
}: CreateListingWarningModalProps) => (
  <Modal isOpen={isOpen} onClose={closeModal} title="Edit Listing Warnings">
    <div className={styles.modalContent}>
      {warnings?.map((warning) => (
        <p key={warning} className={typography.c2}>
          {warning}
        </p>
      ))}
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Save Anyway"
          onClick={handleOnConfirmSubmit}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </div>
  </Modal>
);
