import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { type AsyncHookData } from '@shared/types/hooks';
import {
  getFloorPlanForRestaurantId,
  getFloorPlanForRestaurantIdWithDraftListings,
} from 'restaurantAdmin/floorPlans/apiHelpers';
import { useRestaurant } from '../context/useRestaurant';

interface ErrorFloorPlanData {
  id: undefined;
  backgroundSrc: undefined;
  floorPlanTables: [];
}

type UseAdminFloorPlanReturn = AsyncHookData<
  'floorPlan',
  FloorPlanData,
  ErrorFloorPlanData
> & {
  fetchFloorPlan: () => void;
};

export const useAdminFloorPlan = (
  activeListingsOnly: boolean,
): UseAdminFloorPlanReturn => {
  const { id: restaurantId } = useRestaurant();
  const errorFloorPlan: ErrorFloorPlanData = {
    id: undefined,
    backgroundSrc: undefined,
    floorPlanTables: [],
  };

  const {
    data: floorPlan,
    isPending: isLoading,
    rerun: fetchFloorPlan,
  } = useAbortEffect(
    {
      effect: () =>
        activeListingsOnly
          ? getFloorPlanForRestaurantId(restaurantId, true)
          : getFloorPlanForRestaurantIdWithDraftListings(restaurantId),
      throwOnError: false,
    },
    [restaurantId],
  );

  if (isLoading) {
    return {
      floorPlan: undefined,
      isLoading: true,
      fetchFloorPlan,
    };
  }
  return {
    floorPlan: floorPlan || errorFloorPlan,
    isLoading,
    fetchFloorPlan,
  };
};
