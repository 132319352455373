import styles from './AdminCalendar.scss';

export const CalendarVerticalGridLines = () => {
  const arrayOfSeven = Array.from({ length: 7 }, (_, i) => i);

  return (
    <>
      {arrayOfSeven.map(
        (num, index) =>
          index > 0 && (
            <div
              className={styles.verticalGridLine}
              key={num}
              style={{
                gridColumn: 2 + index,
                gridRowStart: 2,
                gridRowEnd: 2 + 24 * 4,
              }}
            />
          ),
      )}
    </>
  );
};
