import cx from 'classnames';
import { formatPhone } from '@shared/utils/formatPhone';
import typography from '~styles/typography.scss';
import styles from './ContactInfo.scss';

interface ContactInfoProps {
  phone?: string;
  email?: string;
}

export const ContactInfo = ({ phone, email }: ContactInfoProps) => (
  <section className={styles.container} aria-labelledby="contact-info-section">
    <h3 className={typography.h7} id="contact-info-section">
      Contact Info
    </h3>
    <span className={cx(typography.t1, styles.contactInfo)}>
      {phone ? formatPhone(phone) : 'None'}
    </span>
    {email && (
      <span
        className={cx(typography.t1, styles.contactInfo)}
        data-testid="email"
      >
        {email}
      </span>
    )}
  </section>
);
