import { capitalize, groupBy } from 'lodash-es';
import { type GuestTag } from 'restaurantAdmin/restaurants/apiHelpers';
import { GuestTagPill } from '../GuestTagPill/GuestTagPill';
import styles from './GuestTagList.scss';

interface GuestTagListProps {
  guestTags: GuestTag[];
}

interface GuestTagCategoryListProps {
  category: string;
  tagsForCategory: GuestTag[];
}

const listName = (category: string) => `${capitalize(category)} tags`;

const GuestTagCategoryList = ({
  category,
  tagsForCategory,
}: GuestTagCategoryListProps) => (
  <ul
    aria-label={listName(category)}
    className={styles.categoryList}
    key={category}
  >
    {tagsForCategory.map((tag: GuestTag) => (
      <li key={tag.id}>
        <GuestTagPill {...tag} />
      </li>
    ))}
  </ul>
);

export const GuestTagList = ({ guestTags }: GuestTagListProps) => {
  const tagsGroupedByCategory = groupBy(guestTags, 'category');
  const guestTagCategories = Object.keys(tagsGroupedByCategory);

  if (!guestTags.length) return null;

  return (
    <ul aria-label="guest tags">
      <li className={styles.categoryList}>
        {guestTagCategories.map((category) => (
          <GuestTagCategoryList
            category={category}
            key={category}
            tagsForCategory={tagsGroupedByCategory[category]}
          />
        ))}
      </li>
    </ul>
  );
};
