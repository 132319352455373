// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__pageContainer___Nr8Zk{padding:3rem;width:100%}.styles__card___yAZOn{margin:0 auto;width:360px}.styles__formContainer___Y75aO{display:flex;flex-direction:column}.styles__formHeader___C8PAU{color:var(--white);margin-bottom:1rem}.styles__formLabel___vuHAw{color:var(--primary100);margin-bottom:8px}.styles__input___Gv1N5{height:40px;border-radius:4px;border:2px solid var(--darkGrey300);padding:0 8px;width:100%;margin-bottom:16px;background-color:rgba(0,0,0,0);color:var(--white)}.styles__resetPasswordLink___RWsyq{margin-top:16px;font-size:1rem;align-self:center;color:var(--white)}.styles__loginLink___n_jue{margin-left:4px;margin-right:4px}.styles__requestSentMessage___ObLkt,.styles__successResponseMessage___tYdsJ{margin-top:8px;color:var(--darkGrey200)}.styles__invalidPasswordsMessage___JT_4A,.styles__errorResponseMessage___HwCWd{color:var(--pink);margin-top:8px}.styles__error___H44ke{color:var(--pink);margin-top:2px;text-align:center}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/auth/styles.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,UAAA,CAGF,sBACE,aAAA,CACA,WAAA,CAGF,+BACE,YAAA,CACA,qBAAA,CAGF,4BACE,kBAAA,CACA,kBAAA,CAGF,2BACE,uBAAA,CACA,iBAAA,CAGF,uBACE,WAAA,CACA,iBAAA,CACA,mCAAA,CACA,aAAA,CACA,UAAA,CACA,kBAAA,CACA,8BAAA,CACA,kBAAA,CAGF,mCACE,eAAA,CACA,cAAA,CACA,iBAAA,CACA,kBAAA,CAGF,2BACE,eAAA,CACA,gBAAA,CAGF,4EAEE,cAAA,CACA,wBAAA,CAGF,+EAEE,iBAAA,CACA,cAAA,CAGF,uBACE,iBAAA,CACA,cAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "styles__pageContainer___Nr8Zk",
	"card": "styles__card___yAZOn",
	"formContainer": "styles__formContainer___Y75aO",
	"formHeader": "styles__formHeader___C8PAU",
	"formLabel": "styles__formLabel___vuHAw",
	"input": "styles__input___Gv1N5",
	"resetPasswordLink": "styles__resetPasswordLink___RWsyq",
	"loginLink": "styles__loginLink___n_jue",
	"requestSentMessage": "styles__requestSentMessage___ObLkt",
	"successResponseMessage": "styles__successResponseMessage___tYdsJ",
	"invalidPasswordsMessage": "styles__invalidPasswordsMessage___JT_4A",
	"errorResponseMessage": "styles__errorResponseMessage___HwCWd",
	"error": "styles__error___H44ke"
};
export default ___CSS_LOADER_EXPORT___;
