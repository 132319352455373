// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfiniteOccupantList__noContent___NxvMT{color:var(--white);display:flex;justify-content:center}.InfiniteOccupantList__listWrapper___y8aO0{flex:1;overflow-y:auto}.InfiniteOccupantList__listWrapper___y8aO0 .InfiniteOccupantList__list___nanh7{height:100%;overflow:auto;width:100%}.InfiniteOccupantList__listWrapper___y8aO0 .InfiniteOccupantList__list___nanh7 h3{align-items:center;border-radius:.5rem;color:var(--white70);display:flex;height:1.5em;justify-content:center;letter-spacing:.025em;margin-bottom:1rem;text-transform:uppercase}.InfiniteOccupantList__listWrapper___y8aO0 .InfiniteOccupantList__list___nanh7 h4{align-items:center;background-color:var(--hairline);border-radius:.5rem;display:flex;justify-content:center;margin-bottom:.5rem;padding:.75rem}.InfiniteOccupantList__listWrapper___y8aO0 .InfiniteOccupantList__list___nanh7 [role=status]{height:60px;margin:auto;width:60px}.InfiniteOccupantList__listWrapper___y8aO0 .InfiniteOccupantList__noMoreOccupants___bNonN{color:var(--white70);text-align:center}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/InfiniteOccupantList.scss"],"names":[],"mappings":"AAAA,yCACE,kBAAA,CACA,YAAA,CACA,sBAAA,CAGF,2CACE,MAAA,CACA,eAAA,CAEA,+EACE,WAAA,CACA,aAAA,CACA,UAAA,CAEA,kFACE,kBAAA,CACA,mBAAA,CACA,oBAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,qBAAA,CACA,kBAAA,CACA,wBAAA,CAGF,kFACE,kBAAA,CACA,gCAAA,CACA,mBAAA,CACA,YAAA,CACA,sBAAA,CACA,mBAAA,CACA,cAAA,CAGF,6FACE,WAAA,CACA,WAAA,CACA,UAAA,CAIJ,0FACE,oBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noContent": "InfiniteOccupantList__noContent___NxvMT",
	"listWrapper": "InfiniteOccupantList__listWrapper___y8aO0",
	"list": "InfiniteOccupantList__list___nanh7",
	"noMoreOccupants": "InfiniteOccupantList__noMoreOccupants___bNonN"
};
export default ___CSS_LOADER_EXPORT___;
