import { DragOverlay, useDndContext } from '@dnd-kit/core';
import cx from 'classnames';
import { Avatar } from '@components/avatar/Avatar';
import { Icon } from '@components/icon/Icon';
import styles from './DraggingAvatar.scss';

export const DraggingAvatar = () => {
  const { active, over } = useDndContext();
  if (!active?.data.current) return null;

  const isDroppable = Boolean(
    over && over.data.current?.accepts.includes(active.data.current.type),
  );

  const isNotDroppable = Boolean(
    over && !over.data.current?.accepts.includes(active.data.current.type),
  );

  const renderAvatarIcon = () => {
    if (isDroppable) {
      return (
        <Icon className={styles.draggingIndicator} name="arrowDownCircle" />
      );
    }

    if (isNotDroppable) {
      return <Icon className={styles.draggingIndicator} name="xCircle" />;
    }

    return null;
  };

  const firstName = (active.data.current.reservation?.guest.firstName ||
    active.data.current.waitListEntry?.firstName ||
    '') as string;

  const lastName = (active.data.current.reservation?.guest.lastName ||
    active.data.current.waitListEntry?.lastName ||
    '') as string;

  return (
    <DragOverlay>
      <Avatar
        testId={active ? 'dragging-avatar' : 'avatar'}
        className={cx({
          [styles.draggingAvatar]: true,
          [styles.isDroppable]: isDroppable,
          [styles.isNotDroppable]: isNotDroppable,
        })}
        firstName={firstName}
        lastName={lastName}
      >
        {renderAvatarIcon()}
      </Avatar>
    </DragOverlay>
  );
};
