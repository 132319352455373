import cx from 'classnames';
import { centsToCompactDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import styles from './CancellationFeeStatusTag.scss';

interface CancellationFeeStatusTagProps {
  fee: number | null;
  isEligibleForCancellationFee: boolean;
  status: 'collected' | 'collection failed' | 'waived' | null;
}

export const CancellationFeeStatusTag = ({
  fee,
  status,
  isEligibleForCancellationFee,
}: CancellationFeeStatusTagProps) => {
  if (status === null && !isEligibleForCancellationFee) {
    return null;
  }
  const feeTextClassName = cx(
    {
      [styles.notYetCollectedTag]: status === null,
      [styles.collectedTag]: status === 'collected',
      [styles.collectionFailedTag]: status === 'collection failed',
      [styles.waivedTag]: status === 'waived',
    },
    typography.t3,
  );

  const feeInDollars = fee ? centsToCompactDollar(fee) : null;

  const feeText = () => {
    if (status === 'collected') {
      return `${feeInDollars} collected`;
    }

    if (status === 'collection failed') {
      return `${feeInDollars} collection failed`;
    }

    if (status === 'waived') {
      return 'waived';
    }
    return `${feeInDollars} not yet collected`;
  };

  return (
    <div className={cx(styles.cancellationFeeContainer)}>
      <div className={cx(styles.cancellationFee, typography.t3)}>
        CANCELLATION FEE:
      </div>
      <div className={feeTextClassName}>{feeText()}</div>
    </div>
  );
};
