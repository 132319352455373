import { mapHalfHourIncrements } from '../utils/listingUtils';
import styles from './AdminCalendar.scss';

export const CalendarHorizontalGridLines = () => (
  <>
    {mapHalfHourIncrements((i) => (
      <div
        className={styles.horizontalGridLine}
        key={i}
        style={{
          gridColumnStart: 2,
          gridColumnEnd: 2 + 7,
          gridRow: 2 + i * 2,
        }}
      />
    ))}
  </>
);
