import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { PageHeader } from 'restaurantAdmin/layout/PageHeader';
import {
  OPERATIONS_LISTINGS_CALENDAR_PATH,
  OPERATIONS_LISTINGS_CREATE_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PATH,
} from 'restaurantAdmin/paths';
import { useListingsContext } from '../ListingsContext';
import styles from './ListingsPageHeader.scss';

export const ListingsPageHeader = () => {
  const { pathname } = useLocation();
  const { clearSelectedListing } = useListingsContext();

  const tabLinkClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.tabLink]: true,
      [styles.isActive]: isActive,
    });

  return (
    <PageHeader category="Operations" title="Listings">
      <nav>
        <NavLink
          className={tabLinkClassNames}
          onClick={clearSelectedListing}
          to={OPERATIONS_LISTINGS_FLOOR_PLAN_PATH}
        >
          Floor Plan
        </NavLink>
        <NavLink
          className={tabLinkClassNames}
          onClick={clearSelectedListing}
          to={OPERATIONS_LISTINGS_CALENDAR_PATH}
        >
          Calendar
        </NavLink>
      </nav>
      <LinkStyledAsButton
        variant={ButtonVariants.Secondary}
        to={OPERATIONS_LISTINGS_CREATE_PATH}
        label="Create Listing"
        state={{
          referrer: pathname,
        }}
      />
    </PageHeader>
  );
};
