// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CancellationPolicyFormActions__container___i_5UJ{display:flex;flex-direction:column;gap:1rem;justify-content:flex-end}.CancellationPolicyFormActions__container___i_5UJ .CancellationPolicyFormActions__deleteButton___hX406{align-items:center;background-color:inherit;border:none;color:#fff;display:flex;gap:.5rem;padding-left:0}.CancellationPolicyFormActions__container___i_5UJ .CancellationPolicyFormActions__deleteButton___hX406 svg{min-width:1.5rem;stroke:#fff;width:1.5rem}.CancellationPolicyFormActions__container___i_5UJ .CancellationPolicyFormActions__deleteButton___hX406:active,.CancellationPolicyFormActions__container___i_5UJ .CancellationPolicyFormActions__deleteButton___hX406:focus,.CancellationPolicyFormActions__container___i_5UJ .CancellationPolicyFormActions__deleteButton___hX406:hover{color:var(--gold)}.CancellationPolicyFormActions__container___i_5UJ .CancellationPolicyFormActions__deleteButton___hX406:active svg,.CancellationPolicyFormActions__container___i_5UJ .CancellationPolicyFormActions__deleteButton___hX406:focus svg,.CancellationPolicyFormActions__container___i_5UJ .CancellationPolicyFormActions__deleteButton___hX406:hover svg{stroke:var(--gold)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/cancellationPolicy/CancellationPolicyFormActions.scss"],"names":[],"mappings":"AAEA,kDACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,wBAAA,CAEA,uGACE,kBAAA,CACA,wBAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,SAAA,CACA,cAAA,CAEA,2GACE,gBAAA,CACA,WAAA,CACA,YAAA,CAGF,wUAOE,iBAAA,CAJA,oVACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CancellationPolicyFormActions__container___i_5UJ",
	"deleteButton": "CancellationPolicyFormActions__deleteButton___hX406"
};
export default ___CSS_LOADER_EXPORT___;
