import { ContactInfo } from '../components/contactInfo/ContactInfo';
import { GuestNotes } from '../components/guestNotes/GuestNotes';
import { GuestTags } from '../components/guestTags/GuestTags';
import type { GuestsApiGuest } from './apiHelpers';

export const GuestProfile = ({ guest }: { guest: GuestsApiGuest }) => (
  <>
    <ContactInfo phone={guest.phone} email={guest.email} />
    <GuestTags guestId={guest.id} />
    <GuestNotes guestId={guest.id} />
  </>
);
