import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './ListingOverlapWarning.scss';

export const ListingOverlapWarning = () => (
  <div aria-labelledby="overlapping-tables" className={styles.container}>
    <div className={styles.heading}>
      <Icon name="alertCircle" className={styles.icon} />
      <h2 id="overlapping-tables" className={typography.t1}>
        Overlapping Tables
      </h2>
    </div>
    <p className={typography.t1}>
      One or more tables in this listing has a time overlap with another
      listing. This may cause double-bookings.
    </p>
  </div>
);
