import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import { type GuestTagCategory } from '../../../restaurants/apiHelpers';
import styles from './GuestTagPill.scss';

const categoryStyles: Record<GuestTagCategory, string> = {
  allergies: styles.green,
  custom: styles.kelp,
  'dietary restrictions': styles.green,
  'guest attributes': styles.grey,
  other: styles.gold,
} as const;

interface GuestTagPillProps {
  title: string;
  category: GuestTagCategory;
  createdBy?: string;
}

const VIP = 'VIP';

export const GuestTagPill = ({
  title,
  category,
  createdBy,
}: GuestTagPillProps) => (
  <span className={styles.tag}>
    <div
      className={cx(
        styles.pill,
        typography.t2,
        categoryStyles[category] ?? styles.gold,
      )}
    >
      {title === VIP && (
        <Icon ariaLabel="crown icon" name="crown" fill="black" />
      )}
      {title}
    </div>
    {createdBy && (
      <span className={typography.t2}>{`Added by: ${createdBy}`}</span>
    )}
  </span>
);
