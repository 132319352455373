import { type ReactNode } from 'react';
import { ModalContainer } from './ModalContainer';
import { ModalContent } from './ModalContent';

interface ModalBasicProps {
  children?: ReactNode;
  disablePortal?: boolean;
  isOpen: boolean;
  onClose: () => void;
  subtitle?: string;
}
interface ModalTitleProps {
  ariaLabel?: never;
  title: string;
}
interface ModalAriaLabelProps {
  ariaLabel: string;
  title?: never;
}

export type ModalProps = ModalBasicProps &
  (ModalTitleProps | ModalAriaLabelProps);

export const Modal = ({
  ariaLabel,
  children,
  disablePortal,
  isOpen,
  onClose,
  subtitle,
  title,
}: ModalProps) => (
  <ModalContainer
    disablePortal={disablePortal}
    isOpen={isOpen}
    onClose={onClose}
  >
    <ModalContent
      ariaLabel={ariaLabel}
      onClose={onClose}
      subtitle={subtitle}
      title={title}
    >
      {children}
    </ModalContent>
  </ModalContainer>
);
