import type { DependencyList, RefObject } from 'react';
import { useEffect } from 'react';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

export const useResetZoom = (
  ref: RefObject<ReactZoomPanPinchRef>,
  deps: DependencyList,
  condition = true,
) => {
  const resetZoom = () => {
    if (ref?.current) {
      const { resetTransform } = ref.current;
      resetTransform();
    }
  };

  useEffect(() => {
    if (condition) resetZoom();
  }, deps);
};
