import cx from 'classnames';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import { formatPhone } from '@utils/formatPhone';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { type ReservationAttributes } from '../../apiHelpers';
import styles from './ReservationReportItem.scss';

export const ReservationReportItem = ({
  guest: { firstName, lastName, phone },
  guestCount,
  guestNotes,
  guestTags,
  hostBookedBy,
  isStranded,
  listingName,
  time,
}: ReservationAttributes) => (
  <li className={styles.reservation}>
    <section>
      <p className={typography.h5}>{ISOTimeTo12HourTime(time)}</p>
      <p className={cx(typography.c2, styles.stranded)}>
        {isStranded && <span>STRANDED</span>}
      </p>
      <p className={typography.c2}>{appendGuestCountLabel(guestCount)}</p>
      <p className={typography.c2}>{listingName}</p>
      {hostBookedBy && (
        <p className={cx(typography.t1, styles.fillRow, styles.booker)}>
          Booked by {hostBookedBy}
        </p>
      )}
    </section>
    <section>
      <p className={typography.h5}>{`${firstName} ${lastName}`}</p>
      {phone && (
        <p className={cx(typography.c2, styles.fillRow)}>
          {formatPhone(phone)}
        </p>
      )}
      {guestTags && <GuestTags tags={guestTags} />}
      {guestNotes && (
        <p className={cx(styles.fillRow, styles.notes)}>{guestNotes}</p>
      )}
    </section>
  </li>
);

const GuestTags = ({ tags }: { tags: string[] }) => (
  <ul className={styles.tagList}>
    {tags.map((tag: string) => {
      const tagClass = cx(typography.t1, {
        [styles.tagVIP]: tag.toLowerCase() === 'vip',
        [styles.tagStranded]: tag.toLowerCase() === 'stranded',
      });

      return (
        <li key={tag} className={tagClass}>
          {tag}
        </li>
      );
    })}
  </ul>
);
