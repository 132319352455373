import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

interface CloseRestaurantModalProps {
  closeModal: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}

export const OpenRestaurantModal = ({
  closeModal,
  onConfirm,
  isOpen,
}: CloseRestaurantModalProps) =>
  isOpen ? (
    <Modal
      ariaLabel="Open restaurant"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you want to open the restaurant for the day?
      You will accept new reservations to be booked by doing this."
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Confirm"
          onClick={onConfirm}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  ) : null;
