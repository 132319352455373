import { ISOTimeFloorToQuarterHour } from '@utils/time';
import type { ServiceOccupant } from '../apiHelpers';

type Time = string;
type TimeMap<T> = Record<Time, T>;

export const groupOccupantsByFifteenMinuteIncrement = (
  sortedOccupants: ServiceOccupant[],
): TimeMap<ServiceOccupant[]> =>
  sortedOccupants.reduce(
    (acc: TimeMap<ServiceOccupant[]>, occupant: ServiceOccupant) => {
      const key = ISOTimeFloorToQuarterHour(occupant.time);

      if (!acc[key]) acc[key] = [];

      acc[key].push(occupant);

      return acc;
    },
    {},
  );
