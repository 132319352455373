// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card__card___QqoxS{background:var(--primary500);border-radius:.5rem;padding:1rem}.Card__card___QqoxS.Card__bordered___rONCH{border:1px solid var(--hairline)}", "",{"version":3,"sources":["webpack://./src/shared/components/card/Card.scss"],"names":[],"mappings":"AAAA,oBACE,4BAAA,CACA,mBAAA,CACA,YAAA,CAEA,2CACE,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Card__card___QqoxS",
	"bordered": "Card__bordered___rONCH"
};
export default ___CSS_LOADER_EXPORT___;
