import cx from 'classnames';
import { parseToShortDateRange } from '@utils/dateFormatters';
import { ISOTimeTo12HourTime } from '@utils/time';
import { formatWeekDays } from '@utils/weekDayFormatters';
import typography from '~styles/typography.scss';
import { RestaurantSettingsSection } from '../shared/RestaurantSettingsSection';
import { ServiceWindowsForm } from './ServiceWindowsForm';
import styles from './ServiceWindowsSetting.scss';

export interface ServiceWindowsSettingProps {
  serviceWindows: {
    id: string;
    startDate: string;
    endDate: string | null;
    repeat: string[];
    name: string;
    startTime: string;
    endTime: string;
  }[];
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const ServiceWindowsSetting = ({
  serviceWindows,
  refreshRestaurant,
  restaurantId,
}: ServiceWindowsSettingProps) => (
  <RestaurantSettingsSection
    heading="Service Windows"
    isEditable
    renderForm={(exitEditMode) => (
      <ServiceWindowsForm
        serviceWindows={serviceWindows}
        exitEditMode={exitEditMode}
        refreshRestaurant={refreshRestaurant}
        restaurantId={restaurantId}
      />
    )}
  >
    <p className={typography.t2}>
      Create service windows for your restaurant. This setting will help define
      the reservable days and times of your listings.
    </p>
    {serviceWindows.length ? (
      <ul className={styles.list}>
        {serviceWindows.map(
          ({ id, name, startDate, endDate, startTime, endTime, repeat }) => (
            <li key={id}>
              <span className={cx(typography.h5, styles.name)}>{name}</span>
              <span
                className={cx(typography.t1, styles.time)}
              >{`${ISOTimeTo12HourTime(startTime)}–${ISOTimeTo12HourTime(endTime)}`}</span>
              <span className={cx(typography.t1, styles.date)}>
                {parseToShortDateRange(startDate, endDate)}
              </span>
              <span className={cx(typography.t1, styles.days)}>
                {formatWeekDays(repeat)}
              </span>
            </li>
          ),
        )}
      </ul>
    ) : (
      <h3 className={typography.h6}>No service windows created</h3>
    )}
  </RestaurantSettingsSection>
);
