import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

export interface WalkInTurnTimeInformationModalProps {
  closeModal: () => void;
  handleConfirm: () => void;
  turnTime: number;
  isOpen: boolean;
}

export const WalkInTurnTimeInformationModal = ({
  closeModal,
  handleConfirm,
  turnTime,
  isOpen,
}: WalkInTurnTimeInformationModalProps) =>
  isOpen ? (
    <Modal
      disablePortal
      isOpen={isOpen}
      onClose={closeModal}
      subtitle={`The table you are seating on has a maximum turn time of ${turnTime} minutes.`}
      title="Turn Time Information"
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Continue"
          onClick={() => {
            handleConfirm();
            closeModal();
          }}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  ) : null;
