import { kebabCase } from 'lodash-es';
import { type ReactNode, useState } from 'react';
import { Card } from '@components/card/Card';
import typography from '~styles/typography.scss';
import styles from './RestaurantSettingsSection.scss';

interface RestaurantSettingsSectionProps {
  children: ReactNode;
  isEditable?: boolean;
  heading: string;
  renderForm?: (exitEditMode: () => void) => ReactNode;
}

export const RestaurantSettingsSection = ({
  children,
  isEditable = false,
  heading,
  renderForm,
}: RestaurantSettingsSectionProps) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Card bordered className={styles.card}>
      <header>
        <h2 className={typography.h4} id={kebabCase(heading)}>
          {heading}
        </h2>
        {isEditable && !isEditing && (
          <button className={typography.c2} onClick={() => setIsEditing(true)}>
            Edit
          </button>
        )}
      </header>
      {isEditing ? renderForm?.(() => setIsEditing(false)) : children}
    </Card>
  );
};
