import { ISOTimeTo12HourTime, TIME_VALUES } from '@shared/utils/time';
import { type FormSelectOption } from './ControlledFormSelect';

const TIME_OPTIONS: FormSelectOption[] = TIME_VALUES.map((time) => ({
  label: ISOTimeTo12HourTime(time),
  value: time,
}));

export const filterTimeOptions = (minTime: string, maxTime: string) => {
  let options = TIME_OPTIONS;
  const minTimeIndex = options.findIndex(({ value }) => value === minTime);
  const maxTimeIndex = options.findIndex(({ value }) => value === maxTime);
  const hasValidMinTime = minTimeIndex >= 0;
  const hasValidMaxTime = maxTimeIndex >= 0;
  if (!hasValidMinTime && minTime !== '') {
    throw new Error(`Invalid minTime: ${minTime} in ControlledFormTimeSelect`);
  }
  if (!hasValidMaxTime && maxTime !== '') {
    throw new Error(`Invalid maxTime: ${maxTime} in ControlledFormTimeSelect`);
  }
  if (hasValidMinTime && hasValidMaxTime && minTime > maxTime) {
    throw new Error(
      'minTime must be less than or equal to maxTime in ControlledFormTimeSelect',
    );
  }
  if (hasValidMaxTime) {
    options = options.slice(0, maxTimeIndex + 1);
  }
  if (hasValidMinTime) {
    options = options.slice(minTimeIndex);
  }
  return options;
};
