import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import {
  Status,
  StatusTag,
} from 'restaurantAdmin/components/statusTag/StatusTag';
import typography from '~styles/typography.scss';
import { type ServiceWalkIn } from '../apiHelpers';
import { ServiceStatusSelect } from '../serviceStatus/ServiceStatusSelect';
import styles from './ActiveReservationCard.scss';
import { ServerBadge } from './servers/ServerBadge';

export interface WalkInCardProps {
  onClick: () => void;
  onUpdated: () => void;
  walkIn: ServiceWalkIn;
}

export const SeatedWalkInCard = ({
  onClick,
  onUpdated,
  walkIn,
}: WalkInCardProps) => {
  const walkInName =
    `${walkIn.firstName || ''} ${walkIn.lastName || ''}`.trim() || 'WALK-IN';

  const { server } = walkIn;

  return (
    <li aria-label={`walk-in card for ${walkInName}`} className={styles.card}>
      <ServiceStatusSelect
        occupantType="walkIn"
        occupantId={walkIn.id}
        onUpdated={onUpdated}
        value={walkIn.serviceStatus}
      />
      <button
        className={styles.button}
        onClick={onClick}
        aria-label="walk-in details"
      >
        <div className={styles.infoContainer}>
          <div className={styles.timeAndStatusRow}>
            <div className={cx(typography.h8, styles.time)}>
              {ISOTimeTo12HourTime(walkIn.time)}
            </div>
            <div className={styles.status}>
              <div className={typography.t3}>Seated</div>
              {server && (
                <ServerBadge
                  displayMode="card"
                  serverName={server.name}
                  badgeColor={server.hexColor}
                />
              )}
            </div>
          </div>
          <div className={styles.nameAndGuestCountRow}>
            <div className={styles.userFullName}>{walkInName}</div>
            <div className={styles.guestStack}>
              <span className={cx(typography.t3, styles.arrow)}>
                {walkIn.seatedTableName}
              </span>
              <div
                aria-label={appendGuestCountLabel(walkIn.guestCount)}
                className={styles.guestCount}
              >
                <Icon name="couple" /> {walkIn.guestCount}
              </div>
            </div>
          </div>
        </div>
        {walkIn.hasUpcomingReservation && (
          <div className={styles.cardFooter}>
            <StatusTag status={Status.Upcoming} />
          </div>
        )}
      </button>
    </li>
  );
};
