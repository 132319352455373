import cx from 'classnames';
import typography from '~styles/typography.scss';
import { formatServerNameAsInitials } from '../../serverUtils';
import styles from './ServerBadge.scss';

interface ServerBadgeProps {
  displayMode: 'card' | 'floorPlan';
  serverName: string;
  badgeColor?: string;
}

export const ServerBadge = ({
  displayMode,
  serverName,
  badgeColor,
}: ServerBadgeProps) => {
  const badgeStyles = cx({
    [styles.container]: true,
    [typography.h8]: displayMode === 'card',
    [styles.card]: displayMode === 'card',
    [typography.t3_12]: displayMode === 'floorPlan',
    [styles.floorPlan]: displayMode === 'floorPlan',
  });

  return (
    <div
      data-testid="server-badge"
      className={badgeStyles}
      {...(badgeColor && { style: { backgroundColor: badgeColor } })}
    >
      {formatServerNameAsInitials(serverName)}
    </div>
  );
};
