import { tz } from '@date-fns/tz';
import cx from 'classnames';
import { addMinutes, format, secondsToMilliseconds } from 'date-fns';
import { debounce } from 'lodash-es';
import { useMemo } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import { ContactInfo } from 'restaurantAdmin/components/contactInfo/ContactInfo';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import typography from '~styles/typography.scss';
import { sendTableReadyMessage, type WaitListEntry } from './apiHelpers';
import { RemoveFromWaitListConfirmationModal } from './RemoveFromWaitListConfirmationModal';
import { useWaitListContext } from './state/WaitListContext';
import styles from './WaitListSheet.scss';
import { WaitListTimer } from './WaitListTimer';

interface WaitListSheetProps {
  className?: string;
  onCanceled: () => void;
  onClose: () => void;
  onEnableSeatMode: () => void;
  waitListEntry: WaitListEntry;
  seatModeEnabled: boolean;
}

const SEND_TABLE_READY_DEBOUNCE_DURATION = secondsToMilliseconds(1);

export const WaitListSheet = ({
  className,
  onCanceled,
  onClose,
  onEnableSeatMode,
  waitListEntry,
  seatModeEnabled,
}: WaitListSheetProps) => {
  const { timezone } = useRestaurant();
  const { close, isOpen, open } = useIsOpen();

  const waitEndDate = addMinutes(
    new Date(waitListEntry.createdAt),
    waitListEntry.estimatedWait,
  );
  const waitEndDisplay = format(waitEndDate, 'p', { in: tz(timezone) });

  const hasMoreThanOneSendTableReadyMessageEvent =
    waitListEntry.events.filter(
      (event) => event.type === 'sendTableReadyMessage',
    ).length > 1;
  const { fetchWaitListEntries } = useWaitListContext();

  const handleClickSendTableReadyMessage = useMemo(
    () =>
      debounce(async () => {
        try {
          await sendTableReadyMessage(
            waitListEntry.restaurantId,
            waitListEntry.id,
          );
        } catch (e) {
          if (e instanceof ApiError) {
            errorToast({ message: e.message });
          }
          reportAppError(e);
          return;
        }
        fetchWaitListEntries();
        successToast({ message: 'SMS message sent' });
      }, SEND_TABLE_READY_DEBOUNCE_DURATION),
    [waitListEntry],
  );

  const refetchAndNavigate = () => {
    fetchWaitListEntries();
    onCanceled();
  };

  return (
    <div
      aria-label="Wait List Sheet"
      className={cx(styles.container, className)}
    >
      <section className={styles.controls}>
        <div />
        <IconButton
          ariaLabel="close sheet"
          onClick={onClose}
          iconName="close"
        />
      </section>
      <section className={styles.essentialInfo}>
        <div>
          <WaitListTimer waitListEntry={waitListEntry} />
          <p className={cx(typography.h5, styles.name)}>
            {waitListEntry.firstName} {waitListEntry.lastName}
          </p>
        </div>
        <div>
          <p className={cx(typography.h6, styles.waitTime)}>
            Estimate - {waitEndDisplay}
          </p>
          <p className={cx(typography.h7, styles.guestCount)}>
            {appendGuestCountLabel(waitListEntry.guestCount)}
          </p>
        </div>
      </section>
      {!seatModeEnabled && (
        <Button
          label="Seat Guest"
          variant={ButtonVariants.Primary}
          onClick={onEnableSeatMode}
        />
      )}
      {!seatModeEnabled && (
        <Button
          isDisabled={hasMoreThanOneSendTableReadyMessageEvent}
          label="Send Table Ready Message"
          variant={ButtonVariants.Secondary}
          onClick={handleClickSendTableReadyMessage}
        />
      )}
      <ContactInfo phone={waitListEntry.phone} />
      {!seatModeEnabled && (
        <>
          <Button
            className={typography.h7}
            label="Remove From Waitlist"
            variant={ButtonVariants.Error}
            onClick={open}
          />
          <RemoveFromWaitListConfirmationModal
            closeModal={close}
            isOpen={isOpen}
            onCanceled={refetchAndNavigate}
            waitListEntry={waitListEntry}
          />
        </>
      )}
    </div>
  );
};
