// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorHero__button___dcHX0{margin:24px auto 0;width:fit-content}.ErrorHero__container___Xv6R2{display:flex;flex-direction:column;padding:15vh 32px 32px;text-align:center}@media only screen and (min-width: 768px){.ErrorHero__container___Xv6R2{padding-top:20vh}}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/errors/ErrorHero.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,2BACE,kBAAA,CACA,iBAAA,CAGF,8BACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,iBAAA,CCqBA,0CDzBF,8BAOI,gBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ErrorHero__button___dcHX0",
	"container": "ErrorHero__container___Xv6R2"
};
export default ___CSS_LOADER_EXPORT___;
