// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServiceWindowsSetting__list___nQOPR{display:flex;flex-direction:column;row-gap:24px}.ServiceWindowsSetting__list___nQOPR>li{display:grid;grid-template-columns:1fr 1fr;row-gap:4px;align-items:center}.ServiceWindowsSetting__list___nQOPR>li .ServiceWindowsSetting__name___TXQSX{color:var(--white)}.ServiceWindowsSetting__list___nQOPR>li .ServiceWindowsSetting__time___wg1hQ{justify-self:flex-end;color:var(--white)}.ServiceWindowsSetting__list___nQOPR>li .ServiceWindowsSetting__date___kY8rt{color:var(--white70)}.ServiceWindowsSetting__list___nQOPR>li .ServiceWindowsSetting__days___LDP8N{justify-self:flex-end;color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/serviceWindows/ServiceWindowsSetting.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,qBAAA,CACA,YAAA,CAEA,wCACE,YAAA,CACA,6BAAA,CACA,WAAA,CACA,kBAAA,CAEA,6EACE,kBAAA,CAGF,6EACE,qBAAA,CACA,kBAAA,CAGF,6EACE,oBAAA,CAGF,6EACE,qBAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "ServiceWindowsSetting__list___nQOPR",
	"name": "ServiceWindowsSetting__name___TXQSX",
	"time": "ServiceWindowsSetting__time___wg1hQ",
	"date": "ServiceWindowsSetting__date___kY8rt",
	"days": "ServiceWindowsSetting__days___LDP8N"
};
export default ___CSS_LOADER_EXPORT___;
