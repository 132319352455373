// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InviteTeamMemberPage__form___pQDNr{align-items:center;display:flex;flex-direction:column;gap:3rem;margin:2.5rem auto 0;width:90%}@media only screen and (min-width: 810px){.InviteTeamMemberPage__form___pQDNr{max-width:47rem;width:70%}}.InviteTeamMemberPage__form___pQDNr fieldset{display:flex;gap:2rem;justify-content:space-between;width:100%}.InviteTeamMemberPage__form___pQDNr fieldset h2{width:max-content}.InviteTeamMemberPage__form___pQDNr fieldset h2.InviteTeamMemberPage__error____69I_{color:var(--pink)}.InviteTeamMemberPage__form___pQDNr fieldset .InviteTeamMemberPage__fieldsContainer___TG7Xg{background-color:var(--darkBlue);border-radius:.5rem;padding:2.5rem;flex:auto 2 1;border:solid 1px rgba(0,0,0,0)}.InviteTeamMemberPage__form___pQDNr fieldset .InviteTeamMemberPage__fieldsContainer___TG7Xg.InviteTeamMemberPage__errorBorder___DPLWv{border:solid 1px var(--pink)}.InviteTeamMemberPage__formActions___JGLA3{display:flex;gap:1rem;justify-content:stretch;margin-left:auto;width:30rem}.InviteTeamMemberPage__formActions___JGLA3 button{flex:1}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/team/InviteTeamMemberPage.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,oCACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,oBAAA,CACA,SAAA,CCwBA,0CD9BF,oCASI,eAAA,CACA,SAAA,CAAA,CAGF,6CACE,YAAA,CACA,QAAA,CACA,6BAAA,CACA,UAAA,CAEA,gDACE,iBAAA,CAEA,oFACE,iBAAA,CAIJ,4FACE,gCAAA,CACA,mBAAA,CACA,cAAA,CACA,aAAA,CACA,8BAAA,CAEA,sIACE,4BAAA,CAMR,2CACE,YAAA,CACA,QAAA,CACA,uBAAA,CACA,gBAAA,CACA,WAAA,CAEA,kDACE,MAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "InviteTeamMemberPage__form___pQDNr",
	"error": "InviteTeamMemberPage__error____69I_",
	"fieldsContainer": "InviteTeamMemberPage__fieldsContainer___TG7Xg",
	"errorBorder": "InviteTeamMemberPage__errorBorder___DPLWv",
	"formActions": "InviteTeamMemberPage__formActions___JGLA3"
};
export default ___CSS_LOADER_EXPORT___;
