// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabsList__tabList___c6duR{display:flex;flex-wrap:wrap;margin-bottom:1rem}.TabsList__tabList___c6duR button{background:none;color:inherit;border:none;border-bottom:1px solid var(--hairline);border-top:1px solid rgba(0,0,0,0);cursor:pointer;flex:1 1 0;padding-bottom:1rem;padding-top:1rem}.TabsList__tabList___c6duR button[aria-selected=true]{border-bottom:solid 2px var(--accent300);padding-bottom:calc(1rem - 1px)}.TabsList__tabList___c6duR button:focus[aria-selected=true]{border-bottom:solid 2px var(--accent300);padding-bottom:calc(1rem - 1px)}.TabsList__tabList___c6duR button:focus-visible[aria-selected=true]{outline:dashed 1px var(--darkGrey200);outline-offset:-1px;border-bottom:solid 2px var(--accent300);padding-bottom:calc(1rem - 1px)}.TabsList__tabList___c6duR button:focus-visible{outline:dashed 1px var(--darkGrey200);outline-offset:-1px}.TabsList__tabList___c6duR button:hover[aria-selected=false]{border-bottom:solid 2px var(--darkGrey100);padding-bottom:calc(1rem - 1px)}", "",{"version":3,"sources":["webpack://./src/shared/components/tabs/TabsList.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,cAAA,CACA,kBAAA,CAEA,kCACE,eAAA,CACA,aAAA,CACA,WAAA,CACA,uCAAA,CACA,kCAAA,CACA,cAAA,CACA,UAAA,CACA,mBAAA,CACA,gBAAA,CAEA,sDACE,wCAAA,CACA,+BAAA,CAGF,4DACE,wCAAA,CACA,+BAAA,CAGF,oEACE,qCAAA,CACA,mBAAA,CACA,wCAAA,CACA,+BAAA,CAGF,gDACE,qCAAA,CACA,mBAAA,CAGF,6DACE,0CAAA,CACA,+BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabList": "TabsList__tabList___c6duR"
};
export default ___CSS_LOADER_EXPORT___;
