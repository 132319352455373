import cx from 'classnames';
import type { ReactElement } from 'react';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import type { AdminAvailabilityData } from '../concierge/apiHelpers';
import { NoTables } from '../concierge/NoTables';
import styles from './AdminAvailability.scss';
import type { SelectedAdminAvailabilityProps } from './AdminAvailabilityItem';
import { AdminAvailabilityItem } from './AdminAvailabilityItem';

type AdminAvailabilityProps = Omit<
  SelectedAdminAvailabilityProps,
  'selectedDate'
> & {
  availabilities: AdminAvailabilityData[];
  availabilityItemAction: ReactElement;
  handleShowEarlier: () => void;
  handleShowLater: () => void;
  hasEarlier: boolean;
  hasLater: boolean;
};

export const AdminAvailability = ({
  availabilities,
  availabilityItemAction,
  handleShowEarlier,
  handleShowLater,
  hasEarlier,
  hasLater,
  selectedAvailability,
  selectedGuestCount,
  setSelectedAvailability,
}: AdminAvailabilityProps) => {
  if (!availabilities.length) return <NoTables />;

  return (
    <div data-testid="availability-container" className={styles.container}>
      {hasEarlier && (
        <button
          className={cx(typography.h7m, styles.showMoreButton)}
          onClick={handleShowEarlier}
        >
          <Icon className={styles.up} name="chevron" />
          Show Earlier Times
        </button>
      )}
      <ul className={styles.list} aria-label="Restaurant Availabilities">
        {availabilities.map((a) => (
          <AdminAvailabilityItem
            availability={a}
            availabilityItemAction={availabilityItemAction}
            key={a.listing.id + a.time}
            selectedAvailability={selectedAvailability}
            selectedGuestCount={selectedGuestCount}
            setSelectedAvailability={setSelectedAvailability}
          />
        ))}
      </ul>
      {hasLater && (
        <button
          className={cx(typography.h7m, styles.showMoreButton)}
          onClick={handleShowLater}
        >
          <Icon className={styles.down} name="chevron" />
          Show Later Times
        </button>
      )}
    </div>
  );
};
