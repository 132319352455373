import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import styles from './GuestSearchBar.scss';

interface GuestSearchBarProps {
  onSearchSubmit: (searchText: string) => void;
}

interface SearchFormData {
  searchText: string;
}

export const GuestSearchBar = ({ onSearchSubmit }: GuestSearchBarProps) => {
  const { handleSubmit, register } = useForm<SearchFormData>({});
  return (
    <form
      onSubmit={handleSubmit(({ searchText }) => {
        onSearchSubmit(searchText);
      })}
      className={styles.form}
    >
      <input
        autoComplete="off"
        placeholder="Find Guest"
        {...register('searchText')}
      />
      <Button label="Search" type="submit" variant={ButtonVariants.Tertiary} />
    </form>
  );
};
