import { createContext, type ReactNode, useMemo, useReducer } from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import { useRestaurant } from '../../../context/useRestaurant';
import * as apiHelpers from '../apiHelpers';
import {
  canUndoMerge,
  isMergeable,
  isReadyToPersistMerge,
  isSelectedForMerge,
  isStagedForUnmerge,
  isStagedPrimaryMergedTableId,
  isStagedSecondaryMergedTableId,
  isUnmergeable,
} from './splitMergeTablesDerivedState';
import {
  clearMerges,
  selectTable,
  splitMergeTablesInitialState,
  splitMergeTablesReducer,
  stageMerge,
  stageUnMerge,
  undoMerge,
  unSelectTable,
} from './splitMergeTablesReducer';

export interface SplitMergeTablesContextState {
  // derived state
  canUndoMerge: boolean;
  isMergeable: boolean;
  isReadyToPersistMerge: boolean;
  isSelectedForMerge: (tableId: string) => boolean;
  isStagedForUnmerge: (tableId: string) => boolean;
  isStagedPrimaryMergedTableId: (tableId: string) => boolean;
  isStagedSecondaryMergedTableId: (tableId: string) => boolean;
  isUnmergeable: boolean;
  // handlers
  clearMergeState: () => void;
  persistMergedTables: () => Promise<void>;
  selectTableForMerge: (table: HostFloorPlanTable) => void;
  stageMerge: () => void;
  stageUnmerge: () => void;
  undoMerge: () => void;
  unselectTableForMerge: (tableId: string) => void;
}

const SplitMergeTablesContext =
  createContext<SplitMergeTablesContextState | null>(null);
SplitMergeTablesContext.displayName = 'SplitMergeTables';

export const useSplitMergeTables = (): SplitMergeTablesContextState =>
  useDefinedContext(SplitMergeTablesContext);

export const SplitMergeTablesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    splitMergeTablesReducer,
    splitMergeTablesInitialState,
  );
  const { mergedTables, unMergedTableIds } = state;

  // custom hooks
  const { id: restaurantId } = useRestaurant();

  const persistMergedTables = async () => {
    await apiHelpers.mergeTables(restaurantId, mergedTables, unMergedTableIds);
  };

  const value = useMemo<SplitMergeTablesContextState>(
    () => ({
      // derived state
      canUndoMerge: canUndoMerge(state),
      isMergeable: isMergeable(state),
      isReadyToPersistMerge: isReadyToPersistMerge(state),
      isSelectedForMerge: isSelectedForMerge(state),
      isStagedForUnmerge: isStagedForUnmerge(state),
      isStagedPrimaryMergedTableId: isStagedPrimaryMergedTableId(state),
      isStagedSecondaryMergedTableId: isStagedSecondaryMergedTableId(state),
      isUnmergeable: isUnmergeable(state),
      // handlers
      clearMergeState: () => dispatch(clearMerges()),
      persistMergedTables,
      selectTableForMerge: (selectedTable: HostFloorPlanTable) =>
        dispatch(selectTable(selectedTable)),
      stageMerge: () => dispatch(stageMerge()),
      stageUnmerge: () => dispatch(stageUnMerge()),
      undoMerge: () => dispatch(undoMerge()),
      unselectTableForMerge: (unSelectedTableId: string) =>
        dispatch(unSelectTable(unSelectedTableId)),
    }),
    [state],
  );

  return (
    <SplitMergeTablesContext.Provider value={value}>
      {children}
    </SplitMergeTablesContext.Provider>
  );
};
