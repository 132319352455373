import cx from 'classnames';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { useSplitMergeTables } from 'restaurantAdmin/reservations/splitMergeTables/state/SplitMergeTablesContext';
import typography from '~styles/typography.scss';
import styles from './SplitMergeTablesSheet.scss';

export interface SplitMergeTablesSheetProps {
  className?: string;
  handleOnCloseSheet: () => void;
}

export const SplitMergeTablesSheet = ({
  className,
  handleOnCloseSheet,
}: SplitMergeTablesSheetProps) => {
  const {
    canUndoMerge,
    clearMergeState,
    isMergeable,
    isReadyToPersistMerge,
    isUnmergeable,
    persistMergedTables,
    stageMerge,
    stageUnmerge,
    undoMerge,
  } = useSplitMergeTables();

  const handleMergeTablesClick = () => {
    stageMerge();
  };

  const handleUnmergeTablesClick = () => {
    stageUnmerge();
  };

  const handleSaveAndExit = () => {
    void (async () => {
      try {
        await persistMergedTables();
        successToast({ message: 'Table changes updated successfully' });
        clearMergeState();
        handleOnCloseSheet();
      } catch (error) {
        if (error instanceof Error) {
          errorToast({ message: error.message });
        } else {
          errorToast({ message: 'An error occurred' });
        }
        reportAppError(error);
      }
    })();
  };

  const handleCancel = () => {
    clearMergeState();
    handleOnCloseSheet();
  };

  const handleUndoMerge = () => {
    undoMerge();
  };

  return (
    <Card bordered className={cx(styles.container, className)}>
      <h2 className={typography.h4}>Split/Merge Tables</h2>
      <ul className={typography.c2}>
        <li>Select multiple tables to merge them.</li>
        <li>Select a merged table to un-merge it.</li>
      </ul>
      {isMergeable && (
        <Button
          label="Merge Tables"
          onClick={handleMergeTablesClick}
          variant={ButtonVariants.Primary}
        />
      )}
      {canUndoMerge && (
        <Button
          label="Undo Merge"
          onClick={handleUndoMerge}
          variant={ButtonVariants.Primary}
        />
      )}
      {isUnmergeable && (
        <Button
          label="Unmerge Table"
          onClick={handleUnmergeTablesClick}
          variant={ButtonVariants.Primary}
        />
      )}
      {isReadyToPersistMerge && (
        <Button
          label="Save and Exit"
          onClick={handleSaveAndExit}
          variant={ButtonVariants.Secondary}
        />
      )}
      <Button
        label="Cancel"
        onClick={handleCancel}
        variant={ButtonVariants.Tertiary}
      />
    </Card>
  );
};
