import type { CancellationPolicy } from '@shared/reservations/types';
import { centsToWholeDollar } from '@shared/utils/currency';
import typography from '~styles/typography.scss';
import { RestaurantSettingsSection } from '../shared/RestaurantSettingsSection';
import { CancellationPolicyForm } from './CancellationPolicyForm';
import { formatHoursBeforeReservation } from './utils';

export interface CancellationPolicySettingsProps {
  cancellationPolicy: CancellationPolicy | null;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}
export const CancellationPolicySettings = ({
  cancellationPolicy,
  refreshRestaurant,
  restaurantId,
}: CancellationPolicySettingsProps) => {
  const hasCancellationPolicy = !!cancellationPolicy;

  return (
    <RestaurantSettingsSection
      heading="Cancellation Policy"
      isEditable
      renderForm={(exitEditMode) => (
        <CancellationPolicyForm
          cancellationPolicy={cancellationPolicy}
          exitEditMode={exitEditMode}
          refreshRestaurant={refreshRestaurant}
          restaurantId={restaurantId}
        />
      )}
    >
      <p className={typography.t2}>
        Set the cancellation policy for guests. Guests canceling reservations at
        or below the threshold price within the set time period will be subject
        to the cancellation fee at your discretion.
      </p>
      <p className={typography.t2}>
        Admins will always have the final say on whether to charge or waive the
        fee, up to 72 hours after the reservation.
      </p>
      <h3 className={typography.h6}>
        {hasCancellationPolicy ? 'Fee' : 'No cancellation policy set'}
      </h3>
      {hasCancellationPolicy && (
        <>
          <div className={typography.c2}>
            {centsToWholeDollar(cancellationPolicy.fee)}
          </div>
          <h3 className={typography.h6}>Time Before Fees Apply</h3>
          <div className={typography.c2}>
            {formatHoursBeforeReservation(
              cancellationPolicy.hoursBeforeReservation,
            )}
          </div>
          <h3 className={typography.h6}>Price Threshold</h3>
          <div className={typography.c2}>
            {centsToWholeDollar(cancellationPolicy.threshold)}
          </div>
        </>
      )}
    </RestaurantSettingsSection>
  );
};
