import { api } from '@shared/api';

interface CustomerSessionClientSecret {
  customerSessionClientSecret: string;
}

export const getCustomerSessionClientSecret = async (): Promise<string> => {
  const { customerSessionClientSecret } =
    await api.post<CustomerSessionClientSecret>(
      `/payments/customer-payment-session`,
    );

  return customerSessionClientSecret;
};
