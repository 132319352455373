import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useRestaurant } from '../context/useRestaurant';
import type { GuestReservation } from './apiHelpers';
import { getReservationsForGuest } from './apiHelpers';
import { GuestReservationItem } from './GuestReservationItem';
import styles from './GuestReservations.scss';

interface GuestReservationsProps {
  guestId: string;
  handleGuestReservationClick: (reservation: GuestReservation) => void;
  selectedGuestReservationId?: string;
}

export const GuestReservations = ({
  guestId,
  handleGuestReservationClick,
  selectedGuestReservationId,
}: GuestReservationsProps) => {
  const { id: restaurantId } = useRestaurant();

  const { data: reservations = [] } = useAbortEffect(
    () => getReservationsForGuest(restaurantId, guestId),
    [guestId],
  );

  if (!reservations.length) {
    return null;
  }

  return (
    <ul aria-label="Reservations" className={styles.container}>
      {reservations.map((reservation) => (
        <GuestReservationItem
          handleGuestReservationClick={handleGuestReservationClick}
          key={reservation.id}
          reservation={reservation}
          selectedGuestReservationId={selectedGuestReservationId}
        />
      ))}
    </ul>
  );
};
