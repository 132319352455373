import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js';
import { type ReactNode, useEffect, useState } from 'react';
import { getCustomerSessionClientSecret } from './apiHelpers';

const getApiKey = () => {
  const apiKey = process.env.STRIPE_API_PUBLISHABLE_KEY;
  if (!apiKey) {
    throw new Error('Stripe Api Publishable Key is required');
  }
  return apiKey;
};

const promise = loadStripe(getApiKey(), { apiVersion: '2024-04-10' });

interface StripeElementsProps {
  children: ReactNode;
}

interface NewStripeElementsProps {
  children: ReactNode;
  amount?: number;
}

export const StripeElements = ({ children }: StripeElementsProps) => (
  <Elements stripe={promise}>{children}</Elements>
);

const defaultOptions: StripeElementsOptions = {
  appearance: {
    labels: 'floating',
    rules: {
      '.CheckboxLabel': { color: '#fff' },
      '.Input:focus': {
        border: '1px solid #b69967',
      },
    },
    variables: {
      colorBackground: '#18202d',
      colorText: 'white',
      tabIconColor: 'white',
    },
  },
  currency: 'usd',
  setup_future_usage: 'off_session',
};

// Will replace StripeElements so rename it, probably to StripeElementsContext for future use
export const NewStripeElements = ({
  children,
  amount,
}: NewStripeElementsProps) => {
  const [customerSessionClientSecret, setCustomerSessionClientSecret] =
    useState<string>();

  const fetchCustomerSessionClientSecret = async () => {
    const newCustomerSessionClientSecret =
      await getCustomerSessionClientSecret();

    setCustomerSessionClientSecret(newCustomerSessionClientSecret);
  };

  useEffect(() => {
    void fetchCustomerSessionClientSecret();
  }, []);

  const options: StripeElementsOptions = {
    ...defaultOptions,
    mode: amount ? 'payment' : 'setup',
    ...(amount && { amount }),
    customerSessionClientSecret,
  };

  return (
    customerSessionClientSecret && (
      <Elements stripe={promise} options={options}>
        {children}
      </Elements>
    )
  );
};
