import { type ReservationMetricsFilter } from './ReservationsMetrics';
import styles from './ReservationsMetricsPopover.scss';

export interface ReservationsMetricsPopoverProps {
  finished: number;
  upcoming: number;
  noShow: number;
  total: number;
  setFilterAndClosePopover: (filter: ReservationMetricsFilter) => void;
}

export const ReservationsMetricsPopover = ({
  finished,
  upcoming,
  noShow,
  total,
  setFilterAndClosePopover,
}: ReservationsMetricsPopoverProps) => {
  const EM_DASH = <code>&#8212;&nbsp;</code>;

  const finishedPercentage = total
    ? Math.ceil((finished / total) * 100)
    : EM_DASH;
  const noShowPercentage = total ? Math.ceil((noShow / total) * 100) : EM_DASH;
  const upcomingPercentage = total
    ? Math.ceil((upcoming / total) * 100)
    : EM_DASH;

  return (
    <div className={styles.container} data-testid="reservation-metrics-popover">
      <button
        onClick={() => {
          setFilterAndClosePopover('total');
        }}
      >
        <div>
          <strong>Reservations</strong>
        </div>
        <div>All Day</div>
        <div>
          <strong>{total}</strong>
        </div>
      </button>

      <button
        onClick={() => {
          setFilterAndClosePopover('finished');
        }}
      >
        <div>Finished</div>
        <div>{finishedPercentage}%</div>
        <div>{finished}</div>
      </button>

      <button
        onClick={() => {
          setFilterAndClosePopover('upcoming');
        }}
      >
        <div>Upcoming</div>
        <div>{upcomingPercentage}%</div>
        <div>{upcoming}</div>
      </button>

      <button
        onClick={() => {
          setFilterAndClosePopover('noShow');
        }}
      >
        <div>No Show</div>
        <div>{noShowPercentage}%</div>
        <div>{noShow}</div>
      </button>
    </div>
  );
};
