// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormSelect__defaultLabelTypography____DBW8>span{display:flex;flex-direction:row;font-size:14px;font-weight:400;gap:.5rem;line-height:16px}.FormSelect__iconButton___MxD8U{align-items:center;background-color:rgba(0,0,0,0);display:inline-flex;justify-content:center}.FormSelect__iconButton___MxD8U:active,.FormSelect__iconButton___MxD8U:focus,.FormSelect__iconButton___MxD8U:hover{background-color:var(--accent300);stroke:var(--darkBlue)}.FormSelect__iconButton___MxD8U svg{height:20px;min-width:20px;stroke:var(--gold);width:20px}.FormSelect__selectPlusError___ER5qZ{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/shared/components/formSelect/FormSelect.scss"],"names":[],"mappings":"AACE,iDACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,SAAA,CACA,gBAAA,CAIJ,gCACE,kBAAA,CACA,8BAAA,CACA,mBAAA,CACA,sBAAA,CAEA,mHAGE,iCAAA,CACA,sBAAA,CAGF,oCACE,WAAA,CACA,cAAA,CACA,kBAAA,CACA,UAAA,CAIJ,qCACE,YAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultLabelTypography": "FormSelect__defaultLabelTypography____DBW8",
	"iconButton": "FormSelect__iconButton___MxD8U",
	"selectPlusError": "FormSelect__selectPlusError___ER5qZ"
};
export default ___CSS_LOADER_EXPORT___;
