import { Button, ButtonVariants } from '@components/button/Button';
import styles from './SplitMergeTablesButton.scss';

interface SplitMergeTablesButtonProps {
  onClick: () => void;
}

export const SplitMergeTablesButton = ({
  onClick,
}: SplitMergeTablesButtonProps) => (
  <Button
    className={styles.container}
    label="Split/Merge"
    variant={ButtonVariants.Tertiary}
    onClick={onClick}
  />
);
