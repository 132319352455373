import { type MenuListboxSlotProps } from '@mui/base/Menu';
import cx from 'classnames';
import { forwardRef, type HTMLAttributes } from 'react';
import styles from './MenuListbox.scss';

export const MenuListbox = forwardRef<
  HTMLUListElement,
  HTMLAttributes<HTMLUListElement> & MenuListboxSlotProps
>(({ className, ownerState, ...rest }, ref) => (
  <ul ref={ref} className={cx(styles.menuListbox, className)} {...rest} />
));
MenuListbox.displayName = 'MenuListbox';
