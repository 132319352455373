import { Spinner } from '@components/Spinner';
import type { ListingWithServiceWindows } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import { ListingCard } from './ListingCard';
import styles from './ListingsList.scss';

interface ListingsListProps {
  filteredListings: ListingWithServiceWindows[];
}

export const ListingsList = ({ filteredListings }: ListingsListProps) => {
  const { isLoading, setSelectedListingId } = useListingsContext();

  if (isLoading) {
    return (
      <div className={styles.tabSpinnerContainer}>
        <Spinner className={styles.tabSpinner} />
      </div>
    );
  }
  if (filteredListings.length === 0) {
    return (
      <div className={styles.tabNoMatchingListings}>
        <div>No matching listings</div>
      </div>
    );
  }
  return (
    <ul className={styles.main}>
      {filteredListings.map((listing) => (
        <ListingCard
          key={listing.id}
          listing={listing}
          onClick={() => setSelectedListingId(listing.id)}
        />
      ))}
    </ul>
  );
};
