import {
  addWeeks,
  hoursToMinutes,
  parseISO,
  startOfWeek,
  subDays,
} from 'date-fns';
import { compact, intersection, max, min } from 'lodash-es';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { CENTS_IN_DOLLAR } from '@shared/utils/currency';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { parseDateToWeekDay } from '@shared/utils/weekDayFormatters';
import {
  ISOTimeAddMinutes,
  ISOTimeSaturatingAddMinutes,
  ISOTimeToMinuteOfDay,
} from '@utils/time';
import {
  OPERATIONS_LISTINGS_CALENDAR_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PATH,
} from '../../../paths';
import {
  type ListingWithServiceWindows,
  type ServiceWindow,
} from '../apiHelpers';
import { type ListingFormData, type PricePointFormData } from '../types';
import {
  hasOverlappingDay,
  mergeOverlappingTimeRanges,
  type TimeRange,
  timeRangeOverlaps,
} from './timeRange';

const MINUTES_PER_DAY = hoursToMinutes(24);

/**
 * Applies the supplied mapper function to a range of integers
 * from 0 to N - 1 and returns the result as an array of length N.
 *
 * @param n - The number of elements to map.
 * @param mapper - The function that maps each element.
 * @returns An array of type `T` with `n` elements.
 *
 * @example
 * mapN(5, (n) => n * 2); // [0, 2, 4, 6, 8]
 */
export const mapN = <T>(n: number, mapper: (n: number) => T): T[] =>
  Array.from({ length: n }, (_, i) => mapper(i));

export const mapHalfHourIncrements = <T>(mapper: (n: number) => T): T[] =>
  mapN(24 * 2, (i) => mapper(i));

/**
 * Converts a boolean value indicating whether a seating arrangement
 * is communal to a corresponding seating type string.
 * @param isCommunal - A boolean value indicating whether the
 * seating arrangement is communal.
 * @returns The seating type string, either 'Communal' or 'Table'.
 */
export const seatingTypeFromIsCommunal = (isCommunal: boolean) =>
  isCommunal ? 'Communal' : 'Table';

/** Returns true if the listing has availability on the given date */
export const serviceWindowIsOnDate = (
  serviceWindow: ServiceWindow,
  date: string,
) =>
  serviceWindow.startDate <= date &&
  (!serviceWindow.endDate || serviceWindow.endDate >= date) &&
  serviceWindow.repeat.includes(parseDateToWeekDay(date).toString());

/** Returns true if the turn time goes past midnight */
export const serviceWindowHasRollover = (
  serviceWindow: ServiceWindow,
  turnTime: number,
) => ISOTimeToMinuteOfDay(serviceWindow.endTime) + turnTime > MINUTES_PER_DAY;

/**
 * Calculates a list of time ranges which the given listing occupies for a given
 * date. There may be up to two time ranges since there may be overflow from the
 * previous day. Turn time is included in the time ranges.
 */
export const listingTimeRangesOnDate = (
  listing: ListingWithServiceWindows,
  date: string,
): TimeRange[] => {
  const { turnTime, serviceWindows } = listing;

  return serviceWindows.reduce<TimeRange[]>((ranges, window) => {
    const windowHasRolloverFromPreviousDay =
      serviceWindowHasRollover(window, turnTime) &&
      serviceWindowIsOnDate(
        window,
        toISODateFormat(subDays(parseISO(date), 1)),
      );
    const windowIsOnDate = serviceWindowIsOnDate(window, date);

    if (windowHasRolloverFromPreviousDay) {
      ranges.push({
        startTime: '00:00:00',
        endTime: ISOTimeAddMinutes(window.endTime, turnTime),
      });
    }
    if (windowIsOnDate) {
      ranges.push({
        startTime: window.startTime,
        endTime: ISOTimeSaturatingAddMinutes(window.endTime, turnTime),
      });
    }

    return mergeOverlappingTimeRanges(ranges);
  }, []);
};

export const hasOverlappingServiceWindow = (
  listingA: ListingWithServiceWindows,
  listingB: ListingWithServiceWindows,
): boolean =>
  listingA.serviceWindows.some((windowA) =>
    listingB.serviceWindows.some(
      (windowB) =>
        hasTableHighlightOverlap(listingA, listingB) &&
        hasDateOverlap(windowA, windowB) &&
        hasTimeOverlapWithRollover(
          windowA,
          listingA.turnTime,
          windowB,
          listingB.turnTime,
        ),
    ),
  );

export const hasTableHighlightOverlap = (
  listingA: ListingWithServiceWindows,
  listingB: ListingWithServiceWindows,
) =>
  intersection(
    listingA.highlightedFloorPlanTableIds,
    listingB.highlightedFloorPlanTableIds,
  ).length > 0;

export const hasDateOverlap = (
  windowA: ServiceWindow,
  windowB: ServiceWindow,
) => {
  const startDate = max([windowA.startDate, windowB.startDate])!;
  const endDate = min(compact([windowA.endDate, windowB.endDate]));

  if (!endDate) return true;

  return endDate >= startDate;
};

export const hasTimeOverlapWithRollover = (
  windowA: ServiceWindow,
  turnTimeA: number,
  windowB: ServiceWindow,
  turnTimeB: number,
) => {
  if (hasOverlappingDay(windowA.repeat, windowB.repeat)) {
    const timeRangeA = {
      startTime: windowA.startTime,
      endTime: ISOTimeSaturatingAddMinutes(windowA.endTime, turnTimeA),
    };
    const timeRangeB = {
      startTime: windowB.startTime,
      endTime: ISOTimeSaturatingAddMinutes(windowB.endTime, turnTimeB),
    };
    return timeRangeOverlaps(timeRangeA, timeRangeB);
  }
  if (
    serviceWindowHasRollover(windowA, turnTimeA) &&
    hasAdjacentRepeat(windowA, windowB)
  ) {
    return timeRangeOverlaps(
      {
        startTime: '00:00:00',
        endTime: ISOTimeAddMinutes(windowB.endTime, turnTimeB),
      },
      windowB,
    );
  }
  if (
    serviceWindowHasRollover(windowB, turnTimeB) &&
    hasAdjacentRepeat(windowB, windowA)
  ) {
    return timeRangeOverlaps(
      {
        startTime: '00:00:00',
        endTime: ISOTimeAddMinutes(windowA.endTime, turnTimeA),
      },
      windowA,
    );
  }

  return false;
};

export const hasAdjacentRepeat = (
  windowA: ServiceWindow,
  windowB: ServiceWindow,
) =>
  intersection(
    windowA.repeat.map((r) => String(Number(r) + 1)),
    windowB.repeat,
  ).length > 0;

/**
 * Returns true if a given listing overlaps with a given time block on a calendar
 * It will account for turn time after the end time as well as the rollover to the next day
 *
 * @param startTime - start time of the range
 * @param endTime - end time of the range
 * @param date - date string in ISO format
 * @returns a filter function that takes in listing as a parameter
 */
export const isOverlappingInRange =
  (startTime: string, endTime: string, date: string) =>
  (listing: ListingWithServiceWindows) =>
    listingTimeRangesOnDate(listing, date).some((timeRange) =>
      timeRangeOverlaps(timeRange, { startTime, endTime }),
    );

export const getListingsForWeekByDate = (
  listings: ListingWithServiceWindows[],
  selectedDate: string,
): ListingWithServiceWindows[] => {
  const weekStart = startOfWeek(parseISO(selectedDate));
  const weekEnd = addWeeks(weekStart, 1);

  return listings
    .map((listing) => ({
      ...listing,
      serviceWindows: listing.serviceWindows.filter(
        (window) =>
          parseISO(window.startDate) < weekEnd &&
          (!window.endDate || parseISO(window.endDate) >= weekStart),
      ),
    }))
    .filter((listing) => listing.serviceWindows.length);
};

export const isOverlappingPricePoints = (
  pricePointA: PricePointFormData,
  pricePointB: PricePointFormData,
): boolean =>
  hasOverlappingDay(pricePointA.activeDays, pricePointB.activeDays) &&
  timeRangeOverlaps(
    {
      startTime: pricePointA.startTime,
      endTime: pricePointA.endTime,
    },
    {
      startTime: pricePointB.startTime,
      endTime: pricePointB.endTime,
    },
  );

export const findOverlappingPricePointIndexes = (
  pricePoints: PricePointFormData[],
) =>
  pricePoints.reduce<number[]>(
    (errorIndexes, pricePointA, pricePointAIndex) => {
      const hasOverlap = pricePoints.some(
        (pricePointB, pricePointBIndex) =>
          pricePointAIndex !== pricePointBIndex &&
          isOverlappingPricePoints(pricePointB, pricePointA),
      );
      if (hasOverlap) errorIndexes.push(pricePointAIndex);
      return errorIndexes;
    },
    [],
  );

export const getDefaultFormValues = (
  floorPlan: FloorPlanData,
  listing?: ListingWithServiceWindows,
  isDuplicating = false,
): ListingFormData => {
  if (listing) {
    return {
      highlightedTables: floorPlan
        ? floorPlan.floorPlanTables.filter((table) =>
            listing.highlightedFloorPlanTableIds.includes(table.id),
          )
        : [],
      iconName: listing.iconName,
      interval: listing.interval,
      inventoryCount: listing.inventoryCount.toString(),
      isCommunal: listing.isCommunal,
      maximumGuests: listing.maximumGuests.toString(),
      minimumGuests: listing.minimumGuests.toString(),
      name: listing.name,
      publicName: `${listing.publicName}${isDuplicating ? ' - Copy' : ''}`,
      turnTime: listing.turnTime,
      price: (listing.price / CENTS_IN_DOLLAR).toString(),
      pricePoints: listing.pricePoints.map((pricePoint) => ({
        activeDays: pricePoint.activeDays,
        endTime: pricePoint.endTime,
        id: pricePoint.id,
        price: (pricePoint.price / CENTS_IN_DOLLAR).toString(),
        startTime: pricePoint.startTime,
      })),
      serviceWindowIds: listing.serviceWindows.map((window) => window.id),
    };
  }

  return {
    highlightedTables: [],
    isCommunal: false,
    interval: 15,
    price: '0',
    pricePoints: [],
    serviceWindowIds: [],
  } as unknown as ListingFormData;
};

export const getDestinationPath = (
  referrer: string,
  tab: 'published' | 'draft' | 'inactive',
) => {
  const isFromFloorPlan = referrer.includes('floor-plan');

  return `${isFromFloorPlan ? OPERATIONS_LISTINGS_FLOOR_PLAN_PATH : OPERATIONS_LISTINGS_CALENDAR_PATH}/${tab}`;
};
