import cx from 'classnames';
import { isEqual, sortBy } from 'lodash-es';
import Papa from 'papaparse';
import type { ChangeEvent, FormEvent } from 'react';
import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { Button, ButtonVariants } from '@components/button/Button';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import typography from '~styles/typography.scss';
import { PageContent } from '../layout/PageContent';
import { PageHeader } from '../layout/PageHeader';
import { type ImportedGuest, importGuests } from './apiHelpers';
import styles from './GuestImport.scss';

const HEADERS: Record<string, string> = {
  firstName: 'First Name *',
  lastName: 'Last Name *',
  phone: 'Phone *',
  notes: 'Notes (optional)',
  tags: 'Tags (optional)',
};
const REQUIRED_HEADERS = Object.keys(HEADERS);

const validators: Record<string, (value: string) => boolean> = {
  firstName: (firstName: string) => !!firstName,
  lastName: (lastName: string) => !!lastName,
  phone: (phone: string) => isValidPhoneNumber(phone),
  notes: (notes: string) => notes.length <= 600,
};

export const GuestImport = () => {
  const { id: restaurantId } = useRestaurant();
  const [parsedData, setParsedData] = useState<string[][]>([]);
  const [hasInvalidData, setHasInvalidData] = useState(false);
  const [hasInvalidHeaders, setHasInvalidHeaders] = useState(false);

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const [_headers, ...rows] = parsedData;

    const toImportedGuest = (row: string[]) => ({
      firstName: row[0],
      lastName: row[1],
      phone: row[2],
      notes: row[3] || null,
      tags: row[4] ? row[4].split(',') : null,
    });

    const importedGuestsJson = rows.map<ImportedGuest>(toImportedGuest);

    const handleImportedGuests = async () => {
      try {
        await importGuests(restaurantId, importedGuestsJson);

        successToast({ message: 'Guests imported successfully' });
      } catch (e) {
        errorToast({ message: 'Failed to import guests' });
        reportAppError(e);
      }
    };
    void handleImportedGuests();
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setParsedData([]);
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    Papa.parse(file, {
      complete: ({ data }: { data: string[][] }) => {
        const [headers, ...rows] = data;

        const hasValidHeaders = isEqual(
          sortBy(headers),
          sortBy(REQUIRED_HEADERS),
        );

        if (hasValidHeaders && rows.length > 0) {
          setParsedData(data);
        } else {
          setHasInvalidHeaders(true);
        }
      },
    });
  };

  const [headers, ...rows] = parsedData;

  const hasError = (field: string, header: string) => {
    if (validators[header]) {
      const isValid = validators[header](field);
      if (!isValid && !hasInvalidData) setHasInvalidData(true);
      return !validators[header](field);
    }
    return false;
  };

  return (
    <>
      <PageHeader title="Guest Import" />
      <PageContent className={styles.container}>
        <form onSubmit={handleOnSubmit}>
          <Button
            isDisabled={!rows.length || hasInvalidData}
            label="Do it, I dare you"
            type="submit"
            variant={ButtonVariants.Primary}
          />
          <label htmlFor="guest-importer">Import Guests</label>
          <input
            type="file"
            accept=".csv"
            id="guest-importer"
            onChange={handleOnChange}
          />
          {hasInvalidHeaders && (
            <p className={styles.error}>
              To be importable, a CSV must contain at least 1 row and only
              contain the following headers: firstName, lastName, phone, notes,
              and tags.
            </p>
          )}
        </form>
        {!!parsedData.length && (
          <table>
            <thead>
              <tr>
                <th className={typography.c1}>1</th>
                {headers.map((header) => (
                  <th className={typography.c1} key={header}>
                    {HEADERS[header]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={JSON.stringify(row)}>
                  <td className={typography.c3}>{rowIndex + 2}</td>
                  {row.map((field, columnIndex) => (
                    <td
                      className={cx(typography.c3, {
                        [styles.error]: hasError(field, headers[columnIndex]),
                      })}
                      key={field}
                    >
                      {field}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </PageContent>
    </>
  );
};
