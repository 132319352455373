import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { finishReservation } from 'restaurantAdmin/reservations/apiHelpers';

export interface GuestFinishedConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onFinishReservation: () => void;
  reservationId: string;
}

export const GuestFinishedConfirmationModal = ({
  isOpen,
  closeModal,
  onFinishReservation,
  reservationId,
}: GuestFinishedConfirmationModalProps) => {
  const { id: restaurantId } = useRestaurant();

  const handleOnClickGuestFinished = (): void => {
    void (async () => {
      try {
        await finishReservation({
          reservationId,
          restaurantId,
        });
      } catch (error) {
        if (error instanceof ApiError) {
          errorToast({ message: error.message });
        } else {
          errorToast({ message: 'An error occurred' });
        }
        reportAppError(error);
      }

      onFinishReservation();
      closeModal();
    })();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Guest Finished"
      subtitle="Are you sure you would like to finish the guest reservation?"
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Yes, guest finished"
          onClick={handleOnClickGuestFinished}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
