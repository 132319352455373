import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormDateRange } from '@components/formInputs/ControlledFormDateRange';
import { ControlledFormDayRadio } from '@components/formInputs/ControlledFormDayRadio';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormTimeRange } from '@components/formInputs/ControlledFormTimeRange';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from '../../../context/useRestaurant';
import { updateServiceWindows } from '../../../settings/general/apiHelpers';
import styles from './CreateServiceWindowModal.scss';

interface ServiceWindowFormData {
  startDate: string;
  endDate: string | null;
  repeat: string[];
  name: string;
  startTime: string;
  endTime: string;
}

export interface CreateServiceWindowModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleOnCreateServiceWindow: (createdServiceWindowIds: string[]) => void;
}

export const CreateServiceWindowModal = ({
  isOpen,
  closeModal,
  handleOnCreateServiceWindow,
}: CreateServiceWindowModalProps) => {
  const { id: restaurantId } = useRestaurant();
  const { control, handleSubmit, watch } = useForm<ServiceWindowFormData>({
    defaultValues: {
      repeat: [],
    },
  });

  const handleOnClickConfirm = handleSubmit(
    async (data: ServiceWindowFormData) => {
      try {
        const { createdServiceWindowIds } = await updateServiceWindows(
          restaurantId,
          {
            ignoreWarnings: true,
            createdServiceWindows: [
              {
                startDate: data.startDate,
                endDate: data.endDate || null,
                repeat: data.repeat,
                name: data.name,
                startTime: data.startTime,
                endTime: data.endTime,
              },
            ],
            updatedServiceWindows: [],
            deletedServiceWindowIds: [],
          },
        );
        handleOnCreateServiceWindow(createdServiceWindowIds);
        successToast({ message: 'Service Windows successfully created' });
        closeModal();
      } catch (error) {
        errorToast({ message: 'Failed to create Service Windows' });
        reportAppError(error);
      }
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} title="Create Service Window">
      <form className={styles.form}>
        <ControlledFormInput
          control={control}
          label="Name"
          name="name"
          rules={{
            required: true,
          }}
          type="text"
          variant={LayoutVariant.Vertical}
        />
        <ControlledFormDateRange
          control={control}
          fromProps={{
            label: 'Start Date',
            name: `startDate`,
            rules: { required: true },
          }}
          toProps={{
            label: 'End Date',
            name: `endDate`,
            rules: {
              min: {
                value: watch(`startDate`),
                message: 'End Date must be on or after the Start Date.',
              },
            },
          }}
          groupLabel="Start and End"
          variant={LayoutVariant.Vertical}
        />
        <ControlledFormTimeRange
          control={control}
          groupLabel="Time Frame"
          fromProps={{
            label: 'Start Time',
            name: `startTime`,
            rules: { required: true },
          }}
          toProps={{
            label: 'End Time',
            name: `endTime`,
            rules: {
              required: true,
              validate: {
                isGreaterThanOrEqualToStartTime: (
                  endTime: string,
                ): string | boolean =>
                  watch(`startTime`) && endTime >= watch(`startTime`)
                    ? true
                    : 'End Time must be on or after the Start Time.',
              },
            },
          }}
          variant={LayoutVariant.Vertical}
        />
        <ControlledFormDayRadio
          control={control}
          label="Repeats On"
          name="repeat"
          rules={{
            required: true,
          }}
        />
        <ModalActions>
          <Button
            type="button"
            label="Cancel"
            onClick={closeModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            type="button"
            label="Create"
            onClick={handleOnClickConfirm}
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </form>
    </Modal>
  );
};
