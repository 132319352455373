import type { Dispatch } from 'react';
import { useState } from 'react';

/**
 * Call `setError` to throw an error that would not otherwise be thrown.
 *
 * Used to manually throw an error that will be caught by the app's error
 * boundary and render the generic error page.
 *
 * We prefer displaying error toast messages to users when possible; use
 * `setError` sparingly.
 */
export const useError = (): Dispatch<unknown> => {
  const [error, setError] = useState<unknown>(null);
  if (error) throw error;
  return setError;
};
