// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateAccountPage__pageContainer___QcbDQ{background-color:var(--primary400);height:100vh;padding-top:7rem;width:100%}.CreateAccountPage__card___Q4SNA{align-items:center;background-color:var(--darkBlue);border-radius:.5rem;display:flex;flex-direction:column;gap:1.5rem;margin:0 auto;padding:3rem;width:35rem}.CreateAccountPage__card___Q4SNA h1{color:var(--white)}.CreateAccountPage__card___Q4SNA form{display:flex;flex-direction:column;gap:1rem;width:100%}.CreateAccountPage__card___Q4SNA form a{color:var(--gold);padding-bottom:2px;text-decoration:underline}.CreateAccountPage__card___Q4SNA form a:focus{outline:var(--accent200) solid medium;outline-offset:4px}.CreateAccountPage__card___Q4SNA form>p{align-self:center;margin:0}.CreateAccountPage__card___Q4SNA form .CreateAccountPage__tosContainer___DfPDQ{display:flex;align-items:baseline;gap:1rem}.CreateAccountPage__card___Q4SNA form .CreateAccountPage__tosCheckbox___GdaoB span{left:-3000em;position:absolute;top:-2000em}.CreateAccountPage__card___Q4SNA form .CreateAccountPage__error___WRB7O{color:var(--pink)}.CreateAccountPage__card___Q4SNA form button{align-self:center;width:12em}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/auth/CreateAccountPage.scss"],"names":[],"mappings":"AAAA,0CACE,kCAAA,CACA,YAAA,CACA,gBAAA,CACA,UAAA,CAGF,iCACE,kBAAA,CACA,gCAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA,CACA,WAAA,CAEA,oCACE,kBAAA,CAGF,sCACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,UAAA,CAEA,wCACE,iBAAA,CACA,kBAAA,CACA,yBAAA,CAEA,8CACE,qCAAA,CACA,kBAAA,CAIJ,wCACE,iBAAA,CACA,QAAA,CAGF,+EACE,YAAA,CACA,oBAAA,CACA,QAAA,CAIA,mFAEE,YAAA,CACA,iBAAA,CACA,WAAA,CAIJ,wEACE,iBAAA,CAGF,6CACE,iBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "CreateAccountPage__pageContainer___QcbDQ",
	"card": "CreateAccountPage__card___Q4SNA",
	"tosContainer": "CreateAccountPage__tosContainer___DfPDQ",
	"tosCheckbox": "CreateAccountPage__tosCheckbox___GdaoB",
	"error": "CreateAccountPage__error___WRB7O"
};
export default ___CSS_LOADER_EXPORT___;
