export const VALID_HOURS = [
  {
    label: '1 hour',
    value: 1,
  },
  {
    label: '2 hours',
    value: 2,
  },
  {
    label: '3 hours',
    value: 3,
  },
  {
    label: '4 hours',
    value: 4,
  },
  {
    label: '5 hours',
    value: 5,
  },
  {
    label: '6 hours',
    value: 6,
  },
  {
    label: '7 hours',
    value: 7,
  },
  {
    label: '8 hours',
    value: 8,
  },
  {
    label: '9 hours',
    value: 9,
  },
  {
    label: '10 hours',
    value: 10,
  },
  {
    label: '11 hours',
    value: 11,
  },
  {
    label: '12 hours',
    value: 12,
  },
  {
    label: '16 hours',
    value: 16,
  },
  {
    label: '24 hours',
    value: 24,
  },
  {
    label: '36 hours',
    value: 36,
  },
  {
    label: '48 hours',
    value: 48,
  },
  {
    label: '60 hours',
    value: 60,
  },
  {
    label: '3 days',
    value: 72,
  },
  {
    label: '4 days',
    value: 96,
  },
  {
    label: '5 days',
    value: 120,
  },
  {
    label: '6 days',
    value: 144,
  },
  {
    label: '7 days',
    value: 168,
  },
];

export const formatHoursBeforeReservation = (
  hoursBeforeReservation: number,
) => {
  const matchingTime = VALID_HOURS.find(
    (validHour) => validHour.value === hoursBeforeReservation,
  );

  const timeLabel = matchingTime
    ? matchingTime.label
    : `${hoursBeforeReservation} hours`;

  return `${timeLabel} prior to reservation`;
};
