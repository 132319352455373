import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useRestaurant } from '../../../context/useRestaurant';
import {
  getServiceReservationDetails,
  getServiceWalkInDetails,
  type ServiceOccupant,
} from '../apiHelpers';
import type { OccupantType } from '../state/types';

export interface OccupantId {
  id: string;
  type: OccupantType;
}

export const useFetchOccupantDetails = (id: OccupantId | null) => {
  const { id: restaurantId } = useRestaurant();
  const { data: occupant, rerun: invalidate } = useAbortEffect(
    {
      effect: async () => {
        if (!id) return undefined;
        const { id: occupantId, type: occupantType } = id;
        const getOccupantDetails = getOccupantDetailsFunction(occupantType);
        return getOccupantDetails(restaurantId, occupantId);
      },
      throwOnError: true,
    },
    [restaurantId, id],
  );
  return { occupant, invalidate };
};

type GetOccupantDetails = (
  restaurantId: string,
  occupantId: string,
) => Promise<ServiceOccupant>;

const getOccupantDetailsFunction = (
  occupantType: string,
): GetOccupantDetails => {
  if (occupantType === 'reservation') return getServiceReservationDetails;
  if (occupantType === 'walkIn') return getServiceWalkInDetails;
  throw new TypeError('occupantType fallthrough');
};
