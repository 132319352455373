// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PacingControlsForm__form___I6bgI{display:flex;flex-direction:column;gap:2rem}.PacingControlsForm__form___I6bgI .PacingControlsForm__actions___qQYas{display:flex;flex-direction:column-reverse;gap:1rem;justify-content:flex-end}.PacingControlsForm__form___I6bgI .PacingControlsForm__deleteButton___Zw2CD{align-items:center;background-color:inherit;border:none;color:#fff;display:flex;gap:.5rem;padding-left:0}.PacingControlsForm__form___I6bgI .PacingControlsForm__deleteButton___Zw2CD svg{min-width:1.5rem;stroke:#fff;width:1.5rem}.PacingControlsForm__form___I6bgI .PacingControlsForm__deleteButton___Zw2CD:active,.PacingControlsForm__form___I6bgI .PacingControlsForm__deleteButton___Zw2CD:focus,.PacingControlsForm__form___I6bgI .PacingControlsForm__deleteButton___Zw2CD:hover{color:var(--gold)}.PacingControlsForm__form___I6bgI .PacingControlsForm__deleteButton___Zw2CD:active svg,.PacingControlsForm__form___I6bgI .PacingControlsForm__deleteButton___Zw2CD:focus svg,.PacingControlsForm__form___I6bgI .PacingControlsForm__deleteButton___Zw2CD:hover svg{stroke:var(--gold)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/pacingControls/PacingControlsForm.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,uEACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,wBAAA,CAGF,4EACE,kBAAA,CACA,wBAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,SAAA,CACA,cAAA,CAEA,gFACE,gBAAA,CACA,WAAA,CACA,YAAA,CAGF,uPAOE,iBAAA,CAJA,mQACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "PacingControlsForm__form___I6bgI",
	"actions": "PacingControlsForm__actions___qQYas",
	"deleteButton": "PacingControlsForm__deleteButton___Zw2CD"
};
export default ___CSS_LOADER_EXPORT___;
