import type { ModalOwnerState } from '@mui/base/Modal/Modal.types';
import cx from 'classnames';
import { forwardRef } from 'react';

export const Backdrop = forwardRef<
  HTMLDivElement,
  { className?: string; open?: boolean; ownerState: ModalOwnerState }
>((props, ref) => {
  const { className, open, ownerState, ...other } = props;

  return (
    <div
      className={cx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.displayName = 'Backdrop';
