import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './ClearCalendarSelectionButton.scss';

export const ClearCalendarSelectionButton = () => (
  <button className={styles.clearCalendarSelectionButton}>
    <Icon name="reset" />
    <span className={typography.t1}>Clear calendar selection</span>
  </button>
);
