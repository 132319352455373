// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantSelector__restaurantName___ZP3PS{margin-right:auto}.RestaurantSelector__restaurantSelector___sf8by{margin-right:auto}.RestaurantSelector__restaurantSelector___sf8by>div:not(:first-of-type){box-shadow:0 0 0 0 rgba(0,0,0,.4),0 .5rem 1.5rem rgba(0,0,0,.4)}.RestaurantSelector__restaurantSelector___sf8by>div:first-of-type{background-color:var(--primary400);border-color:rgba(0,0,0,0)}.RestaurantSelector__restaurantSelector___sf8by>div:first-of-type:hover{border-color:var(--accent300)}.RestaurantSelector__restaurantSelector___sf8by [role=option]{padding-inline:1.25rem}.RestaurantSelector__restaurantSelector___sf8by .select__single-value{font-size:.875rem;font-weight:400;line-height:1rem}.RestaurantSelector__restaurantSelectorLabel___cnTb0{font-style:italic}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/layout/RestaurantSelector.scss"],"names":[],"mappings":"AAEA,4CACE,iBAAA,CAGF,gDACE,iBAAA,CAGA,wEACE,+DACE,CAKJ,kEACE,kCAAA,CACA,0BAAA,CAEA,wEACE,6BAAA,CAIJ,8DACE,sBAAA,CAGF,sEACE,iBAAA,CACA,eAAA,CACA,gBAAA,CAIJ,qDACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restaurantName": "RestaurantSelector__restaurantName___ZP3PS",
	"restaurantSelector": "RestaurantSelector__restaurantSelector___sf8by",
	"restaurantSelectorLabel": "RestaurantSelector__restaurantSelectorLabel___cnTb0"
};
export default ___CSS_LOADER_EXPORT___;
