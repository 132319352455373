import { api } from '../../api';
import {
  type GuestAttributes,
  type ReservationAttributes,
  type RestaurantReservationsReportResponse,
} from '../apiHelpers';

export interface WalkInAttributes {
  guest: GuestAttributes;
  guestCount: number;
  guestNotes?: string;
  guestTags: string[];
  listingName?: string;
  time: string;
  type: 'walkIn';
}

export interface RestaurantCoversReportResponse {
  name: string;
  occupants: (ReservationAttributes | WalkInAttributes)[];
  totals: {
    covers: number;
    reservationCovers: number;
    reservations: number;
    walkInCovers: number;
    walkIns: number;
  };
}

export const getRestaurantsReservationsReport = async (
  restaurantId: string,
  date: string,
  signal?: AbortSignal,
): Promise<RestaurantReservationsReportResponse> =>
  api.get(`/restaurants/${restaurantId}/reservations-report?date=${date}`, {
    signal,
  });

export const getRestaurantsCoversReport = async (
  restaurantId: string,
  date: string,
  signal?: AbortSignal,
): Promise<RestaurantCoversReportResponse> =>
  api.get(`/restaurants/${restaurantId}/covers-report?date=${date}`, {
    signal,
  });
