import cx from 'classnames';
import type { ReactElement } from 'react';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './ConciergeAdminAvailability.scss';
import { ConciergeAdminAvailabilityItem } from './ConciergeAdminAvailabilityItem';
import { useConciergeAvailabilityContext } from './ConciergeAvailabilityContext';
import { NoTables } from './NoTables';

interface ConciergeAdminAvailabilityProps {
  availabilityItemAction: ReactElement;
}

export const ConciergeAdminAvailability = ({
  availabilityItemAction,
}: ConciergeAdminAvailabilityProps) => {
  const {
    filteredAvailabilities,
    handleShowEarlier,
    handleShowLater,
    hasEarlier,
    hasLater,
  } = useConciergeAvailabilityContext();
  if (!filteredAvailabilities.length) return <NoTables />;

  return (
    <div data-testid="availability-container" className={styles.container}>
      {hasEarlier && (
        <button
          className={cx(typography.h7m, styles.showMoreButton)}
          onClick={handleShowEarlier}
        >
          <Icon className={styles.up} name="chevron" />
          Show Earlier Times
        </button>
      )}
      <ul className={styles.list} aria-label="Restaurant Availabilities">
        {filteredAvailabilities.map((a) => (
          <ConciergeAdminAvailabilityItem
            availability={a}
            availabilityItemAction={availabilityItemAction}
            key={a.listing.id + a.time}
          />
        ))}
      </ul>
      {hasLater && (
        <button
          className={cx(typography.h7m, styles.showMoreButton)}
          onClick={handleShowLater}
        >
          <Icon className={styles.down} name="chevron" />
          Show Later Times
        </button>
      )}
    </div>
  );
};
