// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RescueReservationConfirmationModal__checkbox___n1PPi{display:block;margin-top:1rem;text-align:center}.RescueReservationConfirmationModal__checkbox___n1PPi input{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/stranded/RescueReservationConfirmationModal.scss"],"names":[],"mappings":"AAAA,sDACE,aAAA,CACA,eAAA,CACA,iBAAA,CAEA,4DACE,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "RescueReservationConfirmationModal__checkbox___n1PPi"
};
export default ___CSS_LOADER_EXPORT___;
