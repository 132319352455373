import { addHours, eachMinuteOfInterval } from 'date-fns';
import { filter, findIndex, uniq } from 'lodash-es';
import { createReferenceDate } from '@shared/utils/createReferenceDate';
import { dateToISOTime } from '@utils/time';
import { type AdminAvailabilityData } from './apiHelpers';
import type { AvailabilityState } from './conciergeAvailabilityReducer';

const DEFAULT_TIME_WINDOW_IN_HOURS = 2;

export const getFilteredAvailabilitiesByGuestCount = (
  state: AvailabilityState,
): AdminAvailabilityData[] =>
  filter(
    state.availabilities,
    (item: AdminAvailabilityData) =>
      state.selectedGuestCount === item.guestCount,
  );

export const getFilteredAvailabilitiesByGuestCountAndTime = (
  state: AvailabilityState,
): AdminAvailabilityData[] => {
  const selectedTimeDate = createReferenceDate(state.selectedTime);
  const timesToFilter = eachMinuteOfInterval(
    {
      start: selectedTimeDate,
      end: addHours(selectedTimeDate, DEFAULT_TIME_WINDOW_IN_HOURS),
    },
    { step: 15 },
  ).map(dateToISOTime);

  return filter(
    getFilteredAvailabilitiesByGuestCount(state),
    (item: AdminAvailabilityData) => timesToFilter.includes(item.time),
  );
};

export const getAvailableTimes = (state: AvailabilityState): string[] =>
  uniq(getFilteredAvailabilitiesByGuestCount(state).map(({ time }) => time));

export const getFirstAvailableTime = (state: AvailabilityState): string =>
  getAvailableTimes(state)[0] || '00:00:00';

const MIN_START_INDEX = 0;
// TODO: Refactor this function to be more readable and cohesive/single responsibility
export const calculateFilteredData = (state: AvailabilityState) => {
  const {
    availabilities,
    selectedFloorPlanListingIds,
    showEarlierOffset,
    showLaterOffset,
  } = state;
  const availabilitiesFilteredByGuestCount =
    getFilteredAvailabilitiesByGuestCount(state);

  const availabilitiesFilteredByGuestCountAndTime =
    getFilteredAvailabilitiesByGuestCountAndTime(state);

  const hasUnsupportedGuestCount =
    !availabilitiesFilteredByGuestCount.length && !!availabilities.length;

  // start index is inclusive
  const indexOfFirstFilteredAvailability = findIndex(
    availabilitiesFilteredByGuestCount,
    availabilitiesFilteredByGuestCountAndTime[0],
  );
  // end index is exclusive
  const indexOfLastFilteredAvailability =
    indexOfFirstFilteredAvailability +
    availabilitiesFilteredByGuestCountAndTime.length;

  const indexOfFirstExpandedAvailability =
    indexOfFirstFilteredAvailability - showEarlierOffset <= MIN_START_INDEX
      ? MIN_START_INDEX
      : indexOfFirstFilteredAvailability - showEarlierOffset;
  const indexOfLastExpandedAvailability =
    indexOfLastFilteredAvailability + showLaterOffset;

  const expandedAvailability = availabilitiesFilteredByGuestCount.slice(
    indexOfFirstExpandedAvailability,
    indexOfLastExpandedAvailability,
  );

  const hasEarlier = indexOfFirstExpandedAvailability > MIN_START_INDEX;
  const hasLater =
    indexOfLastExpandedAvailability < availabilitiesFilteredByGuestCount.length;

  const expandedAvailabilityListingIds = uniq(
    expandedAvailability.map((availability) => availability.listing.id),
  );

  const filteredAvailabilities = selectedFloorPlanListingIds.length
    ? expandedAvailability.filter((availability: AdminAvailabilityData) =>
        selectedFloorPlanListingIds.includes(availability.listing.id),
      )
    : expandedAvailability;

  return {
    filteredAvailabilities,
    hasEarlier,
    hasLater,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  };
};
