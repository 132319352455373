import { api } from '../api';

export interface ImportedGuest {
  firstName: string;
  lastName: string;
  phone: string;
  notes: string | null;
  tags: string[] | null;
}

export const importGuests = async (
  restaurantId: string,
  importedGuest: ImportedGuest[],
): Promise<void> => {
  await api.post(`/restaurants/${restaurantId}/guest-imports`, importedGuest);
};
