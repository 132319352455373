import { Outlet } from 'react-router-dom';
import { AdminHeader } from './AdminHeader';
import styles from './Layout.scss';

interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => (
  <>
    <AdminHeader />
    <main className={styles.main}>
      <Outlet />
      {children}
    </main>
  </>
);
