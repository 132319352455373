import { tz, type TZDate } from '@date-fns/tz';
import { parseISO } from 'date-fns';

/**
 * @param date e.g., '2022-03-10'
 * @param time e.g., '09:00:00' <- Don't forget the leading zero!
 * @param timeZone e.g., 'America/New_York'
 */
export const zonedDateTime = (
  date: string,
  time: string,
  timeZone: string,
): TZDate => parseISO(`${date}T${time}`, { in: tz(timeZone) });
