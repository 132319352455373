import type { Dispatch, KeyboardEvent, SetStateAction } from 'react';
import { Icon } from '@components/icon/Icon';
import styles from './FormInputTooltip.scss';

export type SetTooltipIsOpenDispatch = Dispatch<SetStateAction<boolean>>;
export const setTooltipIsOpenNoOp = (() =>
  undefined) as SetTooltipIsOpenDispatch;

interface FormInputTooltipProps {
  fieldLabel: string;
  id: string;
  setTooltipIsOpen: SetTooltipIsOpenDispatch;
  text?: string;
}

export const FormInputTooltip = ({
  fieldLabel,
  id,
  setTooltipIsOpen,
  text,
}: FormInputTooltipProps) => {
  const handleEscapeKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'esc' || event.key === 'Escape') {
      setTooltipIsOpen(false);
    }
  };

  return text ? (
    <button
      aria-describedby={`${id}-popup`}
      aria-label={`${fieldLabel} Information`}
      className={styles.iconButton}
      data-tooltip-content={text}
      data-tooltip-id="group-info-tooltip"
      id={id}
      onBlur={() => setTooltipIsOpen(false)}
      onClick={undefined}
      onFocus={() => setTooltipIsOpen(true)}
      onKeyDown={handleEscapeKeyDown}
      onMouseOut={() => setTooltipIsOpen(false)}
      onMouseOver={() => setTooltipIsOpen(true)}
      type="button"
    >
      <Icon name="infoCircle" className={styles.infoIcon} />
    </button>
  ) : null;
};
