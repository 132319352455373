import type { AnyIcon } from '@components/icon/Icon';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { api } from 'restaurantAdmin/api';

interface AvailabilityListing {
  iconName: AnyIcon;
  id: string;
  isCommunal: boolean;
  publicName: string;
  price: number;
}

export interface AdminAvailabilityData {
  guestCount: number;
  listing: AvailabilityListing;
  time: string;
}

export interface HostBookReservationPayload {
  customPrice?: number;
  date: string; // '2022-03-07
  expectedCancellationPolicyId: string | null;
  firstName: string;
  guestCount: number;
  guestId?: string;
  lastName: string;
  listingId: string;
  listingPrice: number;
  paymentMethodId?: string;
  phone?: string; // '+15555555555'
  restaurantId: string;
  time: string; // '00:00:00
}

export const getAvailabilities = (
  restaurantId: string,
  date: Date,
): Promise<AdminAvailabilityData[]> =>
  api.get(
    `/admin/restaurants/${restaurantId}/availabilities/${toISODateFormat(
      date,
    )}`,
  );

export const hostBookReservation = (
  payload: HostBookReservationPayload,
): Promise<void> =>
  api.post(`/restaurants/${payload.restaurantId}/reservations`, payload);
