import { Button, ButtonVariants } from '@components/button/Button';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { unseatReservation } from 'restaurantAdmin/reservations/apiHelpers';
import { isReservation } from 'restaurantAdmin/reservations/service/apiHelpers';
import { useReservationServiceContext } from 'restaurantAdmin/reservations/service/state/ReservationServiceContext';
import { GuestFinishedConfirmationModal } from './GuestFinishedConfirmationModal';
import { UnseatGuestConfirmationModal } from './UnseatGuestConfirmationModal';

export interface ReservationGuestSheetActionsProps {
  isSeated: boolean;
  onEnableSeatMode: () => void;
  reservationId: string;
}

export const ReservationGuestSheetActions = ({
  isSeated,
  onEnableSeatMode,
  reservationId,
}: ReservationGuestSheetActionsProps) => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();
  const {
    isOpen: isUnseatModalOpen,
    open: openUnseatModal,
    close: closeUnseatModal,
  } = useIsOpen();
  const { id: restaurantId } = useRestaurant();
  const {
    closeSidePanelSheet,
    refreshOccupants,
    refreshFloorPlan,
    selectedOccupant,
  } = useReservationServiceContext();

  const onFinishReservation = () => {
    refreshFloorPlan();
    refreshOccupants();
    closeSidePanelSheet();
  };

  const handleUnseatGuest = async (): Promise<void> => {
    if (!isReservation(selectedOccupant)) return;

    try {
      await unseatReservation({
        restaurantId,
        reservationId: selectedOccupant.id,
      });

      closeSidePanelSheet();
      refreshOccupants();
      refreshFloorPlan();
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message: 'Failed to unseat guest. Please try again.',
        });
      }
      reportAppError(error);
    }
  };

  return isSeated ? (
    <>
      <Button
        label="Guest Finished"
        onClick={openModal}
        variant={ButtonVariants.Primary}
      />
      <GuestFinishedConfirmationModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        onFinishReservation={onFinishReservation}
        reservationId={reservationId}
      />
      <Button
        label="Change Seat"
        onClick={onEnableSeatMode}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Unseat Guest"
        onClick={openUnseatModal}
        variant={ButtonVariants.Tertiary}
      />
      <UnseatGuestConfirmationModal
        closeModal={closeUnseatModal}
        isOpen={isUnseatModalOpen}
        onUnseatGuest={handleUnseatGuest}
      />
    </>
  ) : (
    <Button
      label="Seat Guest"
      onClick={onEnableSeatMode}
      variant={ButtonVariants.Primary}
    />
  );
};
