import typography from '~styles/typography.scss';
import { RestaurantSettingsSection } from '../shared/RestaurantSettingsSection';
import { SCHEDULE_SETTINGS_EXPLANATION } from './constants';
import { ScheduleForm } from './ScheduleForm';

interface ScheduleSettingsProps {
  schedule: number;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const ScheduleSettings = ({
  schedule,
  refreshRestaurant,
  restaurantId,
}: ScheduleSettingsProps) => (
  <RestaurantSettingsSection
    heading="Schedule"
    isEditable
    renderForm={(exitEditMode) => (
      <ScheduleForm
        schedule={schedule}
        exitEditMode={exitEditMode}
        refreshRestaurant={refreshRestaurant}
        restaurantId={restaurantId}
      />
    )}
  >
    <p className={typography.t2}>{SCHEDULE_SETTINGS_EXPLANATION}</p>
    <h3 className={typography.h6}>Publish to Marketplace</h3>
    <div
      className={typography.c2}
    >{`${schedule} day${schedule > 1 ? 's' : ''} in advance`}</div>
  </RestaurantSettingsSection>
);
