// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationGuestSheetReceipt__section___p12k4{background-color:var(--panelBlue);border-radius:8px;display:flex;flex-direction:column;gap:.5rem;padding:1rem}.ReservationGuestSheetReceipt__section___p12k4 h3{display:flex;justify-content:space-between}.ReservationGuestSheetReceipt__section___p12k4 dl{display:flex;flex-direction:column;gap:.5rem}.ReservationGuestSheetReceipt__section___p12k4 dl div{align-items:center;display:flex;justify-content:space-between}.ReservationGuestSheetReceipt__section___p12k4 dl div dt{color:var(--white70)}.ReservationGuestSheetReceipt__section___p12k4 dl div dt strong{color:var(--white80);font-size:inherit;font-weight:inherit}.ReservationGuestSheetReceipt__section___p12k4 dl div dd{color:var(--white70);font-variant-numeric:tabular-nums}.ReservationGuestSheetReceipt__section___p12k4 dl div dd strong{color:var(--white80);font-size:inherit;font-weight:inherit}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/sidePanel/ReservationGuestSheetReceipt.scss"],"names":[],"mappings":"AAAA,+CACE,iCAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,SAAA,CACA,YAAA,CAEA,kDACE,YAAA,CACA,6BAAA,CAGF,kDACE,YAAA,CACA,qBAAA,CACA,SAAA,CAEA,sDACE,kBAAA,CACA,YAAA,CACA,6BAAA,CAEA,yDACE,oBAAA,CAEA,gEACE,oBAAA,CACA,iBAAA,CACA,mBAAA,CAIJ,yDACE,oBAAA,CACA,iCAAA,CAEA,gEACE,oBAAA,CACA,iBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "ReservationGuestSheetReceipt__section___p12k4"
};
export default ___CSS_LOADER_EXPORT___;
