import cx from 'classnames';
import CloseIcon from '@assets/icons/close.svg';
import { Avatar } from '@components/avatar/Avatar';
import { SnapshotFloorPlan } from '@components/floorPlan/SnapshotFloorPlan';
import { parseToShortDateFormat } from '@shared/utils/dateFormatters';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { ContactInfo } from '../../components/contactInfo/ContactInfo';
import { GuestNotes } from '../../components/guestNotes/GuestNotes';
import { GuestTags } from '../../components/guestTags/GuestTags';
import { RefundButton } from '../../components/refundButton/RefundButton';
import { RemediationForm } from '../../components/remediationForm/RemediationForm';
import { ReservationGuestSheetStatuses } from '../../components/reservationGuestSheetStatuses/ReservationGuestSheetStatuses';
import { ReservationGuestSheetReceipt } from '../service/sidePanel/reservationGuestSheet/ReservationGuestSheetReceipt';
import type { StrandedReservation } from './apiHelpers';
import styles from './StrandedSheet.scss';

interface StrandedSheetProps {
  reservation: StrandedReservation;
  onCloseClick: () => void;
}

export const StrandedSheet = ({
  reservation,
  onCloseClick,
}: StrandedSheetProps) => {
  const {
    id: reservationId,
    guestCount,
    guest,
    status,
    transactionDetails: { fee, price, tax, receiptNumber },
  } = reservation;
  const guestCountText = appendGuestCountLabel(guestCount);
  const isHostBooked = !!reservation.transactionDetails.createdBy.fullName;

  return (
    <div aria-label="Stranded Sheet" role="dialog" className={styles.container}>
      <button
        aria-label="close sheet"
        className={styles.closeButton}
        onClick={() => onCloseClick()}
      >
        <CloseIcon className={styles.icon} />
      </button>
      <header>
        <div className={styles.guestIdentifiers}>
          <Avatar firstName={guest.firstName} lastName={guest.lastName} />
          <h2 className={typography.h5}>
            {guest.firstName} {guest.lastName}
          </h2>
        </div>
        <div className={styles.details}>
          <span>{`${parseToShortDateFormat(reservation.date)} @ ${ISOTimeTo12HourTime(
            reservation.time,
          )}`}</span>
          <span className={cx(typography.t1, styles.guestCount)}>
            {guestCountText}
          </span>
        </div>
        <ReservationGuestSheetStatuses
          isHostBooked={isHostBooked}
          isStranded
          showHostBooked
        />
      </header>
      <div className={styles.floorplan}>
        <SnapshotFloorPlan reservationId={reservationId} disableControls />
      </div>
      <ContactInfo phone={guest.phone} email={guest.email} />
      <GuestTags guestId={guest.id} />
      <GuestNotes guestId={guest.id} />
      <ReservationGuestSheetReceipt
        fee={fee}
        price={price}
        receiptNumber={receiptNumber}
        tax={tax}
        refundAmount={null}
      />
      <RemediationForm
        reservationId={reservationId}
        reservationStatus={status}
      />
      <RefundButton
        reservationId={reservationId}
        reservationPrice={price}
        isRefunded={false}
      />
    </div>
  );
};
