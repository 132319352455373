import { api } from '../../api';

export interface MergingTable {
  primaryMergeTableId: string;
  secondaryMergeTableIds: string[];
}

export const mergeTables = (
  restaurantId: string,
  mergingTables: MergingTable[],
  unmergingTableIds: string[],
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/merge-tables`, {
    mergingTables,
    unmergingTableIds,
  });
