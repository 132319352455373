// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatusTag__canceledTag___A7NCA{display:flex;align-items:center}.StatusTag__canceledTag___A7NCA svg{width:12px}.StatusTag__canceledTag___A7NCA span{text-transform:uppercase}.StatusTag__canceledTag___A7NCA svg{stroke:var(--white70)}.StatusTag__canceledTag___A7NCA span{color:var(--white70)}.StatusTag__noShowTag___SUMPw,.StatusTag__hostBookedTag___yWKtU{display:flex;align-items:center}.StatusTag__noShowTag___SUMPw svg,.StatusTag__hostBookedTag___yWKtU svg{width:12px}.StatusTag__noShowTag___SUMPw span,.StatusTag__hostBookedTag___yWKtU span{text-transform:uppercase}.StatusTag__noShowTag___SUMPw svg,.StatusTag__hostBookedTag___yWKtU svg{fill:var(--white70)}.StatusTag__noShowTag___SUMPw span,.StatusTag__hostBookedTag___yWKtU span{color:var(--white70)}.StatusTag__strandedTag___u08SW,.StatusTag__overtimeTag___ec9xC,.StatusTag__upcomingTag___muJvJ{display:flex;align-items:center}.StatusTag__strandedTag___u08SW svg,.StatusTag__overtimeTag___ec9xC svg,.StatusTag__upcomingTag___muJvJ svg{width:12px}.StatusTag__strandedTag___u08SW span,.StatusTag__overtimeTag___ec9xC span,.StatusTag__upcomingTag___muJvJ span{text-transform:uppercase}.StatusTag__strandedTag___u08SW svg,.StatusTag__overtimeTag___ec9xC svg,.StatusTag__upcomingTag___muJvJ svg{stroke:var(--pink)}.StatusTag__strandedTag___u08SW span,.StatusTag__overtimeTag___ec9xC span,.StatusTag__upcomingTag___muJvJ span{color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/statusTag/StatusTag.scss"],"names":[],"mappings":"AAaA,gCAZE,YAAA,CACA,kBAAA,CAEA,oCACE,UAAA,CAGF,qCACE,wBAAA,CAOF,oCACE,qBAAA,CAGF,qCACE,oBAAA,CAIJ,gEAxBE,YAAA,CACA,kBAAA,CAEA,wEACE,UAAA,CAGF,0EACE,wBAAA,CAoBF,wEACE,mBAAA,CAGF,0EACE,oBAAA,CAIJ,gGArCE,YAAA,CACA,kBAAA,CAEA,4GACE,UAAA,CAGF,+GACE,wBAAA,CAkCF,4GACE,kBAAA,CAGF,+GACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canceledTag": "StatusTag__canceledTag___A7NCA",
	"noShowTag": "StatusTag__noShowTag___SUMPw",
	"hostBookedTag": "StatusTag__hostBookedTag___yWKtU",
	"strandedTag": "StatusTag__strandedTag___u08SW",
	"overtimeTag": "StatusTag__overtimeTag___ec9xC",
	"upcomingTag": "StatusTag__upcomingTag___muJvJ"
};
export default ___CSS_LOADER_EXPORT___;
