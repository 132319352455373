// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SeatOutsideListingConfirmationModal__message___quGWb{color:var(--white70);text-align:center;max-width:30em}.SeatOutsideListingConfirmationModal__link___arl9c{color:inherit;font-size:1rem;font-weight:600;line-height:1.5rem;text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/SeatOutsideListingConfirmationModal.scss"],"names":[],"mappings":"AAAA,sDACE,oBAAA,CACA,iBAAA,CACA,cAAA,CAGF,mDACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "SeatOutsideListingConfirmationModal__message___quGWb",
	"link": "SeatOutsideListingConfirmationModal__link___arl9c"
};
export default ___CSS_LOADER_EXPORT___;
