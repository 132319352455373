import { Fragment } from 'react';
import { FormTooltip } from '@components/formInputs/FormTooltip';
import { centsToDollar, ZERO } from '@utils/currency';
import typography from '~styles/typography.scss';
import styles from './FinanceCard.scss';

interface FinanceCardProps {
  financialData: {
    restaurantNetRevenue: number;
    peakNetRevenue?: number;
    taxesCollected?: number;
    afterMarketVolume?: number;
  };
}

const FIELD_LABELS: Record<string, string> = {
  restaurantNetRevenue: 'Restaurant Revenue',
  peakNetRevenue: 'Peak Revenue',
  taxesCollected: 'Taxes Collected',
  afterMarketVolume: 'Aftermarket Volume',
};

const FIELD_TIPS: Record<string, string> = {
  restaurantNetRevenue:
    'Calculated revenue for the restaurant that takes into account of all initial purchases, processed refunds, and collected cancellation fees.',
  peakNetRevenue:
    'Calculated revenue for Peak that takes into account of all initial purchases, processed refunds, and fees that were collected and paid.',
  taxesCollected:
    'Total amount of taxes collected from initial purchases, accepted offers, and buy-now resales',
  afterMarketVolume:
    'Total volume that has gone through the aftermarket. This includes all accepted offers and buy-now resales.',
};

const formatValueInCents = (valueInCents: number): string =>
  valueInCents === ZERO ? '--' : centsToDollar(valueInCents);

export const FinanceCard = ({ financialData }: FinanceCardProps) => (
  <dl className={styles.container}>
    {Object.entries(financialData).map(([key, value]) => (
      <Fragment key={key}>
        <FormTooltip label={key} text={FIELD_TIPS[key]} />
        <dt className={typography.c3}>{FIELD_LABELS[key]}</dt>
        <dd className={typography.d3}>{formatValueInCents(value)}</dd>
      </Fragment>
    ))}
  </dl>
);
