import * as icons from '@assets/icons';
import * as floorPlanIcons from '@assets/icons/floorPlan';
import * as serviceStatusIcons from '@assets/icons/serviceStatus';

export const IconComponents = {
  // General Icons
  accountCircleOff: icons.AccountCircleOff,
  alertCircle: icons.AlertCircle,
  alertOctagon: icons.AlertOctagon,
  alertTriangle: icons.AlertTriangle,
  amex: icons.AmEx,
  arrowDownCircle: icons.ArrowDownCircle,
  arrowRightCircle: icons.ArrowRightCircle, // Not just a rotation of arrowDownCircle; different proportions
  arrowLeft: icons.ArrowLeft,
  browser: icons.Browser,
  calendar: icons.Calendar,
  checkMark: icons.CheckMark,
  checkMarkCircle: icons.CheckMarkCircle,
  chevron: icons.Chevron,
  clock: icons.Clock,
  close: icons.Close,
  copy: icons.Copy,
  couple: icons.Couple,
  creditCard: icons.CreditCard,
  crown: icons.Crown,
  cutlery: icons.Cutlery,
  defaultCardLogo: icons.DefaultCardLogo,
  edit: icons.Edit,
  email: icons.Email,
  filter: icons.Filter,
  gear: icons.Gear,
  hourglass: icons.Hourglass,
  infoCircle: icons.InfoCircle,
  link: icons.Link,
  list: icons.List,
  logo: icons.Logo,
  locationArrow: icons.LocationArrow,
  mastercard: icons.Mastercard,
  messageCircle: icons.MessageCircle,
  minus: icons.Minus,
  note: icons.Note,
  options: icons.Options,
  pencil: icons.Pencil,
  phone: icons.Phone,
  plus: icons.Plus,
  printer: icons.Printer,
  questionMark: icons.QuestionMark,
  reset: icons.Reset,
  roomService: icons.RoomService,
  server: icons.Server,
  smallArrowLeft: icons.SmallArrowLeft,
  smallCheck: icons.SmallCheck,
  splitMerge: icons.SplitMerge,
  stepper: icons.Stepper,
  table: icons.Table,
  tent: icons.Tent,
  timer: icons.Timer,
  trash: icons.Trash,
  turnTime: icons.TurnTime,
  user: icons.User,
  visa: icons.Visa,
  walkIn: icons.WalkIn,
  walkInNoBackground: icons.WalkInNoBackground,
  wrench: icons.Wrench,
  xCircle: icons.XCircle,
  zoomIn: icons.ZoomIn,
  zoomOut: icons.ZoomOut,
  // Chairs
  armChair: floorPlanIcons.ArmChair,
  armChairError: floorPlanIcons.ArmChairError,
  armChairSelected: floorPlanIcons.ArmChairSelected,
  loveSeat: floorPlanIcons.LoveSeat,
  loveSeatError: floorPlanIcons.LoveSeatError,
  loveSeatSelected: floorPlanIcons.LoveSeatSelected,
  // Bar Seating
  barSeat: floorPlanIcons.BarSeat,
  barSeatError: floorPlanIcons.BarSeatError,
  barSeatSelected: floorPlanIcons.BarSeatSelected,
  barSeatSmall: floorPlanIcons.BarSeatSmall,
  barSeatSmallError: floorPlanIcons.BarSeatSmallError,
  barSeatSmallSelected: floorPlanIcons.BarSeatSmallSelected,
  // Cabana
  cabanaTable: floorPlanIcons.CabanaTable,
  cabanaTableError: floorPlanIcons.CabanaTableError,
  cabanaTableSelected: floorPlanIcons.CabanaTableSelected,
  // Merged Tables
  mergedTable: floorPlanIcons.MergedTable,
  mergedTableError: floorPlanIcons.MergedTableError,
  mergedTableSelected: floorPlanIcons.MergedTableSelected,
  // Oval Tables
  ovlTable2Armchairs: floorPlanIcons.OvlTable2Armchairs,
  ovlTable2ArmchairsError: floorPlanIcons.OvlTable2ArmchairsError,
  ovlTable2ArmchairsSelected: floorPlanIcons.OvlTable2ArmchairsSelected,
  ovlTable4Armchairs: floorPlanIcons.OvlTable4Armchairs,
  ovlTable4ArmchairsError: floorPlanIcons.OvlTable4ArmchairsError,
  ovlTable4ArmchairsSelected: floorPlanIcons.OvlTable4ArmchairsSelected,
  ovlTable2Benches: floorPlanIcons.OvlTable2Benches,
  ovlTable2BenchesError: floorPlanIcons.OvlTable2BenchesError,
  ovlTable2BenchesSelected: floorPlanIcons.OvlTable2BenchesSelected,
  ovlTable2LoveSeats: floorPlanIcons.OvlTable2LoveSeats,
  ovlTable2LoveSeatsError: floorPlanIcons.OvlTable2LoveSeatsError,
  ovlTable2LoveSeatsSelected: floorPlanIcons.OvlTable2LoveSeatsSelected,
  ovlTable6RndChairs: floorPlanIcons.OvlTable6RndChairs,
  ovlTable6RndChairsError: floorPlanIcons.OvlTable6RndChairsError,
  ovlTable6RndChairsSelected: floorPlanIcons.OvlTable6RndChairsSelected,
  // Rectangular Tables
  recTable10SqrChairs: floorPlanIcons.RecTable10SqrChairs,
  recTable10SqrChairsError: floorPlanIcons.RecTable10SqrChairsError,
  recTable10SqrChairsSelected: floorPlanIcons.RecTable10SqrChairsSelected,
  recTable1Bench1SqrChairLeft: floorPlanIcons.RecTable1Bench1SqrChairLeft,
  recTable1Bench1SqrChairLeftError:
    floorPlanIcons.RecTable1Bench1SqrChairLeftError,
  recTable1Bench1SqrChairLeftSelected:
    floorPlanIcons.RecTable1Bench1SqrChairLeftSelected,
  recTable1Bench1SqrChairRight: floorPlanIcons.RecTable1Bench1SqrChairRight,
  recTable1Bench1SqrChairRightError:
    floorPlanIcons.RecTable1Bench1SqrChairRightError,
  recTable1Bench1SqrChairRightSelected:
    floorPlanIcons.RecTable1Bench1SqrChairRightSelected,
  recTable2Benches2SqrChairs: floorPlanIcons.RecTable2Benches2SqrChairs,
  recTable2Benches2SqrChairsError:
    floorPlanIcons.RecTable2Benches2SqrChairsError,
  recTable2Benches2SqrChairsSelected:
    floorPlanIcons.RecTable2Benches2SqrChairsSelected,
  recTable2Benches: floorPlanIcons.RecTable2Benches,
  recTable2BenchesError: floorPlanIcons.RecTable2BenchesError,
  recTable2BenchesSelected: floorPlanIcons.RecTable2BenchesSelected,
  recTable2Couches: floorPlanIcons.RecTable2Couches,
  recTable2CouchesError: floorPlanIcons.RecTable2CouchesError,
  recTable2CouchesSelected: floorPlanIcons.RecTable2CouchesSelected,
  recTable2LoveSeats: floorPlanIcons.RecTable2LoveSeats,
  recTable2LoveSeatsError: floorPlanIcons.RecTable2LoveSeatsError,
  recTable2LoveSeatsSelected: floorPlanIcons.RecTable2LoveSeatsSelected,
  recTable2RndChairsOneSide: floorPlanIcons.RecTable2RndChairsOneSide,
  recTable2RndChairsOneSideError: floorPlanIcons.RecTable2RndChairsOneSideError,
  recTable2RndChairsOneSideSelected:
    floorPlanIcons.RecTable2RndChairsOneSideSelected,
  recTable2SqrChairs50PctWrapBench:
    floorPlanIcons.RecTable2SqrChairs50PctWrapBench,
  recTable2SqrChairs50PctWrapBenchError:
    floorPlanIcons.RecTable2SqrChairs50PctWrapBenchError,
  recTable2SqrChairs50PctWrapBenchSelected:
    floorPlanIcons.RecTable2SqrChairs50PctWrapBenchSelected,
  recTable2SqrChairsAndBench: floorPlanIcons.RecTable2SqrChairsAndBench,
  recTable2SqrChairsAndBenchError:
    floorPlanIcons.RecTable2SqrChairsAndBenchError,
  recTable2SqrChairsAndBenchSelected:
    floorPlanIcons.RecTable2SqrChairsAndBenchSelected,
  recTable3SeatCouch2ArmChairs: floorPlanIcons.RecTable3SeatCouch2ArmChairs,
  recTable3SeatCouch2ArmChairsError:
    floorPlanIcons.RecTable3SeatCouch2ArmChairsError,
  recTable3SeatCouch2ArmChairsSelected:
    floorPlanIcons.RecTable3SeatCouch2ArmChairsSelected,
  recTable3SeatCouch5ArmChairs: floorPlanIcons.RectTable3SeatCouch5ArmChairs,
  recTable3SeatCouch5ArmChairsError:
    floorPlanIcons.RectTable3SeatCouch5ArmChairsError,
  recTable3SeatCouch5ArmChairsSelected:
    floorPlanIcons.RectTable3SeatCouch5ArmChairsSelected,
  recTable3SqrChairs50PctWrapBench:
    floorPlanIcons.RecTable3SqrChairs50PctWrapBench,
  recTable3SqrChairs50PctWrapBenchError:
    floorPlanIcons.RecTable3SqrChairs50PctWrapBenchError,
  recTable3SqrChairs50PctWrapBenchSelected:
    floorPlanIcons.RecTable3SqrChairs50PctWrapBenchSelected,
  recTable3SqrChairsAndBench: floorPlanIcons.RecTable3SqrChairsAndBench,
  recTable3SqrChairsAndBenchError:
    floorPlanIcons.RecTable3SqrChairsAndBenchError,
  recTable3SqrChairsAndBenchSelected:
    floorPlanIcons.RecTable3SqrChairsAndBenchSelected,
  recTable4RndChairs: floorPlanIcons.RecTable4RndChairs,
  recTable4RndChairsError: floorPlanIcons.RecTable4RndChairsError,
  recTable4RndChairsSelected: floorPlanIcons.RecTable4RndChairsSelected,
  recTable4SqrChairs: floorPlanIcons.RecTable4SqrChairs,
  recTable4SqrChairsError: floorPlanIcons.RecTable4SqrChairsError,
  recTable4SqrChairsSelected: floorPlanIcons.RecTable4SqrChairsSelected,
  recTable4SqrChairsWithV: floorPlanIcons.RecTable4SqrChairsWithV,
  recTable4SqrChairsWithVError: floorPlanIcons.RecTable4SqrChairsWithVError,
  recTable4SqrChairsWithVSelected:
    floorPlanIcons.RecTable4SqrChairsWithVSelected,
  recTable5SqrChairs50PctWrapBench:
    floorPlanIcons.RecTable5SqrChairs50PctWrapBench,
  recTable5SqrChairs50PctWrapBenchError:
    floorPlanIcons.RecTable5SqrChairs50PctWrapBenchError,
  recTable5SqrChairs50PctWrapBenchSelected:
    floorPlanIcons.RecTable5SqrChairs50PctWrapBenchSelected,
  recTable5SqrChairs: floorPlanIcons.RecTable5SqrChairs,
  recTable5SqrChairsError: floorPlanIcons.RecTable5SqrChairsError,
  recTable5SqrChairsSelected: floorPlanIcons.RecTable5SqrChairsSelected,
  recTable6RndChairs: floorPlanIcons.RecTable6RndChairs,
  recTable6RndChairsError: floorPlanIcons.RecTable6RndChairsError,
  recTable6RndChairsSelected: floorPlanIcons.RecTable6RndChairsSelected,
  recTable6SqrChairs: floorPlanIcons.RecTable6SqrChairs,
  recTable6SqrChairsError: floorPlanIcons.RecTable6SqrChairsError,
  recTable6SqrChairsSelected: floorPlanIcons.RecTable6SqrChairsSelected,
  recTable6SqrChairsV2: floorPlanIcons.RecTable6SqrChairsV2,
  recTable6SqrChairsV2Error: floorPlanIcons.RecTable6SqrChairsV2Error,
  recTable6SqrChairsV2Selected: floorPlanIcons.RecTable6SqrChairsV2Selected,
  recTable8RndChairs: floorPlanIcons.RecTable8RndChairs,
  recTable8RndChairsError: floorPlanIcons.RecTable8RndChairsError,
  recTable8RndChairsSelected: floorPlanIcons.RecTable8RndChairsSelected,
  recTableLong3SqrChairs50PctWrapBench:
    floorPlanIcons.RecTableLong3SqrChairs50PctWrapBench,
  recTableLong3SqrChairs50PctWrapBenchError:
    floorPlanIcons.RecTableLong3SqrChairs50PctWrapBenchError,
  recTableLong3SqrChairs50PctWrapBenchSelected:
    floorPlanIcons.RecTableLong3SqrChairs50PctWrapBenchSelected,
  recTableLrgLoveSeat2SqrChairs: floorPlanIcons.RecTableLrgLoveSeat2SqrChairs,
  recTableLrgLoveSeat2SqrChairsError:
    floorPlanIcons.RecTableLrgLoveSeat2SqrChairsError,
  recTableLrgLoveSeat2SqrChairsSelected:
    floorPlanIcons.RecTableLrgLoveSeat2SqrChairsSelected,
  rectTable2LoveSeats2SqrChairs: floorPlanIcons.RectTable2LoveSeats2SqrChairs,
  rectTable2LoveSeats2SqrChairsError:
    floorPlanIcons.RectTable2LoveSeats2SqrChairsError,
  rectTable2LoveSeats2SqrChairsSelected:
    floorPlanIcons.RectTable2LoveSeats2SqrChairsSelected,
  rectTable2LoveSeats: floorPlanIcons.RectTable2LoveSeats,
  rectTable2LoveSeatsError: floorPlanIcons.RectTable2LoveSeatsError,
  rectTable2LoveSeatsSelected: floorPlanIcons.RectTable2LoveSeatsSelected,
  // Round Tables
  rndTable1Bench2ArmChairs: floorPlanIcons.RndTable1Bench2ArmChairs,
  rndTable1Bench2ArmChairsError: floorPlanIcons.RndTable1Bench2ArmChairsError,
  rndTable1Bench2ArmChairsSelected:
    floorPlanIcons.RndTable1Bench2ArmChairsSelected,
  rndTable2ArmChairs: floorPlanIcons.RndTable2ArmChairs,
  rndTable2ArmChairsError: floorPlanIcons.RndTable2ArmChairsError,
  rndTable2ArmChairsSelected: floorPlanIcons.RndTable2ArmChairsSelected,
  rndTable2RndChairs: floorPlanIcons.RndTable2RndChairs,
  rndTable2RndChairsError: floorPlanIcons.RndTable2RndChairsError,
  rndTable2RndChairsSelected: floorPlanIcons.RndTable2RndChairsSelected,
  rndTable2SeatCouch: floorPlanIcons.RndTable2SeatCouch,
  rndTable2SeatCouchError: floorPlanIcons.RndTable2SeatCouchError,
  rndTable2SeatCouchSelected: floorPlanIcons.RndTable2SeatCouchSelected,
  rndTable3SqrChairs: floorPlanIcons.RndTable3SqrChairs,
  rndTable3SqrChairsError: floorPlanIcons.RndTable3SqrChairsError,
  rndTable3SqrChairsSelected: floorPlanIcons.RndTable3SqrChairsSelected,
  rndTable4LoveSeats1ArmChair: floorPlanIcons.RndTable4LoveSeats1ArmChair,
  rndTable4LoveSeats1ArmChairError:
    floorPlanIcons.RndTable4LoveSeats1ArmChairError,
  rndTable4LoveSeats1ArmChairSelected:
    floorPlanIcons.RndTable4LoveSeats1ArmChairSelected,
  rndTable4RndChairs: floorPlanIcons.RndTable4RndChairs,
  rndTable4RndChairsError: floorPlanIcons.RndTable4RndChairsError,
  rndTable4RndChairsSelected: floorPlanIcons.RndTable4RndChairsSelected,
  rndTable4SqrChairs: floorPlanIcons.RndTable4SqrChairs,
  rndTable4SqrChairsError: floorPlanIcons.RndTable4SqrChairsError,
  rndTable4SqrChairsSelected: floorPlanIcons.RndTable4SqrChairsSelected,
  rndTable50PctWrapBench: floorPlanIcons.RndTable50PctWrapBench,
  rndTable50PctWrapBenchError: floorPlanIcons.RndTable50PctWrapBenchError,
  rndTable50PctWrapBenchSelected: floorPlanIcons.RndTable50PctWrapBenchSelected,
  rndTable50PctWrapCornerBench: floorPlanIcons.RndTable50PctWrapCornerBench,
  rndTable50PctWrapCornerBenchError:
    floorPlanIcons.RndTable50PctWrapCornerBenchError,
  rndTable50PctWrapCornerBenchSelected:
    floorPlanIcons.RndTable50PctWrapCornerBenchSelected,
  rndTables3SeatCouch2ArmChairs: floorPlanIcons.RndTables3SeatCouch2ArmChairs,
  rndTables3SeatCouch2ArmChairsError:
    floorPlanIcons.RndTables3SeatCouch2ArmChairsError,
  rndTables3SeatCouch2ArmChairsSelected:
    floorPlanIcons.RndTables3SeatCouch2ArmChairsSelected,
  rndTable4SqrChairsWithV: floorPlanIcons.RndTable4SqrChairsWithV,
  rndTable4SqrChairsWithVError: floorPlanIcons.RndTable4SqrChairsWithVError,
  rndTable4SqrChairsWithVSelected:
    floorPlanIcons.RndTable4SqrChairsWithVSelected,
  rndTable6SqrChairs: floorPlanIcons.RndTable6SqrChairs,
  rndTable6SqrChairsError: floorPlanIcons.RndTable6SqrChairsError,
  rndTable6SqrChairsSelected: floorPlanIcons.RndTable6SqrChairsSelected,
  rndTable8RndChairs: floorPlanIcons.RndTable8RndChairs,
  rndTable8RndChairsError: floorPlanIcons.RndTable8RndChairsError,
  rndTable8RndChairsSelected: floorPlanIcons.RndTable8RndChairsSelected,
  rndTable50PctBenchWrap1SqrChair:
    floorPlanIcons.RndTable50PctBenchWrap1SqrChair,
  rndTable50PctBenchWrap1SqrChairError:
    floorPlanIcons.RndTable50PctBenchWrap1SqrChairError,
  rndTable50PctBenchWrap1SqrChairSelected:
    floorPlanIcons.RndTable50PctBenchWrap1SqrChairSelected,
  // Square Tables
  sqrTable1RndChair1SqrChair: floorPlanIcons.SqrTable1RndChair1SqrChair,
  sqrTable1RndChair1SqrChairError:
    floorPlanIcons.SqrTable1RndChair1SqrChairError,
  sqrTable1RndChair1SqrChairSelected:
    floorPlanIcons.SqrTable1RndChair1SqrChairSelected,
  sqrTable1RndChairBenchADA: floorPlanIcons.SqrTable1RndChairBenchADA,
  sqrTable1RndChairBenchADAError: floorPlanIcons.SqrTable1RndChairBenchADAError,
  sqrTable1RndChairBenchADASelected:
    floorPlanIcons.SqrTable1RndChairBenchADASelected,
  sqrTable2ArmChairs6LoveSeats: floorPlanIcons.SqrTable2ArmChairs6LoveSeats,
  sqrTable2ArmChairs6LoveSeatsError:
    floorPlanIcons.SqrTable2ArmChairs6LoveSeatsError,
  sqrTable2ArmChairs6LoveSeatsSelected:
    floorPlanIcons.SqrTable2ArmChairs6LoveSeatsSelected,
  sqrTable2RndChairs: floorPlanIcons.SqrTable2RndChairs,
  sqrTable2RndChairsError: floorPlanIcons.SqrTable2RndChairsError,
  sqrTable2RndChairsSelected: floorPlanIcons.SqrTable2RndChairsSelected,
  sqrTable2SqrChairs: floorPlanIcons.SqrTable2SqrChairs,
  sqrTable2SqrChairsError: floorPlanIcons.SqrTable2SqrChairsError,
  sqrTable2SqrChairsSelected: floorPlanIcons.SqrTable2SqrChairsSelected,
  sqrTable4RndChairs: floorPlanIcons.SqrTable4RndChairs,
  sqrTable4RndChairsError: floorPlanIcons.SqrTable4RndChairsError,
  sqrTable4RndChairsSelected: floorPlanIcons.SqrTable4RndChairsSelected,
  sqrTable4SqrChairs: floorPlanIcons.SqrTable4SqrChairs,
  sqrTable4SqrChairsError: floorPlanIcons.SqrTable4SqrChairsError,
  sqrTable4SqrChairsSelected: floorPlanIcons.SqrTable4SqrChairsSelected,
  sqrTable50PctWrapBench: floorPlanIcons.SqrTable50PctWrapBench,
  sqrTable50PctWrapBenchError: floorPlanIcons.SqrTable50PctWrapBenchError,
  sqrTable50PctWrapBenchSelected: floorPlanIcons.SqrTable50PctWrapBenchSelected,
  sqrTable75PctWrapBench: floorPlanIcons.SqrTable75PctWrapBench,
  sqrTable75PctWrapBenchError: floorPlanIcons.SqrTable75PctWrapBenchError,
  sqrTable75PctWrapBenchSelected: floorPlanIcons.SqrTable75PctWrapBenchSelected,
  sqrTable75PctWrapCornerBench: floorPlanIcons.SqrTable75PctWrapCornerBench,
  sqrTable75PctWrapCornerBenchError:
    floorPlanIcons.SqrTable75PctWrapCornerBenchError,
  sqrTable75PctWrapCornerBenchSelected:
    floorPlanIcons.SqrTable75PctWrapCornerBenchSelected,
  twoRndTable3SeatCouch2LoveSeats1ArmChair:
    floorPlanIcons.TwoRndTable3SeatCouch2LoveSeats1ArmChair,
  twoRndTable3SeatCouch2LoveSeats1ArmChairError:
    floorPlanIcons.TwoRndTable3SeatCouch2LoveSeats1ArmChairError,
  twoRndTable3SeatCouch2LoveSeats1ArmChairSelected:
    floorPlanIcons.TwoRndTable3SeatCouch2LoveSeats1ArmChairSelected,
  twoRndTablesTwo4SeatCouches: floorPlanIcons.TwoRndTablesTwo4SeatCouches,
  twoRndTablesTwo4SeatCouchesError:
    floorPlanIcons.TwoRndTablesTwo4SeatCouchesError,
  twoRndTablesTwo4SeatCouchesSelected:
    floorPlanIcons.TwoRndTablesTwo4SeatCouchesSelected,
  twoSqrTable2LoveSeats3ArmChairs:
    floorPlanIcons.TwoSqrTable2LoveSeats3ArmChairs,
  twoSqrTable2LoveSeats3ArmChairsError:
    floorPlanIcons.TwoSqrTable2LoveSeats3ArmChairsError,
  twoSqrTable2LoveSeats3ArmChairsSelected:
    floorPlanIcons.TwoSqrTable2LoveSeats3ArmChairsSelected,
  sqrTable2LoveSeats2ArmChairs1Ottoman:
    floorPlanIcons.SqrTable2LoveSeats2ArmChairs1Ottoman,
  sqrTable2LoveSeats2ArmChairs1OttomanError:
    floorPlanIcons.SqrTable2LoveSeats2ArmChairs1OttomanError,
  sqrTable2LoveSeats2ArmChairs1OttomanSelected:
    floorPlanIcons.SqrTable2LoveSeats2ArmChairs1OttomanSelected,
  sqrTable2LoveSeats2SqrChairs: floorPlanIcons.SqrTable2LoveSeats2SqrChairs,
  sqrTable2LoveSeats2SqrChairsError:
    floorPlanIcons.SqrTable2LoveSeats2SqrChairsError,
  sqrTable2LoveSeats2SqrChairsSelected:
    floorPlanIcons.SqrTable2LoveSeats2SqrChairsSelected,
  sqrTable4LoveSeats1ArmChair1Ottoman:
    floorPlanIcons.SqrTable4LoveSeats1ArmChair1Ottoman,
  sqrTable4LoveSeats1ArmChair1OttomanError:
    floorPlanIcons.SqrTable4LoveSeats1ArmChair1OttomanError,
  sqrTable4LoveSeats1ArmChair1OttomanSelected:
    floorPlanIcons.SqrTable4LoveSeats1ArmChair1OttomanSelected,
  sqrTable4LoveSeats1Ottoman: floorPlanIcons.SqrTable4LoveSeats1Ottoman,
  sqrTable4LoveSeats1OttomanError:
    floorPlanIcons.SqrTable4LoveSeats1OttomanError,
  sqrTable4LoveSeats1OttomanSelected:
    floorPlanIcons.SqrTable4LoveSeats1OttomanSelected,
  sqrTable4LoveSeats2Ottomans: floorPlanIcons.SqrTable4LoveSeats2Ottomans,
  sqrTable4LoveSeats2OttomansError:
    floorPlanIcons.SqrTable4LoveSeats2OttomansError,
  sqrTable4LoveSeats2OttomansSelected:
    floorPlanIcons.SqrTable4LoveSeats2OttomansSelected,
  // Service Status
  appetizer: serviceStatusIcons.Appetizer,
  arrived: serviceStatusIcons.Arrived,
  booked: serviceStatusIcons.Booked,
  bottleService: serviceStatusIcons.BottleService,
  checkDropped: serviceStatusIcons.CheckDropped,
  cleared: serviceStatusIcons.Cleared,
  confirmed: serviceStatusIcons.Confirmed,
  dessert: serviceStatusIcons.Dessert,
  drinks: serviceStatusIcons.Drinks,
  entree: serviceStatusIcons.Entree,
  leftMessage: serviceStatusIcons.LeftMessage,
  noAnswer: serviceStatusIcons.NoAnswer,
  onTheWay: serviceStatusIcons.OnTheWay,
  paid: serviceStatusIcons.Paid,
  partiallyArrived: serviceStatusIcons.PartiallyArrived,
  partiallySeated: serviceStatusIcons.PartiallySeated,
  postMealDrink: serviceStatusIcons.PostMealDrink,
  runningLate: serviceStatusIcons.RunningLate,
  seated: serviceStatusIcons.Seated,
  tableKnock: serviceStatusIcons.TableKnock,
  tableReady: serviceStatusIcons.TableReady,
  unconfirmed: serviceStatusIcons.Unconfirmed,
  wrongNumber: serviceStatusIcons.WrongNumber,
} as const;
