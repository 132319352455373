// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServiceWindowsForm__form___FUYsR{display:flex;flex-direction:column;row-gap:1rem}.ServiceWindowsForm__form___FUYsR .ServiceWindowsForm__panel___uNh5S{background-color:var(--panelBlue);padding:1rem;border-radius:8px;display:flex;flex-direction:column;row-gap:1rem}.ServiceWindowsForm__form___FUYsR .ServiceWindowsForm__addButton___ZrWos{border:1px dashed var(--white30);border-radius:6px;background-color:rgba(0,0,0,0);display:flex;flex-direction:row;justify-content:space-between;align-items:center;padding:16px 24px;color:var(--white30);font-size:14px;font-weight:700;line-height:1.1429;width:100%;cursor:pointer}.ServiceWindowsForm__form___FUYsR .ServiceWindowsForm__addButton___ZrWos:hover:enabled{border:1px solid var(--darkGrey100)}.ServiceWindowsForm__form___FUYsR .ServiceWindowsForm__addButton___ZrWos svg{width:1.5rem;stroke:var(--white)}.ServiceWindowsForm__form___FUYsR .ServiceWindowsForm__removeIconButton___sYCnU{background-color:unset;padding:0;align-self:flex-start}.ServiceWindowsForm__form___FUYsR .ServiceWindowsForm__removeIconButton___sYCnU>svg{width:1rem;stroke:var(--white70)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/serviceWindows/ServiceWindowsForm.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,qBAAA,CACA,YAAA,CAEA,qEACE,iCAAA,CACA,YAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CAGF,yEACE,gCAAA,CACA,iBAAA,CACA,8BAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,oBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA,CACA,cAAA,CAEA,uFACE,mCAAA,CAGF,6EACE,YAAA,CACA,mBAAA,CAIJ,gFACE,sBAAA,CACA,SAAA,CACA,qBAAA,CAEA,oFACE,UAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "ServiceWindowsForm__form___FUYsR",
	"panel": "ServiceWindowsForm__panel___uNh5S",
	"addButton": "ServiceWindowsForm__addButton___ZrWos",
	"removeIconButton": "ServiceWindowsForm__removeIconButton___sYCnU"
};
export default ___CSS_LOADER_EXPORT___;
