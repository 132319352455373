import { api } from 'restaurantAdmin/api';

export interface Server {
  floorPlanTables: Record<'id', string>[];
  hexColor: string;
  id: string;
  name: string;
}

export interface ServerPayload {
  name: string;
  hexColor: string;
  floorPlanTableIds: string[];
}

export const createServer = async (
  restaurantId: string,
  serverPayload: ServerPayload,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/servers`, serverPayload);

export const updateServer = async (
  restaurantId: string,
  serverId: string,
  serverPayload: ServerPayload,
): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/servers/${serverId}`, serverPayload);

export const getServers = (
  restaurantId: string,
  signal?: AbortSignal,
): Promise<Server[]> =>
  api.get(`/restaurants/${restaurantId}/servers`, { signal });

export const deleteServer = async (
  restaurantId: string,
  serverId: string,
): Promise<void> =>
  api.delete(`/restaurants/${restaurantId}/servers/${serverId}`);
