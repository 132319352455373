import cx from 'classnames';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Layout.scss';

export const PageContent = ({
  children,
  className,
  ...attrs
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => (
  <div
    className={cx(styles.pageMargin, styles.pageContent, className)}
    {...attrs}
  >
    {children}
  </div>
);
