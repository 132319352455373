/* eslint-disable jsx-a11y/label-has-associated-control */
import cx from 'classnames';
import Select, {
  type ClearIndicatorProps,
  components as DefaultComponents,
  type GroupBase,
  type Props as SelectProps,
  type StylesConfig,
} from 'react-select';
import {
  FormInputTooltip,
  type SetTooltipIsOpenDispatch,
  setTooltipIsOpenNoOp,
} from '@components/formInputTooltip/FormInputTooltip';
import formSelectStyles from './FormSelect.scss';

interface CustomProps {
  hideLabel?: boolean;
  label: string;
  labelClassName?: string;
  setTooltipIsOpen?: SetTooltipIsOpenDispatch;
  tooltipButtonClassName?: string;
  tooltipText?: string;
}

export interface SelectNumberOption {
  label: string;
  value: number;
}

export interface SelectStringOption {
  label: string;
  value: string;
}

export type FormSelectItem = SelectNumberOption | SelectStringOption;

export const FormSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  'aria-label': ariaLabel,
  className,
  components,
  defaultValue,
  hideLabel,
  isDisabled,
  isMulti,
  label,
  labelClassName,
  name,
  onChange,
  options,
  setTooltipIsOpen,
  styles,
  tooltipText,
  value,
}: SelectProps<Option, IsMulti, Group> & CustomProps) => {
  const customStyles: StylesConfig<Option, IsMulti, Group> = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused
        ? '2px solid var(--accent300)'
        : '2px solid var(--darkGrey300)',
      cursor: 'pointer',
      backgroundColor: 'var(--primary500)',
      '&:hover': {
        border: state.isFocused
          ? '2px solid var(--accent300)'
          : '2px solid var(--darkGrey300)',
      },
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      alignItems: 'center',
      backgroundColor: state.isSelected ? 'var(--gold)' : 'var(--primary400)',
      color: state.isSelected ? 'var(--black)' : 'var(--white)',
      cursor: 'pointer',
      display: 'flex',
      fontSize: '0.875rem',
      lineHeight: '2.5rem',
      textWrap: 'nowrap',
      width: '100%',
      '&:hover': {
        backgroundColor: 'var(--gold70)',
        color: 'var(--black)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'var(--primary400)',
      color: 'var(--white)',
      width: 'fit-content',
      'z-index': 'var(--z_conciergeDrawer)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDisabled ? 'var(--primary200)' : 'var(--white)',
    }),
    input: (provided) => ({
      ...provided,
      color: isDisabled ? 'var(--primary200)' : 'var(--white)',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDisabled ? 'var(--primary200)' : 'var(--white)',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'var(--primary200)',
      '&:hover': {
        color: 'var(--primary200)',
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: 'var(--primary200)',
      '&:hover': {
        color: 'var(--primary200)',
      },
    }),
  };

  const customDropdownIndicator = components?.DropdownIndicator
    ? { DropdownIndicator: components.DropdownIndicator }
    : {};
  const selectProps: SelectProps<Option, IsMulti, Group> = {
    'aria-errormessage': `${name}-error`,
    'aria-label': ariaLabel || label,
    classNamePrefix: 'select',
    closeMenuOnSelect: !isMulti,
    components: {
      ...customDropdownIndicator,
      // eslint-disable-next-line react/no-unstable-nested-components
      ClearIndicator: (props: ClearIndicatorProps<Option, IsMulti, Group>) => (
        <div aria-label="Clear all">
          <DefaultComponents.ClearIndicator {...props} />
        </div>
      ),
      IndicatorSeparator: () => null,
    },
    defaultValue,
    isDisabled,
    isMulti,
    name,
    onChange,
    options,
    styles: styles || customStyles,
    value,
  };

  if (hideLabel) {
    const placeholder = (
      <label className={labelClassName} data-testid={`${name}-placeholder`}>
        {label}
      </label>
    );
    return (
      <Select
        {...selectProps}
        className={className}
        placeholder={placeholder}
      />
    );
  }
  return (
    <label
      className={cx(formSelectStyles.defaultLabelTypography, labelClassName)}
      data-testid={`${name}-visible-label`}
      htmlFor={label}
    >
      <span>
        {label}
        <FormInputTooltip
          fieldLabel={label}
          id={`${name}-tooltip`}
          setTooltipIsOpen={setTooltipIsOpen || setTooltipIsOpenNoOp}
          text={tooltipText}
        />
      </span>
      <Select {...selectProps} className={className} />
    </label>
  );
};
