import { offset } from '@floating-ui/react';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { Icon } from '@components/icon/Icon';
import { MenuItem } from '@components/menu/MenuItem';
import { MenuListbox } from '@components/menu/MenuListbox';
import {
  RESERVATIONS_COVERS_REPORT_PATH,
  RESERVATIONS_RESERVATIONS_REPORT_PATH,
} from 'restaurantAdmin/paths';
import styles from './OccupantsPrintDropdown.scss';

export const OccupantsPrintDropdown = ({ date }: { date: string }) => (
  <Dropdown>
    <MenuButton aria-label="Print a report" className={styles.printButton}>
      <Icon name="printer" />
    </MenuButton>
    <Menu
      slotProps={{
        root: { placement: 'bottom-end', middleware: [offset(8)] },
      }}
      slots={{ listbox: MenuListbox }}
    >
      <MenuItem
        onClick={() =>
          window
            .open(
              `${RESERVATIONS_RESERVATIONS_REPORT_PATH}?date=${date}`,
              '_blank',
              'noopener,noreferrer',
            )
            ?.focus()
        }
      >
        Reservations
      </MenuItem>
      <MenuItem
        onClick={() =>
          window
            .open(
              `${RESERVATIONS_COVERS_REPORT_PATH}?date=${date}`,
              '_blank',
              'noopener,noreferrer',
            )
            ?.focus()
        }
      >
        Covers
      </MenuItem>
    </Menu>
  </Dropdown>
);
