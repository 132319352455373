import { useState } from 'react';

type IsOpenWithData<T> =
  | {
      isOpen: true;
      data: T;
    }
  | {
      isOpen: false;
      data: null;
    };

export const useIsOpenWithData = <T>() => {
  const [isOpenWithData, setIsOpenWithData] = useState<IsOpenWithData<T>>({
    isOpen: false,
    data: null,
  });

  return {
    isOpen: isOpenWithData.isOpen,
    data: isOpenWithData.data,
    isOpenWithData,
    open: (data: T) =>
      setIsOpenWithData({
        isOpen: true,
        data,
      }),
    close: () =>
      setIsOpenWithData({
        isOpen: false,
        data: null,
      }),
  };
};
