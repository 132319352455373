import { EmailSupport } from '@components/emailSupport/EmailSupport';
import typography from '~styles/typography.scss';
import styles from './ErrorHero.scss';

interface ErrorHeroProps {
  subtitle: string;
  title: string;
}

export const ErrorHero = ({ subtitle, title }: ErrorHeroProps) => (
  <div className={styles.container}>
    <h1 className={typography.d3}>{title}</h1>
    <p className={typography.c2}>{subtitle}</p>
    <EmailSupport className={styles.button} label="Contact us for help" />
  </div>
);
