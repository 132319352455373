import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Icon } from '@components/icon/Icon';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { IPAD1024 } from '@shared/styles/breakpoints';
import { deletePacingControls, updatePacingControls } from '../apiHelpers';
import styles from './PacingControlsForm.scss';

interface PacingControlsFormData {
  pacingLimit: number;
  pacingWindow: number;
}

export interface PacingControlsFormProps {
  pacingControls: {
    pacingLimit: number;
    pacingWindow: number;
  } | null;
  exitEditMode: () => void;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

const PACING_WINDOW_OPTIONS = [
  {
    label: '15 mins',
    value: 15,
  },
  {
    label: '30 mins',
    value: 30,
  },
  {
    label: '45 mins',
    value: 45,
  },
  {
    label: '60 mins',
    value: 60,
  },
  {
    label: '75 mins',
    value: 75,
  },
  {
    label: '90 mins',
    value: 90,
  },
  {
    label: '105 mins',
    value: 105,
  },
  {
    label: '120 mins',
    value: 120,
  },
  {
    label: '135 mins',
    value: 135,
  },
  {
    label: '150 mins',
    value: 150,
  },
  {
    label: '165 mins',
    value: 165,
  },
  {
    label: '180 mins',
    value: 180,
  },
  {
    label: '195 mins',
    value: 195,
  },
  {
    label: '210 mins',
    value: 210,
  },
  {
    label: '225 mins',
    value: 225,
  },
  {
    label: '240 mins',
    value: 240,
  },
];

export const PacingControlsForm = ({
  pacingControls,
  exitEditMode,
  refreshRestaurant,
  restaurantId,
}: PacingControlsFormProps) => {
  const { control, handleSubmit } = useForm<PacingControlsFormData>({
    defaultValues: {
      pacingLimit: pacingControls?.pacingLimit || undefined,
      pacingWindow: pacingControls?.pacingWindow || undefined,
    },
  });

  const handleOnSubmit = handleSubmit(async (data) => {
    try {
      await updatePacingControls(restaurantId, {
        pacingLimit: data.pacingLimit,
        pacingWindow: data.pacingWindow,
      });
      await refreshRestaurant();
      successToast({ message: 'Pacing Controls successfully updated' });
      exitEditMode();
    } catch (error) {
      errorToast({ message: 'Failed to update Pacing Controls' });
      reportAppError(error);
    }
  });

  const handleOnDelete = (): void => {
    void (async () => {
      try {
        await deletePacingControls(restaurantId);

        await refreshRestaurant();
        successToast({ message: 'Pacing Controls successfully deleted' });
        exitEditMode();
      } catch (error) {
        errorToast({ message: 'Failed to delete Pacing Controls' });
        reportAppError(error);
      }
    })();
  };

  const layoutVariant = useMediaQuery({ minWidth: IPAD1024 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  return (
    <form onSubmit={handleOnSubmit} className={styles.form}>
      <ControlledFormInput
        control={control}
        label="Pacing Limit"
        name="pacingLimit"
        rules={{
          min: {
            message: `Must be at least 1`,
            value: 1,
          },
          required: true,
        }}
        type="number"
        variant={layoutVariant}
      />
      <ControlledFormSelect
        control={control}
        label="Pacing Window"
        name="pacingWindow"
        options={PACING_WINDOW_OPTIONS}
        rules={{
          required: true,
        }}
        variant={layoutVariant}
      />
      <div className={styles.actions}>
        {!!pacingControls && (
          <button
            aria-label="Remove Pacing Controls"
            type="button"
            className={styles.deleteButton}
            onClick={handleOnDelete}
          >
            <Icon ariaLabel="Remove Pacing Controls" name="trash" />
            <span className={styles.buttonText}>Remove Pacing Controls</span>
          </button>
        )}
        <Button
          variant={ButtonVariants.Tertiary}
          label="Discard Edits"
          onClick={exitEditMode}
        />
        <Button
          label="Save Changes"
          type="submit"
          variant={ButtonVariants.Primary}
        />
      </div>
    </form>
  );
};
