import { type SelectNumberOption } from '@components/formSelect/FormSelect';

export const TURN_TIME_OPTIONS: SelectNumberOption[] = [
  {
    label: '30 mins',
    value: 30,
  },
  {
    label: '45 mins',
    value: 45,
  },
  {
    label: '60 mins',
    value: 60,
  },
  {
    label: '75 mins',
    value: 75,
  },
  {
    label: '90 mins',
    value: 90,
  },
  {
    label: '105 mins',
    value: 105,
  },
  {
    label: '120 mins',
    value: 120,
  },
  {
    label: '135 mins',
    value: 135,
  },
  {
    label: '150 mins',
    value: 150,
  },
  {
    label: '165 mins',
    value: 165,
  },
  {
    label: '180 mins',
    value: 180,
  },
  {
    label: '195 mins',
    value: 195,
  },
  {
    label: '210 mins',
    value: 210,
  },
  {
    label: '225 mins',
    value: 225,
  },
  {
    label: '240 mins',
    value: 240,
  },
];
