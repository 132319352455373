import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { useOverlay } from '@shared/hooks/useOverlay';

interface AvailabilityData {
  date: string;
  guestCount: number;
  listingPrice: number;
  listingId: string;
  time: string;
}

interface AvailabilityDrawerContextProviderProps {
  children: ReactNode;
}

export interface AvailabilityDrawerContextState {
  closeDrawer: () => void;
  availabilityData?: AvailabilityData;
  isOpen: boolean;
  openDrawer: (data: AvailabilityData) => void;
}

const AvailabilityDrawerContext =
  createContext<AvailabilityDrawerContextState | null>(null);
AvailabilityDrawerContext.displayName = 'AvailabilityDrawer';

export const useAvailabilityDrawerContext = () =>
  useDefinedContext(AvailabilityDrawerContext);

export const AvailabilityDrawerContextProvider = ({
  children,
}: AvailabilityDrawerContextProviderProps) => {
  const {
    isOpen: isOpenDrawer,
    open: _openDrawer,
    close: closeDrawer,
  } = useIsOpen();
  const [availabilityData, setAvailabilityData] = useState<AvailabilityData>();

  useOverlay(isOpenDrawer);

  const openDrawer = (newData: AvailabilityData): void => {
    _openDrawer();
    setAvailabilityData(newData);
  };

  const value = useMemo<AvailabilityDrawerContextState>(
    () => ({
      closeDrawer,
      availabilityData,
      isOpen: isOpenDrawer,
      openDrawer,
    }),
    [isOpenDrawer],
  );

  return (
    <AvailabilityDrawerContext.Provider value={value}>
      {children}
    </AvailabilityDrawerContext.Provider>
  );
};
