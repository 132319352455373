import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { type CancellationPolicy } from '@shared/reservations/types';
import { centsToCompactDollar } from '@utils/currency';
import styles from './CancellationFee.scss';
import { CollectFeeConfirmationModal } from './CollectFeeConfirmationModal';
import { WaiveFeeConfirmationModal } from './WaiveFeeConfirmationModal';

interface CancellationFeeProps {
  cancellationPolicy: CancellationPolicy;
  isEligibleForCancellationFee: boolean;
}

export const CancellationFee = ({
  cancellationPolicy,
  isEligibleForCancellationFee,
}: CancellationFeeProps) => {
  const [
    isCollectFeeConfirmationModalOpen,
    setIsCollectFeeConfirmationModalOpen,
  ] = useState(false);
  const [isWaiveFeeConfirmationModalOpen, setIsWaiveFeeConfirmationModalOpen] =
    useState(false);

  if (!isEligibleForCancellationFee) {
    return null;
  }

  return (
    <section className={styles.container}>
      <div>
        <Button
          label={`Collect ${centsToCompactDollar(cancellationPolicy.fee)} Fee`}
          onClick={() => setIsCollectFeeConfirmationModalOpen(true)}
          variant={ButtonVariants.Primary}
        />
        <CollectFeeConfirmationModal
          closeModal={() => setIsCollectFeeConfirmationModalOpen(false)}
          isOpen={isCollectFeeConfirmationModalOpen}
        />
        <Button
          label="Waive Fee"
          onClick={() => setIsWaiveFeeConfirmationModalOpen(true)}
          variant={ButtonVariants.Tertiary}
        />
        <WaiveFeeConfirmationModal
          closeModal={() => setIsWaiveFeeConfirmationModalOpen(false)}
          isOpen={isWaiveFeeConfirmationModalOpen}
        />
      </div>
    </section>
  );
};
