import { Button, ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { PageContent } from '../../../layout/PageContent';
import { PageHeader } from '../../../layout/PageHeader';
import { ListingForm } from '../form/ListingForm';
import { EditListingErrorModal } from './EditListingErrorModal';
import { EditListingWarningModal } from './EditListingWarningModal';
import { useEditListing } from './useEditListing';

export const EditListingPage = () => {
  const {
    closeErrorModal,
    closeWarningModal,
    control,
    errorModalData,
    floorPlan,
    handleOnClickSave,
    handleOnConfirmSubmit,
    isErrorModalOpen,
    isWarningModalOpen,
    referrer,
    warningModalData,
    setValue,
    resetField,
  } = useEditListing();

  if (!floorPlan) return null;

  return (
    <>
      <PageHeader category="Operations" title="Edit Listing">
        <LinkStyledAsButton
          label="Cancel"
          to={referrer}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Save Listing"
          variant={ButtonVariants.Primary}
          type="button"
          onClick={handleOnClickSave}
        />
      </PageHeader>
      <PageContent>
        <ListingForm
          control={control}
          setValue={setValue}
          resetField={resetField}
          floorPlan={floorPlan}
          isNewListing={false}
        />
      </PageContent>
      <EditListingErrorModal
        isOpen={isErrorModalOpen}
        closeModal={closeErrorModal}
        message={errorModalData?.message}
      />
      <EditListingWarningModal
        isOpen={isWarningModalOpen}
        closeModal={closeWarningModal}
        warnings={warningModalData?.warnings}
        handleOnConfirmSubmit={handleOnConfirmSubmit}
      />
    </>
  );
};
