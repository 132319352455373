import { type ChangeEventHandler } from 'react';

interface BasicRadioButtonProps {
  label: string;
  name: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
}

export const BasicRadioButton = ({
  label,
  checked,
  name,
  onChange,
  value,
}: BasicRadioButtonProps) => (
  <label>
    <span>{label}</span>
    <input
      checked={checked}
      name={name}
      onChange={onChange}
      type="radio"
      value={value}
    />
  </label>
);
