import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FeatureFlagContextProvider } from '@shared/context/FeatureFlagContext';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { AuthContextProvider } from './context/AuthContext';
import { featureFlagConfig } from './featureFlags';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.overrides.css';
import './index.scss';

export const Root = () => {
  useDocumentTitle('Peak Reservations');
  return (
    <FeatureFlagContextProvider environmentConfig={featureFlagConfig}>
      <ToastContainer
        autoClose={5000}
        closeButton
        hideProgressBar
        limit={2}
        newestOnTop
        pauseOnHover
        position="top-right"
        theme="colored"
      />
      <AuthContextProvider>
        <Outlet />
      </AuthContextProvider>
    </FeatureFlagContextProvider>
  );
};
