import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import { ApiUnauthorizedError } from '@shared/api/errors';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { reportAppError } from '@shared/reportAppError';
import type { LoginResponse } from '../auth/apiHelpers';
import * as apiHelpers from '../auth/apiHelpers';
import * as restaurantApiHelpers from '../restaurants/apiHelpers';
import { type RestaurantMetadata } from '../restaurants/apiHelpers';

const LAST_VIEWED_RESTAURANT_ID_KEY =
  'peakRestaurantAdminLastViewedRestaurantId';

export interface AuthContextState {
  adminName?: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  loginAndSetRestaurants: (
    email: string,
    password: string,
  ) => Promise<LoginResponse>;
  refreshRestaurants: () => void;
  restaurants: RestaurantMetadata[];
  selectedRestaurant?: RestaurantMetadata;
  selectedRestaurantId?: string;
  updateSelectedRestaurantId: (id: string) => void;
  logout: (onSuccess: () => void) => void;
}

export const AuthContext = createContext<AuthContextState | null>(null);
AuthContext.displayName = 'Auth';

export const useAuth = () => useDefinedContext(AuthContext);

interface ObjectWithId {
  id: string;
}

const toMapWithIdAsKey = <T extends ObjectWithId>(
  arr: T[],
): Record<string, T> =>
  arr.reduce<Record<string, T>>((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

export const AuthContextProvider = ({ children }: { children?: ReactNode }) => {
  const [adminName, setAdminName] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isRestaurantsLoading, setIsRestaurantsLoading] = useState(false);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<string>();
  const [restaurants, setRestaurants] = useState<
    Record<string, RestaurantMetadata>
  >({});

  const defaultSelectedRestaurantId = (
    adminsRestaurants: RestaurantMetadata[] = [],
  ) => {
    const lastViewedRestaurantId =
      localStorage.getItem(LAST_VIEWED_RESTAURANT_ID_KEY) ?? undefined;

    const hasLastViewedRestaurantId = adminsRestaurants.some(
      (r) => r.id === lastViewedRestaurantId,
    );
    if (hasLastViewedRestaurantId) {
      setSelectedRestaurantId(lastViewedRestaurantId);
    } else {
      const firstRestaurantId = adminsRestaurants[0]?.id;
      setSelectedRestaurantId(firstRestaurantId);
      localStorage.setItem(LAST_VIEWED_RESTAURANT_ID_KEY, firstRestaurantId);
    }
  };

  const updateSelectedRestaurantId = (id: string): void => {
    setSelectedRestaurantId(id);
    localStorage.setItem(LAST_VIEWED_RESTAURANT_ID_KEY, id);
  };

  const { isPending: isCheckAuthLoading } = useAbortEffect(
    {
      effect: async (signal) => {
        let authData;
        let restaurantsData;
        try {
          authData = await apiHelpers.checkAuth(signal);
          restaurantsData =
            await restaurantApiHelpers.getAdminRestaurants(signal);
        } catch (e) {
          if (e instanceof ApiUnauthorizedError) {
            return;
          }
          throw e;
        }
        const restaurantMap = toMapWithIdAsKey(restaurantsData);
        setRestaurants(restaurantMap);
        defaultSelectedRestaurantId(restaurantsData);
        setIsAuthenticated(authData.isAuthenticated);
        setAdminName(authData.fullName || '');
      },
      throwOnError: true,
    },
    [],
  );

  const loginAndSetRestaurants = async (
    email: string,
    password: string,
  ): Promise<LoginResponse> => {
    const metadata = await apiHelpers.login(email, password);
    const restaurantsData = await restaurantApiHelpers.getAdminRestaurants();
    const restaurantMap = toMapWithIdAsKey(restaurantsData);
    setRestaurants(restaurantMap);
    defaultSelectedRestaurantId(restaurantsData);
    setIsAuthenticated(true);
    setAdminName(metadata.fullName || '');
    return metadata;
  };

  const logout = (onSuccess: () => void) => {
    void (async () => {
      try {
        await apiHelpers.signOut();
      } catch (e) {
        reportAppError(e);
        return;
      }
      localStorage.clear();
      setIsAuthenticated(false);
      onSuccess();
    })();
  };

  const refreshRestaurants = () => {
    setIsRestaurantsLoading(true);
    void (async () => {
      try {
        const response = await restaurantApiHelpers.getAdminRestaurants();
        const restaurantMap = toMapWithIdAsKey(response);
        setRestaurants(restaurantMap);
        defaultSelectedRestaurantId(response);
      } finally {
        setIsRestaurantsLoading(false);
      }
    })();
  };

  // derived state
  const selectedRestaurant = selectedRestaurantId
    ? restaurants[selectedRestaurantId]
    : undefined;
  const restaurantValues = Object.values(restaurants);

  const isLoading = isCheckAuthLoading || isRestaurantsLoading;

  const value = useMemo<AuthContextState>(
    () => ({
      adminName,
      isAuthenticated,
      isLoading,
      loginAndSetRestaurants,
      refreshRestaurants,
      restaurants: restaurantValues,
      selectedRestaurant,
      selectedRestaurantId,
      updateSelectedRestaurantId,
      logout,
    }),
    [
      adminName,
      isLoading,
      isAuthenticated,
      refreshRestaurants,
      selectedRestaurantId,
      selectedRestaurant,
      restaurants,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
