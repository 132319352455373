import {
  type DependencyList,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { reportAppError } from '@shared/reportAppError';

export interface UseAbortEffectOptions<T> {
  /**
   * The provided AbortSignal will be aborted when dependencies change or when
   * the component is unmounted.
   */
  effect: UseAbortEffectFn<T>;
  /** Invoked when the effect throws an error that is not caused by an aborted signal */
  onError?: (error: unknown) => void;
  /** Throw the error on render to propagate to the error boundary */
  throwOnError?: boolean;
}

export type UseAbortEffectReturn<T> = ResultAndStatus<T> & {
  rerun: () => void;
};

type UseAbortEffectFn<T> = (signal: AbortSignal) => Promise<T>;

type Result<T> = { data: T; error?: never } | { data?: never; error: unknown };

type ResultAndStatus<T> =
  | ({ isPending: true } & Partial<Result<T>>)
  | ({ isPending: false } & Result<T>);

/**
 * Like useEffect but with some added conveniences. This is generally useful
 * whenever you want to fetch data in an effect.
 *
 * The resolved value of the latest invocation of the effect function is
 * returned in the `data` or `error` prop.
 *
 * An AbortSignal is provided to the effect function. The signal is aborted
 * when dependencies change or when the component unmounts.
 *
 * You may specify `throwOnError: true` in the options argument to cause errors
 * to be propagated to the error boundary.
 */
export const useAbortEffect = <T>(
  options: UseAbortEffectFn<T> | UseAbortEffectOptions<T>,
  deps: DependencyList,
): UseAbortEffectReturn<T> => {
  const {
    effect,
    onError = reportAppError,
    throwOnError = false,
  } = typeof options === 'function' ? { effect: options } : options;
  const [result, setResult] = useState<{ id: number } & Result<T>>();
  const [rerunVal, rerun] = useReducer((n: number) => n + 1, 0);
  const idRef = useRef(0);

  const abortController = useMemo(() => {
    idRef.current += 1;
    const id = idRef.current;
    const abort = new AbortController();
    void (async () => {
      try {
        const data = await effect(abort.signal);
        if (!abort.signal.aborted) {
          setResult({ id, data });
        }
      } catch (error) {
        if (!abort.signal.aborted) {
          setResult({ id, error });
        }
        if (!throwOnError && error !== abort.signal.reason) {
          onError(error);
        }
      }
    })();
    return abort;
  }, [rerunVal, ...deps]);

  useEffect(() => () => abortController.abort(), [abortController]);

  let resultAndStatus: ResultAndStatus<T>;
  if (!result) {
    resultAndStatus = { isPending: true };
  } else if (throwOnError && 'error' in result) {
    throw result.error;
  } else {
    resultAndStatus = {
      isPending: result.id !== idRef.current,
      data: result.data,
      error: result.error,
    } as ResultAndStatus<T>;
  }

  return {
    ...resultAndStatus,
    rerun,
  };
};
