// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloorPlan__floorPlanContainer___eTAu8{flex:1;height:100%;max-height:300px;max-width:420px;position:relative;width:100%}@media only screen and (min-width: 768px){.FloorPlan__floorPlanContainer___eTAu8{max-height:580px;max-width:var(--maxFloorPlanDesktopWidth)}}.FloorPlan__noFloorPlanContainer___DId7z{color:var(--white);text-align:center;width:100%}.FloorPlan__floorPlan___xQKdJ{opacity:0;visibility:hidden;display:flex;align-items:center;justify-content:center}.FloorPlan__floorPlanLoaded____vGaa{visibility:visible;opacity:1;transition:visibility 1s,opacity 1s}.FloorPlan__tableIcon___oiz2d{position:relative}.FloorPlan__buttonWithNoStyles___wE0eU{all:unset}", "",{"version":3,"sources":["webpack://./src/shared/components/floorPlan/FloorPlan.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,uCACE,MAAA,CACA,WAAA,CACA,gBAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CCwBA,0CD9BF,uCASI,gBAAA,CACA,yCAAA,CAAA,CAIJ,yCACE,kBAAA,CACA,iBAAA,CACA,UAAA,CAGF,8BACE,SAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,oCACE,kBAAA,CACA,SAAA,CACA,mCACE,CAIJ,8BACE,iBAAA,CAGF,uCACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floorPlanContainer": "FloorPlan__floorPlanContainer___eTAu8",
	"noFloorPlanContainer": "FloorPlan__noFloorPlanContainer___DId7z",
	"floorPlan": "FloorPlan__floorPlan___xQKdJ",
	"floorPlanLoaded": "FloorPlan__floorPlanLoaded____vGaa",
	"tableIcon": "FloorPlan__tableIcon___oiz2d",
	"buttonWithNoStyles": "FloorPlan__buttonWithNoStyles___wE0eU"
};
export default ___CSS_LOADER_EXPORT___;
