// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WaitListTimer__waitTimer___nc3PM{column-gap:.25rem;display:flex;flex-direction:column;height:1.6rem;justify-content:space-between;text-align:center;width:2.5rem}.WaitListTimer__waitBar___g_yKC{background:#364153;border-radius:2px;width:100%}.WaitListTimer__waitBarColor___uCXb9{border:2px solid;border-radius:2px;margin:0}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/waitList/WaitListTimer.scss"],"names":[],"mappings":"AAAA,kCACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,6BAAA,CACA,iBAAA,CACA,YAAA,CAGF,gCACE,kBAAA,CACA,iBAAA,CACA,UAAA,CAGF,qCACE,gBAAA,CACA,iBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"waitTimer": "WaitListTimer__waitTimer___nc3PM",
	"waitBar": "WaitListTimer__waitBar___g_yKC",
	"waitBarColor": "WaitListTimer__waitBarColor___uCXb9"
};
export default ___CSS_LOADER_EXPORT___;
