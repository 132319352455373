import { kebabCase } from 'lodash-es';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import { type AvailableWalkInTime } from '../apiHelpers';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import type { FloorPlanOccupant } from '../state/types';
import { SeatedFloorPlanTable } from './SeatedFloorPlanTable';
import { UnoccupiedFloorPlanTable } from './UnoccupiedFloorPlanTable';

interface OccupantFloorPlanTableFactoryProps {
  floorPlanTable: HostFloorPlanTable;
  handleTableOnClick: (floorPlanTable: HostFloorPlanTable) => void;
  seatedTableOnClick: (occupant: FloorPlanOccupant) => void;
  selectedEmptyTableId: string | undefined;
  availableTime: AvailableWalkInTime;
  tableIconScale: number;
}
export const OccupantFloorPlanTableFactory = ({
  floorPlanTable,
  handleTableOnClick,
  seatedTableOnClick,
  selectedEmptyTableId,
  availableTime,
  tableIconScale,
}: OccupantFloorPlanTableFactoryProps) => {
  const { seatedOccupant } = floorPlanTable;
  const { selectedOccupant } = useReservationServiceContext();
  const isSeated = seatedOccupant !== null;
  const testId = kebabCase(`floor-plan-table-${floorPlanTable.name}`);

  if (isSeated) {
    return (
      <SeatedFloorPlanTable
        handleTableOnClick={() => {
          seatedTableOnClick({
            id: seatedOccupant.id,
            type: seatedOccupant.type,
          });
        }}
        iconName={floorPlanTable.iconName}
        iconWidthScale={floorPlanTable.iconWidthScale}
        isAnyTableHighlighted={selectedOccupant != null}
        isDisabled={false}
        isHighlighted={selectedOccupant?.id === seatedOccupant.id}
        key={floorPlanTable.id}
        left={floorPlanTable.left}
        name={floorPlanTable.name}
        orientation={floorPlanTable.orientation}
        seatedOccupant={seatedOccupant}
        tableIconScale={tableIconScale}
        tableName={floorPlanTable.name}
        testId={testId}
        top={floorPlanTable.top}
        assignedServer={floorPlanTable.assignedServer}
      />
    );
  }

  const isSelectedTable = selectedEmptyTableId
    ? selectedEmptyTableId === floorPlanTable.id
    : false;

  return (
    <UnoccupiedFloorPlanTable
      availableTime={availableTime}
      handleTableOnClick={() => {
        void handleTableOnClick(floorPlanTable);
      }}
      iconName={floorPlanTable.iconName}
      iconWidthScale={floorPlanTable.iconWidthScale}
      isHighlighted={isSelectedTable}
      isSelected={isSelectedTable}
      key={floorPlanTable.id}
      left={floorPlanTable.left}
      name={floorPlanTable.name}
      orientation={floorPlanTable.orientation}
      tableIconScale={tableIconScale}
      testId={testId}
      top={floorPlanTable.top}
      assignedServer={floorPlanTable.assignedServer}
    />
  );
};
