// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuSeparator__separator___QOdll{background-color:var(--darkGrey200);height:.065rem;margin:.5rem 1rem}", "",{"version":3,"sources":["webpack://./src/shared/components/menu/MenuSeparator.scss"],"names":[],"mappings":"AAAA,kCACE,mCAAA,CACA,cAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": "MenuSeparator__separator___QOdll"
};
export default ___CSS_LOADER_EXPORT___;
