import { Navigate, Outlet } from 'react-router-dom';
import { LoadingPage } from '@components/LoadingPage';
import { useAuth } from '../context/AuthContext';
import { ROOT_PATH } from '../paths';

export const IsAuthenticated = () => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingPage />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={ROOT_PATH} />;
};
