import { offset } from '@floating-ui/react';
import { Popup } from '@mui/base/Unstable_Popup/Popup';
import {
  createFilterOptions,
  useAutocomplete,
} from '@mui/base/useAutocomplete';
import cx from 'classnames';
import { useMemo } from 'react';
import { Chip } from '@components/chip/Chip';
import { FormListbox } from '@components/formListbox/FormListbox';
import { Icon } from '@components/icon/Icon';
import styles from './Combobox.scss';

export interface ComboBoxProps<Option extends object> {
  'aria-label'?: string;
  getOptionLabel: (option: Option) => string;
  onChange?: (value: OptionOrInput<Option>[]) => void;
  options: Option[];
  placeholder?: string;
  value?: OptionOrInput<Option>[];
}

// Option must extend object so that it can be differentiated from a string input value
type OptionOrInput<Option extends object> = Option | string;

type FilterOptions<T> = ReturnType<typeof createFilterOptions<T>>;

export const Combobox = <Option extends object>({
  'aria-label': ariaLabel,
  getOptionLabel,
  onChange,
  options,
  placeholder,
  value: valueIn,
}: ComboBoxProps<Option>) => {
  const getValueLabel = (value: OptionOrInput<Option>): string =>
    typeof value === 'string' ? value : getOptionLabel(value);

  const filterOptions = useMemo((): FilterOptions<OptionOrInput<Option>> => {
    const filter = createFilterOptions<OptionOrInput<Option>>();

    return (opts, state) => {
      function* genOptions() {
        yield* filter(opts, state);
        const inputValue = state.inputValue.trim();
        if (inputValue.length > 0) {
          const isExisting = opts.some(
            (o) => state.getOptionLabel(o) === inputValue,
          );
          if (!isExisting) {
            yield inputValue;
          }
        }
      }
      return Array.from(genOptions());
    };
  }, []);

  const {
    anchorEl,
    getClearProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getPopupIndicatorProps,
    getRootProps,
    popupOpen,
    groupedOptions,
    getTagProps,
    setAnchorEl,
    value,
  } = useAutocomplete<OptionOrInput<Option>, true, false, true>({
    autoHighlight: true,
    disableCloseOnSelect: true,
    filterOptions,
    filterSelectedOptions: true,
    freeSolo: true,
    getOptionLabel: getValueLabel,
    includeInputInList: true,
    multiple: true,
    onChange: (_event, val, _reason, _details) => onChange?.(val),
    options,
    isOptionEqualToValue: (option, val) =>
      getValueLabel(option) === getValueLabel(val),
    value: valueIn,
  });

  // not using groupBy
  const displayOptions = groupedOptions as OptionOrInput<Option>[];

  return (
    <>
      <div {...getRootProps()} className={styles.comboBox} ref={setAnchorEl}>
        <div className={styles.values}>
          {value.map((val, index) => {
            const { key, ...props } = getTagProps({ index });
            return (
              <Chip key={key} {...props}>
                {getValueLabel(val)}
              </Chip>
            );
          })}
          <input
            {...getInputProps()}
            aria-label={ariaLabel}
            className={styles.input}
            placeholder={value.length ? undefined : placeholder}
          />
        </div>
        {value.length > 0 && (
          <button
            {...getClearProps()}
            aria-label="Clear"
            className={cx(styles.button, styles.mainButton)}
          >
            <Icon className={styles.clearIcon} name="close" />
          </button>
        )}
        <button
          {...getPopupIndicatorProps()}
          aria-label="Show options"
          className={cx(styles.button, styles.mainButton)}
        >
          <Icon className={styles.popupIndicatorIcon} name="chevron" />
        </button>
      </div>
      {anchorEl && (
        <Popup
          open={popupOpen}
          anchor={anchorEl}
          middleware={[offset(8)]}
          placement="bottom-start"
          className={styles.popup}
        >
          <FormListbox {...getListboxProps()}>
            {displayOptions.map((option, index) => {
              const optionProps = getOptionProps({
                option,
                index,
              });
              const { key, ...props } = optionProps as typeof optionProps & {
                key: string;
              };
              return (
                <li key={key} {...props}>
                  {typeof option === 'string'
                    ? `Create "${option}"`
                    : getOptionLabel(option)}
                </li>
              );
            })}
          </FormListbox>
        </Popup>
      )}
    </>
  );
};
