import cx from 'classnames';
import { parseISO, subHours } from 'date-fns';
import { isCancellationPolicyApplicable } from '@shared/reservations/reservationUtil';
import { centsToDollar } from '@shared/utils/currency';
import { toShortDateTime } from '@shared/utils/dateFormatters';
import typography from '~styles/typography.scss';
import styles from './CancellationPolicyNotice.scss';

export interface CancellationPolicyNoticeProps {
  purchasePrice: number;
  threshold: number;
  availabilityTime: string;
  hoursBeforeReservation: number;
  date: string;
  cancellationFee: number;
}

export const CancellationPolicyNotice = ({
  availabilityTime,
  cancellationFee,
  date,
  hoursBeforeReservation,
  purchasePrice,
  threshold,
}: CancellationPolicyNoticeProps) => {
  if (!isCancellationPolicyApplicable(purchasePrice, threshold)) return null;

  const timeBeforeCancellationApplies = subHours(
    parseISO(`${date}T${availabilityTime}`),
    hoursBeforeReservation,
  );

  return (
    <div className={styles.container}>
      <h3 className={typography.h6}>Cancellation Policy</h3>
      <p className={cx(typography.t2, styles.text)}>
        Free cancellation before{' '}
        {toShortDateTime(timeBeforeCancellationApplies)} (local to the
        restaurant). After that, cancelling this reservation may trigger a fee
        of {centsToDollar(cancellationFee)}
      </p>
    </div>
  );
};
