import cx from 'classnames';
import * as React from 'react';
import type { AnyIcon } from '../icon/Icon';
import { Icon } from '../icon/Icon';
import styles from './IconButton.scss';

interface IconButtonProps {
  ariaLabel: string;
  className?: string;
  disabled?: boolean;
  iconClassName?: string;
  iconFill?: string;
  iconName: AnyIcon;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  testId?: string;
  showLabel?: boolean;
}

export const IconButton = ({
  ariaLabel,
  className,
  disabled = false,
  iconClassName,
  iconFill,
  iconName,
  onClick,
  testId = '',
  showLabel = false,
}: IconButtonProps) => (
  <button
    aria-label={ariaLabel}
    className={cx(styles.button, className)}
    disabled={disabled}
    onClick={onClick}
    title={ariaLabel}
    type="button"
  >
    <Icon
      className={iconClassName}
      fill={iconFill}
      name={iconName}
      testId={testId}
    />
    {showLabel && ariaLabel}
  </button>
);
