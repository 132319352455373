// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestSearchBar__form___RbFV9{display:flex;flex-direction:row;gap:.5rem;justify-content:space-between;padding-right:.5rem;width:50%}.GuestSearchBar__form___RbFV9 input{background-color:var(--primary500);border:2px solid var(--darkGrey300);border-radius:.25rem;color:var(--white);flex:1;padding:.5rem .75rem}.GuestSearchBar__form___RbFV9 button{border:2px solid var(--darkGrey300);color:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/guestBook/GuestSearchBar.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,kBAAA,CACA,SAAA,CACA,6BAAA,CACA,mBAAA,CACA,SAAA,CAEA,oCACE,kCAAA,CACA,mCAAA,CACA,oBAAA,CACA,kBAAA,CACA,MAAA,CACA,oBAAA,CAGF,qCACE,mCAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "GuestSearchBar__form___RbFV9"
};
export default ___CSS_LOADER_EXPORT___;
