import cx from 'classnames';
import { useEffect, useState } from 'react';
import { ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { SETTINGS_INVITE_MEMBER_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { type Admin, getAdmins } from './apiHelpers';
import { TeamKebab } from './TeamKebab';
import styles from './TeamPage.scss';

export const TeamPage = () => {
  const { id: restaurantId } = useRestaurant();
  const [admins, setAdmins] = useState<Admin[]>([]);

  const fetchRestaurantAdmins = async () => {
    const response = await getAdmins(restaurantId);
    setAdmins(response);
  };

  useEffect(() => {
    fetchRestaurantAdmins();
  }, [restaurantId]);

  return (
    <>
      <PageHeader title="Team">
        <LinkStyledAsButton
          to={SETTINGS_INVITE_MEMBER_PATH}
          variant={ButtonVariants.Secondary}
          className={styles.inviteTeamMemberButton}
          label="Invite Team Member"
        />
      </PageHeader>

      <PageContent>
        <table className={styles.teamTable}>
          <thead>
            <tr>
              <th className={typography.t2}>Status</th>
              <th className={typography.t2}>Full Name</th>
              <th className={typography.t2}>Email</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin.id}>
                <td>
                  <span
                    className={cx(
                      typography.t3,
                      styles.pill,
                      styles[admin.status],
                    )}
                  >
                    {admin.status}
                  </span>
                </td>
                <td className={typography.c2}>{admin.fullName}</td>
                <td className={typography.c2}>{admin.email}</td>
                <td aria-label="options">
                  <TeamKebab
                    admin={admin}
                    refetchRestaurantAdmins={fetchRestaurantAdmins}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PageContent>
    </>
  );
};
