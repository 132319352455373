import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

export interface NoShowConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleOnClickNoShow: () => Promise<void>;
}

export const NoShowConfirmationModal = ({
  isOpen,
  closeModal,
  handleOnClickNoShow,
}: NoShowConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClickConfirm = async () => {
    setIsLoading(true);
    await handleOnClickNoShow();
    closeModal();
  };

  return (
    <Modal
      ariaLabel="No Show"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you would like to flag this guest as a no show?"
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isLoading}
          label="Yes, flag as no show"
          onClick={handleOnClickConfirm}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
