import { format, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { Error } from '@components/error/Error';
import { FinanceCard } from '@components/financeCard/FinanceCard';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import type { FinancialSummaryForRestaurantAdmin } from './apiHelpers';
import { getFinancialSummary } from './apiHelpers';
import styles from './FinancesPage.scss';

const previousMonth = () => format(sub(Date.now(), { months: 1 }), 'yyyy-MM');

const isInvalidMonth = (value: string) => !/^\d{4}-\d{2}$/.test(value);

export const FinancesPage = () => {
  const restaurant = useRestaurant();
  const [financialSummary, setFinancialSummary] =
    useState<FinancialSummaryForRestaurantAdmin>();
  const [errorMessage, setErrorMessage] = useState('');
  const [month, setMonth] = useState<string>(previousMonth());

  const clearError = () => setErrorMessage('');

  useEffect(() => {
    if (isInvalidMonth(month)) return;

    const fetch = async () => {
      try {
        const response = await getFinancialSummary(restaurant.id, month);

        setFinancialSummary(response);
        clearError();
      } catch (error) {
        if (error instanceof ApiError) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage('An internal server error occurred');
        }
        reportAppError(error);
      }
    };
    fetch();
  }, [month, restaurant.id]);

  return (
    <>
      <PageHeader title={`${restaurant.name} Finance`} />
      <PageContent>
        <input
          aria-label="month"
          className={styles.datePicker}
          onChange={({ target: { value } }) => setMonth(value)}
          placeholder="YYYY-MM"
          type="month"
          value={month}
        />
        <Error className={styles.error} message={errorMessage} />
        {financialSummary && <FinanceCard financialData={financialSummary} />}
      </PageContent>
    </>
  );
};
