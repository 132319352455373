// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestMetrics__container___xNL3a button{color:var(--white);background-color:rgba(0,0,0,0);outline:none;border:none;cursor:pointer;text-align:left;border-radius:8px}.GuestMetrics__container___xNL3a button:focus:focus-visible{outline:1px solid var(--gold)}.GuestMetrics__container___xNL3a svg{width:12px;stroke:var(--white)}.GuestMetrics__container___xNL3a .GuestMetrics__heading___aOBk0{display:flex;flex-direction:row;align-items:center;column-gap:4px;margin-bottom:4px}.GuestMetrics__container___xNL3a .GuestMetrics__count___sQFFz{display:flex;align-items:center;color:var(--white);width:6.75rem;height:1.5rem;margin:0;text-wrap:nowrap;font-size:18px;font-weight:600;line-height:24px}.GuestMetrics__container___xNL3a .GuestMetrics__count___sQFFz span{font-weight:600;font-size:18px;color:var(--white70)}.GuestMetrics__container___xNL3a .GuestMetrics__count___sQFFz span:last-child{font-size:10px;font-weight:500;line-height:16px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/pageHeader/GuestMetrics.scss"],"names":[],"mappings":"AACE,wCACE,kBAAA,CACA,8BAAA,CACA,YAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAEA,4DACE,6BAAA,CAIJ,qCACE,UAAA,CACA,mBAAA,CAGF,gEACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CAGF,8DACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,aAAA,CACA,QAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAEA,mEACE,eAAA,CACA,cAAA,CACA,oBAAA,CAEA,8EACE,cAAA,CACA,eAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestMetrics__container___xNL3a",
	"heading": "GuestMetrics__heading___aOBk0",
	"count": "GuestMetrics__count___sQFFz"
};
export default ___CSS_LOADER_EXPORT___;
