import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { Card } from '@components/card/Card';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import typography from '~styles/typography.scss';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_CALENDAR_INACTIVE_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
} from '../../../paths';
import { AdminCalendar } from '../calendar/AdminCalendar';
import { useListingsContext } from '../ListingsContext';
import { ListingDetailsSheet } from './ListingDetailsSheet';
import { ListingsList } from './ListingsList';
import styles from './ListingsPage.scss';
import { ListingsPageHeader } from './ListingsPageHeader';

export const ListingsCalendarPage = () => {
  const {
    selectedListing,
    isLoading,
    listings,
    selectedCalendarCellListingIds,
  } = useListingsContext();

  const tabClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.tab]: true,
      [styles.activeTab]: isActive,
      [typography.t1]: true,
    });

  const isTabsVisible = !selectedListing;

  const filteredListings = selectedCalendarCellListingIds.length
    ? listings.filter((listing) =>
        selectedCalendarCellListingIds.includes(listing.id),
      )
    : listings;

  if (isLoading) return null;

  return (
    <>
      <ListingsPageHeader />
      <PageContent className={styles.content}>
        <Card className={styles.displayPanel}>
          <AdminCalendar
            className={styles.calendar}
            highlightedListing={selectedListing}
            interactive
          />
        </Card>
        <div className={styles.sidePanel}>
          <div
            className={styles.tabs}
            data-testid="tabs"
            style={{ display: isTabsVisible ? undefined : 'none' }}
          >
            <div className={styles.tabBar}>
              <NavLink
                className={tabClassNames}
                to={OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH}
              >
                Published
              </NavLink>
              <NavLink
                className={tabClassNames}
                to={OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH}
              >
                Draft
              </NavLink>
              <NavLink
                className={tabClassNames}
                to={OPERATIONS_LISTINGS_CALENDAR_INACTIVE_PATH}
              >
                Inactive
              </NavLink>
            </div>
            <ListingsList filteredListings={filteredListings} />
          </div>
          {selectedListing && (
            <ListingDetailsSheet filteredListings={filteredListings} />
          )}
        </div>
      </PageContent>
    </>
  );
};
