import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import type { FloorPlanData } from '@shared/types/floorPlans';
import { todayInTimezone } from '@shared/utils/dateFormatters';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useAdminFloorPlan } from '../../hooks/useAdminFloorPlan';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_CALENDAR_INACTIVE_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_INACTIVE_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from '../../paths';
import type { ListingWithServiceWindows } from './apiHelpers';
import { useListings } from './useListings';
import { getListingsForWeekByDate } from './utils/listingUtils';

export interface ListingsContextState {
  clearSelectedListing: () => void;
  floorPlan: FloorPlanData;
  isLoading: boolean;
  listings: ListingWithServiceWindows[];
  refreshFloorPlan: () => void;
  refreshListings: () => void;
  selectedDate: string;
  selectedFloorPlanTableListingIds: string[];
  selectedListing: ListingWithServiceWindows | undefined;
  setSelectedDate: (date: string) => void;
  setSelectedFloorPlanTableListingIds: Dispatch<SetStateAction<string[]>>;
  setSelectedListingId: (listingId: string) => void;
  selectedCalendarCellListingIds: string[];
  setSelectedCalendarCellListingIds: Dispatch<SetStateAction<string[]>>;
}

export const ListingsContext = createContext<ListingsContextState | null>(null);
ListingsContext.displayName = 'ListingsContext';

export const useListingsContext = (): ListingsContextState =>
  useDefinedContext(ListingsContext);

export const ListingsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // library hooks
  const { pathname } = useLocation();

  // internal hooks
  const { timezone } = useRestaurant();
  const {
    isLoading: isListingsLoading,
    listings: fetchedListings,
    refreshListings,
  } = useListings(pathname.includes('draft'));

  const activeListingsOnly = !pathname.includes('draft');
  const {
    isLoading: isFloorPlanLoading,
    floorPlan,
    fetchFloorPlan,
  } = useAdminFloorPlan(activeListingsOnly);

  // local state
  const [selectedDate, setSelectedDate] = useState(() =>
    todayInTimezone(timezone),
  );
  const [
    selectedFloorPlanTableListingIds,
    setSelectedFloorPlanTableListingIds,
  ] = useState<string[]>([]);
  const [selectedCalendarCellListingIds, setSelectedCalendarCellListingIds] =
    useState<string[]>([]);
  const [selectedListingId, setSelectedListingId] = useState<string>();

  const getInactiveListings = () => {
    const today = todayInTimezone(timezone);

    return fetchedListings.filter((l) =>
      l.serviceWindows.some((s) => s.endDate != null && s.endDate < today),
    );
  };

  const getActiveListings = () => {
    const today = todayInTimezone(timezone);

    return fetchedListings.filter((l) =>
      l.serviceWindows.some((s) => s.endDate == null || s.endDate >= today),
    );
  };
  // derived state
  const listings = useMemo(() => {
    switch (pathname) {
      case OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH:
      case OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH:
        return fetchedListings;
      case OPERATIONS_LISTINGS_FLOOR_PLAN_INACTIVE_PATH:
        return getInactiveListings();
      case OPERATIONS_LISTINGS_CALENDAR_INACTIVE_PATH:
        return getListingsForWeekByDate(getInactiveListings(), selectedDate);
      case OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH:
        return getActiveListings();
      case OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH:
        return getListingsForWeekByDate(getActiveListings(), selectedDate);
      default:
        return [];
    }
  }, [fetchedListings, pathname, selectedDate]);
  const selectedListing = listings.find(
    (listing) => listing.id === selectedListingId,
  );

  // handlers
  const clearSelectedListing = () => setSelectedListingId(undefined);
  const handleSetSelectedDate = (date: string) => {
    setSelectedFloorPlanTableListingIds([]);
    setSelectedDate(date);
  };

  const value = useMemo(
    (): ListingsContextState => ({
      clearSelectedListing,
      floorPlan: floorPlan as FloorPlanData,
      isLoading: isListingsLoading || isFloorPlanLoading,
      listings,
      refreshFloorPlan: fetchFloorPlan,
      refreshListings,
      selectedDate,
      selectedFloorPlanTableListingIds,
      selectedListing,
      setSelectedDate: handleSetSelectedDate,
      setSelectedFloorPlanTableListingIds,
      setSelectedListingId,
      selectedCalendarCellListingIds,
      setSelectedCalendarCellListingIds,
    }),
    [
      floorPlan,
      isFloorPlanLoading,
      isListingsLoading,
      listings,
      selectedDate,
      selectedFloorPlanTableListingIds,
      selectedListing,
      selectedCalendarCellListingIds,
      setSelectedCalendarCellListingIds,
    ],
  );

  return (
    <ListingsContext.Provider value={value}>
      {children}
    </ListingsContext.Provider>
  );
};
