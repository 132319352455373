import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { IconButton } from '@components/iconButton/IconButton';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '~styles/typography.scss';
import {
  SidePanelSheetMode,
  useReservationServiceContext,
} from '../../state/ReservationServiceContext';
import { ConfirmDeleteServerModal } from './ConfirmDeleteServerModal';
import { ControlledColorPickerRadio } from './ControlledColorPickerRadio';
import styles from './ServerForm.scss';
import {
  SERVER_BADGE_COLORS,
  type ServerFormData,
  useServerFormContext,
} from './ServerFormContext';

export const ServerFormSheet = () => {
  const {
    isOpen: isConfirmDeleteServerModalOpen,
    open: openConfirmDeleteServerModal,
    close: closeConfirmDeleteServerModal,
  } = useIsOpen();
  const { setSidePanelSheet } = useReservationServiceContext();
  const {
    form: { control, handleSubmit, reset },
    handleCreateServer,
    handleUpdateServer,
    selectedServer,
    setSelectedServer,
  } = useServerFormContext();

  const closeAndResetForm = () => {
    setSelectedServer(null);
    setSidePanelSheet({ mode: SidePanelSheetMode.None });
    reset();
  };

  const isEditMode = !!selectedServer;

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <h2 className={typography.h4}>
          {isEditMode ? 'Edit Server' : 'Create Server'}
        </h2>
        <IconButton
          ariaLabel="close form"
          onClick={closeAndResetForm}
          iconName="close"
          className={styles.closeButton}
        />
      </div>
      <form className={styles.form}>
        <ControlledFormInput
          control={control}
          rules={{
            required: true,
            minLength: {
              message: 'Server Name must be at least 2 characters',
              value: 2,
            },
            maxLength: {
              message: 'Server Name must be 50 characters or less',
              value: 50,
            },
          }}
          label="Server Name"
          name="name"
          type="text"
          variant={LayoutVariant.Horizontal}
        />
        <ControlledColorPickerRadio
          colors={SERVER_BADGE_COLORS}
          control={control}
        />
        <div className={styles.actions}>
          {isEditMode ? (
            <Button
              label="Save Edits"
              onClick={handleSubmit(async (data: ServerFormData) => {
                await handleUpdateServer(data, closeAndResetForm);
              })}
              variant={ButtonVariants.Primary}
            />
          ) : (
            <Button
              label="Create Server"
              onClick={handleSubmit(async (data: ServerFormData) => {
                await handleCreateServer(data, closeAndResetForm);
              })}
              variant={ButtonVariants.Primary}
            />
          )}
          {selectedServer && (
            <>
              <Button
                label="Delete Server"
                onClick={openConfirmDeleteServerModal}
                variant={ButtonVariants.Error}
              />
              <ConfirmDeleteServerModal
                closeConfirmDeleteServerModal={closeConfirmDeleteServerModal}
                isConfirmDeleteServerModalOpen={isConfirmDeleteServerModalOpen}
              />
            </>
          )}
          <Button
            label="Cancel"
            onClick={closeAndResetForm}
            variant={ButtonVariants.Tertiary}
          />
        </div>
      </form>
    </Card>
  );
};
