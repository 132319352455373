import cx from 'classnames';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import CloseIcon from '@assets/icons/close.svg';
import { Card } from '@components/card/Card';
import { type OccupantPositionValues } from 'restaurantAdmin/reservations/service/state/ReservationServiceContext';
import typography from '~styles/typography.scss';
import styles from './OccupantSheetWrapper.scss';

export interface OccupantSheetProps {
  children: React.ReactNode;
  occupantPositionValues: OccupantPositionValues;
  onClickNextOccupant: () => void;
  onClickPreviousOccupant: () => void;
  onClose: () => void;
}

export const OccupantSheetWrapper = ({
  children,
  occupantPositionValues: { count: occupantsCount, position: occupantPosition },
  onClickNextOccupant,
  onClickPreviousOccupant,
  onClose,
}: OccupantSheetProps) => (
  <Card bordered aria-label="Occupant Sheet" className={styles.container}>
    <div className={styles.toolBar}>
      <div>
        <button
          aria-label="previous occupant"
          disabled={occupantPosition === 1}
          onClick={onClickPreviousOccupant}
        >
          <ArrowLeft className={styles.icon} />
        </button>
        <span className={cx(styles.occupantPosition, typography.h7)}>
          {`Occupant ${occupantPosition} of ${occupantsCount}`}
        </span>
        <button
          aria-label="next occupant"
          disabled={occupantPosition === occupantsCount}
          onClick={onClickNextOccupant}
        >
          <ArrowLeft className={cx(styles.icon, styles.arrowRightIcon)} />
        </button>
      </div>
      <button aria-label="close sheet" onClick={onClose}>
        <CloseIcon className={styles.icon} />
      </button>
    </div>
    {children}
  </Card>
);
