import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { collectCancellationFee } from 'restaurantAdmin/reservations/apiHelpers';
import { useOccupantContext } from '../state/OccupantContext';

export interface CollectFeeConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const CollectFeeConfirmationModal = ({
  isOpen,
  closeModal,
}: CollectFeeConfirmationModalProps) => {
  const { selectedOccupant, refreshOccupants } = useOccupantContext();
  const restaurant = useRestaurant();
  const [isCollectingFeePending, setIsCollectingFeePending] = useState(false);

  if (!selectedOccupant) return null;

  const handleConfirm = () => {
    void (async () => {
      setIsCollectingFeePending(true);
      try {
        await collectCancellationFee(restaurant.id, selectedOccupant.id);

        successToast({
          message:
            "Success: The guest's reservation cancellation fee has been collected.",
        });
      } catch (error) {
        if (error instanceof Error) {
          errorToast({
            message: `Failed to collect cancellation fee: ${error.message}`,
          });
        } else {
          errorToast({
            message: 'Failed to collect cancellation fee. Please try again.',
          });
        }
        reportAppError(error);
      }
      closeModal();
      refreshOccupants();
    })();
  };

  return (
    <Modal
      ariaLabel="Confirm Fee Collection"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you would like to collect a cancellation fee for this
        reservation?"
    >
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Confirm"
          onClick={handleConfirm}
          isDisabled={isCollectingFeePending}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
