import cx from 'classnames';
import { useState } from 'react';
import { Icon } from '@components/icon/Icon';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '~styles/typography.scss';
import type { OccupantsOccupant } from '../apiHelpers';
import { isOccupantFinished } from '../sidePanel/occupantUtils';
import styles from './GuestMetrics.scss';
import { GuestMetricsPopover } from './GuestMetricsPopover';

interface GuestMetricsProps {
  occupants: OccupantsOccupant[];
}

const isSeated = (occupant: OccupantsOccupant) => {
  if (occupant.type === 'reservation') {
    return !!occupant.seatedTableName;
  }
  return !!occupant.seatedTableId;
};

export type GuestMetricsFilter =
  | 'finished'
  | 'noShow'
  | 'seated'
  | 'total'
  | 'upcoming';

export const GuestMetrics = ({ occupants }: GuestMetricsProps) => {
  const {
    isOpen: isPopOverOpen,
    close: closePopOver,
    toggle: togglePopOver,
  } = useIsOpen();
  const [currentFilter, setCurrentFilter] =
    useState<GuestMetricsFilter>('total');

  const setFilterAndClosePopover = (filter: GuestMetricsFilter) => {
    setCurrentFilter(filter);
    closePopOver();
  };

  const { ref } = useHandleClickOutside(closePopOver);

  const metrics: Record<GuestMetricsFilter, number> = {
    finished: 0,
    noShow: 0,
    seated: 0,
    total: 0,
    upcoming: 0,
  };

  occupants.forEach((occupant) => {
    metrics.total += occupant.guestCount;

    if (isOccupantFinished(occupant.status)) {
      metrics.finished += occupant.guestCount;
    } else if (occupant.status === 'noShow') {
      metrics.noShow += occupant.guestCount;
    } else if (isSeated(occupant)) {
      metrics.seated = occupant.guestCount;
    } else {
      metrics.upcoming += occupant.guestCount;
    }
  });

  const totalCopy = currentFilter === 'total' ? '' : `/ ${metrics.total}`;

  const guestCount = metrics.total ? (
    metrics[currentFilter]
  ) : (
    <code>&#8212;</code>
  );

  const filterCopy: Record<GuestMetricsFilter, string> = {
    finished: `Finished`,
    noShow: `No Show`,
    seated: 'Seated',
    total: 'Total',
    upcoming: `Upcoming`,
  };

  return (
    <div className={styles.container} ref={ref}>
      <button aria-label="guest metrics" onClick={togglePopOver}>
        <div className={styles.heading}>
          <h2 className={typography.h8}>Guests</h2>
          <Icon name={isPopOverOpen ? 'minus' : 'plus'} />
        </div>
        <p className={cx(styles.count)}>
          {guestCount}
          <span>&nbsp;{totalCopy}</span>{' '}
          <span>&nbsp;{filterCopy[currentFilter]}</span>
        </p>
      </button>
      {isPopOverOpen && (
        <GuestMetricsPopover
          {...metrics}
          setFilterAndClosePopover={setFilterAndClosePopover}
        />
      )}
    </div>
  );
};
