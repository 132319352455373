// this gets transformed into more specific imports by @babel/preset-env
import 'core-js/stable';

import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { initGoogleAnalyticsAdmin } from '@utils/googleAnalytics';
import initSentry from '@utils/sentry';
import { router } from './router';

initSentry();
initGoogleAnalyticsAdmin();

const appRoot = document.getElementById('app');

if (!appRoot) {
  throw new Error('Root element not found');
}

createRoot(appRoot).render(<RouterProvider router={router} />);
