// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServersList__container___MQ7e6{display:flex;flex-direction:column;gap:.5rem}.ServersList__serverGroupHeading___KgJEQ{align-items:center;background-color:var(--hairline);border-radius:12px;display:flex;padding:.75rem .5rem}.ServersList__serverGroupHeading___KgJEQ h4{flex:1}.ServersList__serversList___kRodo{display:flex;flex-direction:column;gap:.5rem}.ServersList__serverListItem___V1XDB{background-color:var(--darkBlue);border-radius:12px;display:flex;align-items:center;gap:.5rem;padding:.75rem .5rem;cursor:pointer}.ServersList__serverListItem___V1XDB:hover{background-color:var(--iconBlue)}.ServersList__serverListItem___V1XDB svg{height:24px;width:24px;stroke:var(--white);transform:rotate(180deg)}.ServersList__serverName___gc9H8{flex:1}.ServersList__covers___pmUo7{text-align:center;width:2.8rem}.ServersList__unassignAllServersButton___IXLRK{border-width:1px}.ServersList__newServerButton___Kp9gM{align-items:center;background-color:unset;border:1px dashed var(--white);border-radius:6px;color:var(--white70);cursor:pointer;display:flex;font-weight:700;justify-content:space-between;padding:1rem 1.5rem}.ServersList__newServerButton___Kp9gM svg{width:1.5rem;stroke:var(--white)}.ServersList__newServerButton___Kp9gM:hover{border-color:var(--darkGrey100)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/servers/ServersList.scss"],"names":[],"mappings":"AAAA,gCACE,YAAA,CACA,qBAAA,CACA,SAAA,CAGF,yCACE,kBAAA,CACA,gCAAA,CACA,kBAAA,CACA,YAAA,CACA,oBAAA,CAEA,4CACE,MAAA,CAIJ,kCACE,YAAA,CACA,qBAAA,CACA,SAAA,CAGF,qCACE,gCAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA,CACA,oBAAA,CACA,cAAA,CAEA,2CACE,gCAAA,CAGF,yCACE,WAAA,CACA,UAAA,CACA,mBAAA,CACA,wBAAA,CAIJ,iCACE,MAAA,CAGF,6BACE,iBAAA,CACA,YAAA,CAGF,+CACE,gBAAA,CAGF,sCACE,kBAAA,CACA,sBAAA,CACA,8BAAA,CACA,iBAAA,CACA,oBAAA,CACA,cAAA,CACA,YAAA,CACA,eAAA,CACA,6BAAA,CACA,mBAAA,CAEA,0CACE,YAAA,CACA,mBAAA,CAGF,4CACE,+BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ServersList__container___MQ7e6",
	"serverGroupHeading": "ServersList__serverGroupHeading___KgJEQ",
	"serversList": "ServersList__serversList___kRodo",
	"serverListItem": "ServersList__serverListItem___V1XDB",
	"serverName": "ServersList__serverName___gc9H8",
	"covers": "ServersList__covers___pmUo7",
	"unassignAllServersButton": "ServersList__unassignAllServersButton___IXLRK",
	"newServerButton": "ServersList__newServerButton___Kp9gM"
};
export default ___CSS_LOADER_EXPORT___;
