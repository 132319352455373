// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuItem__menuItem___xktzz{background:rgba(0,0,0,0);color:var(--white);cursor:pointer;padding:.875rem 1.5rem;user-select:none;white-space:nowrap}.MenuItem__menuItem___xktzz[aria-disabled=true]{color:var(--darkGrey200);cursor:not-allowed}.MenuItem__menuItem___xktzz:active,.MenuItem__menuItem___xktzz:focus,.MenuItem__menuItem___xktzz:hover{background:var(--primary400);outline:none}.MenuItem__menuItem___xktzz.MenuItem__pink___Vj0R3{color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/shared/components/menu/MenuItem.scss"],"names":[],"mappings":"AAAA,4BACE,wBAAA,CACA,kBAAA,CACA,cAAA,CACA,sBAAA,CACA,gBAAA,CACA,kBAAA,CAEA,gDACE,wBAAA,CACA,kBAAA,CAGF,uGAGE,4BAAA,CACA,YAAA,CAGF,mDACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": "MenuItem__menuItem___xktzz",
	"pink": "MenuItem__pink___Vj0R3"
};
export default ___CSS_LOADER_EXPORT___;
