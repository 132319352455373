import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import {
  ControlledFormSelect,
  type FormSelectOption,
} from '@components/formInputs/ControlledFormSelect';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { type ReservationStatus } from 'restaurantAdmin/reservations/apiHelpers';
import {
  type EligibleCancellationFeeResponse,
  eligibleForCancellationFee,
} from '../cancelButton/apiHelpers';
import { CancelAndCollectFeeConfirmationModal } from './CancelAndCollectFeeConfirmationModal';
import { CancelAndWaiveFeeConfirmationModal } from './CancelAndWaiveFeeConfirmationModal';
import { CancelConfirmationModal } from './CancelConfirmationModal';
import styles from './RemediationForm.scss';

interface RemediationFormData {
  action: 'Cancel' | 'Cancel & Collect Fee' | 'Cancel & Waive Fee';
}

export interface RemediationFormProps {
  disableConfirmationModalPortal?: boolean;
  reservationId: string;
  reservationStatus: ReservationStatus;
}

const CANCEL = {
  label: 'Cancel',
  value: 'Cancel',
};
const CANCEL_AND_COLLECT_FEE = {
  label: 'Cancel & Collect Fee',
  value: 'Cancel & Collect Fee',
};
const CANCEL_AND_WAIVE_FEE = {
  label: 'Cancel & Waive Fee',
  value: 'Cancel & Waive Fee',
};

export const RemediationForm = ({
  disableConfirmationModalPortal,
  reservationId,
  reservationStatus,
}: RemediationFormProps) => {
  const restaurant = useRestaurant();

  const [cancelFeeData, setCancelFeeData] =
    useState<EligibleCancellationFeeResponse>({
      isEligibleForCancellationFeeUponCancellation: false,
      eligibleForCancellationFeeUntil: '',
      cancellationPolicy: null,
    });
  const {
    isOpen: isCancelConfirmationModalOpen,
    open: openCancelConfirmationModal,
    close: closeCancelConfirmationModal,
  } = useIsOpen();
  const {
    isOpen: isCancelAndCollectFeeConfirmationModalOpen,
    open: openCancelAndCollectFeeConfirmationModal,
    close: closeCancelAndCollectFeeConfirmationModal,
  } = useIsOpen();
  const {
    isOpen: isCancelAndWaiveFeeConfirmationModalOpen,
    open: openCancelAndWaiveFeeConfirmationModal,
    close: closeCancelAndWaiveFeeConfirmationModal,
  } = useIsOpen();

  const { control, handleSubmit } = useForm<RemediationFormData>({});
  const selectedAction = useWatch({
    control,
    name: 'action',
  });

  const getCancelFeeOptions = async () => {
    try {
      const response = await eligibleForCancellationFee(
        restaurant.id,
        reservationId,
      );
      setCancelFeeData(response);
    } catch (error) {
      reportAppError(error);
    }
  };

  useEffect(() => {
    void getCancelFeeOptions();
  }, [reservationId]);

  if (reservationStatus !== 'active') return null;

  const remediationOptions: FormSelectOption[] = [CANCEL];
  if (cancelFeeData.isEligibleForCancellationFeeUponCancellation) {
    remediationOptions.push(CANCEL_AND_COLLECT_FEE, CANCEL_AND_WAIVE_FEE);
  }

  const isSubmitButtonDisabled = !selectedAction;
  const handleRemediation = (data: RemediationFormData) => {
    if (data.action === 'Cancel') {
      openCancelConfirmationModal();
    }

    if (data.action === 'Cancel & Collect Fee') {
      openCancelAndCollectFeeConfirmationModal();
    }

    if (data.action === 'Cancel & Waive Fee') {
      openCancelAndWaiveFeeConfirmationModal();
    }
  };

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(handleRemediation)}>
        <ControlledFormSelect
          control={control}
          label="Remediation"
          name="action"
          options={remediationOptions}
          placeHolder="Select"
          rules={{
            required: true,
          }}
        />
        <Button
          isDisabled={isSubmitButtonDisabled}
          label="Execute Remediation"
          type="submit"
          variant={ButtonVariants.Secondary}
        />
      </form>
      <CancelConfirmationModal
        cancellationPolicy={cancelFeeData.cancellationPolicy}
        closeModal={closeCancelConfirmationModal}
        disablePortal={disableConfirmationModalPortal}
        eligibleForCancellationFeeUntil={
          cancelFeeData.eligibleForCancellationFeeUntil
        }
        isEligibleForCancellationFeeUponCancellation={
          cancelFeeData.isEligibleForCancellationFeeUponCancellation
        }
        isOpen={isCancelConfirmationModalOpen}
        reservationId={reservationId}
      />
      <CancelAndCollectFeeConfirmationModal
        cancellationPolicy={cancelFeeData.cancellationPolicy!}
        closeModal={closeCancelAndCollectFeeConfirmationModal}
        disablePortal={disableConfirmationModalPortal}
        isOpen={isCancelAndCollectFeeConfirmationModalOpen}
        reservationId={reservationId}
      />
      <CancelAndWaiveFeeConfirmationModal
        cancellationPolicy={cancelFeeData.cancellationPolicy!}
        closeModal={closeCancelAndWaiveFeeConfirmationModal}
        disablePortal={disableConfirmationModalPortal}
        isOpen={isCancelAndWaiveFeeConfirmationModalOpen}
        reservationId={reservationId}
      />
    </>
  );
};
