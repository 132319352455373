import { Input } from '@mui/base';
import { Button } from '@mui/base/Button';
import { Popper } from '@mui/base/Popper';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import React, { forwardRef, type HTMLAttributes } from 'react';
import typography from '~styles/typography.scss';
import { type GuestsApiSearch } from './apiHelpers';
import styles from './GuestAutocomplete.scss';
import { useGuestSearch } from './hooks';

// This type is a workaround for getOptionProps not being typed correctly
type GetOptionsType = HTMLAttributes<HTMLLIElement> & {
  key: string;
};

const valueOrNone = (value: string | null) => value || 'None';

interface GuestAutocompleteProps {
  onSelect: (value: GuestsApiSearch) => void;
  onClear: () => void;
}

export const GuestAutocomplete = forwardRef(
  (props: GuestAutocompleteProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { guests, searchGuests, loading } = useGuestSearch();
    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      popupOpen,
      anchorEl,
      setAnchorEl,
      groupedOptions,
      getClearProps,
    } = useAutocomplete<GuestsApiSearch, false, false, false>({
      isOptionEqualToValue: (option, value) => option.id === value.id,
      getOptionLabel: (option) =>
        typeof option !== 'string'
          ? `${option.firstName} ${option.lastName}`
          : option,
      getOptionKey: (option) =>
        typeof option !== 'string' ? option.id : '_new',
      options: guests,
      filterOptions: (options) => options,
      onInputChange: (_event, value) => {
        void searchGuests(value);
      },
      onChange: (_event, value) => {
        if (value) {
          props.onSelect(value);
        }
      },
    });

    const rootRef = useForkRef(ref, setAnchorEl);
    const inputProps = getInputProps();

    const renderOption = (options: GetOptionsType, option: GuestsApiSearch) => {
      const { key, ...rest } = options;
      return (
        <li
          key={key}
          {...rest}
          aria-labelledby={`guest-autocomplete-option-${option.id}`}
        >
          <div
            className={typography.c2}
            id={`guest-autocomplete-option-${option.id}`}
          >
            {option.firstName} {option.lastName}
          </div>
          {option.phone && <div>{option.phone}</div>}
          <div className={typography.c3}>
            Tags: {valueOrNone(option.tags.join(', '))}
          </div>
          <div className={typography.c3}>Notes: {valueOrNone(option.note)}</div>
        </li>
      );
    };

    const renderOptions = () => (
      <ul {...getListboxProps()} data-testid="guest-autocomplete-results">
        {(groupedOptions as GuestsApiSearch[]).map((option, index) => {
          const options = getOptionProps({
            option,
            index,
          }) as GetOptionsType;
          return renderOption(options, option);
        })}
      </ul>
    );

    const renderNoResults = () => (
      <div className={styles.noResults}>No results found</div>
    );

    const renderLoading = () => (
      <div className={styles.noResults}>Loading...</div>
    );

    const { onClick: onClearClick, ...clearProps } = getClearProps();

    return (
      <div className={styles.fieldContainer}>
        <div className={styles.labelContainer}>
          <label className={typography.c2_20} htmlFor={inputProps.id}>
            Search
          </label>
        </div>
        <Input
          slotProps={{
            root: {
              ...getRootProps(),
              ref: rootRef,
              className: styles.inputContainer,
            },
            input: {
              ...inputProps,
              placeholder: 'Type at least 3 characters',
            },
          }}
          endAdornment={
            <Button
              {...clearProps}
              onClick={(e) => {
                if (onClearClick) onClearClick(e);
                props.onClear();
              }}
            >
              Clear
            </Button>
          }
        />
        {anchorEl && (
          <Popper
            open={popupOpen}
            anchorEl={anchorEl}
            disablePortal
            className={styles.popperContainer}
            style={{
              width: anchorEl.clientWidth,
            }}
          >
            <div className={styles.popper}>
              {loading && renderLoading()}
              {!loading && groupedOptions.length > 0 && renderOptions()}
              {!loading && groupedOptions.length === 0 && renderNoResults()}
            </div>
          </Popper>
        )}
      </div>
    );
  },
);
GuestAutocomplete.displayName = 'GuestAutocomplete';
