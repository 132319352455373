// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OccupantsPrintDropdown__printButton___ZHBOI{align-items:center;background:none;border:none;display:flex;height:3rem;justify-content:center;width:3rem}.OccupantsPrintDropdown__printButton___ZHBOI svg{stroke:var(--white);min-width:1.75rem;width:1.75rem}.OccupantsPrintDropdown__printButton___ZHBOI:active,.OccupantsPrintDropdown__printButton___ZHBOI:hover{background-color:var(--gold);border-radius:50%;outline:none}.OccupantsPrintDropdown__printButton___ZHBOI:active svg,.OccupantsPrintDropdown__printButton___ZHBOI:hover svg{stroke:var(--black)}.OccupantsPrintDropdown__printButton___ZHBOI:focus{border-radius:50%;outline:none;border:2px solid var(--gold)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/pageHeader/OccupantsPrintDropdown.scss"],"names":[],"mappings":"AAAA,6CACE,kBAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,UAAA,CAEA,iDACE,mBAAA,CACA,iBAAA,CACA,aAAA,CAGF,uGAEE,4BAAA,CACA,iBAAA,CACA,YAAA,CAEA,+GACE,mBAAA,CAIJ,mDACE,iBAAA,CACA,YAAA,CACA,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"printButton": "OccupantsPrintDropdown__printButton___ZHBOI"
};
export default ___CSS_LOADER_EXPORT___;
