// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormInputTooltip__iconButton___eHgUU{cursor:pointer;border:none;border-radius:50%;padding:4px;align-items:center;background-color:rgba(0,0,0,0);display:inline-flex;justify-content:center}.FormInputTooltip__iconButton___eHgUU .FormInputTooltip__infoIcon___aFxtb{height:20px;min-width:20px;stroke:var(--gold);width:20px}.FormInputTooltip__iconButton___eHgUU:active,.FormInputTooltip__iconButton___eHgUU:focus,.FormInputTooltip__iconButton___eHgUU:hover{background-color:var(--accent300)}.FormInputTooltip__iconButton___eHgUU:active .FormInputTooltip__infoIcon___aFxtb,.FormInputTooltip__iconButton___eHgUU:focus .FormInputTooltip__infoIcon___aFxtb,.FormInputTooltip__iconButton___eHgUU:hover .FormInputTooltip__infoIcon___aFxtb{stroke:var(--darkBlue)}", "",{"version":3,"sources":["webpack://./src/shared/components/formInputTooltip/FormInputTooltip.scss"],"names":[],"mappings":"AAAA,sCACE,cAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,kBAAA,CACA,8BAAA,CACA,mBAAA,CACA,sBAAA,CAEA,0EACE,WAAA,CACA,cAAA,CACA,kBAAA,CACA,UAAA,CAGF,qIAGE,iCAAA,CAEA,iPACE,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "FormInputTooltip__iconButton___eHgUU",
	"infoIcon": "FormInputTooltip__infoIcon___aFxtb"
};
export default ___CSS_LOADER_EXPORT___;
