import { type GuestMetricsFilter } from './GuestMetrics';
import styles from './GuestMetricsPopover.scss';

export interface GuestMetricsPopoverProps {
  finished: number;
  noShow: number;
  seated: number;
  setFilterAndClosePopover: (filter: GuestMetricsFilter) => void;
  total: number;
  upcoming: number;
}

export const GuestMetricsPopover = ({
  finished,
  noShow,
  seated,
  setFilterAndClosePopover,
  total,
  upcoming,
}: GuestMetricsPopoverProps) => {
  const EM_DASH = <code>&#8212;&nbsp;</code>;

  const seatedPercentage = total ? Math.floor((seated / total) * 100) : EM_DASH;
  const finishedPercentage = total
    ? Math.floor((finished / total) * 100)
    : EM_DASH;
  const upcomingPercentage = total
    ? Math.floor((upcoming / total) * 100)
    : EM_DASH;
  const noShowPercentage = total ? Math.floor((noShow / total) * 100) : EM_DASH;

  return (
    <div className={styles.container} data-testid="guest-metrics-popover">
      <button
        onClick={() => {
          setFilterAndClosePopover('total');
        }}
      >
        <div>
          <strong>Guests</strong>
        </div>
        <div>All Day</div>
        <div>
          <strong>{total}</strong>
        </div>
      </button>

      <button
        onClick={() => {
          setFilterAndClosePopover('seated');
        }}
      >
        <div>Seated</div>
        <div>{seatedPercentage}%</div>
        <div>{seated}</div>
      </button>

      <button
        onClick={() => {
          setFilterAndClosePopover('finished');
        }}
      >
        <div>Finished</div>
        <div>{finishedPercentage}%</div>
        <div>{finished}</div>
      </button>

      <button
        onClick={() => {
          setFilterAndClosePopover('upcoming');
        }}
      >
        <div>Upcoming</div>
        <div>{upcomingPercentage}%</div>
        <div>{upcoming}</div>
      </button>

      <button
        onClick={() => {
          setFilterAndClosePopover('noShow');
        }}
      >
        <div>No Show</div>
        <div>{noShowPercentage}%</div>
        <div>{noShow}</div>
      </button>
    </div>
  );
};
