import cx from 'classnames';
import type { JSX } from 'react';
import type {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
} from 'react-hook-form';
import { useController } from 'react-hook-form';
import typography from '~styles/typography.scss';
import styles from './ControlledFormCheckbox.scss';
import { FormTooltip } from './FormTooltip';
import { ValidationErrorMessage } from './ValidationErrorMessage';

export interface ControlledFormCheckboxProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  children?: React.ReactNode;
  className?: string;
  control: Control<T>;
  defaultValue?: PathValue<T, Name>;
  disabled?: boolean;
  label: string | JSX.Element;
  name: Name;
  rules?: UseControllerProps<T, Name>['rules'];
  tooltipText?: string;
}

export const ControlledFormCheckbox = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  children,
  className,
  control,
  defaultValue,
  disabled,
  label,
  name,
  rules = {},
  tooltipText,
}: ControlledFormCheckboxProps<T, Name>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
  });

  const sanitizedValue = (rawValue?: string | boolean): boolean | undefined => {
    if (rawValue === undefined) return defaultValue;
    if (rawValue === 'false') return false;
    return !!rawValue;
  };

  return (
    <div className={cx(styles.container, className)}>
      <div>
        <label className={typography.c3} htmlFor={name}>
          <span
            className={cx({
              [typography.c2_20]: true,
              [styles.labelError]: !!error,
            })}
          >
            {label}
          </span>
          <input
            checked={sanitizedValue(value)}
            disabled={disabled}
            id={name}
            onChange={onChange}
            type="checkbox"
          />
          {children}
        </label>
        <FormTooltip label={name} text={tooltipText} />
      </div>
      {typeof label === 'string' && (
        <ValidationErrorMessage error={error} label={label} name={name} />
      )}
    </div>
  );
};
