import { useEffect, useMemo, useState } from 'react';

export type GetPage<T> = (limit: number, offset: number) => Promise<T[]>;

export interface UseLoadInfiniteConfig<T> {
  defaultPageSize: number;
  firstPageSize: number;
  getPage: GetPage<T>;
}

export interface UseFetchInfiniteReturn<T> {
  hasMore: boolean;
  isLoading: boolean;
  items: T[];
  loadFirstPage: () => void;
  loadNextPage: () => void;
}

export const useLoadInfinite = <T>({
  getPage,
  defaultPageSize,
  firstPageSize,
}: UseLoadInfiniteConfig<T>): UseFetchInfiniteReturn<T> => {
  interface Loaded {
    items: T[];
    hasMore: boolean;
  }
  const [pendingPage, setPendingPage] = useState<{ first: boolean } | null>(
    null,
  );
  const [loaded, setLoaded] = useState<Loaded>({ items: [], hasMore: true });

  const { items, hasMore } = loaded;
  const isLoading = pendingPage != null;

  useEffect(() => {
    if (!pendingPage) return undefined;
    if (!pendingPage.first && !hasMore) {
      setPendingPage(null);
      return undefined;
    }
    const isFirst = pendingPage.first || items.length === 0;
    const [limit, offset] = isFirst
      ? [firstPageSize, 0]
      : [defaultPageSize, items.length];
    let isCurrent = true;
    void (async () => {
      try {
        const pageItems = await getPage(limit, offset);
        if (isCurrent) {
          setLoaded({
            items: isFirst ? pageItems : [...items, ...pageItems],
            hasMore: pageItems.length >= limit,
          });
        }
      } finally {
        if (isCurrent) {
          setPendingPage(null);
        }
      }
    })();
    return () => {
      isCurrent = false;
    };
  }, [pendingPage]);

  const { loadNextPage, loadFirstPage } = useMemo(
    () => ({
      loadNextPage: () => setPendingPage({ first: false }),
      loadFirstPage: () => setPendingPage({ first: true }),
    }),
    [],
  );

  return { hasMore, isLoading, items, loadNextPage, loadFirstPage };
};
