// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestImport__container___x0Cl7{display:flex;flex-direction:column;row-gap:2rem}.GuestImport__container___x0Cl7 form{display:flex;flex-direction:column;align-items:flex-start;row-gap:1rem}.GuestImport__container___x0Cl7 table{width:100%;display:grid;grid-template-columns:auto repeat(5, 1fr);grid-template-rows:1fr;column-gap:1rem;place-items:baseline flex-start}.GuestImport__container___x0Cl7 table thead{display:contents}.GuestImport__container___x0Cl7 table tbody{display:contents}.GuestImport__container___x0Cl7 table tr{display:contents}.GuestImport__container___x0Cl7 table th{margin-bottom:1rem;border-bottom:1px solid var(--darkGrey200);padding-bottom:1rem;width:100%;display:flex;justify-content:flex-start;align-items:flex-start}.GuestImport__container___x0Cl7 table td{display:flex;justify-content:flex-start;align-items:flex-start;width:100%;height:100%;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;border:1px solid rgba(0,0,0,0)}.GuestImport__container___x0Cl7 table td.GuestImport__error___eZ6sn{color:var(--pink);border:1px solid var(--pink)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/support/GuestImport.scss"],"names":[],"mappings":"AAAA,gCACE,YAAA,CACA,qBAAA,CACA,YAAA,CAEA,qCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,YAAA,CAGF,sCACE,UAAA,CACA,YAAA,CACA,yCAAA,CACA,sBAAA,CACA,eAAA,CACA,+BAAA,CAEA,4CACE,gBAAA,CAGF,4CACE,gBAAA,CAGF,yCACE,gBAAA,CAGF,yCACE,kBAAA,CACA,0CAAA,CACA,mBAAA,CACA,UAAA,CACA,YAAA,CACA,0BAAA,CACA,sBAAA,CAGF,yCACE,YAAA,CACA,0BAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,8BAAA,CAEA,oEACE,iBAAA,CACA,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestImport__container___x0Cl7",
	"error": "GuestImport__error___eZ6sn"
};
export default ___CSS_LOADER_EXPORT___;
