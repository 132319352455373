import cx from 'classnames';
import Logo from '@assets/icons/logo.svg';
import { ROOT_PATH } from '../paths';
import styles from './AdminHeader.scss';
import { AvatarDropdown } from './AvatarDropdown';
import layoutStyles from './Layout.scss';
import { PrimaryNavigationTabs } from './PrimaryNavigationTabs';
import { RestaurantSelector } from './RestaurantSelector';

export const AdminHeader = () => (
  <nav
    aria-label="primary"
    className={cx(layoutStyles.pageMargin, styles.header)}
  >
    <a href={ROOT_PATH} aria-label="Peak restaurant admin home">
      <Logo className={styles.logo} />
    </a>
    <RestaurantSelector />
    <PrimaryNavigationTabs />
    <AvatarDropdown />
  </nav>
);
