const MIN_PASSWORD_LENGTH = 8;

export const isMinLength = (text: string): boolean =>
  text.length >= MIN_PASSWORD_LENGTH;

export const hasNumber = (text: string): boolean => /\d/.test(text);

export const hasSpecialCharacter = (text: string): boolean =>
  /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/g.test(text);

export const isValid = (password: string): boolean =>
  hasNumber(password) && isMinLength(password) && hasSpecialCharacter(password);
