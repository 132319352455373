import {
  MenuItem as MuiMenuItem,
  type MenuItemProps as MuiMenuItemProps,
} from '@mui/base/MenuItem';
import cx from 'classnames';
import styles from './MenuItem.scss';

export interface MenuItemProps
  extends Pick<MuiMenuItemProps, 'children' | 'disabled' | 'onClick'> {
  variant?: MenuItemVariant;
}

export enum MenuItemVariant {
  Pink = 'pink',
}

export const MenuItem = ({ variant, ...props }: MenuItemProps) => (
  <MuiMenuItem
    className={cx(styles.menuItem, variant && variantStyles[variant])}
    {...props}
  />
);

const variantStyles: Record<MenuItemVariant, string> = {
  pink: styles.pink,
};
