import { useMemo } from 'react';
import {
  type Control,
  type FieldPath,
  type FieldValues,
  type PathValue,
  type UseControllerProps,
} from 'react-hook-form';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { ControlledFormSelect } from './ControlledFormSelect';
import { filterTimeOptions } from './filterTimeOptions';

export interface ControlledFormTimeSelectProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  defaultValue?: PathValue<T, Name>;
  disabled?: boolean;
  label: string;
  maxTime?: string;
  minTime?: string;
  name: Name;
  onFocus?: () => void;
  placeHolder?: string;
  rules?: UseControllerProps<T, Name>['rules'];
  tooltipText?: string;
  variant?: LayoutVariant;
}

export const ControlledFormTimeSelect = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  label,
  maxTime = '',
  minTime = '',
  name,
  tooltipText,
  rules,
  disabled = false,
  placeHolder = 'Select...',
  defaultValue,
  variant = LayoutVariant.Vertical,
  onFocus,
}: ControlledFormTimeSelectProps<T, Name>) => {
  const options = useMemo(
    () => filterTimeOptions(minTime, maxTime),
    [minTime, maxTime],
  );

  return (
    <ControlledFormSelect
      className={className}
      control={control}
      defaultValue={defaultValue}
      disabled={disabled}
      label={label}
      name={name}
      onFocus={onFocus}
      options={options}
      placeHolder={placeHolder}
      rules={rules}
      tooltipText={tooltipText}
      variant={variant}
    />
  );
};
