// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InviteExpiredPage__pageContainer___jNPBa{background-color:var(--primary400);height:100vh;padding-top:7rem;width:100%}.InviteExpiredPage__card___Ew5LZ{align-items:center;background-color:var(--darkBlue);border-radius:.5rem;display:flex;flex-direction:column;gap:1.5rem;margin:0 auto;padding:3rem;width:560px}.InviteExpiredPage__card___Ew5LZ .InviteExpiredPage__header___QkolF{color:var(--white)}.InviteExpiredPage__card___Ew5LZ span{color:var(--white70);text-align:center}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/auth/InviteExpiredPage.scss"],"names":[],"mappings":"AAAA,0CACE,kCAAA,CACA,YAAA,CACA,gBAAA,CACA,UAAA,CAGF,iCACE,kBAAA,CACA,gCAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA,CACA,WAAA,CAEA,oEACE,kBAAA,CAGF,sCACE,oBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "InviteExpiredPage__pageContainer___jNPBa",
	"card": "InviteExpiredPage__card___Ew5LZ",
	"header": "InviteExpiredPage__header___QkolF"
};
export default ___CSS_LOADER_EXPORT___;
