import cx from 'classnames';
import { compact } from 'lodash-es';
import { useState } from 'react';
import { type Control, type UseFormSetValue, useWatch } from 'react-hook-form';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { parseToShortDateRange } from '@utils/dateFormatters';
import { ISOTimeTo12HourTime } from '@utils/time';
import { formatWeekDays } from '@utils/weekDayFormatters';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import { getServiceWindows, type ServiceWindow } from '../apiHelpers';
import type { ListingFormData } from '../types';
import { hasDateOverlap } from '../utils/listingUtils';
import { hasOverlappingDay, timeRangeOverlaps } from '../utils/timeRange';
import { CreateServiceWindowModal } from './CreateServiceWindowModal';
import styles from './ReservableTimeAndDaysFieldSet.scss';

export interface ReservableTimeAndDaysFieldSetProps {
  control: Control<ListingFormData>;
  setValue: UseFormSetValue<ListingFormData>;
}

export const ReservableTimeAndDaysFieldSet = ({
  control,
  setValue,
}: ReservableTimeAndDaysFieldSetProps) => {
  const { id: restaurantId } = useRestaurant();
  const { data: serviceWindows = [], rerun: refreshServiceWindows } =
    useAbortEffect(
      (signal) => getServiceWindows(restaurantId, signal),
      [restaurantId],
    );
  const serviceWindowIds = useWatch({
    control,
    name: 'serviceWindowIds',
  });
  const [overlappingServiceWindowIds, setOverlappingServiceWindowIds] =
    useState<string[]>([]);
  const {
    isOpen: isCreateServiceWindowModalOpen,
    close: closeCreateServiceWindowModal,
    open: openCreateServiceWindowModal,
  } = useIsOpen();

  const validateOverlappingServiceWindows = (
    selectedServiceWindowIds: string[],
  ): void => {
    if (!selectedServiceWindowIds.length) return;

    const selectedServiceWindows = compact(
      selectedServiceWindowIds.map((serviceWindowId) =>
        serviceWindows.find(
          (serviceWindow) => serviceWindow.id === serviceWindowId,
        ),
      ),
    );

    const overlappingServiceWindows = selectedServiceWindows.filter(
      (serviceWindowA) =>
        selectedServiceWindows.some((serviceWindowB) => {
          if (serviceWindowA.id === serviceWindowB.id) return false;

          return (
            hasOverlappingDay(serviceWindowA.repeat, serviceWindowB.repeat) &&
            timeRangeOverlaps(
              {
                startTime: serviceWindowA.startTime,
                endTime: serviceWindowA.endTime,
              },
              {
                startTime: serviceWindowB.startTime,
                endTime: serviceWindowB.endTime,
              },
            ) &&
            hasDateOverlap(serviceWindowA, serviceWindowB)
          );
        }),
    );

    setOverlappingServiceWindowIds(
      overlappingServiceWindows.map((serviceWindow) => serviceWindow.id),
    );
  };

  const options = serviceWindows.map((serviceWindow) => ({
    label: <ServiceWindowOption serviceWindow={serviceWindow} />,
    value: serviceWindow.id,
  }));

  const selectedServiceWindows = compact(
    serviceWindowIds.map((serviceWindowId) =>
      serviceWindows.find(
        (serviceWindow) => serviceWindow.id === serviceWindowId,
      ),
    ),
  );

  const handleOnCreateServiceWindow = (createdServiceWindowIds: string[]) => {
    refreshServiceWindows();
    setValue('serviceWindowIds', [
      ...serviceWindowIds,
      ...createdServiceWindowIds,
    ]);
  };

  const handleRemoveServiceWindow = (removedServiceWindowId: string) => {
    setValue(
      'serviceWindowIds',
      serviceWindowIds.filter((id) => id !== removedServiceWindowId),
    );
  };

  return (
    <fieldset
      className={styles.fieldset}
      data-testid="reservable-time-and-days-fieldset"
    >
      <h3 className={typography.t3}>reservable time & days</h3>
      <ControlledFormSelect
        className={styles.select}
        control={control}
        label="Service Window"
        name="serviceWindowIds"
        options={options}
        multiple
        renderValue={() => 'Select Service Windows'}
        rules={{
          required: true,
        }}
        onSelect={validateOverlappingServiceWindows}
      />
      {!!overlappingServiceWindowIds.length && (
        <div className={styles.overlapMessage}>
          <Icon name="alertCircle" />
          <span className={typography.t1}>Overlapping Service Windows</span>
          <p className={typography.t1}>
            Some service windows in this listing overlap with the other service
            windows.
          </p>
        </div>
      )}
      <ul aria-label="selected service windows">
        {selectedServiceWindows.map((serviceWindow) => (
          <ServiceWindowItem
            key={serviceWindow.id}
            hasOverlap={overlappingServiceWindowIds.includes(serviceWindow.id)}
            serviceWindow={serviceWindow}
            handleRemoveServiceWindow={() =>
              handleRemoveServiceWindow(serviceWindow.id)
            }
          />
        ))}
      </ul>
      <button
        className={styles.createServiceWindowButton}
        type="button"
        onClick={openCreateServiceWindowModal}
      >
        Create Service Window
        <Icon name="plus" />
      </button>
      <CreateServiceWindowModal
        isOpen={isCreateServiceWindowModalOpen}
        closeModal={closeCreateServiceWindowModal}
        handleOnCreateServiceWindow={handleOnCreateServiceWindow}
      />
    </fieldset>
  );
};

interface ServiceWindowItemProps {
  hasOverlap: boolean;
  serviceWindow: ServiceWindow;
  handleRemoveServiceWindow: () => void;
}

const ServiceWindowItem = ({
  hasOverlap,
  serviceWindow: { id, name, startDate, endDate, startTime, endTime, repeat },
  handleRemoveServiceWindow,
}: ServiceWindowItemProps) => {
  const itemClassNames = cx({
    [styles.serviceWindowItem]: true,
    [styles.hasOverlap]: hasOverlap,
  });

  return (
    <li
      className={itemClassNames}
      aria-labelledby={`service-window-${id}-name`}
    >
      <IconButton
        ariaLabel="remove service window"
        iconName="close"
        onClick={handleRemoveServiceWindow}
      />
      <div
        className={cx(typography.c2, styles.name)}
        id={`service-window-${id}-name`}
      >
        {name}
      </div>
      <div className={cx(styles.timeAndDays, typography.t3)}>
        <div className={styles.dates}>
          {parseToShortDateRange(startDate, endDate)}
        </div>
        <div className={styles.repeat}>{formatWeekDays(repeat)}</div>
        <div className={styles.times}>
          {ISOTimeTo12HourTime(startTime)}–{ISOTimeTo12HourTime(endTime)}
        </div>
      </div>
    </li>
  );
};

interface ServiceWindowOptionProps {
  serviceWindow: ServiceWindow;
}

const ServiceWindowOption = ({
  serviceWindow: { name, startDate, endDate, startTime, endTime, repeat },
}: ServiceWindowOptionProps) => (
  <div className={styles.optionLabel}>
    <div className={cx(typography.c2, styles.name)}>{name}</div>
    <div className={cx(styles.timeAndDays, typography.t3)}>
      <div className={styles.dates}>
        {parseToShortDateRange(startDate, endDate)}
      </div>
      <div className={styles.repeat}>{formatWeekDays(repeat)}</div>
      <div className={styles.times}>
        {ISOTimeTo12HourTime(startTime)}–{ISOTimeTo12HourTime(endTime)}
      </div>
    </div>
  </div>
);
