import { useState } from 'react';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from '../../context/useRestaurant';
import {
  createGuestNotes,
  getGuestNotes,
  updateGuestNotes,
} from '../../guestBook/apiHelpers';
import type { GuestNotesEditFormOnSaveArgs } from './GuestNotesEditForm/GuestNotesEditForm';
import { GuestNotesEditForm } from './GuestNotesEditForm/GuestNotesEditForm';
import { GuestNotesShow } from './GuestNotesShow/GuestNotesShow';

export interface GuestNotesProps {
  guestId: string;
  onUpdate?: () => void;
}

export const GuestNotes = ({ guestId, onUpdate }: GuestNotesProps) => {
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const { id: restaurantId } = useRestaurant();

  const {
    data: guestNoteResponse,
    isPending: isLoading,
    rerun: fetchGuestNotes,
  } = useAbortEffect(
    (signal) => getGuestNotes(restaurantId, guestId, signal),
    [restaurantId, guestId],
  );

  if (isLoading) return null;

  const noteData = guestNoteResponse?.data;

  const handleSave = ({
    note: guestNoteContent,
  }: GuestNotesEditFormOnSaveArgs): void => {
    void (async () => {
      if (noteData?.id) {
        let updated = false;
        try {
          await updateGuestNotes({
            guestId,
            note: guestNoteContent,
            id: noteData.id,
            restaurantId,
          });
          updated = true;
        } catch (e) {
          if (e instanceof ApiError) {
            errorToast({ message: e.message });
          }
          reportAppError(e);
        }

        if (updated) {
          setIsEditFormVisible(false);
          fetchGuestNotes();
          onUpdate?.();
        }
      } else {
        let created = false;
        try {
          await createGuestNotes({
            guestId,
            note: guestNoteContent,
            restaurantId,
          });
          created = true;
        } catch (e) {
          if (e instanceof ApiError) {
            errorToast({ message: e.message });
          }
          reportAppError(e);
        }
        if (created) {
          setIsEditFormVisible(false);
          fetchGuestNotes();
          onUpdate?.();
        }
      }
    })();
  };

  return isEditFormVisible ? (
    <GuestNotesEditForm
      note={noteData?.note || ''}
      onDiscard={() => {
        setIsEditFormVisible(false);
      }}
      onSave={handleSave}
    />
  ) : (
    <GuestNotesShow
      note={noteData?.note || ''}
      onEdit={() => setIsEditFormVisible(true)}
      updatedBy={noteData?.updatedBy}
    />
  );
};
