// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaxGuestCountForm__form___VR5_Y{display:flex;flex-direction:column;gap:2rem}.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__actions___mBWZ5{display:flex;flex-direction:column-reverse;gap:1rem;justify-content:flex-end}.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__deleteButton___yxQPR{align-items:center;background-color:inherit;border:none;color:#fff;display:flex;gap:.5rem;padding-left:0}.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__deleteButton___yxQPR svg{min-width:1.5rem;stroke:#fff;width:1.5rem}.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__deleteButton___yxQPR:active,.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__deleteButton___yxQPR:focus,.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__deleteButton___yxQPR:hover{color:var(--gold)}.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__deleteButton___yxQPR:active svg,.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__deleteButton___yxQPR:focus svg,.MaxGuestCountForm__form___VR5_Y .MaxGuestCountForm__deleteButton___yxQPR:hover svg{stroke:var(--gold)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/maxGuestCount/MaxGuestCountForm.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,qEACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,wBAAA,CAGF,0EACE,kBAAA,CACA,wBAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,SAAA,CACA,cAAA,CAEA,8EACE,gBAAA,CACA,WAAA,CACA,YAAA,CAGF,iPAOE,iBAAA,CAJA,6PACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "MaxGuestCountForm__form___VR5_Y",
	"actions": "MaxGuestCountForm__actions___mBWZ5",
	"deleteButton": "MaxGuestCountForm__deleteButton___yxQPR"
};
export default ___CSS_LOADER_EXPORT___;
