import { get } from 'lodash-es';
import { useState } from 'react';
import { type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormDateRange } from '@components/formInputs/ControlledFormDateRange';
import { ControlledFormDayRadio } from '@components/formInputs/ControlledFormDayRadio';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormTextareaAutosize } from '@components/formInputs/ControlledFormTextareaAutosize';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { todayInTimezone } from '@shared/utils/dateFormatters';
import { OPERATIONS_EVENTS_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import {
  createRestaurantEvent,
  editRestaurantEvent,
  type RestaurantEvent,
} from './apiHelpers';
import styles from './RestaurantEventForm.scss';

interface RestaurantEventFormData {
  description: string;
  endDate: string;
  name: string;
  repeat: string[];
  startDate: string;
}

export const RestaurantEventForm = () => {
  const restaurant = useRestaurant();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const restaurantEvent: RestaurantEvent = get(
    location,
    'state.restaurantEvent',
    {},
  );

  const { control, handleSubmit } = useForm<RestaurantEventFormData>({
    defaultValues: {
      description: restaurantEvent.description,
      endDate: restaurantEvent.endDate || undefined,
      name: restaurantEvent.name,
      repeat: restaurantEvent.repeat || [],
      startDate:
        restaurantEvent.startDate || todayInTimezone(restaurant.timezone),
    },
  });

  const endDate = useWatch({ control, name: 'endDate' });
  const startDate = useWatch({ control, name: 'startDate' });
  const showRepeatInput = startDate && startDate !== endDate;

  const onValidSubmit: SubmitHandler<RestaurantEventFormData> = async (
    data,
  ) => {
    setIsLoading(true);

    const eventPayload = {
      ...data,
      endDate: data.endDate || null,
      name: data.name,
      repeat: startDate === endDate ? [] : data.repeat,
    };

    try {
      if (restaurantEvent.id) {
        await editRestaurantEvent(restaurant.id, {
          ...eventPayload,
          id: restaurantEvent.id,
        });
      } else {
        await createRestaurantEvent(restaurant.id, eventPayload);
      }
      navigate(OPERATIONS_EVENTS_PATH);
    } catch (e) {
      if (e instanceof ApiError) {
        errorToast({ message: e.message });
      }
      reportAppError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDiscardClick = () => {
    navigate(OPERATIONS_EVENTS_PATH);
  };

  return (
    <>
      <PageHeader title="Events" />
      <PageContent>
        <form
          aria-labelledby="form-title"
          className={styles.form}
          onSubmit={handleSubmit(onValidSubmit)}
        >
          <header>
            <h2 className={typography.h5} id="form-title">
              {restaurantEvent.id ? 'Edit Event' : 'New Event'}
            </h2>
          </header>
          <ControlledFormInput
            control={control}
            disabled={false}
            label="Name"
            name="name"
            rules={{
              maxLength: {
                value: 50,
                message: 'Name must not exceed 50 characters',
              },
              required: true,
            }}
            type="text"
          />
          <ControlledFormTextareaAutosize
            control={control}
            disabled={false}
            label="Description"
            rules={{
              maxLength: {
                value: 200,
                message: 'Description must not exceed 200 characters',
              },
              minLength: {
                message: 'Description must be at least 4 characters',
                value: 4,
              },
              required: true,
            }}
            name="description"
          />
          <ControlledFormDateRange
            control={control}
            fromProps={{
              label: 'Start Date',
              name: `startDate`,
              rules: { required: true },
            }}
            toProps={{
              label: 'End Date',
              name: `endDate`,
              rules: {
                min: {
                  value: startDate,
                  message: 'End Date must be on or after Start Date',
                },
              },
            }}
            groupLabel="Start and End"
          />
          {showRepeatInput && (
            <ControlledFormDayRadio
              className={styles.repeatInput}
              control={control}
              label="Repeats On"
              name="repeat"
              rules={{
                required: true,
              }}
            />
          )}
          <div className={styles.buttonContainer}>
            <span />
            <Button
              label="Discard Edits"
              onClick={handleDiscardClick}
              type="button"
              variant={ButtonVariants.Tertiary}
            />
            <Button
              isDisabled={isLoading}
              label="Save"
              type="submit"
              variant={ButtonVariants.Primary}
            />
          </div>
        </form>
      </PageContent>
    </>
  );
};
