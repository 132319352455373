// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CoverLimitForm__form___cV1DC{display:flex;flex-direction:column;gap:2rem}.CoverLimitForm__form___cV1DC .CoverLimitForm__actions___kqj0d{display:flex;flex-direction:column-reverse;gap:1rem;justify-content:flex-end}.CoverLimitForm__form___cV1DC .CoverLimitForm__deleteButton___qeJVZ{align-items:center;background-color:inherit;border:none;color:#fff;display:flex;gap:.5rem;padding-left:0}.CoverLimitForm__form___cV1DC .CoverLimitForm__deleteButton___qeJVZ svg{min-width:1.5rem;stroke:#fff;width:1.5rem}.CoverLimitForm__form___cV1DC .CoverLimitForm__deleteButton___qeJVZ:active,.CoverLimitForm__form___cV1DC .CoverLimitForm__deleteButton___qeJVZ:focus,.CoverLimitForm__form___cV1DC .CoverLimitForm__deleteButton___qeJVZ:hover{color:var(--gold)}.CoverLimitForm__form___cV1DC .CoverLimitForm__deleteButton___qeJVZ:active svg,.CoverLimitForm__form___cV1DC .CoverLimitForm__deleteButton___qeJVZ:focus svg,.CoverLimitForm__form___cV1DC .CoverLimitForm__deleteButton___qeJVZ:hover svg{stroke:var(--gold)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/coverLimit/CoverLimitForm.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,+DACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,wBAAA,CAGF,oEACE,kBAAA,CACA,wBAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,SAAA,CACA,cAAA,CAEA,wEACE,gBAAA,CACA,WAAA,CACA,YAAA,CAGF,+NAOE,iBAAA,CAJA,2OACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "CoverLimitForm__form___cV1DC",
	"actions": "CoverLimitForm__actions___kqj0d",
	"deleteButton": "CoverLimitForm__deleteButton___qeJVZ"
};
export default ___CSS_LOADER_EXPORT___;
