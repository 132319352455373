import { api } from '../../api';

export interface RestaurantEvent {
  description: string;
  endDate: string | null;
  id: string;
  name: string;
  repeat: string[];
  startDate: string;
}
export interface NewRestaurantEvent {
  description: string;
  endDate: string | null;
  name: string;
  repeat: string[];
  startDate: string;
}

export const getRestaurantEvents = (
  restaurantId: string,
): Promise<RestaurantEvent[]> =>
  api.get(`/restaurants/${restaurantId}/restaurant-events`);

export const createRestaurantEvent = async (
  restaurantId: string,
  newRestaurantEvent: NewRestaurantEvent,
): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/restaurant-events`,
    newRestaurantEvent,
  );

export const deleteRestaurantEvent = async ({
  restaurantId,
  restaurantEventId,
}: {
  restaurantId: string | undefined;
  restaurantEventId: string | undefined;
}): Promise<void> =>
  api.delete(
    `/restaurants/${restaurantId}/restaurant-events/${restaurantEventId}`,
  );

export const editRestaurantEvent = async (
  restaurantId: string,
  event: RestaurantEvent,
): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/restaurant-events`, event);
