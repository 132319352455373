import cx from 'classnames';
import { groupBy } from 'lodash-es';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from '@components/Spinner';
import { parseToDayMonthFormat } from '@shared/utils/dateFormatters';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import styles from './InfiniteOccupantList.scss';
import { OccupantsByTime } from './OccupantsByTime';

export const InfiniteOccupantList = () => {
  const { timezone } = useRestaurant();
  const { fetchSubsequentOccupants, hasMoreOccupants, occupants } =
    useReservationServiceContext();

  const occupantsByDate = groupBy(occupants, 'date');

  if (!occupants.length) {
    return (
      <div className={styles.noContent}>
        There are no upcoming occupants to display.
      </div>
    );
  }

  return (
    <div
      className={styles.listWrapper}
      data-testid="occupant-list-wrapper"
      id="occupant-list-wrapper"
    >
      <ul aria-label="all active occupants" className={styles.list}>
        <InfiniteScroll
          dataLength={occupants.length}
          endMessage={
            <div className={cx(typography.c3, styles.noMoreOccupants)}>
              No more occupants
            </div>
          }
          hasMore={hasMoreOccupants}
          loader={<Spinner />}
          next={fetchSubsequentOccupants}
          scrollableTarget="occupant-list-wrapper"
          style={{ overflow: 'unset' }}
        >
          {Object.keys(occupantsByDate).map((date) => (
            <li key={date}>
              <h3 className={typography.h7}>
                {parseToDayMonthFormat(date, timezone)}
              </h3>
              <ul aria-label="all occupants for a date">
                <OccupantsByTime occupantsForDate={occupantsByDate[date]} />
              </ul>
            </li>
          ))}
        </InfiniteScroll>
      </ul>
    </div>
  );
};
