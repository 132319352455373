import cx from 'classnames';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import {
  toISODateFormat,
  toWeekdayMonthDateAndYear,
} from '@shared/utils/dateFormatters';
import { ISOTimeTo12HourTime, longAssLocalTime } from '@utils/time';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { groupByFifteenMinuteIncrement } from 'restaurantAdmin/reservations/occupants/sidePanel/occupantUtils';
import typography from '~styles/typography.scss';
import { getRestaurantsCoversReport } from '../apiHelpers';
import { CoversReportItem } from './CoversReportItem';
import styles from './DailyCoversReportPage.scss';

export const DailyCoversReportPage = () => {
  const [searchParams] = useSearchParams();
  const { id: restaurantId } = useRestaurant();

  const date = searchParams.get('date') || toISODateFormat(new Date());

  const { data: report } = useAbortEffect(
    {
      effect: (signal) =>
        getRestaurantsCoversReport(restaurantId, date, signal),
      throwOnError: true,
    },
    [],
  );

  useEffect(() => {
    if (report) {
      window.print();
    }
  }, [report]);

  if (!report) return null;

  const occupantsGroupedByTime = groupByFifteenMinuteIncrement(
    report.occupants,
  );

  const timeStamp = longAssLocalTime(new Date());

  return (
    <div className={styles.container}>
      <h1 className={typography.d2}>{report.name}</h1>
      <p className={typography.h2}>
        {toWeekdayMonthDateAndYear(new Date(`${date}T12:00:00`))}
      </p>
      <p
        className={cx(typography.c3, styles.timeStamp)}
      >{`Report created ${timeStamp}`}</p>
      <table aria-label="occupant summary" className={typography.c3}>
        <thead>
          <tr>
            <th>Type</th>
            <th>Count</th>
            <th>Covers</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Reservations</th>
            <td>{report.totals.reservations}</td>
            <td>{report.totals.reservationCovers}</td>
          </tr>
          <tr>
            <th>Walk-ins</th>
            <td>{report.totals.walkIns}</td>
            <td>{report.totals.walkInCovers}</td>
          </tr>
          <tr>
            <th>Total</th>
            <td>{report.totals.reservations + report.totals.walkIns}</td>
            <td>{report.totals.covers}</td>
          </tr>
        </tbody>
      </table>
      <ul
        aria-label="occupants grouped by time"
        className={styles.occupantsList}
      >
        {Object.keys(occupantsGroupedByTime).map((time) => {
          const timeGroupId = `time-group-${time}`;

          return (
            <li aria-labelledby={timeGroupId} key={`time-divider-${time}`}>
              <h2 className={typography.h4} id={timeGroupId}>
                {ISOTimeTo12HourTime(time)}
              </h2>
              <ul
                aria-label={`occupants starting at ${ISOTimeTo12HourTime(time)}`}
                className={styles.timeGroup}
              >
                {occupantsGroupedByTime[time].map((occupant) => (
                  <CoversReportItem
                    key={JSON.stringify(occupant)}
                    {...occupant}
                  />
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
