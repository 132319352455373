import { type Dispatch, type SetStateAction } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { deleteRestaurantEvent, type RestaurantEvent } from './apiHelpers';

export interface DeleteRestaurantEventConfirmationModalProps {
  closeModal: () => void;
  event: RestaurantEvent;
  isOpen: boolean;
  refetchRestaurantEvents: () => void;
  restaurantId: string;
  setEventToDelete: Dispatch<SetStateAction<RestaurantEvent | undefined>>;
}

export const DeleteRestaurantEventConfirmationModal = ({
  closeModal,
  isOpen,
  refetchRestaurantEvents,
  event,
  restaurantId,
  setEventToDelete,
}: DeleteRestaurantEventConfirmationModalProps) => {
  const handleDeleteRestaurantEvent = async (): Promise<void> => {
    closeModal();

    await deleteRestaurantEvent({
      restaurantEventId: event.id,
      restaurantId,
    });

    setEventToDelete(undefined);
    refetchRestaurantEvents();
  };

  const handleClose = (): void => {
    setEventToDelete(undefined);
    closeModal();
  };

  return (
    <Modal
      ariaLabel="confirm delete modal"
      isOpen={isOpen}
      onClose={handleClose}
      subtitle={`Are you sure you would like to delete the event “${event.name}”?`}
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={handleClose}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Yes, delete event"
          onClick={handleDeleteRestaurantEvent}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
