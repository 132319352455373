// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingForm__form___XHRSZ{display:grid;grid-template-columns:1fr 1fr;gap:24px 8px;height:calc(100vh - var(--headerHeight) - 96px - 48px);overflow:auto}.ListingForm__form___XHRSZ .ListingForm__floorPlan___cjvD3{grid-column:span 2}.ListingForm__form___XHRSZ .ListingForm__card___BiauQ{display:flex;flex-direction:column;row-gap:16px;align-items:center}.ListingForm__form___XHRSZ .ListingForm__card___BiauQ>h3{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/ListingForm.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,6BAAA,CACA,YAAA,CACA,sDAAA,CAIA,aAAA,CAEA,2DACE,kBAAA,CAGF,sDACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,kBAAA,CAEA,yDACE,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "ListingForm__form___XHRSZ",
	"floorPlan": "ListingForm__floorPlan___cjvD3",
	"card": "ListingForm__card___BiauQ"
};
export default ___CSS_LOADER_EXPORT___;
