// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[aria-label=\"Edit guest note\"]{background-color:var(--panelBlue);border-radius:8px;padding:1rem}[aria-label=\"Edit guest note\"] h3{margin:0 0 1rem}[aria-label=\"Edit guest note\"] textarea{background-color:var(--darkBlue);border-radius:4px;border:2px solid var(--hairline);color:var(--white);display:block;font-family:-apple-system,BlinkMacSystemFont,Inter,sans-serif;height:120px;margin-bottom:.5rem;padding:.5rem .75rem;width:100%}[aria-label=\"Edit guest note\"] .GuestNotesEditForm__actions___LG_wj{display:flex;gap:8px;justify-content:flex-end}[aria-label=\"Edit guest note\"] .GuestNotesEditForm__actions___LG_wj button{color:var(--white);padding:.5rem 1rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/guestNotes/GuestNotesEditForm/GuestNotesEditForm.scss"],"names":[],"mappings":"AAAA,+BACE,iCAAA,CACA,iBAAA,CACA,YAAA,CAEA,kCACE,eAAA,CAGF,wCACE,gCAAA,CACA,iBAAA,CACA,gCAAA,CACA,kBAAA,CACA,aAAA,CACA,6DAAA,CACA,YAAA,CACA,mBAAA,CACA,oBAAA,CACA,UAAA,CAGF,oEACE,YAAA,CACA,OAAA,CACA,wBAAA,CAEA,2EACE,kBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "GuestNotesEditForm__actions___LG_wj"
};
export default ___CSS_LOADER_EXPORT___;
