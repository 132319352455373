import cx from 'classnames';
import { useId } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Icon } from '@components/icon/Icon';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  type HostFloorPlanTable,
  unassignAllServers,
} from 'restaurantAdmin/floorPlans/apiHelpers';
import typography from '~styles/typography.scss';
import {
  SidePanelSheetMode,
  useReservationServiceContext,
} from '../../state/ReservationServiceContext';
import { type Server } from './apiHelpers';
import { ServerBadge } from './ServerBadge';
import { useServerFormContext } from './ServerFormContext';
import { useServersContext } from './ServersContext';
import styles from './ServersList.scss';

export const ServersList = () => {
  const { id: restaurantId } = useRestaurant();
  const { floorPlan, refreshFloorPlan, setSidePanelSheet } =
    useReservationServiceContext();
  const { assignedServers, fetchServers, unassignedServers } =
    useServersContext();
  const { setSelectedServer } = useServerFormContext();
  const {
    isOpen: isConfirmUnassignAllServersModalOpen,
    open: openConfirmUnassignAllServersModal,
    close: closeConfirmUnassignAllServersModal,
  } = useIsOpen();
  const serverCovers = getServerCovers(floorPlan!.floorPlanTables);

  const openServerForm = () => {
    setSidePanelSheet({ mode: SidePanelSheetMode.ServerForm });
  };

  const editServer = (server: Server) => {
    setSidePanelSheet({ mode: SidePanelSheetMode.ServerForm });
    setSelectedServer(server);
  };

  const onClickConfirmUnassignAllServers = () => {
    closeConfirmUnassignAllServersModal();
    void (async () => {
      await unassignAllServers(restaurantId);
      fetchServers();
      refreshFloorPlan();
    })();
  };

  return (
    <div className={styles.container}>
      <ServersListSection
        title="Assigned Servers"
        servers={assignedServers}
        serverCovers={serverCovers}
        testid="assigned"
        onServerClick={editServer}
      />
      <Button
        className={styles.unassignAllServersButton}
        isDisabled={assignedServers.length === 0}
        label="Unassign All Servers"
        onClick={openConfirmUnassignAllServersModal}
        variant={ButtonVariants.Tertiary}
      />
      <ServersListSection
        title="Unassigned Servers"
        servers={unassignedServers}
        testid="unassigned"
        onServerClick={editServer}
      />
      <button
        className={cx(typography.t1, styles.newServerButton)}
        onClick={openServerForm}
      >
        Create New Server
        <Icon name="plus" />
      </button>
      <Modal
        isOpen={isConfirmUnassignAllServersModalOpen}
        onClose={closeConfirmUnassignAllServersModal}
        title="Unassign All Servers"
        subtitle="Click confirm to remove all server table assignments."
      >
        <ModalActions>
          <Button
            label="Cancel"
            onClick={closeConfirmUnassignAllServersModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            label="Confirm"
            onClick={onClickConfirmUnassignAllServers}
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </Modal>
    </div>
  );
};

const ServersListSection = ({
  title,
  servers,
  serverCovers,
  testid,
  onServerClick,
}: {
  title: string;
  servers: Server[];
  serverCovers?: Map<string, number>;
  testid: string;
  onServerClick: (server: Server) => void;
}) => {
  const headingId = useId();
  return (
    <>
      <hgroup
        className={styles.serverGroupHeading}
        data-testid={`${testid}-hgroup`}
      >
        <h4 className={typography.h6} id={headingId}>
          {title}
        </h4>
        {serverCovers && <span className={typography.t1}>Covers</span>}
      </hgroup>
      <ul className={styles.serversList} aria-labelledby={headingId}>
        {servers.map((server) => (
          <ServerListItem
            key={server.id}
            server={server}
            covers={serverCovers && (serverCovers.get(server.id) ?? 0)}
            onClick={onServerClick}
          />
        ))}
      </ul>
    </>
  );
};

const ServerListItem = ({
  server,
  covers,
  onClick,
}: {
  server: Server;
  covers?: number;
  onClick: (server: Server) => void;
}) => (
  <li className={styles.serverListItem} onClick={() => onClick(server)}>
    <ServerBadge
      displayMode="card"
      serverName={server.name}
      {...(!!server.floorPlanTables.length && { badgeColor: server.hexColor })}
    />
    <span className={cx(typography.h8, styles.serverName)} data-testid="name">
      {server.name}
    </span>
    {!server.floorPlanTables.length && <Icon name="chevron" />}
    {covers != null && (
      <>
        {' '}
        <span className={cx(typography.t2, styles.covers)}>{covers}</span>
      </>
    )}
  </li>
);

const getServerCovers = (tables: HostFloorPlanTable[]): Map<string, number> => {
  const serverCovers = new Map<string, number>();
  tables.forEach((table) => {
    const { assignedServer, seatedOccupant } = table;
    if (assignedServer && seatedOccupant) {
      const current = serverCovers.get(assignedServer.id);
      const next = (current ?? 0) + seatedOccupant.guestCount;
      serverCovers.set(assignedServer.id, next);
    }
  });
  return serverCovers;
};
