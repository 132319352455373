import cx from 'classnames';
import typography from '~styles/typography.scss';
import { type ListingWithServiceWindows } from '../apiHelpers';
import styles from './ListingCard.scss';

interface ListingCardProps {
  listing: ListingWithServiceWindows;
  onClick?: () => void;
}

export const ListingCard = ({ listing, onClick }: ListingCardProps) => {
  const { id, name, publicName, serviceWindows } = listing;

  return (
    <li
      aria-labelledby={`listing-card-${id}-name`}
      className={styles.listItemContainer}
      onClick={onClick}
    >
      <div className={styles.heading}>
        <div className={typography.h5} id={`listing-card-${id}-name`}>
          {name}
        </div>
        <div className={typography.t2}>
          {`${serviceWindows.length} Service Window${serviceWindows.length > 1 ? 's' : ''}`}
        </div>
      </div>
      <div className={cx(typography.t1, styles.publicName)}>{publicName}</div>
    </li>
  );
};
