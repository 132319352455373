import { ISOTimeFloorToQuarterHour } from '@utils/time';
import { type OccupantsOccupant } from '../apiHelpers';

type Time = string;
type TimeMap<T> = Record<Time, T>;

export const groupByFifteenMinuteIncrement = <T extends { time: string }>(
  sortedItems: T[],
): TimeMap<T[]> =>
  sortedItems.reduce((acc: TimeMap<T[]>, item: T) => {
    const key = ISOTimeFloorToQuarterHour(item.time);

    if (!acc[key]) acc[key] = [];

    acc[key].push(item);

    return acc;
  }, {});

export const isOccupantFinished = (
  occupantStatus: Pick<OccupantsOccupant, 'status'>['status'],
) => ['finished', 'autoResolved'].includes(occupantStatus);
