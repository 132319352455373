import { compact, isEqual, uniqWith } from 'lodash-es';
import { type ChangeEvent, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
} from 'react-accessible-accordion';
import { type UseFieldArrayAppend, useForm } from 'react-hook-form';
import {
  PeakAccordionButton,
  PeakAccordionItemPanel,
} from '@components/accordion/Accordion';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { ControlledFormCheckbox } from '@components/formInputs/ControlledFormCheckbox';
import { ValidationErrorMessage } from '@components/formInputs/ValidationErrorMessage';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useRestaurant } from '../../../context/useRestaurant';
import { getPricePointsByListing, type PricePoint } from '../apiHelpers';
import { type ListingFormData, type PricePointFormData } from '../types';
import styles from './CopyPricePointsModal.scss';
import { PricePointItem } from './PricePointItem';

export interface CopyPricePointsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  addPricePoint: UseFieldArrayAppend<ListingFormData, 'pricePoints'>;
}

interface CopyPricePointsFormData {
  listings: { pricePoints: boolean[] }[];
}

export const CopyPricePointsModal = ({
  isOpen,
  closeModal,
  addPricePoint,
}: CopyPricePointsModalProps) => {
  const { id: restaurantId } = useRestaurant();
  const { data: pricePointsByListing = [], isPending } = useAbortEffect(
    {
      effect: async () => {
        if (isOpen) return getPricePointsByListing(restaurantId);
        return [];
      },
      throwOnError: true,
    },
    [isOpen],
  );
  const { control, handleSubmit, getValues, setValue } =
    useForm<CopyPricePointsFormData>();
  const [requiredError, setRequiredError] = useState(false);

  if (isPending) {
    return null;
  }

  const parsePricePoint = (pricePoint: PricePoint): PricePointFormData => ({
    activeDays: pricePoint.activeDays,
    endTime: pricePoint.endTime,
    price: String(pricePoint.price / 100),
    startTime: pricePoint.startTime,
  });

  const parsedPricePointsByListing = pricePointsByListing.map((listing) => ({
    ...listing,
    pricePoints: listing.pricePoints.map((pricePoint) =>
      parsePricePoint(pricePoint),
    ),
  }));

  const handleOnClickConfirm = handleSubmit((data: CopyPricePointsFormData) => {
    const selectedPricePoints = compact(
      data.listings.flatMap((listing, listingIndex) =>
        listing.pricePoints.map((isChecked, pricePointIndex) =>
          isChecked
            ? parsedPricePointsByListing[listingIndex].pricePoints[
                pricePointIndex
              ]
            : null,
        ),
      ),
    );

    if (selectedPricePoints.length) {
      addPricePoint(uniqWith(selectedPricePoints, isEqual));
      closeModal();
    } else {
      setRequiredError(true);
    }
  });

  const areAllPricePointsInThisListingSelected = (listingIndex: number) => {
    const { listings } = getValues();
    const listing = listings[listingIndex];

    return listing.pricePoints.every(Boolean);
  };

  // If all price points in the listing are already checked, uncheck everything
  // otherwise, check everything
  const onCheckOrUncheckListing = (
    e: ChangeEvent<HTMLInputElement>,
    listingIndex: number,
  ) => {
    const { listings } = getValues();
    const listing = listings[listingIndex];

    if (areAllPricePointsInThisListingSelected(listingIndex)) {
      const newValueWithAllPricePointsUnchecked = listing.pricePoints.map(
        (_) => false,
      );
      setValue(`listings.${listingIndex}`, {
        pricePoints: newValueWithAllPricePointsUnchecked,
      });
      e.target.checked = false;
    } else {
      const newValueWithAllPricePointsChecked = listing.pricePoints.map(
        (_) => true,
      );
      setValue(`listings.${listingIndex}`, {
        pricePoints: newValueWithAllPricePointsChecked,
      });
      e.target.checked = true;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Copy Price Points"
      subtitle="Select price points to copy over to this listing."
    >
      <form className={styles.form}>
        <Card>
          <Accordion allowZeroExpanded allowMultipleExpanded>
            <ul>
              {parsedPricePointsByListing.map((listing, listingIndex) => (
                <AccordionItem key={listing.id}>
                  <li key={listing.id}>
                    <div className={styles.listingNameRow}>
                      <input
                        id={`select-all-checkbox-${listing.id}`}
                        style={{ marginTop: '16px' }}
                        onChange={(e) =>
                          onCheckOrUncheckListing(e, listingIndex)
                        }
                        type="checkbox"
                        aria-label={`${listing.name} – ${listing.status}`}
                      />
                      <AccordionItemHeading className={styles.listingName}>
                        <PeakAccordionButton>
                          {`${listing.name} – ${listing.status}`}
                        </PeakAccordionButton>
                      </AccordionItemHeading>
                    </div>
                    <PeakAccordionItemPanel>
                      <ul>
                        {listing.pricePoints.map(
                          (pricePoint, pricePointIndex) => (
                            <li
                              key={
                                pricePointsByListing[listingIndex].pricePoints[
                                  pricePointIndex
                                ].id
                              }
                            >
                              <ControlledFormCheckbox
                                control={control}
                                label={
                                  <PricePointItem
                                    startTime={pricePoint.startTime}
                                    endTime={pricePoint.endTime}
                                    price={pricePoint.price}
                                    activeDays={pricePoint.activeDays}
                                  />
                                }
                                name={`listings.${listingIndex}.pricePoints.${pricePointIndex}`}
                                defaultValue={false}
                              />
                            </li>
                          ),
                        )}
                      </ul>
                    </PeakAccordionItemPanel>
                  </li>
                </AccordionItem>
              ))}
            </ul>
          </Accordion>
        </Card>
        <ValidationErrorMessage
          error={
            requiredError
              ? {
                  type: 'required',
                  message: 'At least one price point is required',
                }
              : undefined
          }
          label="Price Points"
          name="pricePoints"
        />
        <ModalActions>
          <Button
            type="button"
            label="Cancel"
            onClick={closeModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            type="button"
            label="Confirm"
            onClick={handleOnClickConfirm}
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </form>
    </Modal>
  );
};
