import { type Control, Controller } from 'react-hook-form';
import { Icon } from '@components/icon/Icon';
import styles from './ControlledColorPickerRadio.scss';
import { type ServerFormData } from './ServerFormContext';

interface ControlledColorPickerRadioProps {
  colors: string[];
  control: Control<ServerFormData>;
}

export const ControlledColorPickerRadio = ({
  colors,
  control,
}: ControlledColorPickerRadioProps) => (
  <fieldset>
    <div className={styles.container}>
      {colors.map((color) => (
        <Controller
          key={color}
          control={control}
          name="hexColor"
          render={({ field }) => {
            const isChecked = field.value === color;
            return (
              <div>
                <input
                  aria-label={color}
                  type="radio"
                  {...field}
                  id={`color-${color}`}
                  value={color}
                  checked={isChecked}
                />
                <label htmlFor={`color-${color}`}>
                  <span
                    aria-labelledby={`color-${color}`}
                    style={{ backgroundColor: color }}
                  >
                    {isChecked && (
                      <Icon name="checkMark" data-testid="checked-icon" />
                    )}
                  </span>
                </label>
              </div>
            );
          }}
        />
      ))}
    </div>
  </fieldset>
);
