import { SnapshotFloorPlan } from '@components/floorPlan/SnapshotFloorPlan';
import { CancelButton } from 'restaurantAdmin/components/cancelButton/CancelButton';
import { ContactInfo } from 'restaurantAdmin/components/contactInfo/ContactInfo';
import { GuestNotes } from 'restaurantAdmin/components/guestNotes/GuestNotes';
import { GuestTags } from 'restaurantAdmin/components/guestTags/GuestTags';
import { RefundButton } from 'restaurantAdmin/components/refundButton/RefundButton';
import { ReservationGuestSheetStatuses } from 'restaurantAdmin/components/reservationGuestSheetStatuses/ReservationGuestSheetStatuses';
import { AssignedServer } from 'restaurantAdmin/components/server/AssignedServer';
import { OccupantType } from 'restaurantAdmin/reservations/service/apiHelpers';
import {
  type OccupantsReservation,
  type ReservationsApiGuest,
} from '../apiHelpers';
import { useOccupantContext } from '../state/OccupantContext';
import { CancellationFee } from './CancellationFee';
import { CancellationFeeStatusTag } from './CancellationFeeStatusTag';
import styles from './ReservationGuestSheet.scss';
import { ReservationGuestSheetHeader } from './ReservationGuestSheetHeader';
import { ReservationGuestSheetReceipt } from './ReservationGuestSheetReceipt';

export interface ReservationGuestSheetProps {
  guest: ReservationsApiGuest;
  reservation: OccupantsReservation;
}

export const ReservationGuestSheet = ({
  guest,
  reservation,
}: ReservationGuestSheetProps) => {
  const { id: guestId, phone, email } = guest;
  const {
    cancellationPolicy,
    id: reservationId,
    isEligibleForCancellationFee,
    transactionDetails: { fee, price, tax, receiptNumber, refundAmount },
  } = reservation;

  const isCanceled = reservation.status === 'canceled';
  const isHostBooked = !!reservation.transactionDetails.createdBy.fullName;
  const isNoShow = reservation.status === 'noShow';
  const isRefunded = !!reservation.transactionDetails.refundAmount;
  const cancellationFee = reservation.cancellationPolicy?.fee || null;

  const { refreshOccupants } = useOccupantContext();

  return (
    <div className={styles.container}>
      <ReservationGuestSheetHeader guest={guest} reservation={reservation} />
      {reservation.server && (
        <AssignedServer
          isEditable={false}
          occupant={{
            occupantId: reservation.id,
            occupantType: OccupantType.Reservation,
          }}
          onSuccessfulPersist={refreshOccupants}
          server={reservation.server}
        />
      )}
      <ReservationGuestSheetStatuses
        isHostBooked={isHostBooked}
        isNoShow={isNoShow}
        isStranded={reservation.isStranded}
        isCanceled={isCanceled}
      />
      <CancellationFeeStatusTag
        fee={cancellationFee}
        isEligibleForCancellationFee={isEligibleForCancellationFee}
        status={reservation.cancellationFeeStatus}
      />
      {cancellationPolicy && (
        <CancellationFee
          cancellationPolicy={cancellationPolicy}
          isEligibleForCancellationFee={isEligibleForCancellationFee}
        />
      )}
      <div className={styles.floorplan}>
        <SnapshotFloorPlan reservationId={reservationId} disableControls />
      </div>
      <ContactInfo phone={phone} email={email} />
      <GuestTags
        guestId={guestId}
        onUpdate={() => {
          refreshOccupants();
        }}
      />
      <GuestNotes
        guestId={guestId}
        onUpdate={() => {
          refreshOccupants();
        }}
      />
      <ReservationGuestSheetReceipt
        fee={fee}
        price={price}
        receiptNumber={receiptNumber}
        tax={tax}
        refundAmount={refundAmount}
      />
      <CancelButton
        reservationId={reservationId}
        reservationStatus={reservation.status}
      />
      <RefundButton
        reservationId={reservationId}
        reservationPrice={price}
        isRefunded={isRefunded}
      />
    </div>
  );
};
