// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvatarDropdown__menuButton___fNfYt{background-color:rgba(0,0,0,0);outline:none;border:none;color:var(--white70)}.AvatarDropdown__menuButton___fNfYt:active div,.AvatarDropdown__menuButton___fNfYt:focus div,.AvatarDropdown__menuButton___fNfYt:hover div{color:var(--panelBlue);background-color:var(--gold)}.AvatarDropdown__menu____7ing>ul{background-color:var(--primary400);padding:1rem;border:1px solid var(--hairline);display:flex;flex-direction:column;row-gap:1rem}.AvatarDropdown__menuItem___a_E0I{display:flex;padding:.5rem 0}.AvatarDropdown__signOutButton___gqFy6{background-color:rgba(0,0,0,0);color:var(--white);outline:none;border:none;flex:1;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/layout/AvatarDropdown.scss"],"names":[],"mappings":"AAAA,oCACE,8BAAA,CACA,YAAA,CACA,WAAA,CACA,oBAAA,CAKE,2IACE,sBAAA,CACA,4BAAA,CAMJ,iCACE,kCAAA,CACA,YAAA,CACA,gCAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CAIJ,kCACE,YAAA,CACA,eAAA,CAGF,uCACE,8BAAA,CACA,kBAAA,CACA,YAAA,CACA,WAAA,CACA,MAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": "AvatarDropdown__menuButton___fNfYt",
	"menu": "AvatarDropdown__menu____7ing",
	"menuItem": "AvatarDropdown__menuItem___a_E0I",
	"signOutButton": "AvatarDropdown__signOutButton___gqFy6"
};
export default ___CSS_LOADER_EXPORT___;
