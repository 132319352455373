// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OccupantList__noContent___HrQx4{color:var(--white);display:flex;justify-content:center}.OccupantList__timeList___lPx3x{display:flex;flex-direction:column;height:100%;gap:1rem;overflow:auto;width:100%}.OccupantList__timeDivider___GNhY6{align-items:center;background-color:var(--hairline);border-radius:.5rem;display:flex;justify-content:center;margin-bottom:.5rem;padding:.75rem}.OccupantList__cardList___xQATa{display:flex;flex-direction:column;gap:.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/sidePanel/OccupantList.scss"],"names":[],"mappings":"AAAA,iCACE,kBAAA,CACA,YAAA,CACA,sBAAA,CAGF,gCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,QAAA,CACA,aAAA,CACA,UAAA,CAGF,mCACE,kBAAA,CACA,gCAAA,CACA,mBAAA,CACA,YAAA,CACA,sBAAA,CACA,mBAAA,CACA,cAAA,CAGF,gCACE,YAAA,CACA,qBAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noContent": "OccupantList__noContent___HrQx4",
	"timeList": "OccupantList__timeList___lPx3x",
	"timeDivider": "OccupantList__timeDivider___GNhY6",
	"cardList": "OccupantList__cardList___xQATa"
};
export default ___CSS_LOADER_EXPORT___;
