import cx from 'classnames';
import { useState } from 'react';
import { Icon } from '@components/icon/Icon';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { isReservationFinished } from 'restaurantAdmin/reservations/reservationUtils';
import typography from '~styles/typography.scss';
import { type OccupantsReservation } from '../apiHelpers';
import styles from './ReservationsMetrics.scss';
import { ReservationsMetricsPopover } from './ReservationsMetricsPopover';

interface ReservationMetricsProps {
  reservations: OccupantsReservation[];
}

export type ReservationMetricsFilter =
  | 'upcoming'
  | 'finished'
  | 'noShow'
  | 'total';

export const ReservationsMetrics = ({
  reservations,
}: ReservationMetricsProps) => {
  const {
    isOpen: isPopOverOpen,
    close: closePopOver,
    toggle: togglePopOver,
  } = useIsOpen();
  const [currentFilter, setCurrentFilter] =
    useState<ReservationMetricsFilter>('total');

  const setFilterAndClosePopover = (filter: ReservationMetricsFilter) => {
    setCurrentFilter(filter);
    closePopOver();
  };

  const { ref } = useHandleClickOutside(closePopOver);

  const metrics: Record<ReservationMetricsFilter, number> = {
    finished: 0,
    noShow: 0,
    total: reservations.length,
    upcoming: 0,
  };

  reservations.forEach((reservation) => {
    if (isReservationFinished(reservation.status)) {
      metrics.finished += 1;
    } else if (reservation.status === 'noShow') {
      metrics.noShow += 1;
    } else if (!reservation.seatedTableName) {
      metrics.upcoming += 1;
    }
  });

  const totalCopy = currentFilter === 'total' ? '' : `/ ${metrics.total}`;

  const metricsCount = metrics.total ? (
    metrics[currentFilter]
  ) : (
    <code>&#8212;</code>
  );

  const filterCopy: Record<ReservationMetricsFilter, string> = {
    finished: `Finished`,
    noShow: `No Show`,
    total: 'Total',
    upcoming: `Upcoming`,
  };

  return (
    <div className={styles.container} ref={ref}>
      <button aria-label="reservation metrics" onClick={togglePopOver}>
        <div className={styles.heading}>
          <h2 className={typography.h8}>Reservations</h2>
          <Icon name={isPopOverOpen ? 'minus' : 'plus'} />
        </div>
        <p className={cx(styles.count)}>
          {metricsCount}
          <span>&nbsp;{totalCopy}</span>{' '}
          <span>&nbsp;{filterCopy[currentFilter]}</span>
        </p>
      </button>
      {isPopOverOpen && (
        <ReservationsMetricsPopover
          {...metrics}
          setFilterAndClosePopover={setFilterAndClosePopover}
        />
      )}
    </div>
  );
};
