// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WaitList__list___YBCCK{flex:1;overflow-y:auto}.WaitList__addToWaitListButton___th94d{cursor:pointer;width:100%;margin-bottom:.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/waitList/WaitList.scss"],"names":[],"mappings":"AAAA,wBACE,MAAA,CACA,eAAA,CAGF,uCACE,cAAA,CACA,UAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "WaitList__list___YBCCK",
	"addToWaitListButton": "WaitList__addToWaitListButton___th94d"
};
export default ___CSS_LOADER_EXPORT___;
