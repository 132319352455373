import typography from '~styles/typography.scss';
import { RestaurantSettingsSection } from '../shared/RestaurantSettingsSection';
import { MaxGuestCountForm, maxGuestCountTip } from './MaxGuestCountForm';

export interface MaxGuestCountSettingsProps {
  maxGuestCount: number;
  phone: string | null;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const MaxGuestCountSettings = ({
  maxGuestCount,
  phone,
  refreshRestaurant,
  restaurantId,
}: MaxGuestCountSettingsProps) => (
  <RestaurantSettingsSection
    heading="Maximum Guests per Reservation"
    isEditable
    renderForm={(exitEditMode) => (
      <MaxGuestCountForm
        exitEditMode={exitEditMode}
        maxGuestCount={maxGuestCount}
        phone={phone}
        refreshRestaurant={refreshRestaurant}
        restaurantId={restaurantId}
      />
    )}
  >
    <p className={typography.t2}>{maxGuestCountTip(maxGuestCount, phone)}</p>
    <h3 className={typography.h6}>Maximum Guest Count</h3>
    <div className={typography.c2}>{maxGuestCount}</div>
  </RestaurantSettingsSection>
);
