import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';
import styles from './CreateListingModals.scss';

export interface CreateListingErrorModalProps {
  isOpen: boolean;
  closeModal: () => void;
  message?: string;
}

export const CreateListingErrorModal = ({
  isOpen,
  closeModal,
  message,
}: CreateListingErrorModalProps) => (
  <Modal isOpen={isOpen} onClose={closeModal} title="Create Listing Error">
    <div className={styles.modalContent}>
      <p className={typography.c2}>{message}</p>
      <p className={typography.c2}>Please address the error and try again.</p>
      <ModalActions>
        <Button
          label="Understood"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
      </ModalActions>
    </div>
  </Modal>
);
