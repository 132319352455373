import { Button, ButtonVariants } from '@shared/components/button/Button';
import { type WaitListEntry } from 'restaurantAdmin/reservations/service/sidePanel/waitList/apiHelpers';
import {
  SidePanelSheetMode,
  useReservationServiceContext,
} from '../../state/ReservationServiceContext';
import { useWaitListContext } from './state/WaitListContext';
import styles from './WaitList.scss';
import { WaitListEntryCard } from './WaitListEntryCard';

interface WaitListProps {
  waitListEntries: WaitListEntry[];
}

export const WaitList = ({ waitListEntries }: WaitListProps) => {
  const { setSidePanelSheet } = useReservationServiceContext();
  const { setSelectedWaitListEntryIndex } = useWaitListContext();
  const handleAddToWaitList = () => {
    setSidePanelSheet({ mode: SidePanelSheetMode.AddToWaitList });
  };

  return (
    <>
      <Button
        className={styles.addToWaitListButton}
        label="Add to Waitlist"
        variant={ButtonVariants.Secondary}
        onClick={handleAddToWaitList}
      />
      <ul aria-label="wait list" className={styles.list}>
        {waitListEntries.map((waitListEntry, index) => (
          <WaitListEntryCard
            index={index}
            key={waitListEntry.id}
            onClick={() => {
              setSidePanelSheet({
                mode: SidePanelSheetMode.WaitListDetails,
              });
              setSelectedWaitListEntryIndex(index);
            }}
            waitListEntry={waitListEntry}
          />
        ))}
      </ul>
    </>
  );
};
