import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { ApiServerError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from '../../context/useRestaurant';
import { type Admin, removeAdmin } from './apiHelpers';

export interface RemoveAdminConfirmationModalProps {
  admin: Admin;
  closeModal: () => void;
  refetchRestaurantAdmins: () => void;
  isOpen: boolean;
}

export const RemoveAdminConfirmationModal = ({
  admin,
  closeModal,
  refetchRestaurantAdmins,
  isOpen,
}: RemoveAdminConfirmationModalProps) => {
  const { id: restaurantId, name: restaurantName } = useRestaurant();
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleOnClickConfirm = async () => {
    setIsLoading(true);

    try {
      await removeAdmin(restaurantId, admin.id);
      refetchRestaurantAdmins();
    } catch (e) {
      if (e instanceof ApiServerError) errorToast({ message: e.message });
      reportAppError(e);
    } finally {
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      subtitle={`Click confirm to remove ${admin.fullName} from ${restaurantName}. This will not affect any of their existing permissions for other restaurants`}
      title="Remove Admin from Restaurant"
    >
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isLoading}
          label="Confirm"
          onClick={handleOnClickConfirm}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
