import cx from 'classnames';
import { centsToDollar } from '@shared/utils/currency';
import typography from '~styles/typography.scss';
import styles from './ReservationGuestSheetReceipt.scss';

export interface ReservationGuestSheetReceiptProps {
  className?: string;
  fee: number;
  price: number;
  receiptNumber: string;
  refundAmount: number | null;
  tax: number;
}

export const ReservationGuestSheetReceipt = ({
  className,
  fee,
  price,
  receiptNumber,
  refundAmount,
  tax,
}: ReservationGuestSheetReceiptProps) => (
  <section className={cx(styles.section, className)}>
    <h3 className={typography.h7_20}>
      <span>Receipt</span>
      <span>#{receiptNumber}</span>
    </h3>
    <dl className={typography.h7}>
      <div>
        <dt>Price</dt>
        <dd>{centsToDollar(price)}</dd>
      </div>
      {refundAmount && (
        <div>
          <dt>Refund Amount</dt>
          <dd>{centsToDollar(refundAmount)}</dd>
        </div>
      )}
      <div>
        <dt>Tax</dt>
        <dd>{centsToDollar(tax)}</dd>
      </div>
      <div>
        <dt>Processing</dt>
        <dd>{centsToDollar(fee)}</dd>
      </div>
      <div className={typography.h7_20}>
        <dt>
          <strong>Total</strong>
        </dt>
        <dd>
          <strong>{centsToDollar(price + fee + tax)}</strong>
        </dd>
      </div>
    </dl>
  </section>
);
