// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServiceFloorPlanContainer__restaurantFloorPlanContainer___HAE_Y{color:var(--white70)}.ServiceFloorPlanContainer__restaurantFloorPlanContainer___HAE_Y .ServiceFloorPlanContainer__instructions___X3uZO{font:inherit;margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/ServiceFloorPlanContainer.scss"],"names":[],"mappings":"AAAA,iEACE,oBAAA,CAEA,kHACE,YAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restaurantFloorPlanContainer": "ServiceFloorPlanContainer__restaurantFloorPlanContainer___HAE_Y",
	"instructions": "ServiceFloorPlanContainer__instructions___X3uZO"
};
export default ___CSS_LOADER_EXPORT___;
