import { api } from '@shared/api';

export interface FinancialSummaryForRestaurantAdmin {
  restaurantNetRevenue: number;
}

export const getFinancialSummary = async (
  restaurantId: string,
  month: string,
): Promise<FinancialSummaryForRestaurantAdmin> =>
  api.get(
    `/restaurants/${restaurantId}/financial-summary/restaurant-admin?month=${month}`,
  );
