import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import type { OccupantsWalkIn } from '../apiHelpers';
import { isOccupantFinished } from './occupantUtils';
import styles from './ReservationCard.scss';

export interface WalkInCardProps {
  onClick: () => void;
  walkIn: OccupantsWalkIn;
}

const ONE_GUEST = 1;

export const WalkInCard = ({ onClick, walkIn }: WalkInCardProps) => {
  const walkInName =
    `${walkIn.firstName || ''} ${walkIn.lastName || ''}`.trim() || 'WALK-IN';

  const isActive = walkIn.status === 'active';
  const isFinished = isOccupantFinished(walkIn.status);

  return (
    <li aria-label={`walk-in card for ${walkInName}`} className={styles.card}>
      <button className={styles.cardButton} onClick={onClick}>
        <div className={styles.avatarAndInfoStack}>
          <div className={styles.walkInIcon}>
            <Icon name="walkInNoBackground" />
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.timeAndStatusRow}>
              <div className={cx(typography.h8, styles.time)}>
                {ISOTimeTo12HourTime(walkIn.time)}
              </div>
              {isActive && (
                <div className={cx(typography.t3, styles.status)}>Seated</div>
              )}
              {isFinished && (
                <div className={cx(typography.t3, styles.status)}>
                  Finished (Table #{walkIn.seatedTableName})
                </div>
              )}
            </div>
            <div className={styles.nameAndGuestCountRow}>
              <div className={styles.userFullName}>{walkInName}</div>
              <div
                aria-label={`${walkIn.guestCount} guest${
                  walkIn.guestCount > ONE_GUEST ? 's' : ''
                }`}
                className={styles.guestCount}
              >
                <Icon name="couple" /> {walkIn.guestCount}
              </div>
            </div>
          </div>
        </div>
      </button>
    </li>
  );
};
