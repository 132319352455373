import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FormSelect } from '@components/formSelect/FormSelect';
import typography from '~styles/typography.scss';
import { useAuth } from '../context/AuthContext';
import { RESERVATIONS_SERVICE_PATH } from '../paths';
import styles from './RestaurantSelector.scss';

export const RestaurantSelector = () => {
  const navigate = useNavigate();
  const { restaurants, selectedRestaurantId, updateSelectedRestaurantId } =
    useAuth();

  if (restaurants.length === 1) {
    return <h1 className={styles.restaurantName}>{restaurants[0].name}</h1>;
  }

  const restaurantOptions = restaurants
    .map((restaurant) => ({
      label: restaurant.name,
      value: restaurant.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleOnChangeRestaurants = (
    valueOrNull: { value: string } | null,
  ): void => {
    if (valueOrNull !== null) {
      updateSelectedRestaurantId(valueOrNull.value);
      navigate(RESERVATIONS_SERVICE_PATH);
    }
  };

  return (
    <FormSelect
      className={styles.restaurantSelector}
      hideLabel
      label="restaurants"
      labelClassName={cx(typography.t1, styles.restaurantSelectorLabel)}
      name="restaurants"
      onChange={handleOnChangeRestaurants}
      options={restaurantOptions}
      value={restaurantOptions.find((r) => r.value === selectedRestaurantId)}
    />
  );
};
