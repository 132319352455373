import cx from 'classnames';
import { forwardRef, type ReactNode } from 'react';
import {
  calculateContainerStyle,
  calculateIconStyle,
} from '@components/floorPlan/utils';
import { type AnyIcon, Icon, type IconProps } from '@components/icon/Icon';
import styles from './FloorPlanTable.scss';

interface FloorPlanTableComponentProps extends React.ComponentProps<'div'> {
  left: string;
  top: string;
  tableIconScale: number;
  children: ReactNode;
}

const FloorPlanTableComponent = forwardRef<
  HTMLDivElement,
  FloorPlanTableComponentProps
>(({ left, top, tableIconScale, children, className, style, ...rest }, ref) => (
  <div
    className={cx(styles.container, className)}
    style={{
      ...calculateContainerStyle({
        left,
        tableIconScale,
        top,
      }),
      ...style,
    }}
    ref={ref}
    {...rest}
  >
    {children}
  </div>
));
FloorPlanTableComponent.displayName = 'FloorPlanTable';

interface FloorPlanTableIconProps extends Omit<IconProps, 'name'> {
  hasError?: boolean;
  iconName: string;
  iconWidthScale: string;
  isHighlighted?: boolean;
  orientation: string;
  tableIconScale: number;
}

const FloorPlanTableIcon = ({
  hasError = false,
  iconName,
  iconWidthScale,
  isHighlighted = false,
  orientation,
  style,
  tableIconScale,
  ...rest
}: FloorPlanTableIconProps) => {
  const iconSuffix = () => {
    if (hasError) return 'Error';
    if (isHighlighted) return 'Selected';
    return '';
  };

  const stateAwareIconName = `${iconName}${iconSuffix()}` as AnyIcon;

  return (
    <Icon
      name={stateAwareIconName}
      style={{
        ...calculateIconStyle({
          iconWidthScale,
          orientation,
          tableIconScale,
        }),
        ...style,
      }}
      testId={`icon-${stateAwareIconName}`}
      {...rest}
    />
  );
};

export default Object.assign(FloorPlanTableComponent, {
  Icon: FloorPlanTableIcon,
});
