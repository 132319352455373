import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

export interface UnseatGuestConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
  onUnseatGuest: () => Promise<void>;
}

export const UnseatGuestConfirmationModal = ({
  closeModal,
  isOpen,
  onUnseatGuest,
}: UnseatGuestConfirmationModalProps) => {
  const handleUnseatGuest = async (): Promise<void> => {
    closeModal();
    await onUnseatGuest();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Unseat Guest?"
      subtitle="Unseating this guest will remove them from this table, allowing you to
          seat them at another table. Unseating is different from marking a
          guest as finished in which their dining experience is complete."
    >
      <ModalActions>
        <Button
          label="Keep Guest Seated"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Unseat Guest"
          onClick={handleUnseatGuest}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
