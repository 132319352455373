import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { reportAppError } from '@shared/reportAppError';
import { getUpcomingReservationsByListing } from 'restaurantAdmin/reservations/apiHelpers';
import type { ListingWithServiceWindows } from '../apiHelpers';
import { unpublishListing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import { getDestinationPath } from '../utils/listingUtils';

export interface UnpublishListingConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
  listing: ListingWithServiceWindows;
}

export const UnpublishListingConfirmationModal = ({
  closeModal,
  isOpen,
  listing,
}: UnpublishListingConfirmationModalProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setSelectedListingId } = useListingsContext();
  const [numOfUpcomingReservations, setNumOfUpcomingReservations] = useState(0);

  useAbortEffect(
    async (signal) => {
      if (isOpen) {
        const response = await getUpcomingReservationsByListing(
          listing.restaurantId,
          listing.id,
          signal,
        );
        setNumOfUpcomingReservations(response.length);
      }
    },
    [isOpen],
  );

  const noUpcomingReservationMessage = `${listing.name} will be moved to Draft.`;
  const oneUpcomingReservationMessage = `Unpublishing ${listing.name} will affect 1 upcoming reservation. That reservation must be reconciled in Stranded Reservations.`;
  const multipleUpcomingReservationsMessage = `Unpublishing ${listing.name} will affect ${numOfUpcomingReservations} upcoming reservations. Those reservations must be reconciled in Stranded Reservations.`;

  let message;
  if (numOfUpcomingReservations === 0) {
    message = noUpcomingReservationMessage;
  } else if (numOfUpcomingReservations === 1) {
    message = oneUpcomingReservationMessage;
  } else {
    message = multipleUpcomingReservationsMessage;
  }

  const onConfirmUnpublishListing = () => {
    void (async () => {
      try {
        await unpublishListing(listing.restaurantId, listing.id);
        setSelectedListingId(listing.id);
        navigate(getDestinationPath(pathname, 'draft'));
      } catch (e) {
        if (e instanceof ApiError) {
          errorToast({ message: e.message });
        }
        reportAppError(e);
      }
    })();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Unpublish Listing"
      subtitle={message}
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Unpublish Listing"
          onClick={onConfirmUnpublishListing}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
