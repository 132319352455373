import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import { DEFAULT_NO_RECORDED_GUEST_NOTE_TEXT } from '../constants';
import styles from './GuestNotesShow.scss';

export interface GuestNotesShowFormProps {
  note: string;
  onEdit: () => void;
  updatedBy?: string | null;
}

export const GuestNotesShow = ({
  onEdit,
  note,
  updatedBy,
}: GuestNotesShowFormProps) => (
  <section aria-labelledby="guest-notes-heading" className={styles.showForm}>
    <header>
      <h3 id="guest-notes-heading" className={typography.h7}>
        Guest Notes
      </h3>
      <button onClick={onEdit}>
        <Icon name="pencil" />
        Edit
      </button>
    </header>
    <p className={typography.t1}>
      {note || DEFAULT_NO_RECORDED_GUEST_NOTE_TEXT}
    </p>
    {updatedBy && (
      <div className={cx(styles.updatedBy, typography.t2)}>
        Last updated by: {updatedBy}
      </div>
    )}
  </section>
);
