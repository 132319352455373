import { Kebab } from '@components/kebab/Kebab';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from '../../context/useRestaurant';
import { type Admin, resendInvite } from './apiHelpers';
import { RemoveAdminConfirmationModal } from './RemoveAdminConfirmationModal';

export interface TeamKebabProps {
  admin: Admin;
  refetchRestaurantAdmins: () => void;
}

export const TeamKebab = ({
  admin,
  refetchRestaurantAdmins,
}: TeamKebabProps) => {
  const restaurant = useRestaurant();
  const { isOpen, open, close } = useIsOpen();

  const handleResendInvite = async () => {
    try {
      await resendInvite(restaurant.id, admin.email);

      successToast({ message: 'The invite was re-sent successfully' });
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message:
            'An error occurred while resending the invite. Please try again.',
        });
      }
      reportAppError(error);
    }
  };

  const options = [
    {
      disabled: false,
      label: 'Remove from Restaurant',
      onClick: open,
    },
    {
      disabled: admin.status !== 'invited',
      label: 'Resend Invite',
      onClick: handleResendInvite,
    },
  ];

  return (
    <>
      <Kebab options={options} />
      <RemoveAdminConfirmationModal
        admin={admin}
        closeModal={close}
        isOpen={isOpen}
        refetchRestaurantAdmins={refetchRestaurantAdmins}
      />
    </>
  );
};
