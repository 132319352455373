// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FinancesPage__datePicker___pJ7PV{background-color:var(--primary500);color:var(--white);border:2px solid var(--darkGrey300);border-radius:4px;font-family:-apple-system,BlinkMacSystemFont,Inter,sans-serif;height:36px;padding-left:8px;padding-right:8px}.FinancesPage__datePicker___pJ7PV::-webkit-calendar-picker-indicator{font-size:15px;color:var(--white);filter:invert(1)}.FinancesPage__error___rFsUz{display:block;margin-left:64px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/finances/FinancesPage.scss"],"names":[],"mappings":"AAAA,kCACE,kCAAA,CACA,kBAAA,CACA,mCAAA,CACA,iBAAA,CACA,6DAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CAEA,qEACE,cAAA,CACA,kBAAA,CACA,gBAAA,CAIJ,6BACE,aAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePicker": "FinancesPage__datePicker___pJ7PV",
	"error": "FinancesPage__error___rFsUz"
};
export default ___CSS_LOADER_EXPORT___;
