// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestMetricsPopover__container___TE38s{background-color:var(--primary500);border-radius:8px;position:absolute;z-index:var(--z_metricsPopover);text-align:right;row-gap:8px;display:grid;grid-template-columns:1fr;padding:.25rem;text-wrap:nowrap}@media only screen and (min-width: 834px){.GuestMetricsPopover__container___TE38s{padding:1rem;min-width:unset}}.GuestMetricsPopover__container___TE38s button{display:grid;grid-template-columns:1fr 1fr 1fr;text-align:right}.GuestMetricsPopover__container___TE38s button:first-child{margin-bottom:8px}.GuestMetricsPopover__container___TE38s button div:first-child{text-align:left}.GuestMetricsPopover__container___TE38s button:hover{background-color:var(--gold);color:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/pageHeader/GuestMetricsPopover.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,wCACE,kCAAA,CACA,iBAAA,CACA,iBAAA,CACA,+BAAA,CACA,gBAAA,CACA,WAAA,CACA,YAAA,CACA,yBAAA,CACA,cAAA,CACA,gBAAA,CCoBA,0CD9BF,wCAaI,YAAA,CACA,eAAA,CAAA,CAGF,+CAKE,YAAA,CACA,iCAAA,CACA,gBAAA,CANA,2DACE,iBAAA,CAOF,+DACE,eAAA,CAGF,qDACE,4BAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestMetricsPopover__container___TE38s"
};
export default ___CSS_LOADER_EXPORT___;
