import { type HTMLAttributes, useId } from 'react';
import { Icon } from '@components/icon/Icon';
import styles from './Chip.scss';

type ChipProps = ChipOwnProps & HTMLAttributes<HTMLElement>;

interface ChipOwnProps {
  onDelete: (event: unknown) => void;
}

export const Chip = ({ children, onDelete, ...attrs }: ChipProps) => {
  const labelId = useId();
  return (
    <div
      {...attrs}
      aria-labelledby={labelId}
      className={styles.chip}
      data-testid="chip"
    >
      <span id={labelId}>{children}</span>
      <button aria-label="Remove" className={styles.remove} onClick={onDelete}>
        <Icon className={styles.removeIcon} name="close" />
      </button>
    </div>
  );
};
