import { Button, ButtonVariants } from '@components/button/Button';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import styles from './OccupantsPage.scss';
import { CloseRestaurantModal } from './pageHeader/CloseRestaurantModal';
import { OpenRestaurantModal } from './pageHeader/OpenRestaurantModal';
import { useCloseRestaurant } from './pageHeader/useCloseRestaurant';

export const OpenCloseRestaurantButton = () => {
  const {
    canCloseRestaurant,
    canOpenRestaurant,
    closeRestaurant,
    openRestaurant,
  } = useCloseRestaurant();
  const {
    open: openCloseRestaurantModal,
    close: closeCloseRestaurantModal,
    isOpen: isCloseRestaurantModalOpen,
  } = useIsOpen();
  const {
    open: openOpenRestaurantModal,
    close: closeOpenRestaurantModal,
    isOpen: isOpenRestaurantModalOpen,
  } = useIsOpen();

  const onConfirmCloseRestaurant = async () => {
    await closeRestaurant();
    closeCloseRestaurantModal();
  };

  const onConfirmOpenRestaurantModal = async () => {
    await openRestaurant();
    closeOpenRestaurantModal();
  };

  return (
    <>
      {canCloseRestaurant && (
        <>
          <Button
            className={styles.closeOrOpenRestaurantButton}
            label="Close Restaurant"
            onClick={openCloseRestaurantModal}
            type="submit"
            variant={ButtonVariants.Tertiary}
          />
          <CloseRestaurantModal
            closeModal={closeCloseRestaurantModal}
            isOpen={isCloseRestaurantModalOpen}
            onConfirm={onConfirmCloseRestaurant}
          />
        </>
      )}
      {canOpenRestaurant && (
        <>
          <Button
            className={styles.closeOrOpenRestaurantButton}
            label="Open Restaurant"
            onClick={openOpenRestaurantModal}
            type="submit"
            variant={ButtonVariants.Tertiary}
          />
          <OpenRestaurantModal
            closeModal={closeOpenRestaurantModal}
            isOpen={isOpenRestaurantModalOpen}
            onConfirm={onConfirmOpenRestaurantModal}
          />
        </>
      )}
    </>
  );
};
