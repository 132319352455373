// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingCard__listItemContainer___K7ueD{background:var(--primary500);border-left:.5rem solid var(--secondary300);border-radius:.5rem;display:flex;flex-direction:column;flex:1;gap:2px;margin:.5rem 0;padding:.5rem .75rem}.ListingCard__listItemContainer___K7ueD .ListingCard__heading___V4uEB{align-items:start;display:flex;flex-direction:row;justify-content:space-between}.ListingCard__listItemContainer___K7ueD .ListingCard__publicName___OG12M{color:var(--white70);white-space:nowrap;font-size:inherit}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/ListingCard.scss"],"names":[],"mappings":"AAEA,wCACE,4BAAA,CACA,2CAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,MAAA,CACA,OAAA,CACA,cAAA,CACA,oBAAA,CAEA,sEACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,yEACE,oBAAA,CACA,kBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItemContainer": "ListingCard__listItemContainer___K7ueD",
	"heading": "ListingCard__heading___V4uEB",
	"publicName": "ListingCard__publicName___OG12M"
};
export default ___CSS_LOADER_EXPORT___;
