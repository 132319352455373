import cx from 'classnames';
import { useState } from 'react';
import CloseIcon from '@assets/icons/close.svg';
import { Avatar } from '@components/avatar/Avatar';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import type { GuestReservation, GuestsApiGuest } from './apiHelpers';
import styles from './GuestDetails.scss';
import { GuestProfile } from './GuestProfile';
import { GuestReservations } from './GuestReservations';

const PROFILE = 'Profile';
const RESERVATIONS = 'Reservations';
const tabs = [PROFILE, RESERVATIONS] as const;
type GuestDetailTabs = (typeof tabs)[number];

export interface GuestDetailsProps {
  guest?: GuestsApiGuest;
  handleGuestReservationClick: (reservation: GuestReservation) => void;
  onClose?: () => void;
  selectedGuestReservationId?: string;
}

const SelectGuestCTA = () => (
  <div className={cx(styles.container, styles.selectGuestCTA)}>
    <div className={styles.coupleIcon}>
      <Icon name="couple" className={styles.icon} />
    </div>
    <p className={typography.h4}>Please Select a Guest</p>
  </div>
);

export const GuestDetails = ({
  guest,
  handleGuestReservationClick,
  onClose,
  selectedGuestReservationId,
}: GuestDetailsProps) => {
  const [selectedTab, setSelectedTab] = useState<GuestDetailTabs>(PROFILE);

  if (!guest) {
    return <SelectGuestCTA />;
  }

  return (
    <div className={styles.container}>
      <button
        aria-label="close guest details"
        className={styles.closeButton}
        onClick={onClose}
      >
        <CloseIcon className={styles.icon} />
      </button>
      <div className={styles.guestIdentifiers}>
        <Avatar
          firstName={guest.firstName}
          lastName={guest.lastName}
          className={styles.avatar}
        />
        <h2 className={cx(typography.h5)}>
          {guest.firstName} {guest.lastName}
        </h2>
      </div>
      <div className={styles.tabsContainer}>
        {tabs.map((tab) => (
          <button
            className={cx(styles.tab, typography.h7, {
              [styles.selected]: selectedTab === tab,
            })}
            key={tab}
            onClick={() => {
              setSelectedTab(tab);
            }}
          >
            {tab}
          </button>
        ))}
      </div>
      {selectedTab === PROFILE && <GuestProfile guest={guest} />}
      {selectedTab === RESERVATIONS && (
        <GuestReservations
          guestId={guest.id}
          handleGuestReservationClick={handleGuestReservationClick}
          selectedGuestReservationId={selectedGuestReservationId}
        />
      )}
    </div>
  );
};
