import { api } from '../../api';

export interface GuestsApiSearch {
  id: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  tags: string[];
  note: string | null;
}

export const guestSearch = async ({
  restaurantId,
  query,
}: {
  restaurantId: string;
  query: string;
}): Promise<GuestsApiSearch[]> =>
  api.get(
    `/restaurants/${restaurantId}/guests/search?query=${encodeURIComponent(query)}`,
  );
