import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { useServerFormContext } from './ServerFormContext';

interface ConfirmDeleteServerModalProps {
  closeConfirmDeleteServerModal: () => void;
  isConfirmDeleteServerModalOpen: boolean;
}

export const ConfirmDeleteServerModal = ({
  closeConfirmDeleteServerModal,
  isConfirmDeleteServerModalOpen,
}: ConfirmDeleteServerModalProps) => {
  const { handleDeleteServer, selectedServer } = useServerFormContext();

  if (!selectedServer) return null;

  return (
    <Modal
      isOpen={isConfirmDeleteServerModalOpen}
      onClose={closeConfirmDeleteServerModal}
      title="Confirm Deletion"
      subtitle={`Are you sure you want to delete the server ${selectedServer.name}? All of their associated tables will be unassigned. This action cannot be undone.`}
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeConfirmDeleteServerModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Delete Server"
          onClick={() => handleDeleteServer(closeConfirmDeleteServerModal)}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
