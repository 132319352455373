import { tz } from '@date-fns/tz';
import { isSameDay } from 'date-fns';
import type { OccupantState } from './occupantReducer';

const ZERO_INDEX_OFFSET = 1;

export const selectedOccupant = (state: OccupantState) =>
  state.occupants[state.selectedIndex];

export const occupantPositionValues = (state: OccupantState) => ({
  count: state.occupants.length,
  position: state.selectedIndex + ZERO_INDEX_OFFSET,
});

export const isSelectedReservationDateToday =
  (state: OccupantState) => (timezone: string) =>
    isSameDay(state.reservationDate, new Date(), { in: tz(timezone) });
