import { Dropdown } from '@mui/base/Dropdown';
import { Menu as MuiMenu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import cx from 'classnames';
import { type MouseEvent } from 'react';
import { MenuItem, type MenuItemProps } from '@components/menu/MenuItem';
import { MenuListbox } from '@components/menu/MenuListbox';
import { Icon } from '../icon/Icon';
import styles from './Kebab.scss';

export interface KebabOption extends Omit<MenuItemProps, 'children'> {
  label: string;
}

export interface KebabProps {
  ariaLabelPrefix?: string;
  className?: string;
  options?: KebabOption[];
}

export const Kebab = ({
  ariaLabelPrefix,
  className,
  options = [],
}: KebabProps) => (
  // The <div> element has a child <button> element that allows keyboard interaction
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    className={cx(styles.menuWrapper, className)}
    onClick={(e: MouseEvent): void => {
      e.stopPropagation();
      e.preventDefault();
    }}
    onMouseDown={(e: MouseEvent): void => {
      e.stopPropagation();
      e.preventDefault();
    }}
  >
    <Dropdown>
      <MenuButton
        aria-label={[ariaLabelPrefix, 'options'].join(' ')}
        className={styles.menuButton}
      >
        <Icon className={styles.icon} name="options" />
      </MenuButton>
      <MuiMenu
        slots={{ listbox: MenuListbox }}
        slotProps={{ root: { placement: 'left-start' } }}
      >
        {options.map(({ label, ...props }) => (
          <MenuItem key={label} {...props}>
            {label}
          </MenuItem>
        ))}
      </MuiMenu>
    </Dropdown>
  </div>
);
