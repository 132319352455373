// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableList__tableList___Uf5PQ{display:flex;flex-wrap:wrap;gap:1rem;margin:1rem 0}.TableList__tableList___Uf5PQ li{flex:1;flex-basis:20%;display:flex;flex-direction:column;justify-content:space-between;align-items:center;padding:1rem .5rem;background-color:var(--panelBlue);border-radius:1rem;gap:.5rem;max-width:70px}@media only screen and (min-width: 1024px){.TableList__tableList___Uf5PQ li{max-width:80px}}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/TableList.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,cAAA,CACA,QAAA,CACA,aAAA,CAEA,iCACE,MAAA,CACA,cAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CACA,iCAAA,CACA,kBAAA,CACA,SAAA,CACA,cAAA,CCaF,2CDxBA,iCAcI,cAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableList": "TableList__tableList___Uf5PQ"
};
export default ___CSS_LOADER_EXPORT___;
