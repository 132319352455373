import cx from 'classnames';
import { forwardRef, type HTMLAttributes } from 'react';
import styles from './FormListbox.scss';

export const FormListbox = forwardRef<
  HTMLUListElement,
  HTMLAttributes<HTMLUListElement>
>(({ className, ...attrs }, ref) => (
  <ul className={cx(styles.listbox, className)} {...attrs} ref={ref} />
));
FormListbox.displayName = 'FormListbox';
