// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestTagList__categoryList___PBVfD{display:inline}.GuestTagList__categoryList___PBVfD li{display:inline}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/guestTags/GuestTagList/GuestTagList.scss"],"names":[],"mappings":"AAAA,oCACE,cAAA,CAEA,uCACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryList": "GuestTagList__categoryList___PBVfD"
};
export default ___CSS_LOADER_EXPORT___;
