import cx from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '@components/card/Card';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { parseToShortDateFormat } from '@shared/utils/dateFormatters';
import { formatWeekdayList } from '@shared/utils/weekDayFormatters';
import { OPERATIONS_EVENTS_UPDATE_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import type { RestaurantEvent } from './apiHelpers';
import { DeleteRestaurantEventConfirmationModal } from './DeleteRestaurantEventConfirmationModal';
import styles from './RestaurantEventsList.scss';

interface RestaurantEventsTableProps {
  refetchRestaurantEvents: () => void;
  restaurantEvents: RestaurantEvent[];
}

export const RestaurantEventsList = ({
  refetchRestaurantEvents,
  restaurantEvents,
}: RestaurantEventsTableProps) => {
  const { close, isOpen, open } = useIsOpen();
  const { id: restaurantId } = useRestaurant();
  const [eventToDelete, setEventToDelete] = useState<RestaurantEvent>();

  return (
    <>
      <ol aria-label="Restaurant Events" className={styles.eventList}>
        {restaurantEvents.map((restaurantEvent) => {
          const { description, endDate, id, name, repeat, startDate } =
            restaurantEvent;
          const shortStartDate = parseToShortDateFormat(startDate);
          const shortEndDate = endDate
            ? parseToShortDateFormat(endDate)
            : undefined;
          const oneDayEvent = shortStartDate === shortEndDate;

          return (
            <li className={styles.eventItem} key={id}>
              <Card>
                <header className={styles.cardHeader}>
                  <h2 className={typography.h5}>{name}</h2>
                  <div>
                    <Link
                      className={cx(typography.c2, styles.editLink)}
                      state={{ restaurantEvent }}
                      title={`Edit ${name}`}
                      to={OPERATIONS_EVENTS_UPDATE_PATH}
                    >
                      <Icon ariaLabel={`Edit ${name}`} name="pencil" />
                    </Link>
                    <IconButton
                      ariaLabel={`Delete ${name}`}
                      className={styles.deleteButton}
                      iconName="trash"
                      onClick={() => {
                        setEventToDelete(restaurantEvent);
                        open();
                      }}
                    />
                  </div>
                </header>
                <p className={cx(typography.c2, styles.eventContent)}>
                  {description}
                </p>
                <h3 className={cx(typography.h6, styles.eventSubheading)}>
                  {oneDayEvent ? 'Date' : 'Start and end dates'}
                </h3>
                <p className={cx(typography.c2, styles.eventContent)}>
                  {oneDayEvent
                    ? shortStartDate
                    : `${shortStartDate}–${shortEndDate || 'Never'}`}
                </p>
                {Boolean(repeat.length) && (
                  <>
                    <h3 className={cx(typography.h6, styles.eventSubheading)}>
                      Repeats
                    </h3>
                    <p className={cx(typography.c2, styles.eventContent)}>
                      {formatWeekdayList(repeat)}
                    </p>
                  </>
                )}
              </Card>
            </li>
          );
        })}
      </ol>
      {eventToDelete && (
        <DeleteRestaurantEventConfirmationModal
          closeModal={close}
          event={eventToDelete}
          isOpen={isOpen}
          refetchRestaurantEvents={refetchRestaurantEvents}
          restaurantId={restaurantId}
          setEventToDelete={setEventToDelete}
        />
      )}
    </>
  );
};
