import { TZDate } from '@date-fns/tz';
import cx from 'classnames';
import { secondsToMilliseconds } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { centsToDollar } from '@shared/utils/currency';
import { toShortDateTime } from '@shared/utils/dateFormatters';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { cancel } from 'restaurantAdmin/reservations/apiHelpers';
import typography from '~styles/typography.scss';
import styles from './CancelConfirmationModal.scss';

export interface CancelConfirmationModalProps {
  cancellationPolicy: {
    fee: number;
  } | null;
  closeModal: () => void;
  disablePortal?: boolean;
  eligibleForCancellationFeeUntil: string;
  handleAfterConfirm?: () => void;
  isEligibleForCancellationFeeUponCancellation: boolean;
  isOpen: boolean;
  reservationId: string;
}

const CURRENT_PAGE = 0;

export const CancelConfirmationModal = ({
  cancellationPolicy,
  closeModal,
  disablePortal,
  eligibleForCancellationFeeUntil,
  handleAfterConfirm,
  isEligibleForCancellationFeeUponCancellation,
  isOpen,
  reservationId,
}: CancelConfirmationModalProps) => {
  const restaurant = useRestaurant();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(false);

  const cancelOnlyCopy = () => (
    <p className={cx(typography.c2, styles.message)}>
      Are you sure you would like to cancel this reservation? Please reference
      the Peak &nbsp;
      <ExternalRefundLink className={styles.link} />
      &nbsp; for any concerns regarding cancellations.
    </p>
  );

  const cancelWithFeeCollectionDetailsCopy = () => (
    <p className={cx(typography.c2, styles.message)}>
      This reservation has a cancellation fee of{' '}
      {centsToDollar(cancellationPolicy?.fee)} attached. If you skip collecting
      the fee right now, you have until{' '}
      {toShortDateTime(
        TZDate.tz(restaurant.timezone, eligibleForCancellationFeeUntil),
      )}{' '}
      to collect this fee on the Occupants page. Please reference the Peak{' '}
      <ExternalRefundLink className={styles.link} /> for any concerns regarding
      cancellations.
    </p>
  );

  const refreshPage = () =>
    setTimeout(() => navigate(CURRENT_PAGE), secondsToMilliseconds(3));

  const handleCancel = () => {
    setIsPending(true);

    void (async () => {
      try {
        await cancel(restaurant.id, reservationId);
        successToast({
          message:
            "Success: The guest's reservation has been successfully canceled.",
        });
      } catch (error) {
        if (error instanceof ApiError) {
          errorToast({
            message: `Failed to cancel reservation: ${error.message}`,
          });
        } else {
          errorToast({
            message: 'Failed to cancel reservation. Please try again.',
          });
        }
        reportAppError(error);
      }
      if (handleAfterConfirm) {
        handleAfterConfirm();
      } else {
        refreshPage();
      }
    })();
  };

  return (
    <Modal
      ariaLabel="Cancel Confirmation"
      disablePortal={disablePortal}
      isOpen={isOpen}
      onClose={closeModal}
    >
      {isEligibleForCancellationFeeUponCancellation
        ? cancelWithFeeCollectionDetailsCopy()
        : cancelOnlyCopy()}
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isPending}
          label="Confirm"
          onClick={handleCancel}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
