import cx from 'classnames';
import { useId } from 'react';
import {
  type Control,
  type FieldPath,
  type FieldValues,
  useController,
  type UseControllerProps,
} from 'react-hook-form';
import { type DaysOfWeek, INDEX_DAY_MAP } from '@utils/weekDayFormatters';
import typography from '~styles/typography.scss';
import styles from './ControlledFormDayRadio.scss';

const DAYS_OF_WEEK: DaysOfWeek[] = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export interface ControlledFormDayRadioProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  label: string;
  name: Name;
  rules?: UseControllerProps<T, Name>['rules'];
  daysOfWeek?: DaysOfWeek[];
}
export const ControlledFormDayRadio = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  label,
  name,
  rules,
  daysOfWeek = DAYS_OF_WEEK,
}: ControlledFormDayRadioProps<T, Name>) => {
  const instanceId = useId();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  const handleOnClickEveryday = () => {
    onChange(daysOfWeek.map((day) => INDEX_DAY_MAP[day]));
  };

  const handleOnClickDay = (dayIndex: string) => () => {
    const newRepeat = [...value];

    if (newRepeat.includes(dayIndex)) {
      newRepeat.splice(value.indexOf(dayIndex), 1);
      onChange(newRepeat);
    } else {
      newRepeat.push(dayIndex);
      onChange(newRepeat);
    }
  };

  const handleSpaceKeyDown =
    (dayIndex: string) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      const newRepeat = [...value];

      if (event.key === ' ') {
        if (newRepeat.includes(dayIndex)) {
          newRepeat.splice(value.indexOf(dayIndex), 1);
          onChange(newRepeat);
        } else {
          newRepeat.push(dayIndex);
          onChange(newRepeat);
        }
      }
    };

  return (
    <div
      className={cx(
        {
          [styles.fieldContainer]: true,
        },
        className,
      )}
    >
      <label
        className={cx({
          [typography.c2_20]: true,
          [styles.labelError]: !!error,
        })}
        htmlFor={`${instanceId}-repeat-group`}
        id={`${instanceId}-repeat-label`}
      >
        {label}
        <button
          onClick={handleOnClickEveryday}
          type="button"
          className={typography.c3_20}
        >
          Everyday
        </button>
      </label>
      <div
        aria-labelledby={`${instanceId}-repeat-label`}
        className={styles.checkboxContainer}
        id={`${instanceId}-repeat-group`}
        role="group"
      >
        <ul>
          {daysOfWeek.map((day) => (
            <li key={day}>
              <div
                className={cx({
                  [typography.c2_20]: true,
                  [styles.error]: !!error,
                })}
                role="checkbox"
                aria-checked={value.includes(INDEX_DAY_MAP[day])}
                tabIndex={0}
                onClick={handleOnClickDay(INDEX_DAY_MAP[day])}
                onKeyDown={handleSpaceKeyDown(INDEX_DAY_MAP[day])}
              >
                {day}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
