import * as Sentry from '@sentry/react';
import {
  ApiError,
  ApiResourceNotFoundError,
  ApiServerError,
  ApiUnauthorizedError,
} from '@shared/api/errors';
import { NOT_FOUND, UNAUTHORIZED } from '@shared/statusCodes';
import { contentTypeMimeType, MimeType } from '@utils/mimeType';

export interface ApiGetOptions {
  signal?: AbortSignal;
}

export interface ErrorResponse {
  error: string;
  message: string;
  statusCode: number;
}

export class Api {
  constructor(private readonly host: string = process.env.API_HOST!) {}

  delete<T>(path: string): Promise<T> {
    return this.fetch('DELETE', path);
  }

  get<T>(path: string, options?: ApiGetOptions): Promise<T> {
    return this.fetch('GET', path, undefined, options);
  }

  post<T>(path: string, body?: unknown): Promise<T> {
    return this.fetch('POST', path, body);
  }

  put<T>(path: string, body: unknown): Promise<T> {
    return this.fetch('PUT', path, body);
  }

  private async fetch<T>(
    method: string,
    path: string,
    body?: unknown,
    options?: ApiGetOptions,
  ): Promise<T> {
    const url = `${this.host}${path}`;
    const headers = new Headers();
    let bodyForFetch;
    if (body !== undefined) {
      headers.set('Content-Type', 'application/json');
      bodyForFetch = JSON.stringify(body);
    }
    const response = await fetch(url, {
      body: bodyForFetch,
      credentials: 'include',
      headers,
      method,
      signal: options?.signal,
    });
    return handleResponse<T>(response, url);
  }
}

const handleResponse = async <T>(
  response: Response,
  url: string,
): Promise<T> => {
  const contentType = response.headers.get('Content-Type');
  const mimeType = contentType ? contentTypeMimeType(contentType) : undefined;
  if (response.ok) {
    if (mimeType === MimeType.ApplicationJson) {
      return (await response.json()) as T;
    }
    return undefined as T;
  }
  if (mimeType === MimeType.ApplicationJson) {
    const body: unknown = await response.json();
    if (isErrorResponse(body)) {
      const { error, message, statusCode } = body;
      switch (statusCode) {
        case NOT_FOUND:
          throw new ApiResourceNotFoundError(message);
        case UNAUTHORIZED:
          throw new ApiUnauthorizedError(message);
        default:
          if (statusCode >= 500 && statusCode < 600) {
            throw new ApiServerError(statusCode, message);
          }
          throw new ApiError(statusCode, message, error);
      }
    }
  }
  Sentry.captureMessage('invalid API response', {
    tags: { url },
  });
  throw new ApiError(response.status, 'bad response');
};

const isErrorResponse = (body: unknown): body is ErrorResponse =>
  Boolean(typeof body === 'object' && body && 'error' in body);

export const api = new Api();
