import type { HostFloorPlanTable } from './apiHelpers';

type PersistedMergedTable = HostFloorPlanTable & { mergeTableId: string };

const isPersistedMergedTable = (
  table: HostFloorPlanTable,
): table is PersistedMergedTable => table.mergeTableId !== null;

export const isPersistedSecondaryMergedTable = (
  table: HostFloorPlanTable,
): table is PersistedMergedTable =>
  isPersistedMergedTable(table) && table.mergeTableId !== table.id;

export const isPersistedPrimaryMergedTable = (
  table: HostFloorPlanTable,
): table is PersistedMergedTable =>
  isPersistedMergedTable(table) && table.mergeTableId === table.id;

export const convertToMergeAwareTable = (
  nonSecondaryTable: HostFloorPlanTable,
): HostFloorPlanTable => {
  if (!isPersistedPrimaryMergedTable(nonSecondaryTable))
    return nonSecondaryTable;

  return {
    ...nonSecondaryTable,
    iconName: 'mergedTable',
    name: nonSecondaryTable.mergeTableName || nonSecondaryTable.name,
    orientation: '0.00',
  };
};

export const convertToMergeAwareTables = (allTables: HostFloorPlanTable[]) =>
  allTables
    .filter((table) => !isPersistedSecondaryMergedTable(table))
    .map((nonSecondaryTable) => convertToMergeAwareTable(nonSecondaryTable));
