import { type HTMLAttributes, useMemo } from 'react';
import { Icon } from '@components/icon/Icon';
import { reportAppError } from '@shared/reportAppError';
import { SERVICE_STATUS_LABELS, type ServiceStatus } from './ServiceStatus';

interface ServiceStatusIconProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  serviceStatus: ServiceStatus;
  scale?: number;
}

export const ServiceStatusIcon = ({
  className,
  serviceStatus,
  scale = 0.666, // scale down to 40px by default
}: ServiceStatusIconProps) => {
  const iconLabel = useMemo(() => {
    const label = SERVICE_STATUS_LABELS[serviceStatus];
    if (!label) {
      reportAppError(new Error(`unknown service status: ${serviceStatus}`));
    }
    return label;
  }, [serviceStatus]);

  if (!iconLabel) return null;

  return (
    <Icon
      testId="service-status-icon"
      className={className}
      ariaLabel={iconLabel}
      name={serviceStatus}
      role="img"
      style={{
        width: 60 * scale,
        height: 60 * scale,
      }}
    />
  );
};
