import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import typography from '~styles/typography.scss';
import { DEFAULT_NO_RECORDED_GUEST_NOTE_TEXT } from '../constants';
import styles from './GuestNotesEditForm.scss';

export interface GuestNotesEditFormOnSaveArgs {
  note: string;
}

export interface GuestNotesEditFormProps {
  note: string;
  onDiscard: () => void;
  onSave: (args: GuestNotesEditFormOnSaveArgs) => void;
}

export const GuestNotesEditForm = ({
  note,
  onDiscard,
  onSave,
}: GuestNotesEditFormProps) => {
  const [newNote, setNewNote] = useState(note);

  return (
    <section aria-label="Edit guest note">
      <label htmlFor="guest-notes">
        <h3 className={typography.h7}>Guest Notes</h3>
      </label>
      <textarea
        className={typography.c2}
        defaultValue={note}
        id="guest-notes"
        maxLength={600}
        onChange={(event) => setNewNote(event.target.value)}
        placeholder={DEFAULT_NO_RECORDED_GUEST_NOTE_TEXT}
      />
      <div className={styles.actions}>
        <Button
          label="Discard"
          onClick={onDiscard}
          variant={ButtonVariants.Default}
        />
        <Button
          label="Save"
          onClick={() => onSave({ note: newNote })}
          variant={ButtonVariants.Primary}
        />
      </div>
    </section>
  );
};
