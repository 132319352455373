import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

export interface WalkInSeatingConflictModalProps {
  closeModal: () => void;
  handleConfirm: () => void;
  isOpen: boolean;
}

export const WalkInSeatingConflictModal = ({
  closeModal,
  handleConfirm,
  isOpen,
}: WalkInSeatingConflictModalProps) =>
  isOpen ? (
    <Modal
      disablePortal
      isOpen={isOpen}
      onClose={closeModal}
      title="Seating Conflict Warning"
      subtitle="The walk-in you are about to seat conflicts with an existing reservation."
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Continue"
          onClick={() => {
            handleConfirm();
            closeModal();
          }}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  ) : null;
