// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SplitMergeTablesButton__container___VTxQO{height:unset;line-height:unset;padding:.25rem 1rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/splitMergeTables/SplitMergeTablesButton.scss"],"names":[],"mappings":"AAEA,2CACE,YAAA,CACA,iBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SplitMergeTablesButton__container___VTxQO"
};
export default ___CSS_LOADER_EXPORT___;
