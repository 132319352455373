import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

interface CloseRestaurantModalProps {
  closeModal: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}

export const CloseRestaurantModal = ({
  closeModal,
  onConfirm,
  isOpen,
}: CloseRestaurantModalProps) =>
  isOpen ? (
    <Modal
      ariaLabel="Close restaurant"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you want to close the restaurant for the day? Any existing
      reservations for this date will not be canceled."
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Confirm"
          onClick={onConfirm}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  ) : null;
