import cx from 'classnames';
import { ISOTimeTo12HourTime } from '@utils/time';
import { formatWeekDays } from '@utils/weekDayFormatters';
import typography from '~styles/typography.scss';
import { type PricePointFormData } from '../types';
import styles from './PricePointItem.scss';

export const PricePointItem = ({
  startTime,
  endTime,
  price,
  activeDays,
}: PricePointFormData) => (
  <div className={styles.doodadContainer}>
    <div className={typography.h7m}>
      {`${ISOTimeTo12HourTime(startTime)}–${ISOTimeTo12HourTime(endTime)}`}
    </div>
    <div className={cx(styles.price, typography.h7)}>{`$${price}`}</div>
    <div className={cx(styles.activeDays, typography.t1)}>
      {formatWeekDays(activeDays)}
    </div>
  </div>
);
