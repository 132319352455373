// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationGuestSheet__container___HUhVr{display:flex;flex-direction:column;gap:16px}.ReservationGuestSheet__container___HUhVr section{background-color:var(--panelBlue);border-radius:8px;padding:1rem}.ReservationGuestSheet__floorplan___ChLr0{height:320px;width:100%;background-color:var(--panelBlue);border-radius:8px;padding:1rem}.ReservationGuestSheet__actions___oM8fY{display:flex;row-gap:8px;align-items:stretch;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/reservationGuestSheet/ReservationGuestSheet.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,kDACE,iCAAA,CACA,iBAAA,CACA,YAAA,CAIJ,0CACE,YAAA,CACA,UAAA,CACA,iCAAA,CACA,iBAAA,CACA,YAAA,CAGF,wCACE,YAAA,CACA,WAAA,CACA,mBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationGuestSheet__container___HUhVr",
	"floorplan": "ReservationGuestSheet__floorplan___ChLr0",
	"actions": "ReservationGuestSheet__actions___oM8fY"
};
export default ___CSS_LOADER_EXPORT___;
