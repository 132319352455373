import { differenceBy } from 'lodash-es';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormDateRange } from '@components/formInputs/ControlledFormDateRange';
import { ControlledFormDayRadio } from '@components/formInputs/ControlledFormDayRadio';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormTimeRange } from '@components/formInputs/ControlledFormTimeRange';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { useIsOpenWithData } from '@shared/hooks/useIsOpenWithData';
import { reportAppError } from '@shared/reportAppError';
import { DESKTOP1280 } from '@shared/styles/breakpoints';
import { type UpdatedServiceWindow, updateServiceWindows } from '../apiHelpers';
import { ConfirmRemoveModal } from './ConfirmRemoveModal';
import styles from './ServiceWindowsForm.scss';
import { UpdateServiceWindowsWarningsModal } from './UpdateServiceWindowsWarningsModal';

interface ServiceWindowsFormData {
  serviceWindows: {
    id?: string;
    startDate: string;
    endDate: string | null;
    repeat: string[];
    name: string;
    startTime: string;
    endTime: string;
  }[];
}

export interface ServiceWindowsFormProps {
  serviceWindows: {
    id: string;
    startDate: string;
    endDate: string | null;
    repeat: string[];
    name: string;
    startTime: string;
    endTime: string;
  }[];
  exitEditMode: () => void;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

const DEFAULT_SERVICE_WINDOW = {
  startDate: '',
  endDate: '',
  repeat: [],
  name: '',
  startTime: '',
  endTime: '',
};

export const ServiceWindowsForm = ({
  serviceWindows,
  exitEditMode,
  refreshRestaurant,
  restaurantId,
}: ServiceWindowsFormProps) => {
  const { control, handleSubmit, watch } = useForm<ServiceWindowsFormData>({
    defaultValues: {
      serviceWindows,
    },
  });
  const {
    fields,
    append: addServiceWindow,
    remove: removeServiceWindow,
  } = useFieldArray({
    control,
    name: 'serviceWindows',
    // this is needed bc the library maintainer decided it was a good idea to use the key name 'id' for this as a default
    keyName: 'key',
  });
  const {
    open: openConfirmRemoveModal,
    close: closeConfirmRemoveModal,
    isOpen: isConfirmRemoveModalOpen,
    data: confirmRemovalModalData,
  } = useIsOpenWithData<{ index: number }>();

  const {
    open: openUpdateWarningsModal,
    close: closeUpdateWarningsModal,
    isOpen: isUpdateWarningsModalOpen,
    data: warningModalData,
  } = useIsOpenWithData<{ warnings: string[] }>();

  const handleOnSubmit = ({ ignoreWarnings }: { ignoreWarnings: boolean }) =>
    handleSubmit(async (values) => {
      const {
        createdServiceWindows,
        updatedServiceWindows,
        deletedServiceWindowIds,
      } = buildServiceWindowsPayload(serviceWindows, values);

      try {
        const { warnings } = await updateServiceWindows(restaurantId, {
          ignoreWarnings,
          createdServiceWindows,
          updatedServiceWindows,
          deletedServiceWindowIds,
        });
        if (!ignoreWarnings && warnings.length) {
          openUpdateWarningsModal({ warnings });
          return;
        }
        await refreshRestaurant();
        successToast({ message: 'Service Windows successfully updated' });
        exitEditMode();
      } catch (error) {
        errorToast({ message: 'Failed to update Service Windows' });
        reportAppError(error);
      }
    });

  const handleOnConfirmRemove = () => {
    if (!confirmRemovalModalData) return;

    removeServiceWindow(confirmRemovalModalData.index);
    closeConfirmRemoveModal();
  };
  const handleClickOnRemove = (index: number) => (): void => {
    openConfirmRemoveModal({ index });
  };

  const layoutVariant = useMediaQuery({ minWidth: DESKTOP1280 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  return (
    <form
      onSubmit={handleOnSubmit({ ignoreWarnings: false })}
      className={styles.form}
    >
      {fields.map(({ key }, index) => (
        <div key={key} className={styles.panel}>
          <ControlledFormInput
            control={control}
            label="Name"
            name={`serviceWindows.${index}.name`}
            rules={{
              required: true,
            }}
            type="text"
            variant={layoutVariant}
          />
          <ControlledFormDateRange
            control={control}
            fromProps={{
              label: 'Start Date',
              name: `serviceWindows.${index}.startDate`,
              rules: { required: true },
            }}
            toProps={{
              label: 'End Date',
              name: `serviceWindows.${index}.endDate`,
              rules: {
                min: {
                  value: watch(`serviceWindows.${index}.startDate`),
                  message: 'End Date must be on or after the Start Date.',
                },
              },
            }}
            groupLabel="Start and End"
            variant={layoutVariant}
          />
          <ControlledFormTimeRange
            control={control}
            groupLabel="Time Frame"
            fromProps={{
              label: 'Start Time',
              name: `serviceWindows.${index}.startTime`,
              rules: { required: true },
            }}
            toProps={{
              label: 'End Time',
              name: `serviceWindows.${index}.endTime`,
              rules: {
                required: true,
                validate: {
                  isGreaterThanOrEqualToStartTime: (
                    endTime: string,
                  ): string | boolean =>
                    watch(`serviceWindows.${index}.startTime`) &&
                    endTime >= watch(`serviceWindows.${index}.startTime`)
                      ? true
                      : 'End Time must be on or after the Start Time.',
                },
              },
            }}
            variant={layoutVariant}
          />
          <ControlledFormDayRadio
            control={control}
            label="Repeats On"
            name={`serviceWindows.${index}.repeat`}
            rules={{
              required: true,
            }}
          />
          <IconButton
            className={styles.removeIconButton}
            ariaLabel="Delete Window"
            showLabel
            iconName="trash"
            onClick={handleClickOnRemove(index)}
          />
        </div>
      ))}
      <button
        className={styles.addButton}
        type="button"
        onClick={() => addServiceWindow(DEFAULT_SERVICE_WINDOW)}
      >
        Add Service Window
        <Icon name="plus" />
      </button>
      <Button
        label="Save Changes"
        variant={ButtonVariants.Primary}
        type="submit"
      />
      <Button
        label="Discard Edits"
        variant={ButtonVariants.Tertiary}
        onClick={exitEditMode}
      />
      <UpdateServiceWindowsWarningsModal
        closeModal={closeUpdateWarningsModal}
        handleOnConfirm={handleOnSubmit({ ignoreWarnings: true })}
        isOpen={isUpdateWarningsModalOpen}
        warnings={warningModalData?.warnings || []}
      />
      <ConfirmRemoveModal
        closeModal={closeConfirmRemoveModal}
        handleOnConfirmRemove={handleOnConfirmRemove}
        isOpen={isConfirmRemoveModalOpen}
      />
    </form>
  );
};

const buildServiceWindowsPayload = (
  serviceWindows: ServiceWindowsFormProps['serviceWindows'],
  formData: ServiceWindowsFormData,
) => {
  const parsedServiceWindows = formData.serviceWindows.map((serviceWindow) => ({
    ...serviceWindow,
    endDate: serviceWindow.endDate || null,
  }));
  const createdServiceWindows = parsedServiceWindows.filter(
    (window) => !window.id,
  );
  const updatedServiceWindows = parsedServiceWindows.filter(
    (window) => !!window.id,
  ) as UpdatedServiceWindow[];
  const deletedServiceWindowIds = differenceBy(
    serviceWindows,
    updatedServiceWindows,
    'id',
  ).map(({ id }) => id);

  return {
    createdServiceWindows,
    updatedServiceWindows,
    deletedServiceWindowIds,
  };
};
