import cx from 'classnames';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './StatusPill.scss';

export const enum Status {
  Canceled = 'canceled',
  NoShow = 'no-show',
  HostBooked = 'host booked',
  Stranded = 'stranded',
  Overtime = 'overtime',
  Upcoming = 'upcoming',
}

const StatusToIconMap: Record<Status, AnyIcon> = {
  [Status.Canceled]: 'xCircle',
  [Status.NoShow]: 'accountCircleOff',
  [Status.HostBooked]: 'roomService',
  [Status.Stranded]: 'alertTriangle',
  [Status.Overtime]: 'clock',
  [Status.Upcoming]: 'hourglass',
};

interface StatusPillProps {
  status: Status;
}

export const StatusPill = ({ status }: StatusPillProps) => {
  const containerClassNames = cx({
    [styles.canceledPill]: status === Status.Canceled,
    [styles.noShowPill]: status === Status.NoShow,
    [styles.hostBookedPill]: status === Status.HostBooked,
    [styles.strandedPill]: status === Status.Stranded,
    [styles.overtimePill]: status === Status.Overtime,
    [styles.upcomingPill]: status === Status.Upcoming,
  });

  return (
    <div className={containerClassNames}>
      <Icon name={StatusToIconMap[status]} />
      <span className={typography.t1}>&nbsp;{status}</span>
    </div>
  );
};
