import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { useAuth } from 'restaurantAdmin/context/AuthContext';

interface SignOutModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export const SignOutModal = ({ closeModal, isOpen }: SignOutModalProps) => {
  const { logout } = useAuth();

  if (!isOpen) return null;

  const handleSignOutOnClick = () => {
    logout(() => {
      closeModal();
    });
  };

  return (
    <Modal
      ariaLabel="Sign out"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you wish to sign out?"
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Sign Out"
          onClick={handleSignOutOnClick}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
