import { type Control } from 'react-hook-form';
import { ControlledFormCurrencyInput } from '@components/formInputs/ControlledFormCurrencyInput';
import typography from '~styles/typography.scss';
import { type ListingFormData } from '../types';
import styles from './BasePriceFieldSet.scss';

interface BasePriceFieldProps {
  control: Control<ListingFormData>;
}

export const BasePriceFieldSet = ({ control }: BasePriceFieldProps) => (
  <fieldset className={styles.container}>
    <ControlledFormCurrencyInput
      control={control}
      label="Base Price"
      name="price"
      rules={{
        min: {
          message: 'Base listing price cannot be less than $0',
          value: 0,
        },
        max: {
          message: 'Base listing price cannot exceed $999999',
          value: 999999,
        },
        required: true,
      }}
    />
    <p className={typography.t2}>
      This will be the listing price in times where there is no active price
      point.
    </p>
  </fieldset>
);
