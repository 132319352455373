import cx from 'classnames';
import { useEffect, useId, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { reportAppError } from '@shared/reportAppError';
import { Spinner } from 'shared/components/Spinner';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../context/useRestaurant';
import { PageContent } from '../layout/PageContent';
import { PageHeader } from '../layout/PageHeader';
import type { GuestReservation, GuestsApiGuest } from './apiHelpers';
import { getGuests } from './apiHelpers';
import styles from './GuestBookPage.scss';
import { GuestDetails } from './GuestDetails';
import { GuestReservationDetailsDrawer } from './GuestReservationDetailsDrawer';
import { GuestSearchBar } from './GuestSearchBar';

const AllGuestsLoadedIndicator = () => (
  <span className={styles.endMessage}>(つ▀¯▀)つ No more guests</span>
);

const LoadingSpinner = () => (
  <span className={styles.spinnerContainer}>
    <Spinner className={styles.spinner} />
  </span>
);

const GUEST_REQUEST_LIMIT = 50;

export const GuestBookPage = () => {
  const { id: restaurantId } = useRestaurant();
  const [guests, setGuests] = useState<GuestsApiGuest[]>([]);
  const [selectedGuest, setSelectedGuest] = useState<GuestsApiGuest>();
  const [selectedGuestReservation, setSelectedGuestReservation] =
    useState<GuestReservation>();
  const [offset, setOffset] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [hasMoreGuests, setHasMoreGuests] = useState(true);
  const [vipOnly, setVipOnly] = useState(false);
  const guestListContainerId = useId();

  const fetchNextSetOfGuests = async () => {
    const newOffset = offset + GUEST_REQUEST_LIMIT;

    setOffset(newOffset);

    let guestsPage;
    try {
      guestsPage = await getGuests({
        limit: GUEST_REQUEST_LIMIT,
        offset: newOffset,
        restaurantId,
        search: searchText,
        vipOnly,
      });
    } catch (e) {
      reportAppError(e);
      return;
    }

    if (guestsPage.length < GUEST_REQUEST_LIMIT) {
      setHasMoreGuests(false);
    }

    setGuests([...guests, ...guestsPage]);
  };

  useEffect(() => {
    const fetchGuests = async () => {
      let guestsPage;
      try {
        guestsPage = await getGuests({
          restaurantId,
          limit: GUEST_REQUEST_LIMIT,
          offset,
          search: searchText,
          vipOnly,
        });
      } catch (e) {
        reportAppError(e);
        return;
      }
      if (guestsPage.length < GUEST_REQUEST_LIMIT) {
        setHasMoreGuests(false);
      }

      setGuests(guestsPage);
    };

    void fetchGuests();
  }, [searchText, vipOnly]);

  const handleGuestReservationClick = (reservation: GuestReservation) => {
    setSelectedGuestReservation(reservation);
  };
  const handleGuestReservationDetailsDrawerClose = () => {
    setSelectedGuestReservation(undefined);
  };

  const handleSearchSubmit = (search: string) => {
    setSearchText(search);
  };

  const handleVipToggle = () => {
    setVipOnly(!vipOnly);
  };

  const vipToggleStyles = cx(styles.vipToggle, {
    [styles.vipOnly]: vipOnly,
  });

  return (
    <>
      <PageHeader title="Guest Book" />
      <PageContent className={styles.pageContent}>
        <div className={styles.searchBar}>
          <GuestSearchBar onSearchSubmit={handleSearchSubmit} />
          <IconButton
            className={vipToggleStyles}
            ariaLabel="filter VIP guests"
            iconFill="var(--gold)"
            iconName="crown"
            onClick={handleVipToggle}
          />
        </div>
        <div className={styles.container}>
          <div
            id={guestListContainerId}
            data-testid="guest-list-container"
            className={styles.guestList}
          >
            {!!guests.length && (
              <ul data-testid="guest-list">
                <InfiniteScroll
                  dataLength={guests.length}
                  hasMore={hasMoreGuests}
                  loader={<LoadingSpinner />}
                  next={fetchNextSetOfGuests}
                  scrollableTarget={guestListContainerId}
                  style={{ overflow: 'unset' }}
                  endMessage={<AllGuestsLoadedIndicator />}
                >
                  {guests.map((guest) => (
                    <li
                      key={guest.id}
                      className={cx(styles.guestListItem, {
                        [styles.guestListItemSelected]:
                          selectedGuest?.id === guest.id,
                      })}
                    >
                      <button
                        className={cx(typography.h5, styles.guestButton)}
                        onClick={() => {
                          setSelectedGuest(guest);
                        }}
                      >
                        {guest.isVip && (
                          <Icon
                            ariaLabel="Is a VIP"
                            className={styles.vipIcon}
                            name="crown"
                            role="img"
                            fill="var(--gold)"
                          />
                        )}
                        {guest.lastName}, {guest.firstName}
                      </button>
                    </li>
                  ))}
                </InfiniteScroll>
              </ul>
            )}
          </div>
          <GuestDetails
            guest={selectedGuest}
            handleGuestReservationClick={handleGuestReservationClick}
            onClose={() => {
              setSelectedGuest(undefined);
            }}
            selectedGuestReservationId={selectedGuestReservation?.id}
          />
        </div>
        {selectedGuestReservation && (
          <GuestReservationDetailsDrawer
            onClose={handleGuestReservationDetailsDrawerClose}
            guestReservation={selectedGuestReservation}
          />
        )}
      </PageContent>
    </>
  );
};
