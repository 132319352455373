import { Status, StatusPill } from '../statusPill/StatusPill';
import styles from './ReservationGuestSheetStatuses.scss';

export interface ReservationGuestSheetStatusesProps {
  isCanceled?: boolean;
  isHostBooked?: boolean;
  isNoShow?: boolean;
  isStranded?: boolean;
  showHostBooked?: boolean;
}

export const ReservationGuestSheetStatuses = ({
  isCanceled = false,
  isHostBooked = false,
  isNoShow = false,
  isStranded = false,
  showHostBooked = false,
}: ReservationGuestSheetStatusesProps) => {
  const actuallyShowHostBooked = isHostBooked && showHostBooked;
  const hasStatus =
    isCanceled || actuallyShowHostBooked || isNoShow || isStranded;

  return hasStatus ? (
    <section aria-label="status" className={styles.container}>
      {isCanceled && <StatusPill status={Status.Canceled} />}
      {actuallyShowHostBooked && <StatusPill status={Status.HostBooked} />}
      {isNoShow && <StatusPill status={Status.NoShow} />}
      {isStranded && <StatusPill status={Status.Stranded} />}
    </section>
  ) : null;
};
