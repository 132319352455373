import cx from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { FloorPlanBackgroundImg } from '@components/floorPlan/FloorPlanBackgroundImg';
import { ZoomControls } from '@components/floorPlan/ZoomControls';
import { useLoadImage } from '@shared/hooks/useLoadImage';
import typography from '~styles/typography.scss';
import styles from './FloorPlan.scss';

const DEFAULT_INITIAL_TABLE_ICON_SCALE = 1;
const DEFAULT_SIZE = 0;
const REFRESH_RATE = 0;

export const MINIMUM_SCALE = 1;
export const MAXIMUM_SCALE = 6;

export type FloorPlanTablesRenderer = (tableIconScale: number) => ReactNode;

export interface FloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  floorPlanTablesRenderer: FloorPlanTablesRenderer;
  handleBackgroundOnClick?: () => void;
  disableControls?: boolean;
  testId?: string;
}

export const FloorPlan = forwardRef<ReactZoomPanPinchRef, FloorPlanProps>(
  (
    {
      backgroundSrc,
      className,
      floorPlanTablesRenderer,
      handleBackgroundOnClick,
      disableControls = false,
      testId = 'floor-plan',
    },
    zoomRef,
  ) => {
    const [mouseDownTime, setMouseDownTime] = useState<number>(Infinity);
    const { initialHeight, initialWidth, isLoaded } = useLoadImage(
      backgroundSrc || '',
    );
    const {
      height = DEFAULT_SIZE,
      ref,
      width = DEFAULT_SIZE,
    } = useResizeDetector({
      refreshMode: 'debounce',
      refreshRate: REFRESH_RATE,
    });
    const [tableIconScale, setTableIconScale] = useState(
      DEFAULT_INITIAL_TABLE_ICON_SCALE,
    );

    const noFloorPlanAvailable = !backgroundSrc;

    const floorPlanContainerClassNames = cx({
      [className || '']: className,
      [styles.floorPlanContainer]: true,
      [styles.floorPlan]: true,
      [styles.floorPlanLoaded]: isLoaded,
    });

    if (noFloorPlanAvailable) {
      return (
        <div className={styles.noFloorPlanContainer} data-testid="floor-plan">
          <p className={typography.d4}>No floor plan available.</p>
        </div>
      );
    }

    const DELAY = 200;
    const handleOnMouseDown = () => {
      setMouseDownTime(Date.now());
    };

    const handleOnMouseUp = () => {
      if (Date.now() - mouseDownTime < DELAY) {
        handleBackgroundOnClick?.();
        setMouseDownTime(Infinity);
      }
    };

    const renderFloorPlanTables = () => {
      if (!Number.isFinite(tableIconScale)) return null;

      return floorPlanTablesRenderer(tableIconScale);
    };

    return (
      <div
        className={floorPlanContainerClassNames}
        data-testid={testId}
        ref={ref}
      >
        <TransformWrapper
          ref={zoomRef}
          wheel={{
            smoothStep: 0.01,
            wheelDisabled: true,
          }}
          panning={{
            velocityDisabled: true,
          }}
          minScale={MINIMUM_SCALE}
          maxScale={MAXIMUM_SCALE}
          zoomAnimation={{
            size: 0.25,
          }}
          disabled={disableControls}
        >
          {!disableControls && (
            <ZoomControls
              minScale={MINIMUM_SCALE}
              maxScale={MAXIMUM_SCALE}
              scale={MINIMUM_SCALE}
            />
          )}
          <TransformComponent>
            <button
              aria-label="floor plan background"
              className={styles.buttonWithNoStyles}
              onMouseDown={handleOnMouseDown}
              onMouseUp={handleOnMouseUp}
              type="button"
            >
              <FloorPlanBackgroundImg
                backgroundSrc={backgroundSrc}
                height={height}
                initialHeight={initialHeight}
                initialWidth={initialWidth}
                setTableIconScale={setTableIconScale}
                width={width}
              />
            </button>
            {renderFloorPlanTables()}
          </TransformComponent>
        </TransformWrapper>
      </div>
    );
  },
);

FloorPlan.displayName = 'FloorPlan';
