// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Combobox__comboBox___w5L1x{align-items:center;background-color:var(--darkGrey100);border-radius:6px;color:var(--panelBlue);display:flex;flex-direction:row;font-size:14px;gap:.5rem;min-height:3rem;padding:6px 12px 6px 6px}.Combobox__comboBox___w5L1x *{font-size:inherit}.Combobox__comboBox___w5L1x .Combobox__values___pD8oZ{display:flex;flex-flow:row wrap;flex:1;gap:6px}.Combobox__comboBox___w5L1x .Combobox__input___nHsB2{background:none;border:none;color:inherit;flex:1 0 4rem;min-width:0}.Combobox__comboBox___w5L1x .Combobox__mainButton___xDzbe{height:1.5rem;width:1.5rem}.Combobox__comboBox___w5L1x .Combobox__clearIcon___lci8X,.Combobox__comboBox___w5L1x .Combobox__popupIndicatorIcon___IisMZ{stroke:var(--panelBlue)}.Combobox__comboBox___w5L1x .Combobox__clearIcon___lci8X{height:1.25rem;width:1.25rem}.Combobox__comboBox___w5L1x .Combobox__popupIndicatorIcon___IisMZ{height:1.5rem;transform:rotate(-90deg);width:1.5rem}.Combobox__comboBox___w5L1x .Combobox__button___DrdqT{background:none;border:none;cursor:pointer;font-size:0;padding:0}.Combobox__popup___IX3KY{box-shadow:0 16px 16px 0 rgba(39,47,62,.4)}", "",{"version":3,"sources":["webpack://./src/shared/components/combobox/Combobox.scss"],"names":[],"mappings":"AAAA,4BACE,kBAAA,CACA,mCAAA,CACA,iBAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,SAAA,CACA,eAAA,CACA,wBAAA,CAEA,8BACE,iBAAA,CAGF,sDACE,YAAA,CACA,kBAAA,CACA,MAAA,CACA,OAAA,CAGF,qDACE,eAAA,CACA,WAAA,CACA,aAAA,CACA,aAAA,CACA,WAAA,CAGF,0DACE,aAAA,CACA,YAAA,CAGF,2HAEE,uBAAA,CAGF,yDACE,cAAA,CACA,aAAA,CAGF,kEACE,aAAA,CACA,wBAAA,CACA,YAAA,CAGF,sDACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CACA,SAAA,CAIJ,yBACE,0CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comboBox": "Combobox__comboBox___w5L1x",
	"values": "Combobox__values___pD8oZ",
	"input": "Combobox__input___nHsB2",
	"mainButton": "Combobox__mainButton___xDzbe",
	"clearIcon": "Combobox__clearIcon___lci8X",
	"popupIndicatorIcon": "Combobox__popupIndicatorIcon___IisMZ",
	"button": "Combobox__button___DrdqT",
	"popup": "Combobox__popup___IX3KY"
};
export default ___CSS_LOADER_EXPORT___;
