// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestReservations__container___O9jtQ{background-color:var(--primary400);border-radius:.75rem;display:flex;flex-direction:column;overflow-y:auto;padding:.5rem 0 .5rem .375rem;width:100%}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/guestBook/GuestReservations.scss"],"names":[],"mappings":"AAAA,sCACE,kCAAA,CACA,oBAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,6BAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestReservations__container___O9jtQ"
};
export default ___CSS_LOADER_EXPORT___;
