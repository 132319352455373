import { errorToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from '../../context/useRestaurant';
import { getListings } from './apiHelpers';

export const useListings = (isDraft: boolean) => {
  const { id: restaurantId } = useRestaurant();

  const {
    data: listings = [],
    isPending: isLoading,
    rerun: refreshListings,
  } = useAbortEffect(
    {
      effect: (signal) => getListings(restaurantId, isDraft, signal),
      onError: (e) => {
        errorToast({ message: 'Error loading listings' });
        reportAppError(e);
      },
    },
    [restaurantId, isDraft],
  );

  return {
    listings,
    isLoading,
    refreshListings,
  };
};
