// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingsPage__content___MUOZy{display:flex;gap:2.5rem}.ListingsPage__content___MUOZy .ListingsPage__displayPanel___wPXlp{flex:1.8 1 0;flex-direction:column;overflow:auto}.ListingsPage__content___MUOZy .ListingsPage__displayPanel___wPXlp.ListingsPage__alignStart___vOUy1{align-self:flex-start}.ListingsPage__content___MUOZy .ListingsPage__displayPanel___wPXlp .ListingsPage__calendar___nHJJg{height:100%}.ListingsPage__content___MUOZy .ListingsPage__sidePanel___V7S_1{flex:1 1 0}.ListingsPage__content___MUOZy .ListingsPage__sidePanel___V7S_1 .ListingsPage__tabs___Pnz4I{display:flex;flex-direction:column;height:100%}.ListingsPage__content___MUOZy .ListingsPage__sidePanel___V7S_1 .ListingsPage__tabs___Pnz4I .ListingsPage__tabBar___oDbNQ{display:flex;align-items:center;border-bottom:1px solid var(--hairline);margin-bottom:1rem}.ListingsPage__content___MUOZy .ListingsPage__sidePanel___V7S_1 .ListingsPage__tabs___Pnz4I .ListingsPage__tabBar___oDbNQ .ListingsPage__tab___vjHVi{padding:0 1.5rem 1rem;color:var(--white);text-transform:capitalize;display:flex;align-items:center;justify-content:center;border-bottom:2px solid rgba(0,0,0,0)}.ListingsPage__content___MUOZy .ListingsPage__sidePanel___V7S_1 .ListingsPage__tabs___Pnz4I .ListingsPage__tabBar___oDbNQ .ListingsPage__activeTab___K6BR5{border-bottom:2px solid var(--accent300)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/ListingsPage.scss"],"names":[],"mappings":"AAEA,+BACE,YAAA,CACA,UAAA,CAEA,mEACE,YAAA,CACA,qBAAA,CACA,aAAA,CAEA,oGACE,qBAAA,CAGF,mGACE,WAAA,CAIJ,gEACE,UAAA,CAEA,4FACE,YAAA,CACA,qBAAA,CACA,WAAA,CAEA,0HACE,YAAA,CACA,kBAAA,CACA,uCAAA,CACA,kBAAA,CAEA,qJACE,qBAAA,CACA,kBAAA,CACA,yBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qCAAA,CAGF,2JACE,wCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "ListingsPage__content___MUOZy",
	"displayPanel": "ListingsPage__displayPanel___wPXlp",
	"alignStart": "ListingsPage__alignStart___vOUy1",
	"calendar": "ListingsPage__calendar___nHJJg",
	"sidePanel": "ListingsPage__sidePanel___V7S_1",
	"tabs": "ListingsPage__tabs___Pnz4I",
	"tabBar": "ListingsPage__tabBar___oDbNQ",
	"tab": "ListingsPage__tab___vjHVi",
	"activeTab": "ListingsPage__activeTab___K6BR5"
};
export default ___CSS_LOADER_EXPORT___;
