import { TZDate } from '@date-fns/tz';
import { StripeElements } from '@components/stripe/StripeElements';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useAdminFloorPlan } from 'restaurantAdmin/hooks/useAdminFloorPlan';
import { useAvailabilityDrawerContext } from 'restaurantAdmin/reservations/concierge/state/AvailabilityDrawerContext';
import { DateAndGuestCountFilterPill } from '../../components/dateAndGuestCountFilterPill/DateAndGuestCountFilterPill';
import { TimeFilterPill } from '../../components/timeFilterPill/TimeFilterPill';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { ConciergeAdminAvailability } from './ConciergeAdminAvailability';
import { useConciergeAvailabilityContext } from './ConciergeAvailabilityContext';
import { ConciergeDrawer } from './ConciergeDrawer';
import styles from './ConciergePage.scss';
import { InteractiveConciergeFloorPlan } from './InteractiveConciergeFloorPlan';
import { ReserveButton } from './ReserveButton';

export const ConciergePage = () => {
  const { isOpen: isAvailabilityDrawerOpen, openDrawer } =
    useAvailabilityDrawerContext();
  const { floorPlan, isLoading: isFloorPlanLoading } = useAdminFloorPlan(true);
  const { timezone } = useRestaurant();
  const {
    availableTimes,
    clearSelectedAvailability,
    isLoading: isAvailabilitiesLoading,
    selectedAvailability,
    selectedDate,
    selectedGuestCount,
    selectedTime,
    setSelectedDate,
    setSelectedGuestCount,
    setSelectedTime,
  } = useConciergeAvailabilityContext();
  const {
    close: closeGuestCountAndDateFilter,
    isOpen: isGuestCountAndDateFilterOpen,
    open: openGuestCountAndDateFilter,
  } = useIsOpen();
  const {
    close: closeTimeFilter,
    isOpen: isTimeFilterOpen,
    open: openTimeFilter,
  } = useIsOpen();
  const isLoading = isFloorPlanLoading || isAvailabilitiesLoading;

  const handleReserveClick = () => {
    if (selectedAvailability) {
      openDrawer({
        date: toISODateFormat(selectedDate),
        guestCount: selectedGuestCount,
        listingPrice: selectedAvailability.listing.price,
        listingId: selectedAvailability.listing.id,
        time: selectedAvailability.time,
      });
    }
  };

  const renderContent = () => {
    if (isLoading) return null;
    return (
      <>
        <div className={styles.filters}>
          <DateAndGuestCountFilterPill
            closeFilter={closeGuestCountAndDateFilter}
            isOpenFilter={isGuestCountAndDateFilterOpen}
            onFilterApply={() => clearSelectedAvailability()}
            openFilter={() => {
              closeTimeFilter();
              openGuestCountAndDateFilter();
            }}
            selectedDate={selectedDate}
            selectedGuestCount={selectedGuestCount}
            setSelectedDate={setSelectedDate}
            setSelectedGuestCount={setSelectedGuestCount}
            minimumDate={TZDate.tz(timezone)}
          />
          <TimeFilterPill
            availableTimes={availableTimes}
            closeFilter={closeTimeFilter}
            isOpenFilter={isTimeFilterOpen}
            onSelectTime={setSelectedTime}
            openFilter={() => {
              closeGuestCountAndDateFilter();
              openTimeFilter();
            }}
            selectedTime={selectedTime}
          />
        </div>
        <div className={styles.content}>
          {!isFloorPlanLoading && (
            <div className={styles.floorPlanContainer}>
              <InteractiveConciergeFloorPlan
                backgroundSrc={floorPlan.backgroundSrc}
                tables={floorPlan.floorPlanTables}
              />
            </div>
          )}
          <ConciergeAdminAvailability
            availabilityItemAction={
              <ReserveButton handleClick={handleReserveClick} />
            }
          />
        </div>
        {isAvailabilityDrawerOpen && <ConciergeDrawer />}
      </>
    );
  };

  return (
    <StripeElements>
      <PageHeader category="Reservations" title="Concierge" />
      <PageContent className={styles.pageContent}>
        {renderContent()}
      </PageContent>
    </StripeElements>
  );
};
