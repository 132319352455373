import cx from 'classnames';
import typography from '~styles/typography.scss';
import styles from './InviteExpiredPage.scss';

export const InviteExpiredPage = () => (
  <main className={styles.pageContainer}>
    <div className={styles.card}>
      <h1 className={cx(typography.h4, styles.header)}>Link Expired</h1>
      <span>
        The account creation link you received has expired.
        <br />
        Please contact your employer.
      </span>
    </div>
  </main>
);
