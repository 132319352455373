import cx from 'classnames';
import { useRef } from 'react';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import { interactiveFloorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { Icon } from '@components/icon/Icon';
import { WideView } from '@components/wideView/WideView';
import { useResetZoom } from '@shared/hooks/useResetZoom';
import type { FloorPlanTableData } from '@shared/types/floorPlans';
import typography from '~styles/typography.scss';
import { useConciergeAvailabilityContext } from './ConciergeAvailabilityContext';
import styles from './InteractiveConciergeFloorPlan.scss';

export const HAS_SEEN_PULSING_TABLE_GUIDANCE =
  'peak-has-seen-pulsing-table-guidance';

interface InteractiveConciergeFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  tables: FloorPlanTableData[];
}

export const InteractiveConciergeFloorPlan = ({
  backgroundSrc,
  className,
  tables,
}: InteractiveConciergeFloorPlanProps) => {
  const hasSeenPulsingTableGuidance =
    localStorage.getItem(HAS_SEEN_PULSING_TABLE_GUIDANCE) === 'true';
  const zoomRef = useRef<ReactZoomPanPinchRef | null>(null);
  const {
    clearSelectedAvailability,
    selectedAvailability,
    selectedFloorPlanListingIds,
    setSelectedFloorPlanListingIds,
    expandedAvailabilityListingIds,
  } = useConciergeAvailabilityContext();

  useResetZoom(zoomRef, [selectedAvailability], !!selectedAvailability);

  const highlightedListingIds = selectedAvailability
    ? [selectedAvailability.listing.id]
    : selectedFloorPlanListingIds;

  const calculateIsSelectable = (floorPlanTable: FloorPlanTableData) =>
    floorPlanTable.listings.some((listing) =>
      expandedAvailabilityListingIds.includes(listing.id),
    );

  const calculateIsHighlighted = (
    floorPlanTable: FloorPlanTableData,
  ): boolean =>
    calculateIsSelectable(floorPlanTable) &&
    floorPlanTable.listings
      .filter((listing) => expandedAvailabilityListingIds.includes(listing.id))
      .some((listing) => highlightedListingIds.includes(listing.id));

  const handleTableOnClick = (floorPlanTable: FloorPlanTableData) => {
    if (calculateIsSelectable(floorPlanTable)) {
      clearSelectedAvailability();
      setSelectedFloorPlanListingIds(
        floorPlanTable.listings.map(({ id }) => id),
      );

      if (!hasSeenPulsingTableGuidance) {
        window.localStorage.setItem(HAS_SEEN_PULSING_TABLE_GUIDANCE, 'true');
      }
    }
  };

  const clearFloorPlanSelection = () => setSelectedFloorPlanListingIds([]);

  const generateTableWithPulse = () => {
    const firstSelectableTable = tables.find((table) =>
      calculateIsSelectable(table),
    );

    let tableWithPulse = tables;
    if (firstSelectableTable && !hasSeenPulsingTableGuidance) {
      tableWithPulse = tables.map((table) => {
        if (table.id === firstSelectableTable.id) {
          return { ...table, pulse: true };
        }
        return table;
      });
    }
    return tableWithPulse;
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer =
    interactiveFloorPlanTablesRendererFactory<FloorPlanTableData>({
      calculateIsHighlighted,
      calculateIsSelectable,
      handleTableOnClick,
      tables: generateTableWithPulse(),
    });

  return (
    <div className={styles.floorPlanContainer}>
      <div className={styles.floorPlan}>
        <FloorPlan
          backgroundSrc={backgroundSrc}
          className={className}
          floorPlanTablesRenderer={floorPlanTablesRenderer}
          handleBackgroundOnClick={clearFloorPlanSelection}
          ref={zoomRef}
        />
      </div>
      {!!selectedFloorPlanListingIds.length && (
        <WideView
          narrowElement={
            <button
              aria-label="Clear floor plan selection"
              className={styles.clearSelectionButton}
              data-testid="clear-floor-plan-selection-button-narrow"
              onClick={clearFloorPlanSelection}
            >
              <Icon className={styles.resetIcon} name="reset" />
            </button>
          }
          wideElement={
            <button
              className={cx(typography.t1, styles.clearSelectionButton)}
              data-testid="clear-floor-plan-selection-button-wide"
              onClick={clearFloorPlanSelection}
            >
              <Icon className={styles.resetIcon} name="reset" /> Clear floor
              plan selection
            </button>
          }
        />
      )}
    </div>
  );
};
