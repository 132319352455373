// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PricePointFieldSet__container___drFTL{display:flex;flex-direction:column;row-gap:8px;width:100%}.PricePointFieldSet__container___drFTL .PricePointFieldSet__field___XlKCf{background-color:rgba(0,0,0,0);border:0;cursor:pointer;position:relative;padding:0}.PricePointFieldSet__container___drFTL .PricePointFieldSet__field___XlKCf .PricePointFieldSet__overlapIcon___BmMxd{position:absolute;top:4px;right:4px;width:1rem;stroke:var(--pink)}.PricePointFieldSet__container___drFTL .PricePointFieldSet__addButton___e1loR{border:1px dashed var(--white30);border-radius:6px;background-color:rgba(0,0,0,0);display:flex;flex-direction:row;justify-content:space-between;align-items:center;padding:16px 24px;color:var(--white30);font-size:14px;font-weight:700;line-height:1.1429;width:100%;cursor:pointer}.PricePointFieldSet__container___drFTL .PricePointFieldSet__addButton___e1loR:hover:enabled{border:1px solid var(--darkGrey100)}.PricePointFieldSet__container___drFTL .PricePointFieldSet__addButton___e1loR svg{width:1.5rem;stroke:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/PricePointFieldSet.scss"],"names":[],"mappings":"AAAA,uCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,UAAA,CAEA,0EACE,8BAAA,CACA,QAAA,CACA,cAAA,CACA,iBAAA,CACA,SAAA,CAEA,mHACE,iBAAA,CACA,OAAA,CACA,SAAA,CACA,UAAA,CACA,kBAAA,CAIJ,8EACE,gCAAA,CACA,iBAAA,CACA,8BAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,oBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA,CACA,cAAA,CAEA,4FACE,mCAAA,CAGF,kFACE,YAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PricePointFieldSet__container___drFTL",
	"field": "PricePointFieldSet__field___XlKCf",
	"overlapIcon": "PricePointFieldSet__overlapIcon___BmMxd",
	"addButton": "PricePointFieldSet__addButton___e1loR"
};
export default ___CSS_LOADER_EXPORT___;
