import '../Calendar.overrides.css';
import cx from 'classnames';
import { useState } from 'react';
import ReactCalendar from 'react-calendar';
import { Button, ButtonVariants } from '@components/button/Button';
import { Divider } from '@components/divider/Divider';
import { GuestCountPicker } from '@components/guestCountPicker/GuestCountPicker';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import styles from './DateAndGuestCountFilterPill.scss';

interface FilterPopoverProps {
  onApply: (date: Date, guestCount: number) => void;
  selectedDate: Date;
  selectedGuestCount: number;
  minimumDate?: Date;
}

export const FilterPopover = ({
  onApply,
  selectedDate,
  selectedGuestCount,
  minimumDate,
}: FilterPopoverProps) => {
  const { maxReservationGuests } = useRestaurant();

  const [date, setDate] = useState<Date>(selectedDate);
  const [guestCount, setGuestCount] = useState(selectedGuestCount);
  const handleApply = () => onApply(date, guestCount);

  return (
    <div
      className={styles.popover}
      data-testid="date-and-guest-count-filter-popover"
    >
      <GuestCountPicker
        className={styles.guestCountPicker}
        guestCount={guestCount}
        max={maxReservationGuests}
        onChange={setGuestCount}
      />
      <Divider className={styles.divider} />
      <h1 className={cx(typography.h7, styles.selectDate)}>Select Date</h1>
      <ReactCalendar
        calendarType="gregory"
        maxDetail="month"
        minDate={minimumDate}
        minDetail="month"
        next2Label={null}
        nextAriaLabel="Next Month"
        nextLabel={<Icon name="arrowLeft" />}
        onChange={(value) => value instanceof Date && setDate(value)}
        prev2Label={null}
        prevAriaLabel="Previous Month"
        prevLabel={<Icon name="arrowLeft" />}
        showNeighboringMonth={false}
        value={date}
        view="month"
      />
      <Button
        className={styles.applyButton}
        label="Apply"
        onClick={handleApply}
        variant={ButtonVariants.Primary}
      />
    </div>
  );
};
