import { getDay, parseISO } from 'date-fns';

const daysOfWeekShort = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'] as const;
const daysOfWeekMed = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
] as const;
const numberOfDaysInTheWeek = 7;

export type DaysOfWeek = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

export const INDEX_DAY_MAP: Record<DaysOfWeek, string> = {
  Sun: '0',
  Mon: '1',
  Tue: '2',
  Wed: '3',
  Thu: '4',
  Fri: '5',
  Sat: '6',
};

export const DAY_INDEX_MAP: Record<string, DaysOfWeek> = {
  '0': 'Sun',
  '1': 'Mon',
  '2': 'Tue',
  '3': 'Wed',
  '4': 'Thu',
  '5': 'Fri',
  '6': 'Sat',
};

/**
 * @param repeat e.g., ['0', '1', '2', '3', '4', '5', '6']
 *
 * @example: "Sun, Tue, Wed"
 */
export const formatWeekDays = (repeat: string[]) => {
  if (repeat.length === 0) {
    return '';
  }
  const useShort = repeat.length > 3;
  const daysOfWeek = useShort ? daysOfWeekShort : daysOfWeekMed;
  return repeat
    .toSorted()
    .map((r) => daysOfWeek[parseInt(r, 10)])
    .join(', ');
};

/**
 * @param date e.g., '2012-01-01'
 *
 * @example 1
 */
export const parseDateToWeekDay = (date: string) => getDay(parseISO(date));

/**
 * @param repeat e.g., ['0', '1', '2', '3', '4', '5', '6']
 *
 * @example 'Weekly on Sun, Mon'
 * @example 'Daily'
 */
export const formatWeekdayList = (repeat: string[]): string =>
  repeat.length === numberOfDaysInTheWeek
    ? 'Daily'
    : `Weekly on ${repeat
        .map((dayIndex) => DAY_INDEX_MAP[dayIndex])
        .join(', ')}`;
