import { api } from './api';

export interface PricingInfo {
  fee: number;
  tax: number;
  total: number;
}

export const getPricingInfo = async ({
  price,
  zipCode,
}: {
  price: number;
  zipCode: string;
}) => api.get<PricingInfo>(`/pricing-info?price=${price}&zipCode=${zipCode}`);
