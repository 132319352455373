// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CancellationPolicyNotice__container___aDHbl{padding:14px;border:1px solid var(--hairline);border-radius:8px;color:var(--white70);text-align:left}.CancellationPolicyNotice__container___aDHbl h3{margin:0;font-size:16px;font-weight:500}.CancellationPolicyNotice__container___aDHbl .CancellationPolicyNotice__text___EQ6zj{margin-top:8px;margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/shared/components/cancellationPolicyNotice/CancellationPolicyNotice.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,gCAAA,CACA,iBAAA,CACA,oBAAA,CACA,eAAA,CAEA,gDACE,QAAA,CACA,cAAA,CACA,eAAA,CAGF,qFACE,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CancellationPolicyNotice__container___aDHbl",
	"text": "CancellationPolicyNotice__text___EQ6zj"
};
export default ___CSS_LOADER_EXPORT___;
