// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CancelConfirmationModal__message___zb3a6{color:var(--white70);max-width:30em}.CancelConfirmationModal__link___lpuAI{color:inherit;font-size:1rem;font-weight:600;text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/cancelButton/CancelConfirmationModal.scss"],"names":[],"mappings":"AAAA,0CACE,oBAAA,CACA,cAAA,CAGF,uCACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "CancelConfirmationModal__message___zb3a6",
	"link": "CancelConfirmationModal__link___lpuAI"
};
export default ___CSS_LOADER_EXPORT___;
