import cx from 'classnames';
import { type KeyboardEvent, type MouseEvent } from 'react';
import {
  type Control,
  type FieldPath,
  type FieldValues,
  useController,
} from 'react-hook-form';
import { type IconNameType } from '@shared/types/floorPlans';
import typography from '~styles/typography.scss';
import styles from './ControlledFormTableIconRadio.scss';
import { DisplayIconRadioButton } from './DisplayIconRadioButton';

export interface DisplayIconFieldSetProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  control: Control<T>;
  name: Name;
  tableIconNames: IconNameType[];
  label: string;
}

export const ControlledFormTableIconRadio = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  control,
  name,
  tableIconNames,
  label,
}: DisplayIconFieldSetProps<T, Name>) => {
  const {
    field: { onChange: handleOnChangeIconName, value },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      required: true,
    },
  });

  const handleDisplayIconClick = (
    e: MouseEvent | KeyboardEvent,
    iconName: IconNameType,
  ) => {
    e.preventDefault();
    if (iconName === value) return;

    handleOnChangeIconName(iconName);
  };

  const displayIconIsInvalid = !!error && !!tableIconNames.length;

  return (
    <div className={styles.fieldContainer} data-testid="display-icon-field">
      <label
        className={cx({
          [typography.c2_20]: true,
          [styles.labelError]: !!error,
        })}
        htmlFor="table-icon-group"
        id="table-icon-label"
      >
        {label}
      </label>
      {tableIconNames.length ? (
        <div
          id="table-icon-group"
          aria-labelledby="table-icon-label"
          className={styles.displayIconButtons}
          role="radiogroup"
        >
          {tableIconNames.map((iconName, index) => (
            <DisplayIconRadioButton
              displayIconIsInvalid={displayIconIsInvalid}
              displayIconName={value}
              handleDisplayIconClick={handleDisplayIconClick}
              highlightedFloorPlanIcons={tableIconNames}
              iconName={iconName}
              index={index}
              key={iconName}
            />
          ))}
        </div>
      ) : (
        <div className={cx(typography.t2, styles.displayIconEmptyState)}>
          Select at least one table in the floor plan above to use as a display
          icon.
        </div>
      )}
    </div>
  );
};
