import { createContext, type PropsWithChildren, useMemo } from 'react';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { getServers, type Server } from './apiHelpers';

export interface ServersContextState {
  allServers: Server[];
  assignedServers: Server[];
  fetchServers: () => void;
  hasServers: boolean;
  unassignedServers: Server[];
}

const ServersContext = createContext<ServersContextState | null>(null);

export const useServersContext = () => useDefinedContext(ServersContext);

export const ServersContextProvider = ({ children }: PropsWithChildren) => {
  const { id: restaurantId } = useRestaurant();

  const { data: servers = [], rerun: fetchServers } = useAbortEffect(
    {
      effect: (signal) => getServers(restaurantId, signal),
      throwOnError: true,
    },
    [restaurantId],
  );

  const serversSortedAlphabetically = (filteredServers: Server[]): Server[] =>
    filteredServers.sort((a, b) => a.name.localeCompare(b.name));

  const assignedServers = serversSortedAlphabetically(
    servers.filter((server) => !!server.floorPlanTables.length),
  );

  const unassignedServers = serversSortedAlphabetically(
    servers.filter((server) => !server.floorPlanTables.length),
  );

  const value = useMemo(
    () => ({
      allServers: serversSortedAlphabetically(servers),
      assignedServers,
      fetchServers,
      hasServers: !!servers.length,
      unassignedServers,
    }),
    [servers],
  );

  return (
    <ServersContext.Provider value={value}>{children}</ServersContext.Provider>
  );
};
