import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Combobox } from '@components/combobox/Combobox';
import { errorToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { reportAppError } from '@shared/reportAppError';
import {
  getRestaurantTags,
  type GuestTag,
} from 'restaurantAdmin/restaurants/apiHelpers';
import { useRestaurant } from '../../../context/useRestaurant';
import { updateGuestTags } from '../../../guestBook/apiHelpers';
import styles from './GuestTagEditForm.scss';

interface GuestTagEditFormProps {
  guestId: string;
  guestTags: GuestTag[];
  onComplete: () => void;
}

export const GuestTagEditForm = ({
  guestId,
  guestTags,
  onComplete,
}: GuestTagEditFormProps) => {
  const { id: restaurantId } = useRestaurant();

  const [value, setValue] = useState<(GuestTag | string)[]>(guestTags);

  const { data: allTags = [] } = useAbortEffect(async (signal) => {
    const tags = await getRestaurantTags(restaurantId, signal);
    tags.sort((a, b) => a.title.localeCompare(b.title));
    return tags;
  }, []);

  const handleOnSave = async () => {
    const tags = value.map((val) =>
      typeof val === 'string'
        ? { id: null, title: val }
        : {
            id: val.id,
            title: val.title,
          },
    );

    try {
      await updateGuestTags({
        guestId,
        restaurantId,
        tags,
      });
      onComplete();
    } catch (e) {
      errorToast({ message: 'Failed to update guest tags' });
      reportAppError(e);
    }
  };

  return (
    <div className={styles.container}>
      <Combobox
        aria-label="Guest Tags"
        getOptionLabel={(tag) => tag.title}
        onChange={(v) => setValue(v)}
        options={allTags}
        placeholder="Select Guest Tags"
        value={value}
      />
      <div className={styles.actions}>
        <Button
          className={cx(styles.btn, styles.secondaryBtn)}
          label="Discard"
          onClick={onComplete}
          variant={ButtonVariants.DefaultWhite}
        />
        <Button
          className={styles.btn}
          label="Save"
          onClick={handleOnSave}
          variant={ButtonVariants.Primary}
        />
      </div>
    </div>
  );
};
