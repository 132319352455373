import React, { type ReactNode } from 'react';
import typography from '~styles/typography.scss';
import { Icon } from '../icon/Icon';
import styles from './ModalContent.scss';

interface ModalContentProps {
  title?: string;
  subtitle?: string;
  ariaLabel?: string;
  onClose: () => void;
  children: ReactNode;
}

export const ModalContent = React.forwardRef<HTMLDivElement, ModalContentProps>(
  ({ title, subtitle, ariaLabel, onClose, children }, ref) => {
    const ariaLabelProp = title
      ? { 'aria-labelledby': 'dialogTitle' }
      : { 'aria-label': ariaLabel };

    return (
      <div
        tabIndex={-1}
        {...ariaLabelProp}
        className={styles.modalContent}
        role="dialog"
        ref={ref}
      >
        <button
          aria-label="Close modal"
          className={styles.closeButton}
          onClick={onClose}
          type="button"
        >
          <Icon name="close" />
        </button>
        {title && (
          <h1 className={typography.d3} id="dialogTitle">
            {title}
          </h1>
        )}
        {subtitle && <p className={typography.c2}>{subtitle}</p>}
        {children}
      </div>
    );
  },
);

ModalContent.displayName = 'ModalContent';
