import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import styles from './RescueReservationConfirmationModal.scss';

export interface OnRescueReservationCallback {
  notifyGuest: boolean;
}

export interface RescueReservationConfirmationModalProps {
  onRescueReservation: ({
    notifyGuest,
  }: OnRescueReservationCallback) => Promise<void>;
  closeModal: () => void;
  isOpen: boolean;
}

export const RescueReservationConfirmationModal = ({
  isOpen,
  onRescueReservation,
  closeModal,
}: RescueReservationConfirmationModalProps) => {
  const [notifyGuest, setNotifyGuest] = useState(true);
  const handleRescueReservation = async (): Promise<void> => {
    closeModal();
    await onRescueReservation({ notifyGuest });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Rescue Reservation?"
      subtitle="Are you sure? This may change the reservation time and the guest will
          be notified of the changes."
    >
      <label className={styles.checkbox} htmlFor="notifyGuest">
        <input
          checked={notifyGuest}
          id="notifyGuest"
          onChange={(e) => {
            setNotifyGuest(e.target.checked);
          }}
          type="checkbox"
        />
        Send Notifications to Guest about Reservation Time Change
      </label>
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Confirm"
          onClick={handleRescueReservation}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
