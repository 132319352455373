import {
  addMinutes,
  differenceInMilliseconds,
  intervalToDuration,
  minutesToMilliseconds,
  secondsToMilliseconds,
} from 'date-fns';
import { clamp } from 'lodash-es';
import { useEffect, useState } from 'react';
import typography from '~styles/typography.scss';
import type { WaitListEntry } from './apiHelpers';
import styles from './WaitListTimer.scss';

interface WaitListTimerProps {
  waitListEntry: WaitListEntry;
}

function formatWaitDuration(now: Date, waitEnd: Date) {
  const duration = intervalToDuration({
    start: waitEnd,
    end: now,
  });

  const sign = now > waitEnd && (duration.hours || duration.minutes) ? '-' : '';
  const hours = String(Math.abs(duration.hours || 0)).padStart(1, '0');
  const minutes = String(Math.abs(duration.minutes || 0)).padStart(2, '0');

  return `${sign}${hours}:${minutes}`;
}

// at this many minutes or more remaining, the bar will be green
const REMAINING_MINUTES_GREEN = 15;
const GREEN_HUE_DEG = 120;

export function calculateBarHue(now: Date, waitEnd: Date) {
  const remainingMs = differenceInMilliseconds(waitEnd, now);
  const fraction = clamp(
    remainingMs / minutesToMilliseconds(REMAINING_MINUTES_GREEN),
    0,
    1,
  );
  return Math.floor(fraction * GREEN_HUE_DEG);
}

function waitBarWidth(now: Date, waitEnd: Date, estimatedWait: number) {
  const remainingMs = differenceInMilliseconds(waitEnd, now);
  const fraction = clamp(
    remainingMs / minutesToMilliseconds(estimatedWait),
    0,
    1,
  );
  if (fraction === 0) {
    return '100%';
  }
  return `${Math.floor(fraction * 100)}%`;
}

export const WaitListTimer = ({ waitListEntry }: WaitListTimerProps) => {
  const [now, setNow] = useState(() => new Date());

  useEffect(() => {
    const interval = setInterval(
      () => setNow(new Date()),
      secondsToMilliseconds(30),
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  const waitEnd = addMinutes(
    new Date(waitListEntry.createdAt),
    waitListEntry.estimatedWait,
  );
  const waitDuration = formatWaitDuration(now, waitEnd);
  const barHue = calculateBarHue(now, waitEnd);

  return (
    <div className={styles.waitTimer}>
      <div className={typography.h7}>{waitDuration}</div>
      <div className={styles.waitBar}>
        <hr
          className={styles.waitBarColor}
          style={{
            borderColor: `hsl(${barHue}deg 100% 40%)`,
            width: waitBarWidth(now, waitEnd, waitListEntry.estimatedWait),
          }}
        />
      </div>
    </div>
  );
};
