import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

export interface MaxGuestCountConflictModalProps {
  closeModal: () => void;
  handleConfirm: () => void;
  isOpen: boolean;
}

export const MaxGuestCountConflictModal = ({
  closeModal,
  handleConfirm,
  isOpen,
}: MaxGuestCountConflictModalProps) => (
  <Modal
    isOpen={isOpen}
    onClose={closeModal}
    title="Maximum Guest Count Conflict"
    subtitle="At least one published listing has a maximum guest count higher than the value you are setting. Saving this change will affect the availability displayed to guests."
  >
    <ModalActions>
      <Button
        label="Cancel"
        onClick={closeModal}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Continue"
        onClick={() => {
          handleConfirm();
          closeModal();
        }}
        variant={ButtonVariants.Primary}
      />
    </ModalActions>
  </Modal>
);
