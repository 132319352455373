import cx from 'classnames';
import { groupBy } from 'lodash-es';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import {
  toISODateFormat,
  toWeekdayMonthDateAndYear,
} from '@shared/utils/dateFormatters';
import { pluralizer } from '@utils/pluralizer';
import { ISOTimeTo12HourTime, longAssLocalTime } from '@utils/time';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import typography from '~styles/typography.scss';
import { getRestaurantsReservationsReport } from '../apiHelpers';
import styles from './DailyReservationsReportPage.scss';
import { ReservationReportItem } from './ReservationReportItem';

export const DailyReservationsReportPage = () => {
  const [searchParams] = useSearchParams();
  const { id: restaurantId } = useRestaurant();

  const date = searchParams.get('date') || toISODateFormat(new Date());

  const { data: report } = useAbortEffect(
    {
      effect: (signal) =>
        getRestaurantsReservationsReport(restaurantId, date, signal),
      throwOnError: true,
    },
    [],
  );

  useEffect(() => {
    if (report) {
      window.print();
    }
  }, [report]);

  if (!report) return null;

  const reservationsGroupedByTime = groupBy(report.reservations, 'time');

  const covers = report.reservations.reduce(
    (accumulator, reservation) => accumulator + reservation.guestCount,
    0,
  );

  const timeStamp = longAssLocalTime(new Date());

  return (
    <div className={styles.container}>
      <h1 className={typography.d2}>{report.name}</h1>
      <p className={typography.h2}>
        {toWeekdayMonthDateAndYear(new Date(`${date}T12:00:00`))}
      </p>
      <p
        className={cx(typography.c3, styles.timeStamp)}
      >{`Report created ${timeStamp}`}</p>
      <div className={styles.totals}>
        <p className={typography.h4}>
          {pluralizer('Reservation', report.reservations.length)}
        </p>
        <p className={typography.h4}>{pluralizer('Cover', covers)}</p>
      </div>
      <ul className={styles.reservationsList}>
        {Object.keys(reservationsGroupedByTime).map((time) => {
          const timeGroupId = `time-group-${time}`;

          return (
            <li aria-labelledby={timeGroupId} key={`time-divider-${time}`}>
              <h2 className={typography.h4} id={timeGroupId}>
                {ISOTimeTo12HourTime(time)}
              </h2>
              <ul
                aria-label={`reservations starting at ${ISOTimeTo12HourTime(time)}`}
              >
                {reservationsGroupedByTime[time].map((reservation) => (
                  <ReservationReportItem
                    key={JSON.stringify(reservation)}
                    {...reservation}
                  />
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
