// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WeekSelector__main___bobjx{align-items:center;display:flex}.WeekSelector__buttonToday___ZsZx0{margin-right:16px;padding:0;width:64px}.WeekSelector__button___WiEfg{background-color:rgba(0,0,0,0);border-radius:4px;border:none;height:40px;width:40px}.WeekSelector__button___WiEfg:focus svg,.WeekSelector__button___WiEfg:hover svg{stroke:var(--gold);stroke-width:2}.WeekSelector__button___WiEfg:active{background-color:var(--gold)}.WeekSelector__button___WiEfg:active svg{stroke:var(--darkBlue);stroke-width:2}.WeekSelector__arrowRightIcon___cyi4i{transform:rotate(180deg)}.WeekSelector__text___oWcal{min-width:14em;text-align:center}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/weekSelector/WeekSelector.scss"],"names":[],"mappings":"AAAA,4BACE,kBAAA,CACA,YAAA,CAGF,mCACE,iBAAA,CACA,SAAA,CACA,UAAA,CAGF,8BACE,8BAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA,CACA,UAAA,CAIE,gFACE,kBAAA,CACA,cAAA,CAIJ,qCACE,4BAAA,CAEA,yCACE,sBAAA,CACA,cAAA,CAKN,sCACE,wBAAA,CAGF,4BACE,cAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "WeekSelector__main___bobjx",
	"buttonToday": "WeekSelector__buttonToday___ZsZx0",
	"button": "WeekSelector__button___WiEfg",
	"arrowRightIcon": "WeekSelector__arrowRightIcon___cyi4i",
	"text": "WeekSelector__text___oWcal"
};
export default ___CSS_LOADER_EXPORT___;
