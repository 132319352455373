import cx from 'classnames';
import { secondsToMilliseconds } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { refund } from '../../reservations/apiHelpers';
import styles from './RefundConfirmationModal.scss';

export interface RefundConfirmationModalProps {
  handleAfterConfirm?: () => void;
  isOpen: boolean;
  reservationId: string;
  closeModal: () => void;
}

const CURRENT_PAGE = 0;
const SUCCESS_MESSAGE =
  "Success: The guest's reservation has been successfully refunded";

export const RefundConfirmationModal = ({
  handleAfterConfirm,
  isOpen,
  reservationId,
  closeModal,
}: RefundConfirmationModalProps) => {
  const { id: restaurantId } = useRestaurant();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const refreshPage = () =>
    setTimeout(() => navigate(CURRENT_PAGE), secondsToMilliseconds(3));

  const handleRefund = () => {
    setIsLoading(true);

    void (async () => {
      try {
        await refund(restaurantId, reservationId);
        successToast({ message: SUCCESS_MESSAGE });
      } catch (error) {
        if (error instanceof ApiError) {
          errorToast({
            message: `Failed to refund reservation: ${error.message}`,
          });
        } else {
          errorToast({
            message: 'Failed to refund reservation. Please try again.',
          });
        }
      }
      if (handleAfterConfirm) {
        handleAfterConfirm();
      } else {
        refreshPage();
      }
    })();
  };
  return (
    <Modal
      ariaLabel="Refund"
      isOpen={isOpen}
      onClose={closeModal}
      disablePortal
    >
      <p className={(cx(typography.c2), styles.message)}>
        Are you sure you would like to refund this reservation? Please reference
        the Peak <ExternalRefundLink className={styles.link} /> for any concerns
        regarding refunds.
      </p>
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isLoading}
          label="Confirm"
          onClick={handleRefund}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
