import cx from 'classnames';
import { forwardRef, type ReactNode } from 'react';
import { getInitials } from '@shared/utils/formatName';
import typography from '~styles/typography.scss';
import styles from './Avatar.scss';

interface AvatarProps {
  children?: ReactNode;
  className?: string;
  firstName: string;
  lastName: string;
  testId?: string;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ children, className, firstName, lastName, testId, ...rest }, ref) => (
    <div
      data-testid={testId}
      className={cx(typography.h7, styles.initials, className)}
      ref={ref}
      {...rest}
    >
      {children || getInitials(firstName, lastName)}
    </div>
  ),
);

Avatar.displayName = 'Avatar';
