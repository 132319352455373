import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import cx from 'classnames';
import { useController } from 'react-hook-form';
import type {
  Control,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { FormTooltip } from '@components/formInputs/FormTooltip';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { ValidationErrorMessage } from '@components/formInputs/ValidationErrorMessage';
import typography from '~styles/typography.scss';
import styles from './ControlledFormTextareaAutosize.scss';

export interface ControlledFormTextareaAutosizeProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  disabled?: boolean;
  label: string;
  name: Name;
  placeholder?: string;
  rules?: UseControllerProps<T, Name>['rules'];
  tooltipText?: string;
  variant?: LayoutVariant;
  minRows?: number;
  maxRows?: number;
}

export const ControlledFormTextareaAutosize = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  disabled,
  label,
  name,
  placeholder,
  rules,
  tooltipText,
  variant = LayoutVariant.Vertical,
  minRows = 2,
  maxRows = 6,
}: ControlledFormTextareaAutosizeProps<T, Name>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  return (
    <div
      className={cx(
        {
          [styles.fieldContainer]: true,
          [styles[variant]]: true,
        },
        className,
      )}
    >
      <div className={styles.labelContainer}>
        <label
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: !!error,
          })}
          htmlFor={name}
        >
          {label}
        </label>
        <FormTooltip label={name} text={tooltipText} />
      </div>
      <div className={styles.inputContainer}>
        <TextareaAutosize
          aria-errormessage={`${name}-error`}
          aria-invalid={!!error}
          className={cx({
            [typography.t1]: true,
            [styles.inputError]: !!error,
          })}
          disabled={disabled}
          id={name}
          onChange={onChange}
          placeholder={placeholder}
          maxRows={maxRows}
          minRows={minRows}
          value={value || ''}
        />
        <ValidationErrorMessage error={error} label={label} name={name} />
      </div>
    </div>
  );
};
