import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { cancelWaitListEntry, type WaitListEntry } from './apiHelpers';

export interface RemoveFromWaitListConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
  onCanceled: () => void;
  waitListEntry: WaitListEntry;
}

export const RemoveFromWaitListConfirmationModal = ({
  closeModal,
  onCanceled,
  waitListEntry,
  isOpen,
}: RemoveFromWaitListConfirmationModalProps) => {
  if (!isOpen) return null;

  const onClickConfirmRemove = async () => {
    closeModal();
    try {
      await cancelWaitListEntry({
        restaurantId: waitListEntry.restaurantId,
        waitListEntryId: waitListEntry.id,
      });
      onCanceled();
      successToast({ message: 'Waitlist entry successfully canceled' });
    } catch (e) {
      errorToast({ message: 'Failed to remove waitlist entry' });
      reportAppError(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you would like to remove this guest from the waitlist?"
      title="Confirm Removal"
    >
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Remove"
          onClick={onClickConfirmRemove}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
