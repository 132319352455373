import { debounce } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { type GuestsApiSearch, guestSearch } from './apiHelpers';

const MINIMUM_SEARCH_CHARS = 3;
const DEBOUNCE_SEARCH_MS = 2000;
export const useGuestSearch = () => {
  const [guests, setGuests] = useState<GuestsApiSearch[]>([]);
  const [loading, setLoading] = useState(false);
  const { id: restaurantId } = useRestaurant();

  const clearGuests = () => setGuests([]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchText: string) => {
        try {
          const response = await guestSearch({
            restaurantId,
            query: searchText,
          });
          setGuests(response);
        } finally {
          setLoading(false);
        }
      }, DEBOUNCE_SEARCH_MS),
    [],
  );

  const searchGuests = (searchText: string) => {
    if (searchText.length < MINIMUM_SEARCH_CHARS) {
      clearGuests();
      return;
    }
    setLoading(true);
    void debouncedSearch(searchText);
  };

  return { guests, searchGuests, loading };
};
