import cx from 'classnames';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import CloseIcon from '@assets/icons/close.svg';
import { Card } from '@components/card/Card';
import typography from '~styles/typography.scss';
import { type OccupantPositionValues } from '../state/ReservationServiceContext';
import styles from './OccupantSheetWrapper.scss';

export interface OccupantSheetProps {
  children: React.ReactNode;
  className?: string;
  occupantPositionValues: OccupantPositionValues;
  onClickNextOccupant: () => void;
  onClickPreviousOccupant: () => void;
  onClose: () => void;
  shouldShowCarousel: boolean;
}

export const OccupantSheetWrapper = ({
  children,
  className,
  occupantPositionValues: { count: occupantsCount, position: occupantPosition },
  onClickNextOccupant,
  onClickPreviousOccupant,
  onClose,
  shouldShowCarousel,
}: OccupantSheetProps) => (
  <Card
    bordered
    aria-label="Occupant Sheet"
    className={cx(styles.container, className)}
  >
    <div className={styles.toolBar}>
      {shouldShowCarousel && (
        <div>
          <button
            aria-label="previous occupant"
            disabled={occupantPosition === 1}
            onClick={onClickPreviousOccupant}
          >
            <ArrowLeft className={styles.icon} />
          </button>
          <span className={cx(styles.occupantPosition, typography.h7)}>
            {`Occupant ${occupantPosition} of ${occupantsCount}`}
          </span>
          <button
            aria-label="next occupant"
            disabled={occupantPosition === occupantsCount}
            onClick={onClickNextOccupant}
          >
            <ArrowLeft className={cx(styles.icon, styles.arrowRightIcon)} />
          </button>
        </div>
      )}
      <button
        aria-label="close sheet"
        className={styles.button}
        onClick={onClose}
      >
        <CloseIcon className={styles.icon} />
      </button>
    </div>
    {children}
  </Card>
);
