import { Modal as MUIModal } from '@mui/base/Modal';
import cx from 'classnames';
import { createRef, type ReactElement } from 'react';
import { Backdrop } from './Backdrop';
import styles from './Modal.scss';

export interface ModalContainerProps {
  children: ReactElement;
  disablePortal?: boolean;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalContainer = ({
  children,
  disablePortal,
  className,
  isOpen,
  onClose,
}: ModalContainerProps) => {
  if (!isOpen) return null;

  if (disablePortal) {
    const containerRef = createRef<HTMLDivElement>();
    return (
      <div data-testid="click-on-outside-shield" ref={containerRef}>
        <MUIModal
          container={() => containerRef.current}
          disablePortal
          className={cx(className, styles.modal)}
          onClose={onClose}
          open={isOpen}
          role="main"
          slotProps={{
            backdrop: { className: styles.backdrop },
            root: { onClick: (event) => event.stopPropagation() },
          }}
          slots={{ backdrop: Backdrop }}
        >
          {children}
        </MUIModal>
      </div>
    );
  }

  return (
    <MUIModal
      className={cx(className, styles.modal)}
      onClose={onClose}
      open={isOpen}
      role="main"
      slotProps={{
        backdrop: { className: styles.backdrop },
        root: { onClick: (event) => event.stopPropagation() },
      }}
      slots={{ backdrop: Backdrop }}
    >
      {children}
    </MUIModal>
  );
};
