import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { getFullName } from '@utils/formatName';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import type {
  ReservationsApiGuest,
  ServiceReservation,
} from 'restaurantAdmin/reservations/service/apiHelpers';
import { ServiceStatusSelect } from 'restaurantAdmin/reservations/service/serviceStatus/ServiceStatusSelect';
import { useReservationServiceContext } from 'restaurantAdmin/reservations/service/state/ReservationServiceContext';
import typography from '~styles/typography.scss';
import styles from './ReservationGuestSheetHeader.scss';

export interface ReservationGuestSheetHeaderProps {
  guest: ReservationsApiGuest;
  reservation: ServiceReservation;
}

export const ReservationGuestSheetHeader = ({
  guest: { firstName, lastName },
  reservation: {
    id,
    guestCount,
    listingName,
    seatedTableName,
    serviceStatus,
    time,
    transactionDetails: {
      createdBy: { fullName: conciergeName },
    },
  },
}: ReservationGuestSheetHeaderProps) => {
  const { refreshFloorPlan, refreshOccupants } = useReservationServiceContext();
  const guestFullName = getFullName(firstName, lastName);
  const guestCountText = appendGuestCountLabel(guestCount);

  return (
    <header className={styles.container}>
      <ServiceStatusSelect
        occupantType="reservation"
        occupantId={id}
        onUpdated={() => {
          refreshFloorPlan();
          refreshOccupants();
        }}
        showLabel
        value={serviceStatus}
      />
      <div className={styles.guestInfo}>
        <div className={styles.infoRow}>
          <h2 className={cx(typography.h6, styles.guestName)}>
            {guestFullName}
          </h2>
          <span className={cx(typography.h7_20, styles.time)}>
            {ISOTimeTo12HourTime(time)}
          </span>
        </div>
        <div className={styles.infoRow}>
          <span className={cx(typography.t1, styles.guestCount)}>
            {guestCountText}
          </span>
          <div className={styles.listingAndTableNames}>
            <span className={cx(typography.c3, styles.listingName)}>
              {listingName}
            </span>
            {seatedTableName && (
              <span className={typography.h7}>table {seatedTableName}</span>
            )}
          </div>
        </div>
        {conciergeName && (
          <div className={styles.concierge}>
            <Icon name="roomService" />
            <span className={typography.t2}>Booked by {conciergeName}</span>
          </div>
        )}
      </div>
    </header>
  );
};
