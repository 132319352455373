import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { waiveCancellationFee } from 'restaurantAdmin/reservations/apiHelpers';
import { useOccupantContext } from '../state/OccupantContext';

export interface WaiveFeeConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const WaiveFeeConfirmationModal = ({
  isOpen,
  closeModal,
}: WaiveFeeConfirmationModalProps) => {
  const { selectedOccupant, refreshOccupants } = useOccupantContext();
  const restaurant = useRestaurant();
  const [isWaivingFeePending, setIsWaivingFeePending] = useState(false);

  if (!selectedOccupant) return null;

  const handleConfirm = () => {
    setIsWaivingFeePending(true);
    void (async () => {
      try {
        await waiveCancellationFee(restaurant.id, selectedOccupant.id);
        successToast({
          message:
            "Success: The guest's reservation cancellation fee has been waived.",
        });
      } catch (error) {
        if (error instanceof Error) {
          errorToast({
            message: `Failed to waive cancellation fee: ${error.message}`,
          });
        } else {
          errorToast({
            message: 'Failed to waive cancellation fee. Please try again.',
          });
        }
        reportAppError(error);
      }
      closeModal();
      refreshOccupants();
    })();
  };

  return (
    <Modal
      ariaLabel="Confirm Waiving Cancellation Fee"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you would like to waive the cancellation fee for this
        reservation?"
    >
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Confirm"
          onClick={handleConfirm}
          isDisabled={isWaivingFeePending}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
