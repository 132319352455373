import cx from 'classnames';
import { CheckoutReceiptDetails } from '@components/checkout/CheckoutReceiptDetails';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import linkStyles from '~styles/links.scss';
import styles from './ConciergeReceipt.scss';

interface ConciergeReceiptProps {
  fee: number;
  price: number;
  priceLabel: string;
  tax: number;
  total: number;
}

export const ConciergeReceipt = ({
  fee,
  price,
  priceLabel,
  tax,
  total,
}: ConciergeReceiptProps) => (
  <>
    <CheckoutReceiptDetails
      fee={fee}
      priceLabel={priceLabel}
      price={price}
      tax={tax}
      total={total}
    />
    <ExternalRefundLink
      className={cx(linkStyles.linkPrimary, styles.row)}
      label="Refund Policy"
    />
  </>
);
