// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantSettingsSection__card___XdGCR{background-color:var(--primary300);padding:2rem}.RestaurantSettingsSection__card___XdGCR header{display:flex;gap:1rem;margin-bottom:2rem;justify-content:space-between}.RestaurantSettingsSection__card___XdGCR header h2{color:var(--white)}.RestaurantSettingsSection__card___XdGCR header button{background:none;color:var(--gold);border:none;cursor:pointer}.RestaurantSettingsSection__card___XdGCR h3{margin-bottom:.5rem}.RestaurantSettingsSection__card___XdGCR h3+div{margin-bottom:2rem;color:var(--primary100)}.RestaurantSettingsSection__card___XdGCR h3+div:last-child{margin-bottom:0}.RestaurantSettingsSection__card___XdGCR p{color:var(--white70);margin:0 0 1rem}.RestaurantSettingsSection__card___XdGCR p:last-of-type{margin-bottom:2rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/shared/RestaurantSettingsSection.scss"],"names":[],"mappings":"AAAA,yCACE,kCAAA,CACA,YAAA,CAEA,gDACE,YAAA,CACA,QAAA,CACA,kBAAA,CACA,6BAAA,CAEA,mDACE,kBAAA,CAGF,uDACE,eAAA,CACA,iBAAA,CACA,WAAA,CACA,cAAA,CAIJ,4CACE,mBAAA,CAEA,gDACE,kBAAA,CACA,uBAAA,CAGF,2DACE,eAAA,CAIJ,2CACE,oBAAA,CACA,eAAA,CAEA,wDACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "RestaurantSettingsSection__card___XdGCR"
};
export default ___CSS_LOADER_EXPORT___;
