import { offset } from '@floating-ui/react';
import { Dropdown, Menu, MenuButton } from '@mui/base';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  type ButtonProps,
  ButtonVariants,
} from '@components/button/Button';
import { MenuItem } from '@components/menu/MenuItem';
import { MenuListbox } from '@components/menu/MenuListbox';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { noShow } from 'restaurantAdmin/reservations/apiHelpers';
import { type ServiceReservation } from 'restaurantAdmin/reservations/service/apiHelpers';
import { useReservationServiceContext } from 'restaurantAdmin/reservations/service/state/ReservationServiceContext';
import {
  type EligibleCancellationFeeResponse,
  eligibleForCancellationFee,
} from '../cancelButton/apiHelpers';
import { RefundConfirmationModal } from '../refundButton/RefundConfirmationModal';
import { CancelAndCollectFeeConfirmationModal } from '../remediationForm/CancelAndCollectFeeConfirmationModal';
import { CancelAndWaiveFeeConfirmationModal } from '../remediationForm/CancelAndWaiveFeeConfirmationModal';
import { CancelConfirmationModal } from '../remediationForm/CancelConfirmationModal';
import { NoShowConfirmationModal } from './NoShowConfirmationModal';
import { UpdateGuestCountModal } from './UpdateGuestCountModal';

declare module '@mui/base/MenuButton' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface MenuButtonRootSlotPropsOverrides
    extends Pick<ButtonProps, 'label' | 'variant'> {}
}

interface ReservationOptionsProps {
  reservation: ServiceReservation;
}
export const ReservationOptions = ({
  reservation,
}: ReservationOptionsProps) => {
  const restaurant = useRestaurant();
  const { refreshOccupants, closeSidePanelSheet } =
    useReservationServiceContext();

  const menuRef = useRef<HTMLElement>(null);
  const refCurrent = () => menuRef.current!;
  const [menuWidth, setMenuWidth] = useState(1);

  useEffect(() => {
    if (menuRef.current) {
      const { width } = menuRef.current.getBoundingClientRect();
      setMenuWidth(width);
    }
  }, [menuRef.current]);

  const [cancelFeeData, setCancelFeeData] =
    useState<EligibleCancellationFeeResponse>({
      isEligibleForCancellationFeeUponCancellation: false,
      eligibleForCancellationFeeUntil: '',
      cancellationPolicy: null,
    });

  const getCancelFeeOptions = async () => {
    try {
      const response = await eligibleForCancellationFee(
        restaurant.id,
        reservation.id,
      );

      setCancelFeeData(response);
    } catch (error) {
      reportAppError(error);
    }
  };

  useEffect(() => {
    void getCancelFeeOptions();
  }, [reservation]);

  const {
    isOpen: isUpdateGuestCountModalOpen,
    open: openUpdateGuestCountModal,
    close: closeUpdateGuestCountModal,
  } = useIsOpen();
  const {
    isOpen: isNoShowConfirmationModalOpen,
    open: openNoShowConfirmationModal,
    close: closeNoShowConfirmationModal,
  } = useIsOpen();
  const {
    isOpen: isCancelConfirmationModalOpen,
    open: openCancelConfirmationModal,
    close: closeCancelConfirmationModal,
  } = useIsOpen();
  const {
    isOpen: isCancelAndCollectFeeConfirmationModalOpen,
    open: openCancelAndCollectFeeConfirmationModal,
    close: closeCancelAndCollectFeeConfirmationModal,
  } = useIsOpen();
  const {
    isOpen: isCancelAndWaiveFeeConfirmationModalOpen,
    open: openCancelAndWaiveFeeConfirmationModal,
    close: closeCancelAndWaiveFeeConfirmationModal,
  } = useIsOpen();
  const {
    isOpen: isRefundConfirmationModalOpen,
    open: openRefundConfirmationModal,
    close: closeRefundConfirmationModal,
  } = useIsOpen();

  const handleAfterCancelConfirm = () => {
    closeSidePanelSheet();
    closeCancelConfirmationModal();
    refreshOccupants();
  };
  const handleAfterCollectConfirm = () => {
    refreshOccupants();
    closeSidePanelSheet();
    closeCancelAndCollectFeeConfirmationModal();
  };
  const handleAfterWaiveConfirm = () => {
    refreshOccupants();
    closeSidePanelSheet();
    closeCancelAndWaiveFeeConfirmationModal();
  };
  const handleAfterRefundConfirm = () => {
    refreshOccupants();
    closeRefundConfirmationModal();
  };

  const handleOnClickNoShow = async () => {
    try {
      await noShow({
        restaurantId: restaurant.id,
        reservationId: reservation.id,
      });

      refreshOccupants();
      successToast({
        message: 'Success: The reservation has been flagged as a no show',
      });
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message: 'Failed to flag reservation as no show. Please try again.',
        });
      }
      reportAppError(error);
    }
    closeSidePanelSheet();
  };

  const { refundAmount, price } = reservation.transactionDetails;
  const isRefunded = Boolean(refundAmount);

  return (
    <>
      <Dropdown>
        <MenuButton
          ref={menuRef}
          slots={{ root: Button }}
          slotProps={{
            root: { label: 'Options', variant: ButtonVariants.Tertiary },
          }}
        />
        <Menu
          anchor={refCurrent}
          style={{ width: menuWidth }}
          slots={{ listbox: MenuListbox }}
          slotProps={{ root: { middleware: [offset(8)], placement: 'bottom' } }}
        >
          <MenuItem onClick={openUpdateGuestCountModal}>
            Edit Guest Count
          </MenuItem>
          <MenuItem onClick={openNoShowConfirmationModal}>No Show</MenuItem>
          <MenuItem onClick={openCancelConfirmationModal}>Cancel</MenuItem>
          {cancelFeeData.isEligibleForCancellationFeeUponCancellation && (
            <>
              <MenuItem onClick={openCancelAndCollectFeeConfirmationModal}>
                Cancel & Collect Fee
              </MenuItem>
              <MenuItem onClick={openCancelAndWaiveFeeConfirmationModal}>
                Cancel & Waive Fee
              </MenuItem>
            </>
          )}
          {!isRefunded && price > 0 && (
            <MenuItem onClick={openRefundConfirmationModal}>Refund</MenuItem>
          )}
        </Menu>
      </Dropdown>
      <UpdateGuestCountModal
        closeModal={closeUpdateGuestCountModal}
        isOpen={isUpdateGuestCountModalOpen}
        reservation={reservation}
      />
      <NoShowConfirmationModal
        isOpen={isNoShowConfirmationModalOpen}
        closeModal={closeNoShowConfirmationModal}
        handleOnClickNoShow={() => handleOnClickNoShow()}
      />
      <CancelConfirmationModal
        cancellationPolicy={cancelFeeData.cancellationPolicy}
        closeModal={closeCancelConfirmationModal}
        eligibleForCancellationFeeUntil={
          cancelFeeData.eligibleForCancellationFeeUntil
        }
        handleAfterConfirm={handleAfterCancelConfirm}
        isEligibleForCancellationFeeUponCancellation={
          cancelFeeData.isEligibleForCancellationFeeUponCancellation
        }
        isOpen={isCancelConfirmationModalOpen}
        reservationId={reservation.id}
      />
      <CancelAndCollectFeeConfirmationModal
        cancellationPolicy={cancelFeeData.cancellationPolicy!}
        closeModal={closeCancelAndCollectFeeConfirmationModal}
        handleAfterConfirm={handleAfterCollectConfirm}
        isOpen={isCancelAndCollectFeeConfirmationModalOpen}
        reservationId={reservation.id}
      />
      <CancelAndWaiveFeeConfirmationModal
        cancellationPolicy={cancelFeeData.cancellationPolicy!}
        closeModal={closeCancelAndWaiveFeeConfirmationModal}
        handleAfterConfirm={handleAfterWaiveConfirm}
        isOpen={isCancelAndWaiveFeeConfirmationModalOpen}
        reservationId={reservation.id}
      />
      <RefundConfirmationModal
        closeModal={closeCancelConfirmationModal}
        handleAfterConfirm={handleAfterRefundConfirm}
        isOpen={isRefundConfirmationModalOpen}
        reservationId={reservation.id}
      />
    </>
  );
};
