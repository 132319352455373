import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

interface ConfirmRemoveModalProps {
  closeModal: () => void;
  handleOnConfirmRemove: () => void;
  isOpen: boolean;
}

export const ConfirmRemoveModal = ({
  closeModal,
  handleOnConfirmRemove,
  isOpen,
}: ConfirmRemoveModalProps) => (
  <Modal isOpen={isOpen} onClose={closeModal} title="Remove Service Window">
    <ModalActions>
      <Button
        label="Cancel"
        onClick={closeModal}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Remove"
        onClick={handleOnConfirmRemove}
        variant={ButtonVariants.Error}
      />
    </ModalActions>
  </Modal>
);
