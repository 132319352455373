import { Controller, useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { GuestCountPicker } from '@components/guestCountPicker/GuestCountPicker';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { updateGuestCount } from 'restaurantAdmin/reservations/apiHelpers';
import { type ServiceReservation } from 'restaurantAdmin/reservations/service/apiHelpers';
import { useReservationServiceContext } from 'restaurantAdmin/reservations/service/state/ReservationServiceContext';
import styles from './UpdateGuestCountModal.scss';

export interface UpdateGuestCountModalProps {
  closeModal: () => void;
  isOpen: boolean;
  reservation: ServiceReservation;
}
export interface UpdateGuestFormData {
  guestCount: number;
}

export const UpdateGuestCountModal = ({
  closeModal,
  isOpen,
  reservation,
}: UpdateGuestCountModalProps) => {
  const { id: restaurantId, maxReservationGuests } = useRestaurant();
  const { refreshOccupants } = useReservationServiceContext();

  const { control, handleSubmit } = useForm<UpdateGuestFormData>({
    defaultValues: {
      guestCount: reservation.guestCount,
    },
  });

  const handleOnSubmit = (data: UpdateGuestFormData) => {
    void (async () => {
      try {
        await updateGuestCount({
          guestCount: data.guestCount,
          reservationId: reservation.id,
          restaurantId,
        });
        successToast({
          message:
            "Success: The guest's reservation guest count has been successfully updated.",
        });
        refreshOccupants();
      } catch (error) {
        if (error instanceof ApiError) {
          errorToast({
            message: `Failed to update guest count: ${error.message}`,
          });
        } else {
          errorToast({
            message: 'Failed to update guest count. Please try again.',
          });
        }

        reportAppError(error);
      }
    })();
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} title="Update Guest Count">
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className={styles.formInput}>
          <Controller
            control={control}
            name="guestCount"
            render={({ field: { onChange, value } }) => (
              <GuestCountPicker
                className={styles.guestCount}
                guestCount={value}
                max={maxReservationGuests}
                onChange={onChange}
              />
            )}
          />
        </div>
        <ModalActions>
          <Button
            label="Cancel"
            onClick={closeModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            label="Save Count"
            type="submit"
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </form>
    </Modal>
  );
};
