import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import cx from 'classnames';
import { Avatar } from '@components/avatar/Avatar';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { useAuth } from 'restaurantAdmin/context/AuthContext';
import typography from '~styles/typography.scss';
import { SignOutModal } from '../auth/SignOutModal';
import styles from './AvatarDropdown.scss';

export const AvatarDropdown = () => {
  const { adminName } = useAuth();
  const {
    isOpen: isSignOutModalOpen,
    close: closeSignOutModal,
    open: openSignOutModal,
  } = useIsOpen();

  const names = adminName?.split(' ') || [];
  const firstName = names[0];
  const lastName = names.length > 1 ? names[names.length - 1] : '';

  return (
    <Dropdown>
      <MenuButton className={styles.menuButton}>
        <Avatar firstName={firstName} lastName={lastName} />
      </MenuButton>
      <Menu
        className={styles.menu}
        slotProps={{ root: { keepMounted: false } }}
      >
        <MenuItem className={styles.menuItem}>
          <button
            className={cx(typography.t1, styles.signOutButton)}
            onClick={openSignOutModal}
          >
            Sign Out
          </button>
        </MenuItem>
      </Menu>
      <SignOutModal
        closeModal={closeSignOutModal}
        isOpen={isSignOutModalOpen}
      />
    </Dropdown>
  );
};
