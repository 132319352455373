import cx from 'classnames';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './StatusTag.scss';

export const enum Status {
  Canceled = 'canceled',
  NoShow = 'no-show',
  HostBooked = 'host booked',
  Stranded = 'stranded',
  Overtime = 'overtime',
  Upcoming = 'upcoming',
}

const StatusToIconMap: Record<Status, AnyIcon> = {
  [Status.Canceled]: 'xCircle',
  [Status.NoShow]: 'accountCircleOff',
  [Status.HostBooked]: 'roomService',
  [Status.Stranded]: 'alertTriangle',
  [Status.Overtime]: 'clock',
  [Status.Upcoming]: 'hourglass',
};

interface StatusTagProps {
  status: Status;
}

export const StatusTag = ({ status }: StatusTagProps) => {
  const containerClassNames = cx({
    [styles.canceledTag]: status === Status.Canceled,
    [styles.noShowTag]: status === Status.NoShow,
    [styles.hostBookedTag]: status === Status.HostBooked,
    [styles.strandedTag]: status === Status.Stranded,
    [styles.overtimeTag]: status === Status.Overtime,
    [styles.upcomingTag]: status === Status.Upcoming,
  });

  return (
    <div className={containerClassNames}>
      <Icon name={StatusToIconMap[status]} />
      <span className={typography.t3}>&nbsp;{status}</span>
    </div>
  );
};
